
@use "client/css/globals" as *;

/**
 * To maintain the desired layout and alignment, the `.avatar-icon` grid area spans two rows.
 * This ensures proper positioning with the header elements while adhering to the grid structure.
 *
 * Note: According to the CSS Grid specification, named grid areas must form a rectangular shape.
 * Therefore, creating an empty area or hole within the grid is not possible without disrupting the layout.
 * While we could have added an empty element to visually represent the empty space, we chose to avoid unnecessary markup.
 */

.post-container {
    display: grid;
    gap: 0;
    grid-template-areas:
        "avatar header"
        "avatar post";
    grid-template-columns: 35px 1fr;
    grid-template-rows: auto auto;
}

.avatar-icon {
    grid-area: avatar;
    margin-top: 5px;
}

.post-header {
    grid-area: header;
}

.post-text-header {
    font-size: $fontSizeSmall;
    line-height: 1.5;

    &.default {
        margin-bottom: 0;
    }

    &.xs {
        margin-bottom: 5px;
    }

    &.s {
        margin-bottom: 10px;
    }
}

.post-text-container {
    grid-area: post;

    &.background {
        background: $backgroundTonalGrey;
        border-radius: 5px;
        padding: 10px 15px 15px;
    }

    &:not(.background) {
        margin: 5px 5px 0 0;
    }

    &.post-text-container-right-align {
        display: grid;
        gap: 0;
        grid-template-areas:
            "header rightAlign"
            "body rightAlign";
        grid-template-columns: 1fr auto;
        width: 100%;
    }

    .post-body {
        grid-area: body;
    }

    .post-right-align {
        grid-area: rightAlign;
    }
}

.post-icon {
    height: 25px;
    width: 25px;
}

::v-deep .markdown {
    word-break: break-word;
}
