
@use "client/css/globals" as *;

.question-box {
    align-items: flex-start;
    background-color: $brandColorPrimaryContrast;
    display: grid;
    gap: 15px;
    grid-template-columns: minmax(0, auto) 1fr minmax(0, auto);
    padding: 30px;
    transition: background ease-out 300ms;
    width: 100%;
}

.question-box.gu-mirror {
    border-radius: 5px;
    box-shadow:
        0 0 3px $pilotPurple,
        0 2px 8px rgba(95, 46, 229, 0.1);
    opacity: 1;
}

.question-box.gu-transit {
    background: $highlightPurple;
    opacity: 0.5;
}

.question-box.gu-transit > * {
    opacity: 0;
}

.input-wrapper {
    display: grid;
    gap: 5px;
    grid-template-rows: auto;
}

.text-attachment-container {
    border: 1px solid $borderColorLight;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    resize: vertical;
}

.text-area {
    // remove default styling
    appearance: none;
    border: 1px solid transparent;

    // input styling
    box-sizing: border-box;
    font-size: $fontSizeBase;
    height: 100%;
    min-height: 115px;
    min-width: 0;
    outline: none;
    padding: 10px;
    resize: none;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.author {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    text-align: right;
}

.author-attachments {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    text-align: left;
}

.action-text {
    color: $brandColorPrimary;
    cursor: pointer;
    font-size: $fontSizeBase;
}

.handle {
    cursor: grab;
}

.attachment-container {
    padding-left: 10px;
    padding-top: 10px;
}

.file-card {
    padding-bottom: 10px;
}
