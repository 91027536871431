
@use "client/css/globals" as *;

.button-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}

.multiselect {
    width: 100%;
}

.reason-table-cell {
    flex-direction: column;
}

.reason-action {
    align-items: center;
    display: flex;
    width: 100%;
}

.footer-total ::v-deep td {
    border-bottom: 1px solid $borderColorLight;
}
