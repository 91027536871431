
@use "client/css/globals" as *;

#reconcile-dates {
    h4 {
        margin: 0 0 $spacingSmaller;
    }

    .multiselect {
        width: 160px;
    }
}
