
@use "client/css/globals" as *;

.download-row {
    display: flex;
    justify-content: flex-end;
}

.download-button {
    margin-bottom: 20px;
}

.revenue-waterfall-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    white-space: nowrap;

    table {
        margin-bottom: 0;

        // override global table styles
        table-layout: auto;
    }

    td,
    th {
        max-width: 140px;
        min-width: 140px;
        transition: background-color ease-in-out 0.1s;

        &.centered {
            text-align: center;
        }
    }

    th {
        font-weight: normal;
        height: 60px;
    }

    tbody tr:last-child {
        background: $blue1Background;
        border-right: 1px solid $blue1Background;
        border-top: 1px solid $borderColorDark;

        td.recognized-body-cell {
            background: $blue1Background;
        }

        td.recognized-month-total {
            font-weight: bold;

            &:first-of-type {
                border-left: 1px solid $blue1Background;
            }
        }
    }

    tbody tr td.clickable {
        cursor: pointer;

        &:hover {
            background: $brightPurple;
        }
    }

    .recognized-month {
        font-weight: bold;
    }

    .total-monthly-revenue {
        justify-content: flex-end;
        justify-items: flex-end;
    }

    .revenue-tooltip {
        font-weight: normal;
        height: 22px;
    }
}

.revenue-waterfall-booked-container {
    background-color: $backgroundColorPrimary;

    .revenue-waterfall-header,
    th:nth-child(2),
    tr:not(:last-child) td:nth-child(2) {
        border-right: 1px solid $borderColorLight;
    }
}

.revenue-waterfall-recognized-container {
    overflow-x: hidden;

    .revenue-waterfall-recognized-wrapper {
        overflow-x: scroll;
        width: 100%;
    }
}

.recognized-body-cell {
    background-color: $backgroundTonalGrey;
}

.revenue-waterfall-header {
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    width: 100%;
}
