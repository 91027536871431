
@use "client/css/globals" as *;

.container {
    border: 1px solid $borderColorLight;
}

h2 {
    margin-bottom: 30px;
}

h3 {
    margin-top: 30px;
}

.content {
    background: $backgroundDarkPrimaryContrast;
    max-width: 100%;
}

.layout {
    background: $backgroundDarkPrimaryContrast;
    display: grid;
    gap: 30px;
    grid-template-rows: auto;
}
