
.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

.header-tag {
    margin-left: 5px;
}

.right-aligned {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.button-with-disabled-reason {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ::v-deep .button {
        margin-right: 0;
    }
}

h2 {
    font-size: 18px;
    margin-bottom: 0;
}

.body-section {
    padding: 30px;
}

hr {
    margin: 0;
}

.helper-text {
    margin-top: 10px;
}
