
.welcome-card {
    margin-bottom: 15px;
    max-width: 1075px;
}

.welcome-title {
    margin-bottom: 5px;
}

.welcome-text {
    max-width: 90%;
}

.onboarding-steps {
    max-width: 1075px;
}
