
@use "client/css/globals" as *;

::v-deep a.button {
    justify-content: start;
    text-align: left;
}

::v-deep .pilot-tag {
    margin-left: 10px;
}

.connections-table-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}

.header-text {
    font-weight: 600;
}

.remove-button {
    color: $red1;
}

.institution-button {
    justify-content: left;
}

::v-deep .loading {
    padding: 5px 0;
}
