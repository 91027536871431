@use "client/css/globals" as *;

.allocation-close-title {
    margin: 0;
}

.allocation-close-wrapper {
    display: grid;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.border-bottom {
    border-bottom: 1px solid $borderColorLighter;
}

.allocation-upload-row {
    padding: 15px 30px;
}

// Separate from left/right padding so that height stays constant when the plump FileCard appears.
@mixin row-vertical-spacing {
    padding-bottom: 5px;
    padding-top: 5px;
}

@mixin header-row-vertical-spacing {
    padding-bottom: 15px;
    padding-top: 15px;
}
