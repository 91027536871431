
@use "client/css/globals" as *;

.customer-flags {
    align-content: start;
    display: grid;
    gap: 30px;
    grid-template-columns: auto auto;
    justify-content: start;
}

.add-connection-task {
    display: grid;
    gap: 25px;
    grid-template-columns: auto auto;
    justify-content: start;
}

.title {
    font-weight: normal;
}

.header-text {
    font-weight: bold;
    margin-bottom: 15px;
}

.link-section {
    align-content: start;
    align-items: center;
    display: grid;
    gap: 15px 30px;
    grid-template-columns: auto auto;
    justify-content: start;
    margin-bottom: 30px;
}

.subtitled-input {
    display: flex;
    flex-direction: column;
    font-size: $fontSizeSmall;
    gap: 5px;
}

.create-task-button {
    overflow-wrap: break-word;
}
