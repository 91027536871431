
@use "client/components/css/mixins";
@use "client/css/globals" as *;

.pilot-table-pagination {
    @include mixins.grid-table-row;

    > td {
        border-top: 1px solid $borderColorLight;
        grid-column: 1 / -1; // take up the entire row, from first to last column

        &:not(.xsmall) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .pagination {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;

        .pagination-select {
            width: min-content;
        }

        .left {
            align-items: center;
            display: flex;
            gap: 10px;
            justify-content: flex-start;
            left: 30px;
            position: sticky;
        }

        .right {
            align-items: center;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            position: sticky;
            right: 30px;
        }

        .pagination-buttons {
            display: inline-flex;
            gap: 10px;

            > button {
                background: none;
                border: none;
                padding: 0;
            }
        }
    }
}
