
@use "client/css/globals" as *;

.within-6days-container {
    background-color: $lightGrey;
    border: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > p {
        margin-bottom: 0;
    }
}

.tax-periods-container {
    background-color: $lightGrey;
    border: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > p {
        margin-bottom: 0;
    }

    & > ul {
        margin-top: 20px;
    }
}

.computed-periods-container {
    margin: 0;
    padding-left: 20px;
}
