
@use "client/css/globals" as *;

.loading-page {
    align-items: center;
    display: flex;
    height: 500px;
    justify-content: center;

    // override the default FancyLoading width
    ::v-deep .fancy-progress {
        width: 100%;
    }
}

.navigation {
    border-bottom: 1px solid $borderColorLight;
    overflow-x: auto;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        font-size: $fontSizeBase;
        font-weight: bold;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .nav-link {
            align-items: center;
            border-bottom: 3px solid transparent; // color the border when active (un-scoped style block)
            display: flex;
            flex-wrap: nowrap;
            gap: 5px;
            padding-bottom: 15px;
        }

        a {
            color: inherit;
            white-space: nowrap;

            &:hover {
                color: $brandColorPrimary;
                text-decoration: none;
            }
        }
    }
}

// This can't be in a style block with module because that changes the name of the
// class of the rendered style + component. GatedRouterLink requires an exact class
// name. We must use !important due to the unspecific selector
.active-tab {
    border-bottom-color: $brandColorPrimary !important;
    color: $brandColorPrimary !important;
}

.nested-page {
    margin-top: 20px;
}
