
@use "client/css/globals" as *;

.center-text {
    text-align: center;
}

.subheader-text-links {
    color: $pilotPurple;
}
