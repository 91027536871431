
@use "client/css/globals" as *;

.input-container {
    border: 1px solid $borderColorLight;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.text-input {
    border: 1px solid transparent;
    height: 41px;
    min-height: initial;
    overflow: auto;
    padding: 10px 12px;
    resize: none;
    width: 100%;

    &::placeholder {
        color: $darkGrey;
    }

    &.active-input {
        min-height: 78px;
    }
}

.add-response-btn {
    border: 1px solid transparent;
    color: $contentColorTertiary;
    display: flex;
    height: 41px;
    justify-content: flex-start;
    margin: 0;
    padding: 10px 12px;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        border: 1px solid transparent;
        color: $contentColorTertiary;
    }

    &:disabled {
        cursor: wait;
    }
}

.reply-button-container {
    align-items: center;
    border-top: 1px solid $borderColorLight;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.sender-selector-container {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: auto 400px;

    .from-text {
        margin: 0;
    }
}

.status-reply-container {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto;
}

.attachment-btn-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-right: 15px;

    & ::v-deep .file-picker {
        height: 20px;
        width: 20px;
    }
}

.file-card {
    padding: 0 0 10px 10px;
    width: 350px;
}

.reply-icon-wrapper {
    align-items: center;
    display: flex;
    width: fit-content;

    .attachment-icon {
        margin-right: 15px;
    }
}

.reply-button {
    margin-right: 0;
    padding: 3.5px 18px;

    & ::v-deep .spinner {
        right: 25px;
    }
}

.confirm-button {
    min-width: 152px;
    padding: 3.5px 18px;
}

.status-label {
    margin-right: 5px;
}

.status-dropdown {
    align-items: center;
    border-radius: 5px;
    display: flex;
    padding: 0 15px 0 5px;
}

.status-icon {
    margin-left: 5px;
}

.footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .draft-text {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }
}
