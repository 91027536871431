
@use "client/css/globals" as *;

.close-task {
    display: flex;
    flex-direction: column;
    height: 120px;
    overflow: visible;
    padding: $spacingSmall $spacingSmall $spacingSmall $spacingSmall;
    position: relative;

    a,
    a:hover,
    a:visited {
        color: inherit;
        display: block;
        margin-right: $spacingMediumLarge;
        max-height: 3em;
        overflow-y: hidden;
        word-wrap: break-word;
    }
}

.close-status-metadata {
    font-size: $fontSizeSmall;
    justify-content: space-between;
    margin-top: auto;
}

.close-task-action {
    color: $contentColorPrimary;
    opacity: 0;
    padding: 12px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.1s;

    .close-task:hover &,
    .close-task-active & {
        opacity: 1;
        pointer-events: all;
    }

    svg {
        display: block;
        height: 16px;
        opacity: 0.75;
        width: 16px;
    }
}

.close-task-time-tracker {
    bottom: 4px;
    color: rgb(63, 60, 61);
    position: absolute;
    right: 8px;

    .checklist-section-toggl {
        opacity: 0;

        .close-task:hover & {
            // Hovers on customer close card
            opacity: 0.75;

            &:hover {
                // Hovers on timer button
                opacity: 1;
            }
        }

        &.checklist-section-toggl-active {
            opacity: 1;
        }
    }
}

#reason-for-delay {
    // Widen for long message
    .tooltip-content {
        width: 220px;
    }

    // Override .close-task-action global style
    .tooltip-container svg {
        height: 24px;
        width: 24px;
    }
}
