@use "globals" as *;

ul.no-chrome {
    margin: 0;
    padding: 0;

    > li {
        list-style-type: none;
    }
}

li.no-chrome {
    list-style-type: none;
}

ul.checklist {
    li {
        list-style-type: none;
        padding-left: $spacingLarge;
        position: relative;

        &::before,
        &::after {
            background: $brandColorPrimary;
            content: "";
            display: block;
            left: 12px;
            position: absolute;
            top: 10px;
            width: 3px;
        }

        &::before {
            height: 20px;
            transform: rotate(45deg);
        }

        &::after {
            height: 10px;
            transform: rotate(-45deg) translateX(-12px);
        }
    }
}
