
.fancy-progress {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 20px;
    padding-top: 20px;
}

.messages {
    margin-top: 15px;
    min-height: 50px;
    position: relative;
    width: 100%;
}

.title {
    font-weight: bold;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.subtitle {
    bottom: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.slide-leave-to {
    opacity: 0;
    transform: translateY(10px);
}
