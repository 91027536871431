
@use "client/css/globals" as *;

.manage-tax-section {
    margin-bottom: 30px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.manage-tax-heading {
    border-bottom: 1px solid $borderColorLighter;
    font-size: $fontSizeBase;
    margin: 0;
    padding: 20px 30px;
}

.change-history-heading {
    font-size: $fontSizeMedium;
    margin: 0;
    padding: 20px 30px;
}

.manage-tax-rows {
    border: 0;

    > *:not(:last-child) {
        border-bottom: 1px solid $borderColorLighter;
    }
}

.link-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left {
        width: 100%;

        .link {
            background-color: $lightGrey;
            cursor: auto;
            margin: 0;
        }
    }

    .right {
        padding-left: $spacingSmall;
    }

    .copy-link-button {
        white-space: nowrap;
    }
}

.tax-period-picker {
    ::v-deep .row-label {
        display: none;
    }

    ::v-deep .input-container {
        width: fit-content;
    }
}

.tax-firm-input {
    width: 100%;
}

.notification-container {
    align-items: center;
    border: 1px solid $yellow1;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 30px;
    padding: 30px;

    .notification-content {
        line-height: 22.5px;

        & > p {
            margin: 0;
        }
    }
}

.tax-customer-modal-link {
    color: $pilotPurple;
    cursor: pointer;
    margin-top: 15px;
}

.dropbox-display {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
}

label[for="entity-type-period"],
label[for="fiscal-year-start-period"] {
    display: flex; // align info-tooltip icons with label text
}

::v-deep .tooltip-content {
    // Make the tooltip a little easier to read.
    min-width: 200px;
}
