
@use "client/css/globals" as *;

$transitionDuration: 0.2s;

// TODO this styling should be wrapped into the global `field` class
.customer-field {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 $spacingMedium;
    transition: all $transitionDuration;

    &.list-enter,
    &.list-enter-to,
    &.list-leave-to {
        overflow-y: hidden; // override the height set by v-slide
    }

    &.list-enter,
    &.list-leave-to {
        height: 0 !important;
        margin: 0;
        padding: 0;
    }

    &.list-leave-to {
        opacity: 0;
    }

    label {
        width: 100%;
    }

    input,
    .multiselect {
        flex: 1;
    }

    .delete-field {
        margin-left: $spacingSmall;
    }

    .tip {
        width: 100%;

        .warning {
            color: $red1;
        }
    }

    .multiselect__tags {
        background: transparent;
    }

    &.showMissing {
        input,
        .multiselect {
            background: $yellow1Background;
        }
    }
}

.new-contact {
    margin: auto 0 auto $spacingSmall;
}

.new-contact-email,
.new-contact-preferred-name {
    margin-left: $spacingSmall;
}

.customer-field-bool {
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;

    input {
        margin: 0 $spacingSmall 0 0;
        order: -1;
        width: auto;
    }
}
