
@use "client/css/globals" as *;

.institution-item {
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex: 1 0 100%;
    padding: $spacingMedium;
    transition: 0.15s ease-out box-shadow border;

    &:hover {
        box-shadow: $purpleShadow;
    }
}

.card-row {
    align-items: center;
    display: flex;
    flex: 1 0 100%;

    .onboarding-card-checkmark-close {
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
