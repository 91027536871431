
@use "client/css/globals" as *;

td {
    padding-bottom: $spacingMediumSmall;
    padding-top: $spacingMediumSmall;
}

.disabled-institution {
    color: $contentColorTertiary;
    display: flex;
    justify-content: space-between;

    .error-text {
        color: $contentColorSecondary;
        margin-left: 10px;
    }
}
