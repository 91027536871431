
@use "client/css/globals" as *;

.error {
    color: $red1;
    font-weight: normal;

    *:last-child {
        margin-bottom: 0;
    }
}
