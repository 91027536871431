
@use "client/css/globals" as *;

.institution-logo {
    height: 30px;
}

.product-name {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.pill {
    border: 1px solid $borderColorLight;
    border-radius: 20px;
    font-size: $fontSizeSmall;
    padding: 3px 10px;
}

.subproduct-name {
    color: gray;
    font-size: $fontSizeSmall;
}
