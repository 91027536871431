
@use "client/css/globals" as *;

.bank-cc-manage {
    border-bottom: 1px solid $borderColorLight;
    margin-bottom: $spacingLarge;
}

.overflow-header {
    width: 60px;
}

thead tr {
    border-bottom-color: $borderColorLight;
}

.icon-information {
    vertical-align: bottom;
}
