
@use "client/css/globals" as *;

.bench-capture-wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}

.bench-capture-header {
    align-items: center;
    background: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;

    h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
        margin-bottom: 8px;
        max-width: 350px;

        strong {
            color: $pilotPurple;
            display: block;
        }
    }

    p {
        color: $pilotText;
        font-size: 20px;
        line-height: 32px;
    }

    svg {
        width: 46px;
    }

    .bench-capture-header-content {
        max-width: 550px;
        width: 100%;
    }
}

.bench-capture-content {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 16px;
    justify-content: center;
    padding: 20px;

    h2 {
        color: $pilotPurple;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 10px;
    }

    div {
        max-width: 600px;
        width: 100%;
    }

    form {
        width: 100%;

        label {
            display: block;
            margin-bottom: 15px;
        }

        .form-section {
            margin-top: 20px;
        }

        .form-row {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-bottom: 10px;
            width: 100%;

            input {
                width: 100%;
            }
        }

        .error-message {
            color: $red1;
            font-size: $fontSizeBase;
        }
    }

    ol {
        padding-left: 20px;
    }

    li {
        margin-bottom: 15px;
        padding-left: 10px;
        position: relative;

        &::before {
            align-items: center;
            background: $pilotPurple;
            border-radius: 50%;
            color: white;
            content: counter(list-item);
            display: flex;
            font-size: 10px;
            font-weight: 700;
            height: 20px;
            justify-content: center;
            left: -20px;
            position: absolute;
            top: 2px;
            width: 20px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .checkbox-label {
        align-items: center;
        display: flex;
        font-size: 14px;
        gap: 10px;
        width: 100%;

        input[type="checkbox"] {
            width: auto;
        }
    }
}
