
.banner-container {
    margin: 10px 0;
}

.create-entries-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}
