
@use "client/css/globals" as *;

.file-card {
    width: 100%;
}

.file-icon {
    margin-right: 10px;
}

.file-card-compact {
    align-items: center;
    display: grid;

    // Slot and/or 'remove file' can be present or not present in the 2 right-align cells.
    grid-template:
        "icon file right-align-1 right-align-2" auto
        / auto 1fr auto auto;
}

.remove-button {
    margin-left: 30px;
}

// Needed for file name to truncate with ellipses based on width of container
.file-name-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
}

.file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.download-button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-left: 10px;
    padding: 0;
    width: 18px;

    svg {
        fill: $pilotPurple;
        height: 16.5px;
        width: 16.5px;
    }

    &:hover {
        svg {
            fill: $contentColorPrimary;
        }
    }
}

.parent-wrapper {
    // Required to allow the file name to truncate with ellipses based on width of container
    // 75 as an arbitrary number that seems appropriate for mobile viewing
    min-width: 75px;
}
