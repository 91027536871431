
@use "client/css/globals" as *;

// styles below are copied from old-dashboard.vue

.eoy-close-buckets {
    display: flex;
    justify-content: stretch;

    > li {
        flex: 1 1 auto;
        overflow: hidden;
        padding: $spacingMedium;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.eoy-close-bucket-value {
    display: block;
    font-size: $fontSizeLarger;
    line-height: 1.05;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.eoy-bucket-close-pct {
    font-size: $fontSizeMedium;
}

.eoy-close-status-bucket {
    min-width: 10%;
    transition: min-width 0.25s ease-in-out;

    &:hover {
        cursor: pointer;
        min-width: 200px;
    }
}

.eoy-close-status-not-started {
    background: $backgroundColorTertiary;
}

.eoy-close-status-in-progress {
    background: $brighterPurple;
}

.eoy-close-status-complete {
    background: $brandColorPrimary;
    color: $brandColorPrimaryContrast;
}
