
.removable-text-input {
    margin-bottom: 6px;
}

.button-contents {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.icon {
    margin-right: 5px;
}
