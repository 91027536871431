
@use "client/css/globals" as *;

$numberBadgeSize: 32px;
$stepLineSize: 5px;

ol {
    counter-reset: counter;

    // Override browser defaults
    list-style: none;
    margin: 0;
    padding: 0;
}

ol li {
    counter-increment: counter;
    display: grid;
    grid-column-gap: 25px;
    grid-template:
        "step-number step-title" auto
        "........... step-detail" auto
        / auto 1fr;
    position: relative; // Anchor step line positioning calculation
}

ol li:not(:last-child) {
    // Space between steps
    padding-bottom: 50px;
}

ol li h3 {
    align-items: center;
    color: $pilotText;
    display: flex;
    grid-area: step-title;

    // Align with step circle number
    height: $numberBadgeSize;
}

.step-detail {
    grid-area: step-detail;
    margin: 0;
}

ol li::before {
    align-items: center;
    background: $brandColorPrimary;
    border-radius: 50%;
    color: $brandColorPrimaryContrast;

    // Step circle number
    content: counter(counter);
    display: flex;
    font-size: 18px;
    font-weight: bold;
    grid-area: step-number;
    height: var(--size);
    justify-content: center;
    width: var(--size);

    --size: #{$numberBadgeSize};
}

ol li::after {
    border-left: 5px solid $brandColorPrimary;

    // Step line
    content: "";
    height: calc(100% - #{$numberBadgeSize});

    // Center line
    left: calc((#{$numberBadgeSize} - #{$stepLineSize}) / 2);
    position: absolute;

    // Do not overlap line with number
    top: $numberBadgeSize;
}

ol li:last-child::after {
    // Last step does not have a step line
    border: 0;
}

.draft-list-file-container {
    border: 1px solid $borderColorLight;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.draft-list-file {
    align-items: center;
    display: flex;
}

.draft-list-file-name {
    font-weight: bold;
    padding-left: 10px;
}

.draft-list-link {
    line-height: 0;
}
