// used in CustomerInstitutionsModal and OnboardingInstitutionsAddTasksModal
@use "client/css/globals" as *;

.step-header {
    margin: 20px 30px 0;
}

.search-icon-wrapper {
    position: relative;
    width: 100%;

    .search-icon {
        left: 10px;
        position: absolute;
        top: 20%;
    }

    .search-input {
        padding-left: 40px;
        padding-top: 10px;
    }
}

.modal-body-container {
    margin-left: 30px;
    margin-right: 30px;

    @media (min-width: 768px) {
        // Tablet and desktop breakpoint
        height: 450px;
    }
}

.institution-list {
    list-style-type: none;
    margin-top: 20px;
    padding-left: 0;
}

.clear-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
}

.institution-list-item {
    align-items: center;
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    display: flex;
    margin-bottom: $spacingMedium;
    padding: $spacingMedium;
    text-align: left;

    &:hover {
        box-shadow: $purpleShadow;
    }

    &[aria-pressed="true"] {
        border: 2px solid;
        border-color: $pilotPurple;
    }

    &[aria-pressed="false"] {
        border: 1px solid;
        border-color: $borderColorLight;
    }
}

.institutions-selected-text {
    align-content: center;
    margin-left: 10px;
}

.institution-display-name-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: $spacingMedium;
}

.bold {
    font-weight: bold;
}

.no-result-found-text {
    margin-top: 20px;
}

.icon-chevron {
    margin-left: auto;
}
