
@use "client/css/globals" as *;

.header {
    font-weight: bold;
}

.condition-section {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacingMedium;
}

.add-condition {
    width: max-content;
}
