
@use "client/css/globals" as *;

::v-deep #vendor-inspector {
    min-height: 620px; // to reduce chance of jitter from one page to the next

    .actions {
        margin-top: auto;
    }
}

.loading-state {
    align-items: center;
    display: flex;
    height: 400px;
    justify-content: center;

    // override the default FancyLoading width
    ::v-deep .fancy-progress {
        width: 100%;
    }
}

.error-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;

    h2.title {
        margin-bottom: 5px;
        margin-top: 15px;
    }
}

.vendor-inspector-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep .multiselect {
        width: 200px;
    }
}

.no-vendors {
    color: $contentColorTertiary;
    padding: $spacingLarge;
    text-align: center;
}
