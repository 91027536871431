
@use "client/css/globals" as *;

.sidebar {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    box-shadow:
        0 8px 16px rgba(62, 60, 61, 0.08),
        0 0 1px rgba(101, 99, 99, 0.16);
    color: $contentColorPrimary;
    position: sticky;
    position: -webkit-sticky;
    top: $pageHeaderHasSecondaryNavHeight + $spacingLarge + $spacingLarge;
    width: 260px;

    .sidebar__header {
        border-bottom: 1px dashed $borderColorLight;
        padding: 25px 25px 20px;
        position: relative;

        h2 {
            font-size: $fontSizeBase;
            margin: 0;
        }
    }

    .sidebar__content {
        padding: 20px 25px 25px;

        p {
            font-size: $fontSizeSmall;
            margin: 0;
            padding-top: 10px;
        }

        a {
            color: $pilotPurple;
        }
    }
}
