
@use "client/css/globals" as *;

.field:not(.radio) label {
    display: flex; // align info-tooltip icons with label text
}

::v-deep .tooltip-content {
    min-width: 200px;
}

.preview-loader {
    margin-bottom: 20px;
}

.custom-recognition-row {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
}

.amount-wrapper {
    position: relative;
}

.amount-input {
    text-align: right;
}

.amount-input-unit {
    color: $contentColorTertiary;
    margin: $inputPaddingTop 0 0 $inputPaddingHorizontal;
    pointer-events: none;
    position: absolute;
}

.capitalize-service-period-source {
    text-transform: capitalize;
}

.remaining-wrapper {
    display: flex;
    flex-direction: column;
    text-align: right;

    .remaining-amount {
        font-size: $fontSizeMedium;
        white-space: nowrap;
    }

    .remaining-text {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }
}

.vertical-align {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
