
@use "client/css/globals" as *;

.task-customer-note-container {
    margin-bottom: -($flowbotItemPadding);
    margin-left: 25px;
    margin-top: -($flowbotItemPadding);
    margin-top: 15px;
    max-width: 100%;
    word-wrap: break-word;

    textarea {
        resize: vertical;
        width: 100%;
    }

    .buttons {
        margin-top: 10px;
    }
}

.task-customer-note {
    background: $backgroundColorTertiary;
    border: 1px solid $borderColorLight;
    border-radius: 6px;
    padding: 15px;
}

.task-customer-note-header {
    display: flex;
    margin-bottom: 5px;
}

.task-customer-note-date {
    color: $contentColorSecondary;

    .task-customer-note-author + & {
        margin-left: 10px;
    }

    & + .task-customer-note-action {
        margin-left: auto;
    }
}

.task-customer-note-action {
    color: $contentColorSecondary;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        text-decoration: underline;
    }
}

.task-add-customer-note {
    opacity: 0;
    transition: opacity 0.2s;

    .task:hover & {
        opacity: 1;
    }
}

.button.create-note {
    align-items: center;
    display: flex;
}

.create-note-icon {
    margin-right: 5px;
}
