@use "globals" as *;

// TODO: delete when <BaseButton> is used everywhere
.button,
a.button {
    // Specificity to override a pseudo classes
    background: transparent;
    border: 1px solid $brandColorPrimary;
    border-radius: 4px;
    color: $brandColorPrimary;
    cursor: pointer;
    display: inline-block;
    font-family: Euclid, sans-serif;
    font-size: $fontSizeBase;
    line-height: inherit;
    margin-right: $spacingMedium;
    padding: 8px 32px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s;
    user-select: none;

    &:focus {
        border-color: $pilotText;
    }

    &:focus-visible {
        outline-offset: 5px;
    }

    &:hover {
        background: $pilotText;
        border-color: $pilotText;
        color: $brandColorPrimaryContrast;
        text-decoration: none;
    }

    &:active {
        background: $pilotText;
        border-color: $pilotText;
        color: $brandColorPrimaryContrast;
    }

    &:disabled,
    &.disabled {
        border-color: $brandColorPrimaryDesaturated;
        color: $brandColorPrimaryDesaturated;
        cursor: default;

        &:hover {
            background: transparent;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &.compact {
        padding-left: 12px;
        padding-right: 12px;
    }

    &.small {
        margin-right: $spacingSmall;
        padding: 1px 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    &.large {
        font-size: $fontSizeMedium;
        padding: 12px 36px 13px;
    }

    &.larger {
        font-size: $fontSizeLarge;
        padding: 14px 36px 15px;
    }

    &.full-width {
        width: 100%;
    }

    &.primary {
        background: $brandColorPrimary;
        border-color: transparent;
        color: $brandColorPrimaryContrast;

        &:hover,
        &:focus {
            background: $brandColorPrimaryDark;
        }

        &:active {
            background: $pilotText;
        }

        &:disabled,
        &.disabled {
            background: $backgroundColorGrey;
            color: $darkGrey;
        }
    }

    &.tertiary {
        background: transparent;
        border-color: $brandColorPrimary;
        color: $brandColorPrimary;

        &:hover,
        &:focus,
        &:active {
            background: $brandColorPrimaryDark;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            border-color: $darkGrey;
            color: $darkGrey;
        }
    }

    &.cta {
        background: $highlightPurple;
        border-color: transparent;
        color: $pilotText;

        &:hover,
        &:focus,
        &:active {
            background: $brandColorPrimaryDark;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            background: $backgroundColorGrey;
            color: $contentColorTertiary;
        }
    }

    &.delete {
        background-color: $red1;
        border-color: $red1;
        color: $brandColorPrimaryContrast;

        &:hover,
        &:focus,
        &:active {
            background: $backgroundColorDarkRed;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            background: $backgroundColorGrey;
            border: none;
            color: $darkGrey;
        }
    }

    &.borderless {
        border: none;
        padding: 0;

        &:hover,
        &:focus {
            background: transparent;
            color: $pilotText;
        }

        &:disabled,
        &.disabled {
            color: $brandColorPrimaryDesaturated;
        }
    }

    .spinner,
    .button-status {
        position: absolute;
        right: $spacingSmaller;
        top: 50%;
        transform: translateY(-50%);
    }
}

.buttons {
    display: flex;
    justify-content: flex-start;
    margin: $spacingMedium 0;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    &.full-width {
        flex-direction: column;
        text-align: center;

        .button {
            margin-bottom: $spacingMedium;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.compressed {
        margin: $spacingSmall 0;

        &:last-child {
            margin-bottom: 0;
        }

        .button {
            margin-right: $spacingSmall;
        }
    }

    .right-align & {
        justify-content: flex-end;
    }

    &.buttons-stretch-equally {
        .button {
            flex: 1 1 0;
        }
    }
}

// TODO: delete when <BaseCloseButton> is used everywhere
.x {
    background: transparent;
    border: none;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 14px;

    &.disabled {
        cursor: not-allowed;
    }

    &::after,
    &::before {
        background: $darkGrey;
        content: "";
        display: block;
        height: 10px;
        left: 6px;
        position: absolute;
        top: 2px;
        width: 2px;
    }

    &::after {
        transform: rotate(45deg);
    }

    &::before {
        transform: rotate(-45deg);
    }

    &:hover {
        background: $red1;

        &::after,
        &::before {
            background: $backgroundColorPrimary;
        }
    }

    &:focus {
        outline: none;
    }

    &.no-hover {
        &:hover {
            background: transparent;
        }

        &::after,
        &::before {
            background: $contentColorPrimary;
        }
    }

    &.large {
        height: 32px;
        width: 32px;

        &::before,
        &::after {
            height: 20px;
            left: 15px;
            top: 6px;
        }
    }

    &.larger {
        height: 48px;
        width: 48px;

        &::before,
        &::after {
            height: 30px;
            left: 23px;
            top: 10px;
        }
    }
}
