
@use "client/css/globals" as *;

.heading {
    margin-bottom: 30px;
}

.tax-text {
    margin-bottom: 5px;
}

.counter {
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;
}

.text-link-wrapper {
    display: flex;

    .guide-link {
        margin-bottom: 30px;
        margin-left: 5px;
    }
}

.prev-vendor-list {
    $vendorRow: 48px;

    background-color: $lightGrey;
    max-height: $vendorRow * 11; // 10 vendors plus the 'Select all' option
    overflow-y: auto;

    ::v-deep .container {
        border: 0;
        height: 48px;
    }
}

.btn-wrapper {
    padding-top: 30px;
    text-align: right;
}
