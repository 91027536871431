
@use "client/css/globals" as *;

.details-wrapper {
    height: auto;
    position: relative;
    width: 100%;
}

.summary-content {
    position: relative;
    z-index: 1;
}

.details-content {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
}

.show-details-enter-active,
.show-details-leave-active {
    transition:
        transform 0.3s ease,
        opacity 0.3s ease;
}

.show-details-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.show-details-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.show-details-leave {
    opacity: 1;
    transform: translateY(0);
}

.show-details-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}
