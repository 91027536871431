
@use "client/css/globals" as *;

// Customize spinner style for multiselect to match Pilot loading spinner color
::v-deep .multiselect__spinner {
    // height of 37px so it fills the whole dropdown without overlapping the border
    // this also allows us to center the spinner in the dropdown
    height: 37px;
}

::v-deep .multiselect__spinner::after {
    border-color: $contentColorSecondary transparent transparent;
}

::v-deep .multiselect__spinner::before {
    border-color: $contentColorSecondary transparent transparent;
}
