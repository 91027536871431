
$resizeDuration: 0.2s;

.resize-toggle-container {
    position: relative;
    transition: all $resizeDuration;
}

.resize-toggle {
    height: auto;
    transition: all $resizeDuration;

    &.resize-fade-enter,
    &.resize-fade-leave-to {
        opacity: 0;
    }

    &.resize-fade-leave,
    &.resize-fade-enter-active {
        position: absolute;
        top: 0;
        width: 100%;
    }
}
