
@use "client/css/globals" as *;

input:invalid {
    border-color: $warningYellow;
}

.reconcile-institution-module {
    background-color: $backgroundColorPrimary;
    border: solid 1px $borderColorLight;
    border-radius: $borderRadiusMedium;
    padding: $spacingMediumLarge;
}

.reconcile-institution-account {
    margin-bottom: $spacingSmall;
}

.reconcile-account-date-picker {
    border: none;
    padding: 0;
    width: 6em;
}

.date-picker-chevron {
    display: inline-block;
    transform: rotate(90deg);
}

.reconcile-requirements {
    border-bottom: solid 1px $borderColorLight;
    padding: 30px 0;

    h5 {
        margin: 0 0 2px;
    }
}

.reconcile-steps {
    position: relative;
}

.requirements-overlay {
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.reconcile-step {
    align-items: center;
    border-bottom: solid 1px $borderColorLight;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    &:last-child {
        border: none;
        padding-bottom: 0;
    }
}

.reconcile-step-header {
    color: $contentColorTertiary;
    font-weight: normal;
    margin: 0 0 2px;
}

$actionWidth: 145px;

.reconcile-step-action {
    width: $actionWidth;
}

.csv-uploaded {
    align-items: center;
    display: flex;

    span {
        display: inline-block;
        padding: 0 6px;
        white-space: nowrap;
    }
}

.transactions-eom-actions {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    .upload-to-quickbooks {
        margin: 0 0 10px;
    }
}

.icon {
    vertical-align: text-top;
}

.tooltip {
    white-space: nowrap;
}

.reconcile-step-text-header {
    font-weight: normal;
    margin: 0 0 2px;
}
