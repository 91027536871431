
@use "client/css/globals" as *;

.description {
    padding-bottom: 20px;
}

.tax-period {
    display: flex;
    flex-direction: column;
    gap: $spacingSmall;
    padding-bottom: 32.25px;
}
