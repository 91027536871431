
@use "client/css/globals" as *;

.transaction-detail {
    margin-bottom: 15px;

    strong {
        color: $offBlack;
        font-size: 13px;
        margin-bottom: 5px;
    }

    p {
        color: $offBlack;
        font-size: 15px;
        margin: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .detail-content {
        height: 23px;
        overflow: hidden;
        word-break: break-all;

        &.expanded {
            height: auto;
            overflow: initial;
        }

        &.pending {
            color: $contentColorTertiary;
        }
    }
}
