
@use "client/css/globals" as *;

th {
    color: $contentColorSecondary;
}

.approve-checkbox {
    width: 40px;
}

.edit-rule {
    width: 120px;
}
