
@use "client/css/globals" as *;

.fields {
    margin-bottom: $spacingMediumSmall;

    .field.delete {
        flex: 0;
    }
}
