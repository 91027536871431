
@use "client/css/globals" as *;

.fields {
    margin-bottom: $spacingMediumSmall;

    .field.delete {
        flex: 0;
    }
}

// for screen readers: https://webaim.org/techniques/css/invisiblecontent/
.hidden-label-text {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}
