
@use "client/css/globals" as *;
@use "./tick-and-tie";

$cuff-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);

::v-deep .trouser-cuff {
    background: $backgroundColorPrimary;
    box-shadow: $cuff-shadow;
}

::v-deep .expander-cuff {
    box-shadow: $cuff-shadow;
    height: 80px;
}

// the scrollbar was being hidden by the trouser header and footer,
// so now the body of the trouser has its own scroll container
.root {
    height: 100vh;
    overflow-y: unset;
}

.scroll-container {
    height: calc(100vh - 200px); // 200px comes from trouser padding
    overflow-y: scroll;
}

// create margin between draft group tbody elements;
// can't use actual margin because of global border-collapse: collapse
// style on table elements
.gap-row {
    height: 10px;
}

.txn-section {
    margin-bottom: $spacingMediumSmall;
}

.section-header {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    h3 {
        margin-bottom: 0;
    }
}

.section-switch {
    margin-left: 10px;

    .scroll-target {
        cursor: pointer;
    }
}

.tnt-date-select {
    max-width: 500px;
    padding-bottom: $spacingMedium;
}

.actions-left {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tick-and-tie-sum-container {
    border-right: 1px solid $borderColorLight;
    flex-direction: column;
    margin-right: $spacingMedium;
    min-width: 15em;
    padding: $spacingMediumSmall 50px;
    text-align: right;
}

.tnt-selected-count {
    color: $pilotPurple;
    font-weight: bold;
}

.tick-and-tie-sum {
    font-size: $fontSizeLarger;
    line-height: 1.1;
}
