
.target-text:hover {
    text-decoration: underline;
}

.text-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 100px 1fr;
    white-space: nowrap;
}
