
// Override the hover bubble styles
::v-deep .bubble-down {
    left: 0;
    min-width: 320px;
    right: auto;
}

.bubble-content {
    p {
        margin: 0;
    }
}
