
.loading-page {
    align-items: center;
    display: flex;
    height: 500px;
    justify-content: center;
}

.incomplete-coa {
    padding: 25px;
    text-align: center;
}
