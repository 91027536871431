
@use "client/css/globals" as *;

.container {
    align-items: center;
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    color: $contentColorPrimary;
    display: flex;
    flex-direction: column;
    max-width: 670px;
    padding: 50px 80px;

    .icon {
        margin-bottom: $spacingMediumLarge;
    }

    .heading {
        margin-bottom: $spacingMediumLarge;
    }

    .text {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: $fontSizeMedium;
        margin: 0 0 $spacingMediumLarge;

        p:last-of-type {
            margin: 0;
        }
    }
}
