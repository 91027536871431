
@use "client/css/globals" as *;

#reconcile-results {
    margin-bottom: $spacingMedium;
}

.data-cards {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.data-card {
    flex: 0 0 200px;
    margin: 0 $spacingMedium $spacingMedium 0;
    padding: $spacingMediumSmall 0;

    &:last-child {
        margin-right: 0;
    }
}

.data-card-success {
    color: $green1;
}

.data-card-error {
    color: $red1;
}

.data-card-success,
.data-card-error {
    font-weight: bold;
}

.data-card-data {
    font-size: $fontSizeLarge;
    line-height: 1;
}
