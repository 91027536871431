
// override the default table styles
table {
    margin: 0;
}

// override the default table-bordered styles
::v-deep .table-bordered {
    margin: 0;
}

th.date {
    padding: 15px 0 15px 20px;
}

th.type,
th.memo {
    padding: 15px 0;
}

th.amount {
    padding: 15px 20px 15px 0;
}

td.subheader {
    padding: 10px 20px;
}

td.date-cell {
    padding: 10px 0 10px 20px;
}

td.type-cell,
td.memo-cell {
    padding: 10px 0;
}

td.amount-cell {
    padding: 10px 20px 10px 0;
}

td.total {
    padding: 10px 0 10px 20px;
}

td.subtotal {
    padding: 10px 20px 10px 0;
}

.subtotal,
.subheader {
    font-weight: bold;
}
