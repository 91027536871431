
@use "sass:color";
@use "client/css/globals" as *;

.pilot-tag {
    align-items: center;
    border-radius: 20px;
    display: inline-flex;
    font-size: $fontSizeSmall;
    margin-right: 5px;
    padding: 5px 10px;
    white-space: nowrap;

    &.bold {
        font-weight: 600;
    }

    &.border {
        border: 1px solid $borderColorLight;
    }

    &.xsmall {
        height: 25px;
    }

    &.small {
        padding: 3px 10px;
    }

    &.large {
        font-size: $fontSizeBase;
        padding: 8px 10px;
    }

    &.pilotPurple {
        background: color.scale($brandColorPrimary, $lightness: 90%);
        color: $brandColorPrimary;
    }

    &.purple {
        background: $pastelViolet;
        color: $pilotText;
    }

    &.yellow {
        background-color: $yellow1Background;
        color: $yellow1Text;
    }

    &.green {
        background: $green1Background;
        color: $green1Dark;
    }

    &.red {
        background-color: $red1Background;
        color: $red1;
    }

    &.gray {
        background-color: $borderColorLight;
        color: $contentColorSecondary;
    }

    &.blue {
        background-color: $blue2Background;
        color: $blue2;
    }

    &.white {
        background-color: $backgroundColorPrimary;
        color: $contentColorSecondary;
    }

    .tag-icon {
        align-items: center;
        display: flex;
        margin-right: 10px;

        &.icon-only {
            margin-right: 0;
        }
    }

    .tag-action {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-right: -5px;
        padding: 0 5px;
    }
}
