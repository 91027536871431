
@use "client/css/globals" as *;

.button-container {
    margin-top: 30px;
    text-align: right;
}

.fields-container > * {
    padding: 20px 30px;
}

.field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;

    // SMALL_SCREEN_MAX_WIDTH
    @media (max-width: 559px) {
        & {
            flex-wrap: wrap;
        }
    }
}

.input-button-container {
    align-items: flex-start;
    display: flex;

    // SMALL_SCREEN_MAX_WIDTH
    @media (max-width: 559px) {
        & {
            flex-wrap: wrap;
        }
    }
}

.copy-button {
    margin-left: 15px;

    // SMALL_SCREEN_MAX_WIDTH
    @media (max-width: 559px) {
        margin-left: 0;
    }
}

::v-deep .button.small {
    padding: 8px;
}

::v-deep .shopify-container,
::v-deep .stripe-credential-chooser {
    hr {
        display: none;
    }
}

::v-deep .stripe-credential-chooser {
    .label-and-button {
        padding: 0;

        label {
            font-weight: normal;
        }
    }
}

::v-deep .gusto-banner-container {
    margin: 0;
    padding: 0;

    .gusto-banner {
        border: none;
    }
}

::v-deep .gusto-connection {
    .header {
        border: none;
        margin: 0;
        padding: 0;
    }
}

.custom-width {
    width: 425px;

    @media (max-width: 559px) {
        margin-bottom: 10px;
        width: 100%;
    }
}

::v-deep .multiselect {
    @media (max-width: 559px) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

::v-deep .markdown {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;

    @media (max-width: 559px) {
        margin-bottom: 10px;
    }
}

@media (max-width: 559px) {
    input {
        width: 100%;
    }
}
