@use "client/css/globals" as *;

// SCSS Shared between checklist-task.vue and checklist-new-task-wrapper.vue
.checklist-item {
    padding: $flowbotItemPadding 0;
    position: relative;
    transition: all 0.2s;

    &.list-enter,
    &.list-leave-to {
        height: 0 !important; // Override the height set by v-slide
    }

    &:hover:not(.task-widget-active) {
        // When widgets are open we don't want to show a background color on the task description/notes
        background: $backgroundTonalGrey;
        box-shadow: $primaryNavAdjacentBoxShadow;
    }
}

.task-checkbox-wrapper {
    align-items: center;
    display: flex;
    flex: 0 0 $flowbotCheckboxWidth;
    height: 26px;
    justify-content: space-around;
    margin-left: -8px;
    position: relative;

    .spinner {
        background: #fff;
        position: absolute;
    }
}

.task-bullet {
    border: 1px solid $contentColorPrimary;
    border-radius: 50%;
    display: block;
    height: 6px;
    width: 6px;
}
