
.txns-table-custom-margin {
    margin: 25px 30px;
}

.last-transaction-date {
    margin: 10px 0 0;
}

// modal.vue typically does some magic to determine whether to dynamically insert overflow-y: auto or not
// For some reason, it does not trigger when the transactions table rows load in, resulting in no scrollbar
// Always set overflow-y: auto for this usage
.transaction-list-modal .modal-body {
    overflow-y: auto;
}

.transaction-list-header-container {
    align-items: center;
    display: flex;
    gap: 10px;
}

.reconcile-table-container {
    overflow: auto;
}
