
@use "client/css/globals" as *;
@use "client/css/table";
@use "client/components/css/mixins";

.pilot-table-row-cell {
    align-items: center;
    background-color: $backgroundColorPrimary;
    border-top: 1px solid $borderColorLight;
    display: flex;
    padding: 0 10px;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;

    &.number {
        @include table.tabular-numbers;

        justify-content: flex-end;
    }

    &.selected {
        @include mixins.table-highlighted;
    }

    &.selectable {
        align-items: flex-start;
    }

    @include mixins.table-padding;
}
