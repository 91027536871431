
.header {
    align-items: center;
    display: flex;
}

.text {
    flex: 1;
    line-height: 1.65;

    p:last-child {
        margin-bottom: 0;
    }
}

.icon {
    flex: 0 0 90px;
    margin: 0 30px;

    @media (max-width: 900px) {
        // arbitrary width
        & {
            display: none;
        }
    }
}
