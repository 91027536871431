
@use "sass:color";
@use "client/css/globals" as *;
@use "pkg:vue-multiselect/dist/vue-multiselect.min.css";

.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: $fontSizeBase;
}

.multiselect {
    width: $fieldWidthBase;

    &.highlighted {
        .multiselect__tags {
            background: $yellow1Background;
        }
    }

    &.success {
        .multiselect__tags {
            background: color.adjust($green1Background, $lightness: -10%);
        }
    }

    &.has-error,
    .field.has-error & {
        .multiselect__tags {
            border-color: $red1Light;
        }
    }
}

.multiselect__tags {
    border-color: $borderColorLight;
    border-radius: $borderRadiusSmall;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: $borderRadiusSmall;
    border-bottom-right-radius: $borderRadiusSmall;
}

$contentWrapperOffset: 5px;

.multiselect__content-wrapper {
    border: 0;
    border-radius: $borderRadiusSmall;
    box-shadow:
        0 0 0 1px rgba(99, 114, 130, 0.16),
        0 8px 16px rgba(62, 60, 61, 0.08);
    margin-top: $contentWrapperOffset;
    max-width: 1000px;
    min-width: 100%;
    width: auto;

    .multiselect--above & {
        margin-bottom: $contentWrapperOffset;
        margin-top: 0;
    }

    .multiselect--right & {
        right: 0;
    }
}

.multiselect__select {
    align-items: center;
    background: transparent !important;
    bottom: 1px;
    display: flex;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 0;
    top: 1px;

    &::before {
        display: block;
        height: 0;
        position: static;
        top: 50%;
        transform: rotate(0deg);
        transition: transform 0.3s;
        width: 0;
    }
}

.multiselect--disabled {
    background: $backgroundTonalGrey;
    opacity: 1;

    .multiselect__tags,
    .multiselect__single {
        background: transparent;
    }
}

.multiselect--active {
    z-index: $zIndexFlyout;

    .multiselect__select {
        transform: none;
    }

    .multiselect__select::before {
        transform: rotate(180deg);
    }

    .multiselect__tags {
        border-color: $brandColorPrimary;
    }
}

.multiselect__option--highlight {
    background: $backgroundColorTertiary;
    color: $contentColorPrimary;

    &::after {
        display: none;
    }
}

.multiselect__option--selected {
    background: $brandColorPrimary;
    color: $brandColorPrimaryContrast;
    font-weight: normal;

    &.multiselect__option--highlight {
        background: $brandColorPrimaryDark;
        color: $brandColorPrimaryContrast;

        &::after {
            display: none;
        }
    }
}

.multiselect__option::after {
    display: none;
}

.multiselect__input {
    &,
    &:disabled {
        background: transparent;
    }
}
