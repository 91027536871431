
@use "client/css/globals" as *;

.flex-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}

.reason {
    font-weight: 600;
}

.explanation {
    color: $darkGrey;
}
