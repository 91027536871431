
@use "client/css/globals" as *;

.card {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    color: $contentColorPrimary;

    .clear-button {
        background: none;
        border: none;
        cursor: pointer;
        width: 100%;

        &:hover {
            background: $backgroundTonalGrey;
            border-color: $backgroundTonalGrey;
        }

        .header-section {
            align-items: center;
            display: flex;
            gap: 15px;
            justify-content: space-between;
            padding: 15px 30px;

            .header-content {
                align-items: center;
                display: flex;
                gap: 15px;
                text-align: left;

                .header-text {
                    font-weight: bold;
                }

                .progress-text {
                    width: max-content;
                }

                .progress-bar {
                    height: 6px;
                    min-width: 100px;
                    width: 100px;
                }
            }

            @media (max-width: 400px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .card-content {
        border-top: 1px $borderColorLight solid;
        padding: 15px 30px;
    }
}
