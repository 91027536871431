
@use "client/css/globals" as *;

.section-heading {
    background-color: $borderColorLight;
}

.section-title {
    font-size: 18px;
    margin: 0;
    padding: 15px 0 15px 40px;
}

.questions {
    background-color: $backgroundTonalGrey;
}
