
@use "client/css/globals" as *;

ul {
    // remove default styles
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.item {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    .remove-button {
        flex: 0 0 10px; // flex-basis should match icon width in template
        margin-left: 10px;

        &.align-top {
            align-self: flex-start;
            margin-top: 5px;
        }
    }

    &.separated:not(:last-child) {
        border-bottom: 1px dashed $borderColorLight;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}

.add-button {
    align-items: center;
    display: flex;

    .icon {
        margin-right: 5px;
    }
}
