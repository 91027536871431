
@use "client/css/globals" as *;

.custom-selector {
    margin-bottom: 15px;
    margin-top: 15px;
}

.date-picker-input {
    width: 100%;
}

::v-deep .multiselect__option--selected {
    background-color: white;
    color: #35495e;
}

.start-date-picker {
    margin-bottom: 10px;
}

.option {
    padding: 10px 15px;
}

.option:hover {
    background-color: $backgroundColorTertiary;
}

.option-input:checked {
    accent-color: $brandColorPrimaryDark;
}

.caret-icon {
    position: absolute;
    right: 15px;
    top: 65%;
    transform: rotate(0);
    transition: transform 0.3s;
}

.content {
    min-width: 15rem;
    position: absolute;
}

.save-button {
    margin-bottom: 15px;
    margin-top: 15px;
}

::v-deep .popover {
    margin-top: 5px;
}

.custom-date-picker-container {
    width: 100%;
}

::v-deep .popover-target {
    width: 100%;
}

.custom-option {
    padding: 0 15px;
}
