
.label-row-container {
    align-items: center;
    column-gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.small {
        padding: 15px 30px;
    }

    &.medium {
        padding: 20px 30px;
    }

    &.large {
        padding: 25px 30px;
    }
}

.input-container {
    display: flex;
    width: 100%;

    &.right {
        justify-content: flex-end;
    }

    ::v-deep .multiselect,
    ::v-deep textarea,
    ::v-deep input:not([type="checkbox"]) {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    // Condensed view when width < 1100px
    .label-row-container {
        align-items: flex-start;

        // Disable grid rules
        display: flex;
        flex-direction: column;
    }

    .input-container.right {
        justify-content: flex-start;
    }

    label {
        margin-bottom: 10px;
    }
}
