
@use "sass:math";
@use "client/css/globals" as *;

$caretWidth: 10px;

.list-item-expandable {
    list-style-type: none;
}

.list-item-expandable-header {
    align-items: flex-start;
    background: $backgroundColorSecondary;
    display: flex;
    padding: $spacingMedium;
}

.header-slot {
    align-items: flex-start;
    display: flex;
}

.list-item-expandable-icon {
    cursor: pointer;
    display: flex;
    padding-right: 20px;

    &::before {
        border-color: $contentColorPrimary transparent transparent transparent;
        border-style: solid;
        border-width: 8px math.div($caretWidth, 2);
        content: "";
        transform: translateX(math.div($caretWidth, 2)) translateY(6px) rotate(-90deg);
        transform-origin: 30% 30%;
        transition: transform 0.2s;
        width: 0;
    }

    .list-item-expandable-expanded &::before {
        transform: translateY(6px);
    }
}

.list-item-expandable-content {
    padding: $spacingMedium $spacingMedium $spacingMedium ($spacingMedium * 2 + $caretWidth);
}
