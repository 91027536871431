@use "sass:math";
@use "client/css/globals" as *;

$iconDimension: 15px;
$inputPadding: 12px;

.calendar-icon {
    height: $iconDimension;
    left: $inputPadding;
    position: absolute;
    top: 50%;
    transform: translateY(math.div(-$iconDimension, 2));
    width: $iconDimension;
    z-index: 1; // needed for compatibility with ModalDialog
}

.date-input {
    margin: 0; // Safari adds unwanted vertical margin
    padding-left: 2 * $inputPadding + $iconDimension;
    width: 100%;
}

.calendar-icon-wrapper {
    flex: 1 1 100%;
    position: relative;
}

// matches .has-error in client/components/multiselect-wrapper.vue
.has-error {
    border-color: $red1Light;
}
