
.title {
    padding: 0;
}

.modal-info-container {
    align-items: center;
    display: inline-flex;
    padding-bottom: 5px;

    > h4 {
        margin: 0;
    }
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.icon-container {
    align-items: center;
    display: flex;
    gap: 15px;
}

.report-page-link-container {
    display: flex;
    justify-content: stretch;
    margin-top: 15px;
    width: 100%;
}

.button-with-icon {
    display: flex;
    justify-content: center;
}

.copy-icon {
    margin-right: 5px;
}

.copy-button-container {
    margin-left: 10px;
}
