
@use "client/css/globals" as *;

.card {
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    padding: 30px;

    .section {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }
    }

    .link {
        color: $pilotPurple;
        text-decoration: underline;

        &:hover {
            color: $pilotText;
        }
    }
}
