
.report-months-selector {
    align-items: center;
    display: flex;
    justify-content: center;

    .months-multiselect {
        width: 150px;
    }

    .text {
        margin: 0 10px;
    }

    .month-year-picker {
        flex: 1;
        margin-right: 10px;

        ::v-deep input.date-input {
            width: 150px;
        }
    }
}
