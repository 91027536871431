
@use "client/css/globals" as *;

.card-header {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    height: 75px;
    justify-content: space-between;
    padding: 15px 30px 20px;

    ::v-deep .search-filter {
        max-width: 400px;
        width: 100%;
    }
}

.card-body {
    padding: 15px 30px;
}

.bulk-actions {
    padding-bottom: 10px;
    padding-top: 40px;

    .bulk-action-button {
        margin-right: 20px;
    }

    .bulk-account {
        margin-top: -10px;
    }
}

.institution-logo {
    height: 30px;
}
