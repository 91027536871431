
@use "client/css/globals" as *;

#customer-tools-delete-recognizer-output {
    .buttons,
    .description {
        max-width: $fieldWidthBase;
        position: relative;
    }
}
