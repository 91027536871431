
@use "client/css/globals" as *;

.create-mapping-container {
    border-bottom: 1px solid $borderColorLight;
    display: grid;
    grid-template-columns: 50% 50%;
}

.vendor-container {
    grid-column: 1;
}

.category-container {
    grid-column: 2;
}

.arrow-icon {
    margin-right: $spacingSmall;
}

.input-section {
    display: flex;
    flex-direction: column;
    padding: $spacingSmall $spacingMedium;
}

.vendor-picker-container {
    align-items: center;
    display: flex;
    gap: $spacingSmall;
    justify-content: space-between;
}

.category-picker-container {
    align-items: center;
    display: flex;
    justify-content: left;
}

.add-button {
    margin-left: $spacingSmall;
}
