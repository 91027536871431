
@use "client/css/globals" as *;

.tile-note {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusMedium;
    padding: $spacingMediumSmall;
}

.tile-note-header {
    margin-bottom: $spacingSmall;
}

.tile-note-date {
    color: $contentColorSecondary;
    margin-left: $spacingSmall;
}

.tile-note-content {
    font-size: $fontSizeBase;

    ::v-deep p {
        margin-bottom: 0;
    }
}
