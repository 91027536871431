
@use "client/css/globals" as *;

ul {
    line-height: 2;
}

.buttons {
    padding-top: 50px;
}

.text {
    margin-bottom: $spacingMedium;
}

.card-content-description {
    font-weight: bold;
    line-height: 2;
}
