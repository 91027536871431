
@use "client/css/globals" as *;

.connections-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline-start: 0;
}

.institution-card-empty {
    color: $contentColorSecondary;
    font-size: $fontSizeBase;
    text-align: center;
    width: 100%;
}
