
@use "sass:math";
@use "client/css/globals" as *;

$nextStepDiameter: 28px;
$nextStepRadius: math.div($nextStepDiameter, 2);
$nextStepsLeftSpacing: 30px;

p:last-child {
    margin: 0; // reset global style
}

.preparing-books-card {
    align-items: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .preparing-books-text {
        flex: 1 1 200px;
    }

    .celebrate-graphic {
        flex: 0 0 auto;
        margin-left: $spacingMedium;

        @media (max-width: 1120px) {
            display: none;
        }
    }
}

// replicated from onboarding-section.vue's .section-heading
.heading {
    color: $pilotText;
}

.next-steps-section {
    padding-right: 80px;
}

.next-steps-list {
    counter-reset: next-steps-counter;
    list-style: none;
    margin-top: $spacingLarge;
    padding-left: $nextStepRadius;

    li {
        border-left: 5px solid $brandColorPrimary;
        counter-increment: next-steps-counter;
        margin-left: 0;
        padding-bottom: $spacingLarge;
        padding-left: $nextStepsLeftSpacing;
        position: relative;

        &:last-child {
            border-left-color: transparent;
            padding-bottom: 0;
        }

        &::before {
            align-items: center;
            background-color: $brandColorPrimary;
            border-radius: 50%;
            color: $brandColorPrimaryContrast;
            content: counter(next-steps-counter);
            display: flex;
            font-weight: bold;
            height: $nextStepDiameter;
            justify-content: center;
            left: -$nextStepsLeftSpacing + $nextStepRadius;
            position: absolute;
            top: -3px; // manual adjustment for vertical alignment
            width: $nextStepDiameter;
        }
    }

    h3 {
        color: $brandColorPrimaryDark;
        margin-bottom: $spacingMedium;
    }
}

.expected-delivery-date {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;

    .highlighted-date {
        color: $brandColorPrimary;
        font-size: $fontSizeLarge;
        font-weight: bold;
    }

    p {
        margin: 0;
    }
}
