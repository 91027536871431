
@use "client/css/globals" as *;

#transaction-split-modal {
    // override global styles under .pilot-page-content in logged-out-layout.vue
    .buttons {
        margin-top: 0;
    }
}

.vertical-align {
    align-items: center;
    display: flex;
}

.splits-txn-header {
    flex: 0 0 auto;
}

.icon {
    margin-right: $spacingSmaller;
}

.table-wrapper {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;

    // Customer Body Shadow
    box-shadow: 0 4px 14px rgba(66, 61, 113, 0.08);
    padding: $spacingMedium $spacingMediumLarge;
}

table {
    margin-bottom: 0;

    thead tr,
    tbody tr {
        border-bottom: 1px solid $borderColorLight;
    }

    tbody.no-footer tr:last-child {
        border-bottom: none;
    }

    th {
        color: $contentColorSecondary;
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;
    }

    tfoot td {
        padding-bottom: 0;
        padding-top: $spacingMediumSmall;
    }

    .column-amount {
        width: 10em;
    }

    .column-index,
    .column-delete {
        width: 2em;
    }
}

.balance-remaining {
    display: flex;
    flex-direction: column;

    .value {
        font-size: $fontSizeMedium;
        white-space: nowrap;
    }

    .label {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }
}

.actions-left {
    display: flex;
}

.balance-error {
    max-width: 500px;
}
