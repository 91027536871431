
@use "client/css/globals" as *;

.insight-summary-card.card.default {
    border: 1px solid $brandColorPrimaryDark;
    margin-bottom: 20px;

    .card-header {
        align-items: center;
        display: flex;
        margin-bottom: 10px;

        h4 {
            margin: 0;
        }
    }

    .category-list {
        margin: 10px 0 0;
        padding: 0 0 0 20px;
    }

    .bold {
        font-weight: bold;
    }
}
