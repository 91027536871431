
@use "client/css/globals" as *;

.update-details-container {
    display: inline-block; // Ensures hover works as expected over the container.
}

// Override the disabled button styles
::v-deep .button.borderless:disabled {
    color: $highlightPurple;
    cursor: auto;
}

// Override the bubble position
::v-deep .bubble-down {
    left: auto;
    right: 20px;
}
