
.portal-txns-group {
    margin-top: 20px;
}

::v-deep .pilot-table-header-cell.first {
    border-top-left-radius: 5px;
}

::v-deep .pilot-table-header-cell.last {
    border-top-right-radius: 5px;
}

::v-deep .pilot-table-header-cell {
    height: 50px;
}

.footer-info {
    display: block;
    margin-bottom: 0;
}

.dot {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
    width: 10px;
}
