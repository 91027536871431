
@use "client/css/globals" as *;

.container {
    font-size: $fontSizeBase;
}

.content {
    display: block;
}

p {
    margin: 0;
}

.grey-text {
    color: #7e7b76;
    font-size: $fontSizeSmall;
}
