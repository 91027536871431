
@use "client/css/globals" as *;

label {
    font-weight: bold;
}

h3 {
    margin-bottom: 36px;
}

input[readonly] {
    background: $backgroundColorSecondary;
}

.fields {
    max-width: 400px;
}
