
@use "client/css/globals" as *;

.heading {
    // align title with info tooltip
    display: flex;
}

::v-deep .pilot-table-header-cell.medium {
    padding-left: 30px;
    padding-right: 30px;
}

::v-deep(.pilot-table-row:last-child .pilot-table-row-cell) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

::v-deep .pilot-table-row-cell {
    padding-left: 30px;
    padding-right: 30px;
}

::v-deep .pilot-table-row-cell.medium.last {
    padding-right: 30px;
}

::v-deep .overflow-action-cell {
    justify-content: flex-end;
}

::v-deep .overflow-content {
    min-width: 200px;
}

.vendor-mappings-container {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
}

.vendor-mappings-header {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacingMedium 0 0 $spacingMedium;
}

.search-card {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacingSmall $spacingMedium;
    padding: 13px 30px;
}

.add-mapping-icon {
    margin-right: 5px;
}

// add margin to the first paragraph in the bubble
::v-deep .tooltip-content > p:first-of-type {
    margin-bottom: 20px;
}

.configuration-link {
    color: $pilotPurple;
}
