
@use "client/css/globals" as *;

.table-row > td {
    padding: $spacingMediumSmall $spacingMediumSmall $spacingMediumSmall 0;
}

.table-row:hover {
    background-color: $lightGrey;
}

.customer-name {
    overflow-wrap: break-word;

    .display-name {
        color: $brandColorPrimary;
        font-weight: 600;

        &:hover {
            color: $pilotText;
            text-decoration: none;
        }
    }

    .pilot-username,
    .legal-name {
        color: $darkGrey;
        font-size: $fontSizeSmall;
    }
}

.entity-type {
    color: $darkGrey;
    font-size: $fontSizeSmall;
    overflow-wrap: break-word;
}

.r-and-d-provider {
    color: $darkGrey;
}
