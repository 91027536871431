
.checklist-section-name {
    align-items: center;
    display: flex;
}

.align-right {
    // Right-align single item in flex layout
    margin-left: auto;

    // Hardcode alignment with left-aligned '...' icon in other rows.
    // If the alignment or size of the '...' icon changes, this would also need to change.
    margin-right: 27px;
}

.checklist-section-title-flex {
    display: flex;
}

.checklist-section-time-tracking {
    left: -4px;
    margin-bottom: 14px;
}

.checklist-section-empty {
    margin-bottom: 0;
}
