
@use "client/css/globals" as *;

.tooltip {
    width: 200px;
}

input[readonly] {
    background: $backgroundColorSecondary;
}

.subscription-details-tooltip-container {
    height: 24px;
    vertical-align: bottom;
}

.subscription-details-products-header {
    padding-top: $spacingMedium;
}

.subscription-details-product {
    align-items: center;
    background-color: $backgroundColorPrimary;
    border: solid 1px $borderColorLight;
    border-top-width: 0;
    display: flex;
    padding: $spacingMedium;
    width: $fieldWidthBase;

    &:first-child {
        border-radius: 4px 4px 0 0;
        border-top-width: 1px;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    .active-product-name {
        padding-left: $spacingMediumSmall;
    }

    .tax-note {
        font-size: $fontSizeSmall;
    }

    .tax-link {
        color: $linkColorSecondary;
    }

    .product-status-light {
        align-self: flex-start;
        background-color: $green1;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        margin-top: $spacingSmaller;
        width: 10px;
    }

    .download-qa-history-button {
        margin-bottom: $spacingSmall;
        margin-top: $spacingSmall;
    }
}
