
@use "client/css/globals" as *;

.overflow,
.overflow:disabled {
    border-color: transparent;
    border-radius: 50%;
    padding-left: 17px;
    padding-right: 17px;

    &.border {
        border-color: $brandColorPrimary;
    }

    &.small {
        border-radius: 4px;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.overflow:hover:not(:disabled),
.overflow:focus-visible:not(:disabled) {
    background: $backgroundColorTertiary;
}

// Show border on keyboard focus (but not mouse focus)
.overflow:focus-visible:not(:disabled) {
    border-color: $brandColorPrimary;
}

.overflow:disabled {
    &:hover {
        cursor: not-allowed;
    }

    .ellipses,
    .ellipses::before,
    .ellipses::after {
        background-color: $contentColorTertiary;
    }
}

.ellipses {
    @extend %ellipsis;

    position: relative;
    top: -2px;

    &.small {
        top: -3px;
    }

    &::before,
    &::after {
        @extend %ellipsis;

        content: "";
        position: absolute;
    }

    &::before {
        left: -6px;
    }

    &::after {
        right: -6px;
    }
}

%ellipsis {
    background-color: $contentColorPrimary;
    border-radius: 50%;
    display: inline-block;
    height: 3px;
    width: 3px;
}
