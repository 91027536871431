
$iconSize: 16px;

.attribution {
    height: $iconSize;

    .attribution-icon {
        height: $iconSize;
        width: $iconSize;
    }

    ::v-deep .tooltip-container {
        height: $iconSize;
    }

    ::v-deep .tooltip-content {
        // these are short tooltips; don't wrap the text
        width: max-content;
    }
}
