
@use "client/css/globals" as *;

#customer-tools-upload {
    max-width: $fieldWidthBase;
}

.buttons {
    margin-bottom: 45px;
}

.result-row {
    align-items: center;
    display: flex;
    padding: 20px 30px;

    ::v-deep .tooltip-container {
        align-items: center;
        display: flex;
    }

    .uploaded-account {
        margin-left: 15px;
    }

    .icon {
        cursor: pointer;
    }
}
