
@use "client/css/globals" as *;

// override the default table styles
table {
    margin: 0;
}

// override the default table-bordered styles
::v-deep .table-bordered {
    margin: 0;
}

// override the default table-bordered styles
tbody:last-child > tr:last-child td {
    padding-bottom: 15px;
}

.vendor-inspector-table {
    margin: 0;

    .vendor-row {
        cursor: pointer;

        &:hover {
            &,
            & > td {
                background: $yellow1Background;
                text-decoration: underline;
            }
        }
    }

    .vendor-name-cell,
    .vendor-name-header,
    .total-name-cell {
        background: $backgroundColorPrimary;
        border-right: 1px solid $borderColorLight;
        left: 0;
        position: sticky;
        width: 200px;
        z-index: $zIndexBase + 3;
    }

    .vendor-name-cell,
    .vendor-name-cell::before,
    .total-name-cell,
    .total-name-cell::before {
        z-index: $zIndexBase + 1;
    }

    .month-header {
        background: $backgroundColorPrimary;
        width: 100px;
        z-index: $zIndexBase + 2;
    }

    .vendor-name-cell::before,
    .vendor-name-header::before,
    .total-name-cell::before {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: -8px;
        width: 7px;
    }

    .total-name-cell,
    .total {
        border-top: 1px solid $borderColorLight;
        font-weight: bold;
    }

    th:last-child,
    td:last-child {
        border-left: 1px solid $borderColorLight;
    }

    th:last-child {
        background: $backgroundTonalGrey;
    }
}
