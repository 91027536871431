
@use "client/css/globals" as *;
@use "client/modules/checklist/_components/checklist-task";

.new-task-wrapper {
    background: $backgroundColorSecondary;
    overflow-y: hidden;
    z-index: $zIndexBase + 2;

    .checklist-align-body {
        display: flex;
    }
}
