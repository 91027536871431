@use "client/css/globals" as *;

// The multiselect is a list with classname "multiselect__element" followed by a
// hidden element (that is not a "multiselect__element") which only shows when there
// are no results (which is never the case if we show "none of the  above").
// To make the last item (none of the above) sticky, we select the second-to-last element with type `li`.
// (Also specifying that this second-to-last `li` has classname "multiselect__element".)
@mixin last-item-sticky {
    ::v-deep li.multiselect__element:nth-last-of-type(2) {
        background: white;
        border-top: 1px solid $borderColorLight;
        bottom: 0;
        position: sticky;
    }
}

// To make PilotTable column widths easier to work with, we override the default `display`
// values for the table, thead, tbody, tfoot, and tr elements. The root table element uses `grid`,
// and the descendents use `contents` to let the `grid-template-columns` definition
// from the root table level affect the great-grandchild cell placements.
// See https://developer.mozilla.org/en-US/docs/Web/CSS/display#box for more details.
// `display: subgrid` would be preferable here, not least because it preserves screen-reader access,
// but Chrome doesn't yet support it.
@mixin grid-table-row {
    display: contents;

    // old approach, preserved for the day Chrome supports subgrid
    // display: grid;
    // grid-column: 1 / -1;
    // grid-template-columns: subgrid;
}

@mixin table-highlighted {
    background-color: $purpleSelectionBackground;
    border-top: 1px solid $backgroundColorPrimary;
}

// shared padding styles for header and row cells
@mixin table-padding {
    &.xsmall {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    &.first:not(.xsmall) {
        padding-left: 30px;
    }

    &.first.selectable {
        padding-left: 10px;
    }

    &.last:not(.xsmall) {
        padding-right: 30px;
    }

    &.small {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    &.medium {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    &.large {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    &.xlarge {
        padding-bottom: 25px;
        padding-top: 25px;
    }
}
