
#depreciation-schedules {
    width: 600px;
}

.depreciation-schedule-months {
    width: 180px;
}

.depreciation-schedule-delete {
    width: 30px;
}
