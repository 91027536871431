
@use "client/css/globals" as *;

// remove the default margin from the table
table {
    margin: 0;
}

.report-table {
    width: 100%;

    .report-column:first-of-type {
        width: 250px;
    }

    .report-summary {
        border-top: 1px solid $borderColorDark;
        font-weight: bold;
    }

    .report-toplevel.report-summary {
        border-bottom: 3px double $offBlack;
    }

    @for $i from 1 through 10 {
        .report-indent-#{$i} {
            padding-left: #{$i * $spacingMedium};
        }
    }

    .report-negative {
        color: $red1;
    }

    ul {
        padding: 0 25px;

        li.insight-message {
            font-size: $fontSizeBase;
            list-style-type: disc;
            margin-bottom: $spacingSmall;
        }
    }
}
