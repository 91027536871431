
.center-text {
    text-align: center;
}

.buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
