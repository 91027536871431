
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.split-txn-prompt-cell {
    align-items: center;
    display: grid;
    justify-content: flex-start;
    width: 100%;
}

.cell-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: minmax(auto, categorize.$categorizeInputWidth);
}

.split-txn-prompt {
    background: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    display: grid;
    gap: 10px;
    padding: 10px;
}

.prompt-text {
    align-items: center;
    display: grid;
    gap: 5px;
    grid-template-columns: 24px 1fr;

    ::v-deep .tooltip-container {
        height: 24px;
        width: 24px;
    }
}

.prompt-btns {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;

    ::v-deep .base-button {
        margin: 0;
    }
}

.split-txn-note {
    align-items: center;
    background: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    display: grid;
    gap: 10px;
    grid-template-columns: 16px 1fr;
    padding: 10px;
}
