
@use "client/css/globals" as *;

.spinner {
    margin-right: 10px;
}

.security-answer-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: $spacingMedium;
    max-width: $fieldWidthBase;

    .security-answer-question,
    .security-answer-other,
    .security-answer {
        margin-bottom: $spacingSmall;
        width: 100%;
    }
}

.connection-actions {
    display: grid;
    grid-template-columns: auto auto;

    .left-align {
        text-align: left;
    }

    .right-align {
        text-align: right;
    }
}

.showable-hidable-password {
    margin-right: 10px;
    max-width: 480px; // magic number the same width as input
    position: relative;
}

.show-hide {
    left: 420px; // magic number
    position: absolute;
    top: 0; // magic number
}

.gusto-oauth {
    margin-bottom: 20px;
}

.institution-fields {
    display: flex;
    gap: 15px;
}

.button-right-align {
    display: flex;
    justify-content: flex-end;
}
