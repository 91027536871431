
@use "client/css/globals" as *;

.tax-heading-card {
    align-items: center;
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 15px 25px;

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > p {
            margin-bottom: 10px;
        }

        .tax-deadline {
            color: $pilotPurple;
            margin-bottom: 0;
        }
    }

    .logo-container {
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.tax-text {
    margin-bottom: 30px;
}

.tax-response {
    margin-bottom: 30px;
}
