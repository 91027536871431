
@use "client/css/globals" as *;

.submitted-successfully-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 60px;

    .checkmark-container {
        margin-bottom: 10px;
    }

    .text-container {
        font-size: $fontSizeMedium;
        margin: 15px 0 10px;
        max-width: 500px;
    }
}
