
@use "client/css/globals" as *;

$bannerHeight: 80px;

#customer-portal {
    padding-top: $bannerHeight;
}

.customer-portal-banner {
    background-color: $red1;
    color: $backgroundDarkPrimaryContrast;
    height: $bannerHeight;
    padding: $spacingMedium;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: $zIndexFlyout + 10;

    p {
        margin: 0;
    }

    a {
        color: $backgroundDarkPrimaryContrast;
        text-decoration: underline;
    }
}

.loading-container {
    align-items: center;
    background: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding-top: $bannerHeight;
    position: fixed;
    width: 100vw;
}
