
@use "client/css/globals" as *;

.table-bordered-wrapper {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    flex-direction: column;
    margin-bottom: $spacingMedium;

    &.is-scrollable {
        overflow-y: auto;
    }
}

.table-bordered-header:not(:empty) {
    background: $backgroundColorPrimary;
    border-bottom: 1px solid $borderColorLight;
    padding: $spacingMediumSmall $spacingMedium;
}

.table-bordered-content {
    display: flex;
    flex-direction: column;

    .is-scrollable & {
        overflow-y: auto;
    }
}

thead > tr {
    border-bottom: none;

    > th {
        background: $backgroundColorPrimary;
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;
        position: sticky;
        top: 0;
        z-index: $zIndexBase;

        // Applying bottom border as pseudo element because borders will scroll if position sticky
        &::after {
            border-bottom: 1px solid $borderColorLight;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
        }
    }
}

tbody {
    > tr {
        border-top: none; // override default table styles

        &:first-child {
            border-top: 1px solid $borderColorLight;

            thead + & {
                border-top: none;
            }
        }
    }

    &:last-child > tr:last-child td {
        padding-bottom: $spacingMedium;
    }
}

td {
    padding-bottom: $spacingMediumSmall;
    padding-top: $spacingMediumSmall;
    position: relative;
}

td:first-child,
th:first-child {
    padding-left: $spacingMedium;
}

td:last-child,
th:last-child {
    padding-right: $spacingMedium;
}
