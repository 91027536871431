
@use "client/css/globals" as *;

#solitaire {
    display: block;
    height: 550px;
    margin: 0 auto;
    width: 800px;
}

.solitaire-main-window {
    height: 600px;
    margin: 0 auto;
    width: 806px;
}

.solitaire-window {
    background: $ctaGreen;
    border: 3px ridge #7f787f;
    font-family: sans-serif;

    h2 {
        background: #00007f;
        color: #fff;
        font-size: 14px;
        padding: 5px;
    }

    p {
        margin: 10px;
        padding: 0;
    }
}

@keyframes solitaireDialog {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.solitaire-dialog {
    animation: solitaireDialog 0.1s ease-in 6s forwards;
    background: #bfb8bf;
    height: auto;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 240px;
}

.solitaire-button {
    background: #bfb8bf;
    border: 3px outset #7f787f;
    color: $contentColorPrimary !important;
    display: inline-block;
    font-size: 14px;
    margin: 10px;
    padding: 5px 0;
    text-align: center;
    width: 80px;

    &:focus {
        outline: none;
    }

    &:active {
        border-style: inset;
    }

    &:hover {
        text-decoration: none;
    }
}
