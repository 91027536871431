
@use "client/css/globals" as *;

$wrappingBreakpoint: 1100px; // arbitrary width

section {
    // Override default bottom-margin: 80px which was causing a white bar to appear at the bottom of page
    margin: 0;
}

.main-section {
    display: flex;
    padding-bottom: $spacingMediumLarge * 2;

    @media (max-width: $wrappingBreakpoint) {
        & {
            flex-wrap: wrap;
        }
    }

    .main-content {
        flex: 1 1 600px; // arbitrary width

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
                margin-bottom: $spacingMediumLarge;
            }
        }
    }

    .sidebar {
        flex: 0 0 340px;
        margin: 63px 0 0 20px;

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
                margin-left: 0;
            }
        }
    }
}

.loading-error-container {
    width: 100%;
}

.customer-info-card {
    position: sticky;
    top: 30px;
}

.customer-info-card:not(:last-child) {
    margin-bottom: $spacingMediumLarge;
}
