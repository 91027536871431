
@use "sass:math";
@use "client/css/globals" as *;

table {
    table-layout: auto;
}

th {
    padding-left: math.div($spacingMediumSmall, 2);
    padding-right: math.div($spacingMediumSmall, 2);
    white-space: nowrap;

    &.input-column {
        padding-left: $spacingSmall;
        padding-right: $spacingSmall;
    }
}

.empty-banner {
    padding: $spacingLarge;

    p {
        color: $contentColorSecondary;
    }
}

// TODO move some of these styles to rule drawer
.rule-drawer-view-transactions {
    color: $brandColorPrimary;
    float: right;
    margin-right: 35px;
    margin-top: -3px;
}
