
.open-close-icon {
    &.right {
        transform: rotate(-180deg);
    }

    &.default-color {
        fill: currentColor;
    }
}
