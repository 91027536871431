
@use "client/css/globals" as *;

.nav-container {
    margin-bottom: 15px;
    width: 100%;
}

.nav {
    border-bottom: 1px solid $pastelViolet;
    margin: 0;
    padding: 0;
}

.loading {
    display: flex;
    justify-content: center;
}
