@use "sass:color";
@use "globals" as *;

@mixin tabular-numbers {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    text-align: right;
}

table {
    border-collapse: collapse;
    margin-bottom: $spacingMedium;
    table-layout: fixed;
    width: 100%;
}

td,
th {
    padding: $spacingSmaller $spacingSmall;
    text-align: left;

    &.number {
        @include tabular-numbers;
    }

    table.more-spacing > thead > tr > &,
    table.more-spacing > tbody > tr > & {
        padding-bottom: $spacingMedium;
        padding-top: $spacingMedium;

        &:first-child {
            padding-left: $spacingMedium;
        }

        &:last-child {
            padding-right: $spacingMedium;
        }
    }

    table.less-spacing > thead > tr > &,
    table.less-spacing > tbody > tr > & {
        padding-bottom: 3px;
        padding-top: 3px;
    }
}

thead {
    > tr {
        border-bottom: 1px solid $borderColorDark;

        table.no-chrome > & {
            border-bottom: none;
        }
    }

    th {
        font-weight: bold;
        padding-bottom: $spacingSmaller;
        vertical-align: bottom;

        &.checkbox {
            text-align: center;
        }
    }
}

tbody th {
    font-weight: bold;
}

tbody tr {
    border-top: 1px solid $borderColorLight;

    &:first-child,
    table.no-chrome > & {
        border-top: none;
    }

    &.table-row-success {
        background: $green1Background;
    }

    &.table-row-highlighted {
        background: $yellow1Background;
    }

    .table-zebra &,
    .table-zebra-dark &,
    &.table-zebra-even,
    &.table-zebra-odd {
        border-top: none;
    }

    .table-zebra & {
        &:nth-child(even) {
            background: $backgroundColorSecondary;
        }
    }

    .table-zebra-dark & {
        &:nth-child(even) {
            background: color.adjust($mattePurple, $lightness: 3%, $saturation: -3%);
        }
    }

    &.table-zebra-odd {
        background: $backgroundColorSecondary;

        &.table-row-success {
            background: color.adjust($green1Background, $lightness: -10%);
        }

        &.table-row-highlighted {
            background: color.adjust($yellow1Background, $lightness: -10%);
        }
    }

    &.table-zebra-even + .table-zebra-even,
    &.table-zebra-odd + .table-zebra-odd {
        td {
            padding-top: 0;
        }
    }
}
