
@use "client/css/globals" as *;

.rule-review-row td {
    padding: $spacingMediumSmall $spacingSmall;
    vertical-align: top;
}

.rule-format {
    .rule-format-info {
        margin-bottom: $spacingSmall;
    }

    .label {
        display: inline-block;
        font-weight: bold;
        width: 100px;
    }

    .button.borderless {
        color: $linkColorPrimary;
    }
}

.vendor-category {
    > * {
        margin-bottom: $spacingSmall;
    }
}

.edit-rule .button {
    width: 100px;
}

.highlight,
// for vue-text-highlight
::v-deep .highlight {
    background: $yellow1Background;
    border-radius: 0;
}
