
@use "client/css/globals" as *;

.container {
    margin-top: 30px;
}

.label {
    font-weight: bold;
    margin-bottom: $spacingSmall;
}
