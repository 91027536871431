
@use "client/css/globals" as *;

.change-txns-search-container {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    padding: 30px;
    position: relative;

    // widths of date row elements
    $dateRangeWidth: 185px;
    $dateSelectWidth: 150px;
    $toWidth: 15px;
    $gridGap: 10px;

    // for use in making other input widths align with date row
    $totalDateWidth: $dateRangeWidth + $gridGap + $dateSelectWidth + $gridGap + $toWidth +
        $gridGap + $dateSelectWidth;

    .date-row {
        align-items: flex-end;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: $dateRangeWidth $dateSelectWidth $toWidth $dateSelectWidth;

        span {
            padding-bottom: $gridGap;
        }
    }

    .search-row {
        .unified-search-row {
            align-items: flex-end;
            display: grid;
            grid-template-columns: auto 1fr auto;
            justify-items: flex-start;

            &.expanded {
                grid-template-columns: 1fr auto;
            }
        }

        .info-icon-wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            margin-top: 10px;
        }

        .info-tooltip {
            height: 24px; // match icon height

            ::v-deep .tooltip-content {
                width: 300px;
            }

            &.txn-type-tooltip ::v-deep .tooltip-content {
                width: 450px;
            }
        }

        .search-icon-wrapper {
            position: relative;

            .search-icon {
                left: 5px;
                position: absolute;
                top: 20%;
            }

            .search-input {
                padding-left: 30px;
                width: $totalDateWidth;
            }
        }

        .more-search-options {
            margin-left: 20px;
            padding-bottom: 10px;
        }

        .actions-row {
            align-items: center;
            border-top: 1px solid $borderColorLighter;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-top: 20px;
            padding-top: 20px;
        }
    }

    .advanced-search-row {
        border-top: 1px solid $borderColorLight;
        margin-top: 20px;
        padding-top: 20px;
    }

    .txn-type .multiselect {
        margin-bottom: 10px;
        width: $totalDateWidth;
    }

    .search-criterion {
        align-items: center;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50px auto auto 1fr 40px;
        margin-bottom: 10px;
        margin-top: 10px;

        ::v-deep .multiselect {
            &.search-criterion-attribute,
            &.search-criterion-comparison {
                width: 230px;
            }
        }

        .search-criteria-term {
            width: unset;
        }

        .logical-operator {
            justify-self: flex-end;
        }

        .delete-criterion {
            justify-self: center;
        }
    }

    .icon {
        margin-right: $spacingSmaller;
        position: relative;
        top: 3px;
    }
}
