
@use "client/css/globals" as *;

table {
    margin-bottom: 25px;
    margin-top: 25px;
}

thead > tr {
    background-color: $backgroundColorSecondary;
    border-bottom: none;
    font-weight: bold;
}
