
@use "client/css/globals" as *;
@use "client/modules/portal-activity/css/cash-flow-and-spend-report-table";

::v-deep .pilot-table-header-cell.sticky-header.medium {
    height: 50px;
    z-index: 0; // allows the hover bubble to appear above the column header
}

// lock the upper leftmost cell at the top
::v-deep .pilot-table-header-cell.sticky-header.medium.first {
    left: 0;
    position: sticky;
    z-index: 1;
}

.bold {
    font-weight: bold;
}

.column-header {
    // to lock the leftmost column
    left: 0;
    position: sticky;
    white-space: nowrap;
    z-index: 1;
}

.row-indent {
    padding-left: 20px;
}

.no-data {
    padding-left: 16px;
}

.container {
    min-height: 265px; // prevents everything below the table from shifting after the table loads
    padding-bottom: 10px;
}

::v-deep .pilot-table-row-cell.medium.number.clickable-row-cell:hover {
    background-color: $yellow1Background;
    cursor: pointer;
    text-decoration-line: underline;

    &.negative-amount {
        text-decoration-color: $red1;
    }
}

::v-deep .pilot-table-row-cell.medium.top-border {
    border-top: 1px solid rgba(0, 0, 0, 0.4);
}
