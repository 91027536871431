
@use "client/css/globals" as *;

ul {
    display: grid;
    list-style-type: none;
    margin: 0;
    padding: 0;
    row-gap: 30px;
}

.institution-title,
.institution-without-mapping-title,
h2 {
    font-size: 18px;
    margin: 0;
}

.institution-title {
    padding-left: 10px;
}

a {
    align-items: center;
    color: $contentColorPrimary;
    display: flex;
    line-height: 0; // remove extra bottom spacing from institution logo
    width: fit-content; // make link click target area only within the contents instead of the entire 1fr area
    &:hover {
        color: $brandColorPrimary;
    }
}

.institution-card-header,
.institution-card-without-mapping {
    display: flex;
    padding: 20px 30px;
}

.institution-card-header {
    justify-content: space-between;
}

.link-icon {
    // One-off adjustment to make 24px icon 16px wide to match Design System Figma
    margin-left: -4px;
    margin-right: -4px;
}

.institution-img {
    height: 24px;
    width: 24px;
}

.call-to-action-left-aligned,
.action-button {
    margin-left: 20px;
}

::v-deep a.setup-guide {
    // Make the BaseButton look a bit better when inline with text
    align-items: flex-end;
    gap: 0;
}

.external-link {
    line-height: 0;
}

::v-deep .tooltip-content {
    width: 400px;
}

.bank-sync-title {
    margin: 0;
    padding: 0 0 10px;
}

.text-with-icon {
    align-items: center;
    display: flex;
    line-height: 0;
}

.success-icon {
    margin-left: 5px;
}

.bank-sync-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.institution-card-header-left,
.institution-card-header-right {
    align-items: center;
    display: flex;
    margin: 0;
}

.institution-card-header-right {
    gap: 30px;
}
