
$animation: all 0.2s ease-in-out;

.icon-chevron-circle {
    cursor: pointer;
    transition: $animation;

    &.left {
        transform: rotate(90deg);
    }

    &.up {
        transform: rotate(180deg);
    }

    &.right {
        transform: rotate(270deg);
    }

    &.disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
}
