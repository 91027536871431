
@use "client/css/globals" as *;

.about-section {
    margin-bottom: 15px;
}

.about-header {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    border-radius: 5px;
    justify-content: space-between;
    padding: 15px;
}

.about-title {
    font-size: $fontSizeBase;
    font-weight: bold;
    margin: 0 10px;
}

h3,
p {
    margin: 0;
}

.icon-fields {
    column-gap: 15px;
    display: grid;
    grid-template-columns: 24px 1fr;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    row-gap: 10px;

    ::v-deep input,
    ::v-deep textarea {
        // Override global 480px
        width: auto;
    }
}

.about-header,
.see-more-less-section {
    align-items: center;
    display: flex;
}

.company-description {
    align-items: top;
    display: flex;
}

.company-description,
.company-attributes {
    padding: 15px 30px;
}

.banner {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
}

.see-more-less-section {
    border-top: 1px solid $borderColorLight;
    justify-content: center;
    padding: 15px;
}

.icon {
    height: 20px;
    margin-right: 15px;
    width: 20px;
}

.attribute {
    margin-bottom: 10px;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }
}

.bold-text {
    font-weight: bold;
}

a {
    color: $pilotPurple;
}

.icon-chevron {
    margin-left: 10px;
}

.edit-mode-buttons {
    display: flex;
    flex-direction: flex-end;
}

.cancel-button {
    color: $darkGrey;

    &:hover {
        color: $pilotPurple;
    }
}
