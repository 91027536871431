
@use "client/css/globals" as *;

.clear-button {
    cursor: pointer;
    width: 100%;

    &:disabled {
        border-color: $borderColorLight;
        cursor: default;

        &:focus,
        &:hover {
            box-shadow: none;
        }
    }
}

.connection-task-item {
    align-items: center;
    border: 1px solid;
    border-color: $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    justify-content: space-between !important;
    margin-bottom: $spacingMedium;
    padding: $spacingMedium;
    text-align: left;

    &:hover {
        box-shadow: $purpleShadow;
    }

    .label-display {
        align-items: center;
        display: flex;

        .institution-display-name {
            margin-left: $spacingMedium;
        }
    }

    .connect-display {
        align-items: center;
        display: flex;
        gap: 20px;
        justify-content: center;

        .button-focus {
            // override default base button style so it doesn't stay highlighted after being clicked
            &:focus {
                background: transparent;
                border-color: $red1;
                color: $red1;
            }

            &:hover {
                background: $backgroundColorDarkRed;
                border-color: $backgroundColorDarkRed;
                color: $brandColorPrimaryContrast;
            }
        }
    }
}

.connect-button:last-child {
    // last child is needed to overwrite the BaseButton style
    margin-right: 5px;

    // override default base button style so it doesn't stay highlighted after being clicked
    &:focus {
        background: transparent;
        color: $brandColorPrimary;
    }
}

.remove-button-container {
    align-items: center;
    display: flex;
}

::v-deep .loading {
    padding: 0;

    .spinner,
    .spinner::after {
        border-color: $contentColorTertiary !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
    }
}

.opacity-50 {
    opacity: 0.5;
}
