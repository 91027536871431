
.list {
    display: grid;

    &.horizontal {
        grid-auto-flow: column;
        justify-content: start;

        &.start {
            align-items: start;
        }

        &.center {
            align-items: center;
        }

        &.end {
            align-items: end;
        }
    }

    &.vertical {
        align-content: start;
        grid-auto-flow: row;

        &.start {
            justify-items: start;
        }

        &.center {
            justify-items: center;
        }

        &.end {
            justify-items: end;
        }
    }

    &.five {
        gap: 5px;
    }

    &.ten {
        gap: 10px;
    }

    &.fifteen {
        gap: 15px;
    }

    &.twenty {
        gap: 20px;
    }

    &.thirty {
        gap: 30px;
    }
}
