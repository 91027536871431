
@use "client/css/globals" as *;

.page {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
    overflow: hidden;

    &.banner,
    &.admin {
        height: calc(100vh - 75px); // subtract banner height from viewport height
    }

    &.banner.admin {
        height: calc(
            100vh - 150px
        ); // subtract banner height and header height from viewport height
    }

    .nav {
        &.banner,
        &.admin {
            height: calc(100vh - 75px); // subtract banner height from viewport height
        }

        &.banner.admin {
            height: calc(
                100vh - 150px
            ); // subtract banner height and header height from viewport height
        }
    }

    .main {
        display: grid;
        grid-template-rows: auto 1fr;
        overflow-x: hidden;
        overflow-y: auto;

        .portal-header {
            padding: 25px 30px;
        }

        .portal-content {
            padding: 0 30px 30px;
            transition: all 0.3s;
            width: calc(100vw - 250px); // subtract the width of the nav

            &.collapsed {
                width: calc(100vw - 50px); // subtract the width of the collapsed nav
            }
        }

        .portal-content-mobile {
            padding: 0 30px 30px;
            transition: all 0.3s;
            width: calc(100vw - 50px); // subtract the width of the collapsed nav
        }
    }
}

h1.primary-heading {
    font-size: 24px;
    margin: 0; // reset default
}

.banner-text {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;

    strong {
        padding-right: 5px;
    }

    ::v-deep .button {
        color: $brandColorPrimaryContrast;
        margin: 0;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: $brandColorPrimaryContrast;
        }
    }
}

.logo {
    line-height: 0; // remove extra bottom gap below logo
    position: sticky;
    top: 0;
}

.logo-img {
    &.collapsed {
        transform: translateY(4px) scale(2); // scale and adjust logo image beyond container
        width: 25px; // limit the width of the logo
    }
}

.controls {
    align-items: center;
    display: flex;

    > *:not(:last-child) {
        margin-right: 20px;
    }

    h3 {
        margin: 0; // reset default
    }
}
