
@use "client/css/globals" as *;

.ship-addresses .ship-address {
    align-items: center;

    /* Extra specificity for the .buttons alignment */
    margin-bottom: $spacingMedium;

    &:first-child {
        margin-top: $spacingMedium;
    }

    input {
        width: 340px;
    }

    .buttons {
        align-self: center;
    }
}

.ship-addresses-row {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;

    .input-row {
        align-items: center;
        display: flex;
        gap: 10px;
    }

    &:last-child {
        border-bottom: none;
    }

    input {
        max-width: 340px;
    }

    .buttons {
        align-self: center;
    }
}
