
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.split-line-summary {
    align-items: center;
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    max-width: categorize.$categorizeInputWidth;
    padding: $spacingSmall $spacingMediumSmall;
    width: 100%;
}

.icon-list {
    margin-right: 5px;
}

.button {
    font-size: $fontSizeSmall;
}
