
@use "client/css/globals" as *;

::v-deep .bubble-down.bubble-align-force-right {
    left: auto;
    min-width: 400px;
    right: 0;
}

.wrapper {
    align-items: center;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    justify-items: flex-end;

    .badge {
        border-radius: 100px;
        flex-shrink: 0;
        height: 10px;
        width: 10px;

        &.pre_fmob {
            background: $contentColorTertiary;
        }

        &.in_preparation {
            border: 2px solid $brandColorPrimary;
        }

        &.draft {
            background: $brandColorPrimary;
        }
    }

    .bubble-content {
        h3 {
            font-weight: 600;
            line-height: 150%;
            margin: 0;
        }

        p {
            font-weight: 400;
            margin: 0;
        }

        ::v-deep .card {
            background-color: $backgroundTonalGrey;
            margin-top: 12px;

            p {
                margin-bottom: 5px;
            }
        }
    }

    .text {
        font-weight: bold;
        margin: 0;
        white-space: nowrap;

        &.pre_fmob {
            color: $contentColorTertiary;
        }
    }

    .status-complete-container {
        align-items: center;
        display: flex;

        &__check {
            margin-right: 10px;
        }
    }

    .attention-link {
        color: $brandColorPrimary;
        text-decoration: none;
    }
}
