
@use "client/css/globals" as *;

.checklist-edit-comment {
    textarea {
        border-color: $brandColorPrimary;
        height: 92px;
        resize: vertical;
    }
}
