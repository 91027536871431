
@use "client/css/globals" as *;

$wrappingBreakpoint: 1100px; // arbitrary width

.main-section {
    display: flex;
    padding-bottom: $spacingMediumLarge * 2;

    @media (max-width: $wrappingBreakpoint) {
        & {
            flex-wrap: wrap;
        }
    }

    .monthly-content {
        flex: 1 1 600px; // arbitrary width

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
                margin-bottom: $spacingMediumLarge;
            }
        }
    }

    .steady-content {
        flex: 0 0 380px; // arbitrary width
        margin-left: $spacingMediumLarge;

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
                margin-left: 0;
            }
        }
    }
}

.portal-home-card:not(:last-child) {
    margin-bottom: $spacingMediumLarge;
}

.bank-transactions-table-card {
    margin-bottom: $spacingMediumLarge;
}

.header-text-bold {
    font-weight: bold;
}

.header-text {
    margin-top: 5px;
}

.table-footer {
    border-top: 1px solid $borderColorLight;
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.loading-error-container {
    width: 100%;
}
