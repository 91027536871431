
@use "client/css/globals" as *;

$verticalGutter: $spacingSmall;
$horizontalGutter: $spacingSmall + 2px;
$expanderWidth: 4em; // about the width of "Reply" (with some extra for spacing)
$logoIconDimension: 16px;

.note-wrapper {
    background-color: $backgroundColorSecondary;
    border-radius: 6px; // non-standard size requested by design
    display: flex; // we need flex so it incorporates the margin from the textarea
    flex-direction: column;
    font-size: $fontSizeSmall;
    line-height: $logoIconDimension;
    width: 100%;

    &.collapsed {
        opacity: 0.85;

        &:hover {
            opacity: 1;
        }
    }
}

// this is a button that we want to look like text; it slides in smoothly on hover
.note-expander {
    background: none;
    border: 0;
    color: $brandColorPrimary;
    font-size: inherit;
    opacity: 0; // start transparent for fade-in effect
    padding: 0;
    position: absolute; // expander is relative to the context element
    right: -$expanderWidth; // start outside container for slide-in effect
    text-align: right;
    transition:
        opacity 0.25s,
        right 0.05s ease-in;
    width: $expanderWidth;

    // display when hovering over container
    .note-wrapper:hover & {
        opacity: 1;
        right: $horizontalGutter;
    }

    // display when focused (e.g. via keyboard)
    &:focus {
        opacity: 1;
        right: $horizontalGutter;

        // remove slide transition when focusing this way: it causes the container to scroll
        transition: opacity 0.25s;
    }
}

.pilot-logo {
    left: $horizontalGutter;
    position: absolute;
    top: $verticalGutter;
    width: $logoIconDimension;
}

.note-context {
    border-bottom: 1px solid $borderColorLight;
    margin: 0;
    padding: $spacingSmall;
    padding-left: $horizontalGutter + $logoIconDimension + $spacingSmaller; // space for logo
    position: relative; // expander is relative to this context element

    .collapsed & {
        border-bottom: 0;
        cursor: default; // don't want text cursor: clicking performs an action

        // we only want to display the first line, trailing off, when collapsed
        overflow: hidden;
        text-overflow: ellipsis;
        transition: padding-right 0.05s ease-in;
        white-space: nowrap;

        &:hover {
            padding-right: $expanderWidth; // make room for the expander!
        }
    }
}

.note-textarea {
    border-radius: 1.5em; // for pill shape, can be anything larger than text size
    font-size: inherit;
    line-height: inherit;
    margin: $verticalGutter $horizontalGutter;
    min-height: initial;
    overflow: hidden;
    padding: 8px 12px; // non-standard sizes requested by design
    resize: none;
    width: initial;

    // applies when required attribute is present on empty textarea
    &:invalid {
        border-color: $red1Light;
        box-shadow: none; // Firefox default style for invalid (like focus outline)
    }
}
