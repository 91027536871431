
@use "client/css/globals" as *;
@use "client/components/css/mixins";

.includes-none-of-the-above {
    @include mixins.last-item-sticky;

    &.no-search-results {
        .no-search-results-message {
            font-size: $fontSizeSmall;
            padding: 12px;
        }

        // If there are no search results, the "all accounts" group still has an element in
        // it ("none of the above"), so we hide its label. We can't think of any less brittle
        // way to do this.
        ::v-deep li.multiselect__element:nth-last-of-type(3) {
            display: none;
        }
    }
}

// terrible hack: multiselect isn't applying the correct class to the group labels
// instead of multiselect__option--group, it applies both the --disabled and --selected classes
// when an option is selected, the --selected class is removed but the --disabled class stays
// we never expect to have disabled options in this component, so I am targeting them
// because they those elements are under control of VueMultiselect, I must use ::v-deep
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

// VueMultiselect sets .multiselect__element to `display: inline-block` via JS
// in Firefox, this adds a horizontal scrollbar to the dropdown
// making the wrapper display flex appears to be the only way to remove this scrollbar
::v-deep .multiselect__content-wrapper {
    display: flex;
}

.group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}
