
@use "client/css/globals" as *;

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: $spacingMedium $spacingMediumLarge 0 $spacingMediumLarge;

    .card {
        padding: 10px 15px;
        width: 100%;
    }

    .file-cards {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .attachments-card-list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            list-style-type: none;
            margin: 0;
            padding-left: 0;

            .expanded-close-button {
                ::v-deep .remove-button {
                    transform: scale(1.33);
                }
            }
        }

        .uploading-card-list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            list-style-type: none;
            margin: 0;
            padding-left: 0;

            .uploading-filecard {
                display: flex;
                width: 100%;
            }
        }
    }

    .file-picker {
        // we need to add top: 0 to the invisible class to put the hidden
        // browse button at the top so that it doesn't create empty space
        // at the bottom of the modal with an unnecessary scrollbar
        ::v-deep .invisible {
            top: 0;
        }
    }
}

.cancel-button {
    background: none;
    border: none;
    color: $pilotPurple;

    &:hover,
    &:focus {
        color: $matteBlue;
        color: $pilotText;
        text-decoration: underline;
    }
}

.instructions-header {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .instructions-title {
        font-weight: bold;
    }

    .more-info {
        align-items: center;
        color: $pilotPurple;
        display: flex;
    }
}

.upload-instructions {
    padding-top: 10px;
}

.header-text {
    display: flex;
    flex-direction: column;

    h3 {
        padding-bottom: 5px;
    }
}

.header-container {
    align-items: center;
    display: flex;
    gap: 10px;
    padding: 10px 0;
}

img {
    border: 1px solid $borderColorLight;
    border-radius: 5px;
}

.instruction-card {
    ::v-deep ul {
        padding-left: 20px;
    }
}

hr {
    margin: 10px 0;
}

.bold {
    font-weight: bold;
}

.upload-instructions-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.inspect-cabinet-img {
    flex-shrink: 0;
    padding: 12px 0;
    width: 80px;
}
