
@use "client/css/globals" as *;

@media (min-width: 1050px) {
    .two-col {
        display: grid;
        gap: 30px;
        grid-template-columns: auto minmax(200px, 345px);
    }
}

@media (max-width: 1050px) {
    .side-bar {
        padding-top: 30px;
    }
}

.question-container {
    display: grid;
    gap: 30px;
    grid-template-rows: auto;
}

.questions-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 360px;
    justify-content: center;

    .icon {
        margin-bottom: $spacingMedium;
    }

    h2 {
        font-weight: normal;
    }

    p {
        color: $contentColorSecondary;
        font-size: $fontSizeMedium;
    }
}
