
@use "client/css/globals" as *;

table {
    table-layout: fixed;
}

table td {
    word-wrap: break-word;
}

h2 {
    margin-bottom: 30px;
}

thead > tr {
    border-color: $borderColorLight;
}

th {
    background-color: $backgroundColorPrimary;
    position: sticky;
    top: 0;
    z-index: $zIndexBase + 1;
}

tbody > tr {
    border-color: $borderColorLighter;
}

.center-align {
    display: flex;
    justify-content: center;
}

p {
    margin: 0;
}
