
@use "client/css/globals" as *;

.wrapper {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    padding: 50px 80px;
    width: 670px;
}

.heading {
    color: $contentColorPrimary;
    font-size: $fontSizeLarge;
    margin-bottom: $spacingMediumLarge;
    text-align: center;
}

.heading-icon {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: center;
    margin-bottom: $spacingMediumLarge;
}

.sub-heading {
    color: $contentColorPrimary;
    font-size: $fontSizeMedium;
    margin-bottom: $spacingMediumLarge;
    text-align: center;

    p {
        line-height: 27px;
        margin: 0;
    }
}

.sub-heading.complete {
    text-align: start;
}

.form-list {
    color: $contentColorPrimary;
    margin: 0;
    margin-bottom: $spacingMediumLarge;
    padding: 0;
}

.form-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 27px;
    margin-bottom: $spacingSmall;

    p {
        margin: 0;
    }
}

.form-item:last-of-type {
    margin-bottom: 0;
}

.left {
    align-items: center;
    display: flex;
}

.form-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
}

.form-name {
    font-size: $fontSizeMedium;
    padding-left: $spacingSmall;
}

.form-status {
    font-size: $fontSizeSmall;
}

.tax-page-btn-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $spacingMediumLarge;
}

.icon-check-empty {
    margin-left: 5px;
}
