
.category-title {
    font-weight: bold;
}

.category-info {
    height: 20px;
    margin-bottom: -4px;
}

.title-line {
    margin: 15px -20px;
}

.category-container {
    padding: 5px 10px;
}

.category-level {
    font-size: 14px;
}

@for $level from 1 through 6 {
    .indent-#{$level} {
        margin-left: #{$level}em;
    }
}

.level-line {
    padding-top: 5px;
}
