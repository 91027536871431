
@use "client/css/globals" as *;

.toasts {
    background: none;
    border: none;
    inset: unset;
    margin: 15px 0;
    overflow: unset;
    padding: 0;
    position: fixed;
    right: $pageMargin;
    top: $pageHeaderDefaultHeight;
    width: 420px;

    .admin-has-secondary-nav & {
        top: $pageHeaderHasSecondaryNavHeight;
    }
}

$slideDuration: 0.6s;

.toast {
    background: $green1Background;
    border-radius: 5px;
    color: $offBlack;
    display: inline-block;
    list-style-type: none;
    margin: 0 auto $spacingMedium;
    padding: $spacingMedium;
    position: relative;
    transition: all $slideDuration;
    width: 100%;

    &.toast-slide-enter,
    &.toast-slide-leave-to {
        opacity: 0;
    }

    &.toast-slide-enter {
        transform: translateY(20px);
    }

    &.toast-slide-leave-to {
        transform: translateY(-20px);
    }

    &.toast-slide-leave-active {
        position: absolute;
    }

    &.toast-slide-move {
        transition: transform $slideDuration;
    }

    &.error {
        background: $red1Background;
        color: $red1;

        a {
            color: $red1;
            text-decoration: underline;
        }
    }

    &.alert {
        background: $yellow1;
    }

    .x {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .message ::v-deep p {
        margin-bottom: 0;
        max-height: 400px;
        overflow-y: auto;
    }
}
