
@use "client/css/globals" as *;

.container {
    align-items: center;
    background: #fff8de;
    border: 2px solid $pilotTypeYellow;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

p {
    margin: 0;
}

.grey-text {
    color: #7e7b76;
    font-size: $fontSizeSmall;
}

a {
    color: $pilotPurple;
}
