
@use "client/css/globals" as *;

.ship-history-heading-card {
    margin-bottom: $spacingMediumLarge;

    h2 {
        font-size: $fontSizeMedium;
        margin-bottom: $spacingSmaller;
    }

    p {
        margin-bottom: 0;
    }
}

.month-books-cell {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tooltip {
    width: 200px;
}

// th cells can't use display: flex because they're using display: table-cell
.th-tooltip {
    // Remove extra height from icon
    line-height: 0;
    vertical-align: bottom;
}

.td-with-tooltip-wrapper {
    align-items: center;
    display: flex;
}

.td-tooltip {
    // Remove extra height from icon
    line-height: 0;
    padding-left: 5px;
}

.tooltip-icon {
    height: 18px;

    // IconPilotCircle's svg is unoptimized and appears fuzzy at 16x16. Increasing the size improves its sharpness. DESIGN-112
    width: 18px;
}

.bright-blue {
    color: $brightBlue;
}

.loadmore-container {
    align-items: center;
    display: flex;
    justify-content: left;
}
