
@use "client/css/globals" as *;
@use "client/css/cards";

$connectionCardHorizontalPadding: 30px;

#connection-categories {
    display: grid;
    gap: 30px;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 0;
}

.customer-connections-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

.connection-category {
    height: 100%;

    > .connection-category-title {
        border-bottom: 1px solid $borderColorLight;
        font-weight: normal;
        padding: 15px $connectionCardHorizontalPadding;
    }
}

.connection-header {
    flex-grow: 1;
}

.category-card,
.content-tertiary {
    padding: 30px $connectionCardHorizontalPadding;
}

.category-card {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, cards.$admin-card-size);
}

.institution-card {
    margin: 0;
}

.content-tertiary {
    color: $contentColorTertiary;
}

#customer-connections {
    .notification-banner {
        margin: 20px 0;

        svg {
            margin-right: 10px;
        }
    }
}
