
@use "client/css/globals" as *;

.data-requests-tasks {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
}

.add-doc-button {
    margin-top: $spacingMedium;
}

.download-button-wrapper {
    align-items: center;
    display: flex;
    font-size: $fontSizeSmall;
}

.download-button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-top: 10px;
    padding: 0;

    svg {
        fill: $pilotPurple;
        height: 16.5px;
        width: 16.5px;
    }

    &:hover:not(:disabled) {
        svg {
            fill: $contentColorPrimary;
        }

        text-decoration: underline;
    }

    &:disabled {
        svg {
            fill: $darkGrey;
        }
    }
}
