
@use "client/css/globals" as *;

.pilot-team-multiselect {
    ::v-deep &.multiselect {
        max-width: 400px;
        width: 100%;
    }

    ::v-deep .multiselect__tags {
        display: flex;
    }

    ::v-deep .multiselect__option--selected {
        & > .option-email {
            color: $backgroundColorPrimary;
        }

        &.multiselect__option--highlight {
            & > .option-email {
                color: $backgroundColorPrimary;
            }
        }
    }
}

.label-container {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.label-wrapper {
    align-items: center;
    display: flex;

    & > .label {
        margin-right: 5px;
    }
}

.subtitle {
    color: $contentColorSecondary;
    font-size: 12px;
}

.tooltip {
    width: 140px;
}

.option-name {
    margin-bottom: 5px;
}

.option-email {
    color: $contentColorSecondary;
    font-size: 12px;
}
