
@use "client/css/globals" as *;

.accordion {
    border: none;
}

::v-deep .accordion-item {
    border-bottom: none;
}

::v-deep .accordion-item.accordion-active .accordion-header {
    background-color: $backgroundColorPrimary;
}

.error-page {
    padding: 0 20px;

    > * {
        margin-bottom: 20px;
    }
}

.current-step {
    min-height: 150px;
    position: relative;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.success-or-failure-text {
    font-weight: bold;
    padding-left: 5px;
}

.success-instructions {
    // icon width + padding so the text lines up with the success-or-failure-text
    padding-left: 28px;
}

.failure-instructions {
    // icon width + padding so the text lines up with the success-or-failure-text
    padding-left: 22px;
}

.instructions {
    margin-bottom: 20px;
}
