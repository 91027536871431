
@use "client/css/globals" as *;
@use "client/css/table";

.container {
    // we wrap the table in a container because we can't apply `border-radius` to
    // tables with `border-collapse: collapse
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusLarge;
}

table {
    margin-bottom: 0; // override global style
}

td,
th {
    // padding must go in cells, not on rows
    // give header and data cells the same padding
    padding: 10px 20px;

    // header cells need more vertical space
    thead & {
        padding-bottom: 15px;
        padding-top: 15px;
    }
}

tr {
    // borders between sections, not rows; !important due to global style
    border: none !important;

    > td:last-child:not(:first-child),
    > th:last-child:not(:first-child) {
        // the last column in the table are numbers/totals
        // exclude rows which only have one cell
        @include table.tabular-numbers;
    }
}

tbody {
    border-top: 1px solid $borderColorLight;
}

.total {
    :last-child {
        font-weight: bold; // bold the actual amount...
    }

    :first-child {
        font-weight: normal; // ...but not its heading
    }
}

thead {
    // manually size the columns via the table headings

    .col-date {
        width: 150px;
    }

    .col-type {
        width: 125px;
    }

    .col-amount {
        width: 150px;
    }
}
