
@use "client/css/globals" as *;

.download-status-list {
    bottom: 110px;
    left: 50%;
    margin: 0;
    min-width: 472px;
    padding: 0;
    position: fixed;
    transform: translateX(-50%);
    z-index: 700;
}

.download-status {
    background: $brandColorSecondary;
    border-radius: $borderRadiusLarge;
    box-shadow:
        0 0 1px rgba(101, 99, 99, 0.16),
        0 8px 16px rgba(62, 60, 61, 0.08);
    color: $brandColorPrimaryContrast;
    display: block;
    list-style-type: none;
    margin: $spacingMedium auto 0;
    padding: $spacingMedium;
    position: relative;
    transition: all 0.6s;

    &.download-slide-enter,
    &.download-slide-leave-to {
        opacity: 0;
    }

    &.download-slide-enter {
        transform: translateY(20px);
    }

    &.download-slide-leave-to {
        transform: translateY(-20px);
    }

    &.download-slide-leave-active {
        position: absolute;
    }

    &.download-slide-move {
        transition: transform 0.6s;
    }
}

.loading-icon {
    margin-right: 10px;
}
