
@use "client/css/globals" as *;

.onboarding-checklist-creation-wizard-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.button-cta {
    margin-right: 0;
    padding-left: $spacingLarger;
    padding-right: $spacingLarger;
}

h1 {
    margin-bottom: $spacingMediumLarge;
}

.date-picker {
    margin-bottom: $spacingMediumSmall;
    margin-top: $spacingMediumSmall;
    width: 172px;
}
