
@use "client/css/globals" as *;

.bad-bank-register-transactions-table {
    max-width: $flowbotTaskMaxWidth;
}

.bad-bank-register-transactions-column-date {
    width: 8em;
}

.bad-bank-register-transactions-column-amount {
    width: 8em;
}

.bad-bank-register-transactions-column-type {
    width: 8em;
}
