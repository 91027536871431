
.pilot-title {
    margin-bottom: 5px;
}

.history-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
