
.spinner-container {
    margin-left: -10px;

    ::v-deep .pilot-tag {
        padding: 0 10px !important;
    }

    ::v-deep .spinner.spinner-small {
        height: 17px !important;
        width: 17px !important;
    }

    ::v-deep .spinner.spinner-small::after,
    ::v-deep .spinner.spinner-small::before {
        height: 17px !important;
        width: 17px !important;
    }
}

.category-container {
    align-items: center;
    display: flex;

    .edit-category-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;

        .txn-select-category {
            flex: 1 1 auto;
            max-width: 300px;
        }

        .save-button {
            flex: 0 1 auto;
            max-width: 95px;
        }

        .cancel-button {
            flex: 0 1 auto;
            max-width: 50px;
        }
    }
}
