
@use "client/css/globals" as *;

$transitionDuration: 0.3s;

.checklist-new-task {
    display: grid;
    row-gap: 10px;
    width: 100%;

    .buttons {
        margin: $spacingSmall 0 0;
    }
}

.task-edit-input,
.task-edit-textarea {
    width: 100%;
}

.task-edit-textarea {
    resize: vertical;
}
