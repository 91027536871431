
@use "client/css/globals" as *;

.bulk-amortize {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    width: 180px;
}

.caret {
    border: 5px solid transparent;
    border-top-color: white;
    display: inline-block;
    height: 0;
    margin-left: auto;
    position: relative;
    top: 3px;
    width: 0;
}

.popover-content {
    padding: $spacingSmall;
}

.buttons {
    align-items: center;
}

label[for="bulk-amortize-start-date"] {
    align-items: center;
    display: flex;
}
