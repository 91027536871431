
@use "client/css/globals" as *;

// Removes space between tooltip icon & title
::v-deep(.split-card .left-slot.default) {
    column-gap: normal;
}

// Hide PilotTable's #empty content because it doesn't match
// what _empty_ means for this feature.
::v-deep(.pilot-table .empty-row) {
    display: none;
}

.bucket-container {
    display: flex;
    justify-content: stretch;
}

.buckets {
    display: flex;
    flex: auto;
    justify-content: stretch;
    padding: 0;
}

.bucket-value {
    display: block;
    font-size: $fontSizeLarge;
    font-weight: 600;
    line-height: 1.05;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bucket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 30%;
    overflow: visible;
    padding: $spacingMedium;
    text-overflow: ellipsis;
    transition: min-width 0.25s ease-in-out;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        min-width: 200px;
    }

    &:first-of-type {
        background: $blue1Background;
        border-bottom-left-radius: $borderRadiusLarge;
        border-top-left-radius: $borderRadiusLarge;
    }

    &:last-of-type {
        background: $red1Background;
        border-bottom-right-radius: $borderRadiusLarge;
        border-top-right-radius: $borderRadiusLarge;
        color: $red1;
    }
}

.total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 120px;
    padding: $spacingMedium;
}

.chart {
    display: grid;
    grid-template-areas: "chart buckets";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
}

.aging-buckets {
    border-left: 1px solid $borderColorLight;
    color: $contentColorSecondary;
    list-style-type: none;
    margin-left: 20px;
    padding-left: 20px;

    li {
        align-items: center;
        display: flex;
        gap: 110px;
        justify-content: space-between;
    }
}

.total-cell {
    font-weight: 600;
}

.inner-card {
    border-radius: 0;
}

.outer-card {
    overflow: hidden;
}

.header-container {
    align-items: center;
    display: flex;
    height: 24px;

    ::v-deep .bubble-container {
        height: 24px;
    }
}
