
@use "client/css/globals" as *;

.prepaid-suspect-disabled {
    color: $contentColorTertiary;
}

.prepaid-detective-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

$iconSize: 30px;

.action-button {
    align-items: center;
    background: none;
    border: none;
    border-radius: 50%;
    display: flex;
    height: $iconSize;
    justify-content: center;
    margin: $spacingSmaller;
    padding: 0;
    width: $iconSize;

    .button-border {
        border: 2px solid $brandColorPrimary;
        border-radius: 50%;
        bottom: $spacingSmaller;
        left: $spacingSmaller;
        position: absolute;
        right: $spacingSmaller;
        top: $spacingSmaller;
    }

    &.button-spinner .button-border {
        animation: rotation 0.65s ease-in-out infinite;
        border-color: $brandColorPrimary transparent transparent transparent;
        transform: rotate(45deg);
    }

    &:disabled:not(.button-spinner) {
        .button-border {
            border-color: $darkGrey;
        }

        .prepaid-detective-icon {
            path {
                stroke: $darkGrey;
            }
        }
    }

    &:hover:not([disabled]) {
        background: $brandColorPrimary;
        cursor: pointer;

        .prepaid-detective-icon {
            path {
                stroke: white;
            }
        }
    }

    &:focus {
        outline: none;

        .button-border {
            border-color: $brightBlue;
            border-width: 3px;
            bottom: $spacingSmaller - 2px;
            left: $spacingSmaller - 2px;
            right: $spacingSmaller - 2px;
            top: $spacingSmaller - 2px;
        }

        .prepaid-detective-icon {
            path {
                stroke: $brightBlue;
            }
        }
    }
}

.tooltip {
    width: 100px;
}
