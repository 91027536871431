
@use "client/css/globals" as *;

.card-info {
    padding: 25px;

    .contact-info-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacingMedium;
    }

    .contact-name {
        font-weight: bold;
    }

    .contact-service {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .contact-tag {
        background: $pastelViolet;
        border: none;
        border-radius: 4px;
        color: $pilotText;
        font-size: $fontSizeSmall;
        padding: $spacingSmallest $spacingMediumSmall;
    }

    .center-button {
        display: block;
        font-size: $fontSizeBase;
        height: 35px;
        margin: 0 auto;
        width: 100%;
    }
}
