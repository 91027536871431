
@mixin shimmer-skeleton {
    background: #e1e1e1;
    border-radius: 4px;
    height: var(--row-height);
    overflow: hidden;
    position: relative;

    &::before {
        animation: shimmer var(--animation-speed, 2s) ease-in-out infinite;
        background: linear-gradient(90deg, #e1e1e1 0%, #fff 50%, #e1e1e1 100%);
        background-size: 200% 100%;
        content: "";
        inset: 0;
        position: absolute;
    }
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.shimmer-table {
    padding: 1rem;
}

.shimmer-row {
    align-items: center;
    border-bottom: 0.5px solid #dcdcdc;
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

/* Default: each cell is a single shimmer bar */
.shimmer-cell {
    @include shimmer-skeleton;
}

/* For two-line cells, we replace the default bar with two stacked bars */
.two-line-cell {
    background: none;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &::before {
        content: none;
    }

    .line-top,
    .line-bottom {
        @include shimmer-skeleton;

        height: calc(var(--row-height) / 2);
    }
}
