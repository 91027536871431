
@use "client/css/globals" as *;

table {
    margin: 0;
    width: 540px;
}

thead,
tbody > tr {
    border: 0;
    display: table;
    line-height: 26px;
    table-layout: fixed;
    width: 100%;
}

td {
    padding: 7.5px 0;
}

.table-wrapper {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    padding: 15px 30px;
}

.cell-name {
    display: flex;
    height: auto;
}

.address-container,
.state-tax-container {
    margin: 0;
    position: relative;

    .address-row,
    .state-tax-row {
        border: 0;
    }
}

.needs-input-tag {
    align-items: center;
    background: #fff8de;
    border-radius: 20px;
    color: #7e6713;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    justify-content: center;
    padding: 0 10px;
    width: fit-content;
}

.btn-wrapper {
    padding-top: 30px;
    text-align: right;
}
