
@use "client/css/globals" as *;

.upload-row {
    display: grid;
    grid-template:
        "name     name     input" auto
        "result   result   result" auto
        / 1fr 1fr 30%;
}

// Define top/bottom padding relative to name so that height does
// not change based on what is rendered in the right-aligned content,
// i.e. we don't want overall row's height to expand when the plump FileCard appears.
.upload-name {
    grid-area: name;
    padding-bottom: 15px;
    padding-top: 15px;
}

.result-messages {
    display: grid;
    grid-area: result;
    grid-row-gap: 5px;
    padding-top: 30px;
}

.file-card {
    width: 100%;
}

.success-message {
    align-items: center;
    display: flex;
}

.upload-input {
    align-items: center;
    display: flex;
    grid-area: input;
    justify-content: flex-end;
}

.file-card-loading {
    color: $contentColorSecondary;
}

// Global loading spinner class overrides
.file-card-loading-spinner {
    position: static;

    &::after {
        border-color: $pilotPurple;
        border-left-color: transparent;
        border-right-color: transparent;
        border-width: 1px;
        margin-left: 15px;
    }
}

.file-card-custom-status {
    align-items: center;
    display: flex;
    margin-left: 25px;
}

.run-button {
    margin-left: 15px;
    position: relative;
}

.hide-but-fill-space {
    visibility: hidden;
}

.loading-spinner {
    position: absolute;
    right: 18px;
    top: 18px;
}
