
@use "client/css/globals" as *;

.fields {
    margin-bottom: $spacingMedium;

    .field.delete {
        flex: 0;
        position: relative;
        top: 13px; // align with inputs
    }
}
