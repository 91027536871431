
@use "client/css/globals" as *;

.loading {
    ::v-deep p:last-child {
        margin-bottom: 0;
    }
}

.message {
    color: $contentColorPrimary;
}
