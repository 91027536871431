
@use "client/css/globals" as *;

.checkbox-and-label {
    align-items: center;
    background: $backgroundTonalGrey;
    border: 1px solid $pastelViolet;
    border-radius: 5px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr;
    padding: 12px 15px 12px 10px;

    // Override top styling since that affects the centering calculation
    ::v-deep .pilot-checkbox {
        top: 0;
    }
}
