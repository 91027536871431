
@use "client/css/globals" as *;

.header {
    align-items: center;
    display: flex;
    height: 32px; // Height of close button
}

.header-subtitle {
    margin-bottom: 25px;
    margin-top: 35px;
}

.form-header {
    margin-bottom: 30px;
}

.customer-disposition-form {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    padding: 30px;
    width: 700px;
}

::v-deep input,
::v-deep textarea {
    width: 100%;
}

::v-deep textarea {
    resize: vertical;
}

.last-reported-msg {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    padding-top: 10px;
}
