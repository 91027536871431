
@use "client/css/globals" as *;

.tax-customer-search {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.tax-customer-input {
    flex: 1 1 auto;
    margin: 0;
    margin-right: $spacingMedium;
    width: 240px;
}

.tax-year-dropdown {
    margin: 0;
    margin-right: $spacingMedium;
    padding: 0;
    width: 163px;
}

.tax-export-btn {
    margin: 0;
    width: 153px;
}

.horizontal-scroll {
    overflow-x: auto;
}
