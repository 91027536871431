
@use "client/css/globals" as *;

.hover-effect {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    // Vertical centering is apparently handled by align-items: center on bank-account-item
    &::before {
        background-color: $backgroundColorPrimary;
        border-radius: 0 5px 5px 0;
        content: "";
        height: 33px;
        left: 0;
        position: absolute;
        width: 5px;
    }

    &:hover::before {
        background-color: $contentColorTertiary;
    }

    &:hover {
        background-color: $backgroundTonalGrey;
    }
}

.bank-account-item {
    align-items: center;
    background-color: $backgroundColorPrimary;
    border: none;
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto;
    padding: 10px 30px;
    position: relative;
    text-align: initial;
    width: 100%;

    &.isSelected::before {
        background-color: $brandColorPrimary;
    }

    .balance-status {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }
}
