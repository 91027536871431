
@use "client/css/globals" as *;

.checklist-progress {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
}

.checklist-progress-title {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacingMediumSmall $spacingMedium;
}

.checklist-progress-items {
    padding: $spacingMediumSmall $spacingMedium;
}
