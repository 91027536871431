
.percent-toggle-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
}

.label-text {
    margin-right: 10px;
}
