
@use "client/css/globals" as *;

.navigation-header {
    display: flex;

    ::v-deep .popover-container {
        align-items: center;
        display: flex;
    }
}

.icon {
    align-items: center;
    display: flex;
    margin-right: 20px;
}

.header {
    font-weight: 600;
    margin: 0;

    &.micro {
        font-size: $fontSizeMicro;
    }

    &.small {
        font-size: $fontSizeSmall;
    }

    &.base {
        font-size: $fontSizeBase;
    }

    &.medium {
        font-size: $fontSizeMedium;
    }

    &.large {
        font-size: $fontSizeLarge;
    }

    &.larger {
        font-size: $fontSizeLarger;
    }

    &.huge {
        font-size: $fontSizeHuge;
    }
}

.navigation {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
}

.dropdown {
    padding-bottom: 5px;
    padding-top: 5px;

    // the popover container has a border radius of 5px, but the buttons layer above the container
    :first-child .dropdown-item-button {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    :last-child .dropdown-item-button {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.dropdown-item {
    border: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dropdown-item-button {
    background-color: $backgroundColorPrimary;
    border: none;
    cursor: pointer;
    min-width: 428px;
    padding: 15px;

    &:hover,
    &:focus {
        background: $backgroundColorSecondary;
    }

    .dropdown-item-content {
        align-items: center;
        color: $contentColorPrimary;
        display: flex;
        font-weight: 600;
    }
}

.input-carat-container {
    align-items: center;
    display: flex;
    margin-left: 10px;
}
