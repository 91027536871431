
@use "sass:color";
@use "client/css/globals" as *;

.security-question {
    background: $backgroundColorSecondary;
    display: flex;
    flex-direction: row;
    margin-bottom: $spacingMedium;
    padding: $spacingMedium $spacingLarger $spacingMedium $spacingMedium;
    position: relative;

    input {
        width: 100%;
    }

    .security-question-delete {
        align-items: center;
        background: $backgroundColorTertiary;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: $spacingLarge;

        &:hover {
            background: color.adjust($red1, $lightness: 40%);
        }
    }
}
