
@use "client/css/globals" as *;

// Table cell styles shared with possible-amortisables-row.vue here:
@use "client/modules/customer-portal-tab/css/possible-amortizable-styling";

.vendor-name-text {
    color: $contentColorPrimary;
    font-size: $fontSizeBase;
}

.description-text {
    color: $contentColorTertiary;
    font-size: $fontSizeSmall;
}

.amount-text {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    padding-top: 8px;
    text-align: right;
    width: 100%;
}

/** Account Cell:
 * We do some magic to pad the top of the QBO account cell text (to align it
 * with the service period picker text), but only when there is space in the
 * row.
 *
 * If there is not space in the row, we center the text, roughly aligning it
 * with the date and vendor/description column text. This prevents the column
 * from expanding and looking lopsided.
 *
 * Unfortunately, getting this right involves a bunch of nested divs, because
 * we need to break this out of the flow.
 */
.account-cell-floater {
    height: 100%;
    position: relative;
    width: 100%;
}

.account-cell-padder {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &::before {
        content: "";
        flex: 0 1 8px;
    }
}

.account-text-center {
    align-items: center;
    display: flex;
    flex-direction: row;
    max-height: 100%;
}

.overflow-buttons {
    width: 240px;
}

.pending-note-from-pilot {
    flex-basis: 100%;
    margin-top: $spacingSmall;

    .note-input {
        height: 40px;
        min-height: initial;
        overflow: hidden;
        resize: none;
        width: 100%;
    }
}

.comment-thread {
    margin-bottom: 0;
    margin-top: $spacingSmall;
}

.txn-errors {
    margin-top: $spacingSmall;
}
