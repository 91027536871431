
@use "client/css/globals" as *;

.rules-table-filters {
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    margin-bottom: 30px;

    // override base multiselect styles
    ::v-deep .multiselect {
        max-width: none;
        width: 100%;
    }
}

.rules-table-header {
    border-radius: 5px 5px 0 0;

    .add-rule-btn {
        margin: 0;

        .icon {
            margin-right: 10px;
        }
    }

    .audit-log-link {
        border-left: 1px solid $borderColorLight;
        padding-left: 10px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.rules-table {
    border-top: 1px solid $borderColorLight;
    margin-bottom: 30px;

    .rule-link {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;
        width: 100%;
    }
}

// override PilotTable's pagination styles
::v-deep .pilot-table-pagination {
    .medium {
        background: $backgroundColorPrimary;
        border-radius: 0 0 5px 5px;
        padding: 10px 30px;
    }
}
