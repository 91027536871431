
@use "client/css/globals" as *;

.customer-portal-link-wrapper {
    align-items: center;
    display: flex;

    span {
        color: $brandColorPrimary;
    }

    img {
        margin-right: $spacingSmall;
    }
}
