
@use "client/css/globals" as *;

.amortized-date {
    color: $contentColorSecondary;
    font-size: $fontSizeBase;
    font-weight: normal;
}

.qbo-link {
    color: inherit;
    display: inline-block;
    margin-left: auto;
    text-decoration: underline;
}

.column h3 {
    margin-bottom: $spacingMediumLarge;
}

.original-header {
    font-weight: normal;
}

.grey {
    input,
    textarea {
        color: $contentColorTertiary;
    }
}

.delete-button {
    white-space: nowrap;
}
