
@use "client/css/globals" as *;
@use "./tick-and-tie";

.tick-and-tie-group {
    border-radius: $borderRadiusMedium;

    // using box shadow instead of border lets us use border radius
    box-shadow: 0 0 0 1px #e0e0e0;
    transition: background-color 0.15s ease-in-out;

    tr.tick-and-tie-group-header td {
        padding-top: 15px;

        &:hover {
            cursor: pointer;
        }
    }

    ::v-deep tr:last-child td {
        padding-bottom: 15px;
    }

    &:hover {
        background: $backgroundTonalGrey;
    }

    .tick-and-tie-expand-group {
        align-items: center;
        display: flex;
    }

    &.just-created {
        box-shadow: 0 2px 8px 0 #5f2ee51a;
        box-shadow: 0 0 3px 0 #5f2ee5;
    }

    .input-field {
        margin-bottom: 10px;
    }

    &.in-review {
        tr.tick-and-tie-group-header {
            vertical-align: top;

            td {
                cursor: auto;
            }
        }

        &:hover {
            background: inherit;
        }
    }
}

.selected {
    background: $purpleSelectionBackground;

    &:hover {
        background: $purpleSelectionHoverBackground;
    }
}

.tick-and-tie-status {
    margin-bottom: 5px;
}

.tick-and-tie-group-info {
    font-weight: bold;

    .tick-and-tie-group-info-wrapper {
        display: grid;
        grid-template:
            "expand count diff" auto
            ".      time  diff" auto / auto 1fr auto;

        .tick-and-tie-expand-group {
            grid-area: expand;
            margin-right: 10px;
        }

        .tick-and-tie-info-txn-count {
            align-items: center;
            display: flex;
            grid-area: count;

            .date-tooltip {
                margin-left: 5px;
            }
        }

        .tick-and-tie-group-timestamp {
            color: $contentColorSecondary;
            font-size: $fontSizeSmall;
            font-weight: 400;
            grid-area: time;
            margin-top: 5px;
        }

        .tick-and-tie-group-difference {
            color: $pilotText;
            font-size: $fontSizeBase;
            grid-area: diff;
            height: fit-content;
            text-align: right;
        }
    }
}

.validation-wrapper.has-error ::v-deep .tick-and-tie-note-container {
    margin-bottom: 5px;
}
