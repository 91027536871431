
@use "client/css/globals" as *;

// These styles are shared by institution-tasks.vue
$rightGutterWidth: 240px;

.new-section-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: $spacingLarge;
    position: sticky;
    top: 0;
}

.checklist {
    margin-right: $rightGutterWidth;
}
