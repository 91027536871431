
input,
textarea,
.multiselect {
    width: 100%;
}

.add-issue-container {
    display: grid;
    grid-row-gap: 15px;
}

.description-textarea {
    resize: vertical;
}
