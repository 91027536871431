
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.note-for-customer {
    min-height: 86px;
    min-width: 100%;
}
