
@use "client/css/globals" as *;

.shareholder-data-fields {
    flex-grow: 1;
}

.shareholder-input {
    display: flex;
    width: 100%;
}

.remove-button-wrapper {
    margin-left: $spacingSmall;
    margin-top: $spacingSmall;
    min-width: 15px;
}
