
@use "client/css/globals" as *;

$large: 20px;
$small: 14px;

label.container {
    /**
     * Labels are `display: inline` by default which results in unexpected sizing that affects
     * pseudoelement placement.
     */
    display: inline-block;
}

input[type="checkbox"] {
    /**
     * We want to visually hide the input (so that we can render our own version), but using
     * `display: none` or `visibility: hidden` will also hide the input from screen readers and
     * prevent us from giving focus to the input. We therefore use `opacity: 0` to hide the element
     * and then use `position: absolute` to remove the element from document flow.
     */

    margin: 0;
    opacity: 0;
    position: absolute;
}

.label {
    input[type="checkbox"]:disabled + & {
        color: gray;
    }
}

.checkbox {
    align-items: center;
    column-gap: 10px;
    display: inline-grid;
    grid-auto-flow: column;
    position: relative;

    &.align-top {
        align-items: flex-start;
    }
}

.checkbox::before {
    border-radius: $borderRadiusSmall;
    content: "";
    height: $large;
    width: $large;
}

input[type="checkbox"]:not(:disabled):focus + .checkbox::before {
    outline: 1px solid $brightBlue;
}

input[type="checkbox"]:not(:disabled):active + .checkbox::before {
    outline: 1px inset $brightPurple;
}

input[type="checkbox"][indeterminate],
input[type="checkbox"]:checked {
    + .checkbox::before {
        background-color: $pilotText;
    }

    + .checkbox::after {
        color: $brandColorPrimaryContrast;
    }

    &:not(:disabled):hover + .checkbox::before {
        background-color: $matteBlue;
    }

    &:disabled + .checkbox::before {
        opacity: 0.3;
    }
}

input[type="checkbox"]:not(:checked, [indeterminate]) {
    + .checkbox::before {
        border: 1px solid $borderColorLight;
    }

    &:not(:disabled):hover + .checkbox::before {
        border-color: $darkGrey;
    }

    &:disabled + .checkbox::before {
        background-color: $lightGrey;
    }
}

.checkbox::after {
    /**
     * Positioning the checkbox icon is nuanced. The goal is to overlap the psuedoelement exactly
     * over its containing box and then center whatever content it contains.
     *
     * We do this by giving the icon the same dimensions as the box and then setting
     * `position: absolute`. We align their left edges by setting `left: 0` and use its natural
     * vertical position by setting both `top: auto` and `bottom: auto`. To center the content, we
     * make it `display: flex` with `justify-content: center` and `align-items: center`.
     */

    align-items: center;
    bottom: auto;
    display: flex;
    height: $large;
    justify-content: center;
    left: 0;
    position: absolute;
    top: auto;
    width: $large;

    input[type="checkbox"]:checked + & {
        content: "✓";
    }

    input[type="checkbox"][indeterminate] + & {
        content: "-";
    }
}

.checkbox.small::before,
.checkbox.small::after {
    /**
     * These should be nested above, but the Sass compiler is having trouble creating the correct
     * rule for `.checkbox::before { &.small {} }`.
     */

    height: $small;
    width: $small;
}
