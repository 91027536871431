
@use "client/css/globals" as *;

$crawlBlue: lightblue;

#crawl {
    background: #000;
    height: 600px;
    overflow: hidden;
    perspective: 250px;
    position: relative;

    &::before {
        background-image: linear-gradient(rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 35%);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
}

@keyframes crawlOpenerFade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#crawl-opener-wrapper {
    align-items: center;
    animation: crawlOpenerFade 1s linear 1s forwards;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

#crawl-opener {
    color: $crawlBlue;
    font-size: 60px;
}

#crawl-text-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    transform: rotateX(45deg);
    transform-origin: 0 200px;
}

@keyframes crawlText {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }

    1% {
        opacity: 1;
        transform: translateY(500px);
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}

#crawl-text {
    animation: crawlText 12s linear 2s forwards;
    color: yellow;
    opacity: 0;
    width: 30em;
}

@keyframes crawlCloser {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#crawl-closer {
    animation: crawlCloser 0.5s ease-in 10s forwards;
    background: #000;
    border: 3px solid $crawlBlue;
    border-radius: 10px;
    color: $crawlBlue;
    font-size: 24px;
    left: 50%;
    opacity: 0;
    padding: $spacingLarge;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;

    a,
    a:visited,
    a:hover {
        background: #000;
        border: 3px solid $crawlBlue;
        border-radius: 5px;
        color: $crawlBlue;
        display: inline-block;
        margin: 0 auto;
        padding: 8px 24px;
        text-decoration: none;

        &:hover {
            background: $crawlBlue;
            color: #000;
        }
    }
}
