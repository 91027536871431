
@use "client/css/globals" as *;

.hyperlink {
    color: $pilotPurple;
}

.pilot-page-content {
    text-align: center;
}

.help-message {
    padding-bottom: 40px;
}
