
@use "client/css/globals" as *;

.account-picker-item {
    display: flex;
    justify-content: space-between;

    .account-name {
        margin-right: $spacingMedium;
        max-width: 375px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .account-description {
        color: $contentColorTertiary;
        flex-grow: 0;
        font-size: $fontSizeSmall;
        font-style: italic;
        max-width: 175px;
        overflow: hidden;
        padding-right: 2px; // so italics don't get cut off
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@for $level from 1 through 5 {
    .indented[data-nested="#{$level}"] {
        padding-left: #{$level}em;
    }
}
