
@use "client/css/globals" as *;

td {
    padding: 0; // remove base style from _table.scss.
}

.footer-row {
    background-color: $backgroundColorPrimary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid $borderColorLight;
    display: grid;
    grid-column: 1 / -1; // take up the entire row, from first to last column
    padding: 25px 30px;
}
