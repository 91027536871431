
@use "client/css/globals" as *;

.migrate-coa-account-type-heading {
    border-bottom: 1px solid $borderColorDark;
}

.migrate-coa-depth-1 {
    padding-left: 3em;
}

.migrate-coa-depth-2 {
    padding-left: 4em;
}

.migrate-coa-depth-3 {
    padding-left: 5em;
}

.migrate-coa-depth-4 {
    padding-left: 6em;
}

.migrate-coa-depth-5 {
    padding-left: 7em;
}

.migrate-coa-form-errors {
    margin-bottom: $spacingSmaller;
}

@for $level from 1 through 5 {
    .coa-option[data-nested="#{$level}"] {
        padding-left: #{$level}em;
    }
}
