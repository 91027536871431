
@use "client/css/globals" as *;

$iconWidth: 20px;
$iconHeight: 20px;

button {
    background: transparent;
    border: none;
    cursor: pointer;
    line-height: 0;
    padding: 30px 20px;
}

.icon {
    height: $iconHeight;
    width: $iconWidth;
}

svg {
    color: $contentColorSecondary;
}

.icon-container {
    &:hover {
        color: $pilotPurple;

        svg {
            color: $pilotPurple;
        }

        ::v-deep path {
            stroke: $pilotPurple;
        }
    }
}

.active {
    border-radius: 5px;
    color: $pilotPurple;

    svg {
        color: $pilotPurple;
    }

    ::v-deep path {
        stroke: $pilotPurple;
    }
}
