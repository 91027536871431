
@use "client/css/globals" as *;

.fc-warning {
    font-weight: bold;
    padding-top: 10px;
}

.bp-title {
    text-indent: 20px;
}

.bills td {
    border-top: 1px solid $borderColorDark;
}

tr.bill-comparison-row {
    border-top: 1px solid $borderColorLight;
}
