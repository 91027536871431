
@use "client/css/globals" as *;

.card-row {
    align-items: center;
    display: flex;

    &:focus,
    &:hover {
        background-color: $backgroundTonalGrey;
    }

    // As a link we don't want link styling
    &,
    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &.clickable {
        cursor: pointer;
    }
}

// large and centered item in square container
// should expand for wider contents
// set min width to allow alignment of row descriptions
.highlight {
    align-items: center;
    display: flex;
    flex-basis: $spacingLarge; // min width
    height: $spacingLarge;
    justify-content: center;
}

// description should fill space between highlight (left) and arrow icon (right)
.row-description {
    flex: 1;
    font-size: $fontSizeMedium;
    margin: 0 $spacingSmall;
}
