
@use "client/css/globals" as *;

.pilot-team-wrapper {
    max-width: 1100px;
    padding: 0;
}

.product-section-container {
    list-style-type: none;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 20px;
    }
}

.product-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.tax-dropdown {
    padding-bottom: 15px;
    padding-left: 20px;
    padding-top: 15px;
    width: 170px;
}

.active-status {
    font-size: 13px;
    font-weight: normal;
}

.active {
    color: $activeGreen;
}

.not-active {
    color: $mattePurple;
}

.assignment {
    margin-top: 15px;
}
