
@use "client/css/globals" as *;

.header-content {
    display: flex;
    flex-wrap: wrap;
}

.view-register-btn {
    align-self: center;
    margin-left: auto;
}

.transaction-table-header-container {
    align-items: center;
    display: flex;
    gap: 5px;
}

.reconciliation-result-table-title {
    font-size: 24px;
}

.reconciliation-result-header,
.reconciliation-result-table-title {
    margin-bottom: 0;
}

.transaction-table-container {
    margin-bottom: 30px;
}

.horizontal-padding {
    padding: 10px 0;
}

.sticky-recon-results {
    background-color: $backgroundColorPrimary;
    position: -webkit-sticky; /* For the Safari browser */
    position: sticky;
    top: -25px; // to remove the gap between trouser header and ReconcileResults component upon scroll
    z-index: $zIndexSecondaryView;
}

.transaction-loading {
    display: flex;
    justify-content: center;
}

.btn-container {
    display: flex;
    justify-content: flex-end;
}
