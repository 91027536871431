
@use "sass:color";
@use "sass:math";
@use "client/css/globals" as *;

$toggleWidth: 40px;
$toggleHeight: 20px;
$thumbSpacing: 3px;
$thumbSize: $toggleHeight - ($thumbSpacing * 2);

.toggle-disabled.toggle-container {
    color: $contentColorTertiary;
}

label.toggle {
    display: inline-block;
    height: $toggleHeight;
    margin-right: $spacingSmaller;
    position: relative;
    width: $toggleWidth;

    .toggle-bg {
        // Enabled and Off
        background: $contentColorTertiary;
        border-radius: math.div($toggleHeight, 2);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.25s;
    }

    // Enabled and On
    .toggle-input:checked:not(:disabled) ~ .toggle-bg {
        &.primary {
            background: $brandColorPrimary;
        }

        &.at-risk {
            background: $red1;
        }
    }

    // Disabled and On
    .toggle-input:checked:disabled ~ .toggle-bg {
        &.primary {
            background: $brandColorPrimaryDesaturated;
        }

        &.at-risk {
            background: $pastelRed;
        }
    }

    // Disabled and Off
    .toggle-input:not(:checked):disabled ~ .toggle-bg {
        background: color.adjust($contentColorTertiary, $lightness: 25%);
    }

    .toggle-handle {
        background: $backgroundColorPrimary;
        border-radius: 50%;
        content: "";
        cursor: pointer;
        display: block;
        height: $thumbSize;
        left: $thumbSpacing;
        position: absolute;
        top: $thumbSpacing;
        transition: all 0.25s;
        width: $thumbSize;
        z-index: $zIndexBase + 2;
    }

    // Default HTML checkbox
    .toggle-input {
        opacity: 0;
    }

    .toggle-input:checked + .toggle-handle {
        left: $toggleWidth - $thumbSize - $thumbSpacing;
    }

    .toggle-input:disabled ~ .toggle-handle {
        opacity: 0.8;
    }

    .toggle-input:disabled ~ .toggle-handle,
    .toggle-input:disabled ~ .toggle-bg {
        cursor: not-allowed;
    }

    &:hover {
        .toggle-bg {
            background: color.adjust($contentColorTertiary, $lightness: -4%);
        }

        .toggle-input:checked:not(:disabled) ~ .toggle-bg {
            &.primary {
                background: $brandColorPrimaryDark;
            }

            &.at-risk {
                background: color.adjust($red1, $lightness: 10%);
            }
        }
    }
}
