
@use "client/modules/categorize/css/categorize";

.split-txn-items-cell {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    justify-content: flex-start;
}

::v-deep .split-line-summary {
    width: 100%;
}
