
@use "client/css/globals" as *;

.split-card {
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: grid;
    grid-template-areas:
        "header header"
        "subheader subheader"
        "left   right";
    grid-template-rows: auto auto;

    &.split-card-border {
        .split-card-side {
            padding: $spacingMediumLarge;
        }

        .split-card-left {
            border-right: 1px solid $borderColorLight;
        }
    }

    &:not(.split-card-border) {
        column-gap: $spacingMediumLarge;

        .split-card-left {
            padding-left: $spacingMediumLarge;
        }

        .split-card-right {
            padding-right: $spacingMediumLarge;
        }
    }
}

.split-card-header {
    border-bottom: 1px solid $borderColorLight;
    grid-area: header;
}

.split-card-header-text {
    align-items: center;
    color: $contentColorPrimary;
    display: flex;
    font-size: $fontSizeMedium;
    font-weight: 600;
    height: 65px;
    padding-left: $spacingMediumLarge;
}

.split-card-left {
    grid-area: left;
}

.split-card-right {
    grid-area: right;
}
