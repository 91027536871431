
.file-card {
    width: 100%;
}

.uploading-card {
    align-items: center;
    display: flex;
    gap: 10px;

    .upload-spinner {
        // override default styling of spinner
        top: 0;
    }
}

.file-icon {
    margin-right: 10px;
}

.file-card-compact {
    align-items: center;
    display: grid;

    // Slot and/or 'remove file' can be present or not present in the 2 right-align cells.
    grid-template:
        "icon file right-align-1 right-align-2" auto
        / auto 1fr auto auto;
}

.remove-button {
    margin-left: 30px;
}

// Needed for file name to truncate with ellipses based on width of container
.file-name-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.file-name {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.parent-wrapper {
    // Required to allow the file name to truncate with ellipses based on width of container
    // 75 as an arbitrary number that seems appropriate for mobile viewing
    min-width: 75px;
}
