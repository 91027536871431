
@use "client/css/globals" as *;

p {
    margin-bottom: 0;
}

.match-detail {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    margin-bottom: $spacingHuge;
    padding: $spacingMedium;
}

.match-detail-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: $spacingMediumSmall;
}

.match-detail-type {
    font-weight: bold;
}

.match-detail-info {
    display: inline-flex;

    .icon {
        margin-left: $spacingSmaller;
    }
}

.match-detail-tooltip {
    width: 200px;
}

.match-detail-body {
    font-size: $fontSizeSmall;
}

.match-detail-body-row {
    align-items: center;
    display: grid;
    grid-template-columns: $spacingLarger 1fr;

    &:not(:last-child) {
        padding-bottom: $spacingMediumSmall;
    }
}

.match-detail-who,
.match-detail-amount,
.match-detail-exact-vendor {
    font-weight: bold;
}

.match-detail-value {
    word-wrap: anywhere;
}

.match-detail-green {
    background: $green1Background;
    max-width: fit-content;
}

.match-detail-yellow {
    background: $yellow1Background;
    max-width: fit-content;
}
