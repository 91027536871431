
@use "client/css/globals" as *;

$verticalPadding: 95px;

.form-container {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    color: $contentColorPrimary;
    min-width: 670px;
    padding: 50px $verticalPadding;
}

.layout-container {
    display: flex;
    justify-content: center;
    padding-bottom: 45px;
}

.button-wrapper {
    display: flex;
    justify-content: center;
}
