
@use "client/css/globals" as *;

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.customer-access-request-field {
    margin: 0 0 $spacingMedium;
    position: relative;
}

.additional-context {
    background: $backgroundColorPrimary;
    min-height: 158px;
    min-width: 100%;
}

textarea:invalid {
    border-color: $red1Light;
    box-shadow: none; // Firefox default style for invalid (like focus outline)
}
