
@use "client/css/globals" as *;

#customer-tool-transactions {
    .date-range {
        margin-bottom: $spacingMedium;
        max-width: $fieldWidthBase;
    }

    .txn-column-narrow {
        width: 140px;
    }

    p.pilot-text {
        margin-bottom: $spacingMedium;
        max-width: $fieldWidthBase;
    }

    table.narrow {
        max-width: 1000px;
    }
}
