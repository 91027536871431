
@use "client/css/globals" as *;

td {
    padding-bottom: $spacingMediumSmall;
    padding-top: $spacingMediumSmall;
}

.index {
    color: $contentColorTertiary;
    font-family: monospace;
    font-size: $fontSizeSmall;
}

.amount {
    position: relative;
}

.amount-input {
    text-align: right;
}

.amount-unit {
    color: $contentColorTertiary;
    margin: $inputPaddingTop 0 0 $inputPaddingHorizontal;
    pointer-events: none;
    position: absolute;
}

.has-error {
    border-color: $red1Light;
}
