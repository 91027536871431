
@use "client/css/globals" as *;

.reviewbot-group {
    margin-bottom: $spacingLarger;

    > h3 {
        border-bottom: 3px solid $borderColorLight;
        margin-bottom: $spacingMedium;
        padding-bottom: $spacingSmaller;
    }
}

.reviewbot-group-result {
    margin: 0 0 $spacingLarge;

    > h4 {
        font-size: $fontSizeMedium;
    }
}

.reviewbot-group-result-failures {
    padding-left: 0;
}

.reviewbot-no-results {
    color: $contentColorTertiary;
    font-size: $fontSizeMedium;
}
