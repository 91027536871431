
@use "client/css/globals" as *;

.row {
    border-bottom: 1px solid $borderColorLight;

    &:last-child {
        border-bottom: 0;
    }

    &.headers {
        border-bottom: 1px solid $borderColorLight;
        position: sticky;
        top: 0;
        z-index: 2; // required to sit above checkboxes
    }
}

.values {
    background-color: $backgroundColorSecondary;
    display: flex;
}

.account-column {
    flex: 1 0 250px;
}

.month-column,
.total-column {
    display: flex;
    flex: 0 0 120px; // arbitrary width; must be fixed so that columns align
    flex-direction: column;
    text-align: right;
}

.month-cell,
.total-cell {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: right;
}

.cell {
    padding: $spacingSmaller $spacingSmall;
}

.cell-header {
    font-weight: bold;
}

.insights {
    background-color: $backgroundColorTertiary;
    border-top: 1px solid $borderColorLight;
    list-style-type: none; // reset for `ul`
    margin: 0; // reset for `ul`
    padding: $spacingMediumSmall 25px; // new design system spacing, not in global stylesheet
}

.insight {
    align-items: center;
    display: flex;
    padding: $spacingSmall 0;

    &.custom-insight {
        align-items: flex-start;
    }
}

.include-insight {
    margin-right: $spacingSmall;

    .generated-insight & {
        margin-top: -7px; // adjust top to properly align centers
    }
}

.custom-insight-note {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.add-insight {
    background: 0; // reset button
    border: 0; // reset button
    color: $brandColorPrimary;
    cursor: pointer;
    padding: 0; // reset button

    &:disabled {
        cursor: default;
        opacity: 0.3; // same opacity as disabled checkboxes
    }
}

.missing-vendor-error {
    color: $red1;
}

.disabled-insight {
    color: $contentColorTertiary; // lighten text to indicate disabled option
}
