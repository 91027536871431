
@use "client/css/globals" as *;

.is-bookkeeping {
    font-weight: bold;
    margin-bottom: $spacingMediumSmall;
}

.bookkeeping-bool {
    max-width: 600px;
}
