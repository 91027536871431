
@use "client/css/globals" as *;

$capitalizationsLabelWidth: 250px;
$infoCellWidth: 125px;
$monthCellWidth: 95px;

#customer-capitalizations {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: calc(100vh - $adminPageHeaderWithSecondaryNavAndPaddingHeight);
}

#capitalizations-container {
    overflow-x: auto;
}

.capitalization-group-headers {
    border-bottom: $borderColorDark solid 1px;
    vertical-align: bottom;
}

.capitalization-label {
    background: $backgroundColorPrimary;
    left: 0;
    max-width: 100%;
    overflow-x: hidden;
    position: sticky;
    position: -webkit-sticky;
    text-align: left;
    white-space: nowrap;
    width: $capitalizationsLabelWidth;

    &.asset-name {
        color: $linkColorPrimary;
        text-indent: 1em;
        text-overflow: ellipsis;
    }

    &.group-name {
        font-size: $fontSizeMedium;
        padding-left: 0;
    }

    &.group-total {
        text-align: right;
    }
}

#capitalizations {
    &.fx-enabled {
        th:nth-child(5),
        td:nth-child(5) {
            left: $capitalizationsLabelWidth + $infoCellWidth * 3;
            max-width: 100%;
            overflow-x: hidden;
            position: sticky;
            position: -webkit-sticky;
            width: $infoCellWidth;
        }
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4) {
        left: $capitalizationsLabelWidth;
        max-width: 100%;
        overflow-x: hidden;
        position: sticky;
        position: -webkit-sticky;
        width: $infoCellWidth;
    }

    th:nth-child(3),
    td:nth-child(3) {
        left: $capitalizationsLabelWidth + $infoCellWidth;
    }

    th:nth-child(4),
    td:nth-child(4) {
        left: $capitalizationsLabelWidth + $infoCellWidth + $infoCellWidth;
    }
}

.capitalization-asset + .capitalization-group-total {
    border-top: double $borderColorDark;
}

.capitalization-group-total:last-child {
    & td,
    & th {
        padding-bottom: $spacingLarger;
    }
}

.capitalization-total {
    &:first-child {
        border-top: 1px solid $borderColorDark;
    }

    &:last-child {
        border-bottom: 1px solid $borderColorDark;
    }
}

.capitalization-month-cell {
    text-align: right;
    width: $monthCellWidth;
}

.capitalization-info-cell {
    background-color: $backgroundColorSecondary;
    text-align: right;
    width: $infoCellWidth;
}

.three-col-span-frozen {
    background: $backgroundColorPrimary;
}
