
@use "client/css/globals" as *;

.scheduled-reminder {
    display: inline-block;
}

.purple-pulse {
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    border-radius: 180px;
    transition-timing-function: ease-in-out;
}

@keyframes pulse {
    0% {
        box-shadow: none;
    }

    50% {
        box-shadow: $purpleShadow;
    }

    100% {
        box-shadow: none;
    }
}
