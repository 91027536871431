
td {
    padding: 0; // remove base style from _table.scss.
}

tr {
    display: grid;
    grid-column: 1 / -1; // take up the entire row, from first to last column
    padding: 25px 30px;
}

.suggested-rule-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
}

.bold {
    font-weight: bold;
}

.flex-container {
    column-gap: 5px;
    display: flex;
    margin-top: 10px;
}

.italic {
    font-style: italic;
}
