
/**
 * From https: //vuejs.org/api/sfc-css-features.html#scoped-css:
 *
 * "With scoped, the parent component's styles will not leak into child components.
 * However, a child  component' s root node will be affected by both the parent's scoped
 * CSS and the child' s scoped CSS. This is by design so that the parent can style the
 * child root element for layout purposes."
 *
 * Because <PilotTitle> also has a .container class,
 * we need a different class name to avoid conflicts.
*/
.pilot-card {
    margin-bottom: 30px;
}

.pilot-tag {
    margin-right: 10px;
}
