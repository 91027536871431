
@use "client/css/globals" as *;

.card {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    color: $contentColorPrimary;

    &.none {
        border: none;
    }

    &.default {
        border: 1px solid $borderColorLight;
    }

    &.bottom {
        border-bottom: 1px solid $borderColorLight;
    }

    &.error {
        border: 1px solid $red1Light;
    }

    &.highlight {
        border: 1px solid $highlightPurple;
    }

    &.highlight-glow {
        border: 1px solid $borderColorLight;
        box-shadow:
            0 0 3px $pilotPurple,
            0 2px 8px rgba(95, 46, 229, 0.1);
    }
}

.separated-rows {
    > *:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
    }
}

.pilot-card-header {
    padding: 15px 25px;
}
