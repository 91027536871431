
.card {
    margin-bottom: 30px;
}

.tabs {
    margin-top: 30px;
}

.date-range-picker {
    margin-bottom: 15px;
    margin-top: 20px;
}

.criteria-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.date-pickers {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
}

.search-button {
    width: 150px;
}
