
@use "client/css/globals" as *;

.billing-section {
    margin-bottom: 30px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.billing-heading {
    border-bottom: 1px solid $borderColorLighter;
    font-size: $fontSizeBase;
    margin: 0;
    padding: 20px 30px;
}

.row-subtext {
    color: $contentColorSecondary;
    font-size: $fontSizeMicro;
    margin: 0;
}

.billing-rows {
    border: 0;

    > *:not(:last-child) {
        border-bottom: 1px solid $borderColorLighter;
    }
}
