
@use "client/css/globals" as *;

.customer-context-section {
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    padding: 20px 30px;
}

.checklist-context-header {
    margin: 0;
    padding-bottom: 10px;
    padding-left: 35px; // Includes room for absolutely positioned caret pseudoelement
    padding-top: 10px;
    position: relative;
}

.checklist-context-caret {
    // Custom positioning for Customer Context section, which has different dimensions than other checklist sections
    left: 0;
    top: 9px;
}

.customer-context {
    background: $backgroundColorSecondary;
    margin: 0 0 $spacingMedium;
}

.customer-context-edit-history {
    color: $contentColorTertiary;
    margin: 0;
}

.customer-context-empty {
    color: $contentColorSecondary;
}

.customer-context-rendered-markdown {
    padding: $spacingMedium;

    h1 {
        font-size: $fontSizeLarge;
        text-align: left;
    }

    h2 {
        font-size: $fontSizeMedium;
    }

    h3 {
        font-size: $fontSizeBase;
    }

    p:last-child {
        margin-bottom: 0;
    }

    pre {
        white-space: pre-wrap;
    }
}

.static-context {
    border: none;
    margin: 0;
    padding: 0;
}

.static-context-header {
    padding: 0;
}

.customer-context-subheading {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr auto;
    padding-bottom: 10px;
    padding-top: 15px;
}
