
@use "client/css/globals" as *;

.reconcile-csv-values-container {
    overflow-x: auto;
}

.reconcile-csv-parsing-help {
    overflow-y: auto;
}

.reconcile-csv-values {
    table-layout: auto;
    white-space: nowrap;
}

.sign-toggle {
    margin-bottom: $spacingMedium;
}
