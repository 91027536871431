
@use "client/css/globals" as *;

.messages {
    display: grid;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    z-index: 100;
}

.message {
    background: $yellow1Background;
    border: 2px solid $yellow1;
    border-radius: 5px;
    margin: 0;
    max-width: 500px;
    padding: 5px 10px;
    text-align: center;
}

.nav-extras {
    border-top: 1px solid $borderColorLight; // separate nav extras from primary nav with line
    padding-top: 20px; // space between separator (border) and nav extra content

    // remove default button styling added to collapse sidebar button
    button.collapse-btn {
        background: transparent;
        border: 0;
        padding: 0;
    }

    // highlightable elements are interactive elements (e.g. link or button)
    .highlightable {
        cursor: pointer;
        outline: 0; // we're going to add highlighting to icons so remove default outline

        // the icon had padding so add a margin to both ends of text so it looks aligned
        .report-text {
            margin: 0 10px; // use margin so that space isn't underlined on hover
        }

        // add inactive-state styles to icons for collapse + report
        // matches `.nav.primary-icons .icon` style in `<PilotNav>`
        .collapse-icon,
        .report-icon {
            border: 2px solid transparent; // always have a border (add color when highlighted)
            border-radius: $borderRadiusSmall; // smooth out the corners cuz it's pretty
            box-sizing: content-box; // size of container should include border and padding
            display: grid;
            height: 24px;
            padding: 5px; // add space between content and border
            place-items: center;
            width: 24px;
        }

        &:hover,
        &:focus {
            .collapse-icon,
            .report-icon {
                border-color: $brandColorPrimary; // when highlighted, highlight icon border
            }
        }
    }

    // match primary nav link text (except a little smaller)
    .report-link {
        color: $contentColorPrimary;
        font-size: $spacingMediumSmall;
        font-weight: bold;

        &:focus,
        &:hover {
            text-decoration: underline $brandColorPrimary;
        }
    }

    // when expanded ...
    &:not(.collapsed) {
        .report-icon {
            display: none; // ... hide the report issue icon
        }
    }

    // when collapsed ...
    &.collapsed {
        border-top-color: transparent; // hide separator

        .report-text {
            display: none; // ... hide the report issue text
        }

        // flip the collapse icon direction
        .collapse-icon {
            transform: rotate(180deg);
        }
    }
}
