
@use "client/css/globals" as *;

.header-left {
    align-items: center;
    display: flex;
}

.header-name {
    color: $brandColorPrimary;
    font-size: $fontSizeMedium;
    font-weight: bold;
    margin-right: 5px;
}

.header-category {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.insight-container {
    display: flex;
    margin: 0 -20px;
    padding: 0 20px;
    padding-top: 20px;

    &:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
        padding-bottom: 20px;
    }

    & > .insight-wrapper {
        align-items: center;
        display: flex;
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    width: 140px;
}
