
.multiselect {
    width: 100%;
}

::v-deep .pilot-table-row-cell {
    border-top: none;
}

::v-deep div.multiselect__spinner {
    background: transparent;
}

.padding-bottom {
    padding-bottom: 30px;
}
