
@use "sass:math";
@use "client/css/globals" as *;

$buttonSize: 14px; // copied from base-close-button.vue
$showButtonSize: 23px;

.accordion {
    margin: 0;
    width: 100%;
}

.input-list-field {
    width: 100%;
}

.clear-tax-id-number-btn {
    bottom: math.div($fieldValueHeight - $buttonSize, 2);
    position: absolute;
    right: math.div($fieldValueHeight - $buttonSize, 2);
}

.hide-tax-id-number-btn,
.show-tax-id-number-btn {
    bottom: math.div($fieldValueHeight - $showButtonSize, 2);
    cursor: pointer;
    position: absolute;
    right: math.div($fieldValueHeight - $showButtonSize, 1);
}
