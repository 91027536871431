@use "globals" as *;

$admin-card-size: 80px;
$onboarding-icon-size: 35px;

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.admin-card {
    display: flex;
    flex-direction: column;
    margin: 0 $spacingMedium $spacingMedium 0;
    width: $admin-card-size;

    .admin-card-icon {
        background-color: $backgroundColorPrimary;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        border: 2px solid $borderColorLight;
        border-radius: 0;
        display: inline-block;
        height: $admin-card-size;
        transition: box-shadow 0.2s;
        width: $admin-card-size;
    }

    .admin-card-label {
        color: $contentColorSecondary;
        font-size: 12px;
        text-align: center;
        text-decoration: none;
    }

    &:last-child {
        margin-right: 0;
    }

    &.active {
        .admin-card-icon,
        &:hover .admin-card-icon {
            border: 3px solid $brandColorPrimary;
        }

        .admin-card-label {
            color: $brandColorPrimary;
        }
    }

    &:hover {
        .admin-card-icon {
            border-color: $brandColorPrimary;
        }

        .admin-card-label {
            color: $brandColorPrimary;
        }
    }

    a:hover {
        text-decoration: none;
    }
}


.onboarding-card {
    display: flex;
    flex: 1 0 100%;
    margin: 0 $spacingMedium $spacingMedium 0;

    .onboarding-card-icon {
        background-color: $backgroundColorPrimary;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        flex: 0 0 $onboarding-icon-size;
        height: $onboarding-icon-size;
        transition: box-shadow 0.2s;
        width: $onboarding-icon-size;
    }

    .onboarding-card-label {
        color: $contentColorSecondary;
        flex: 1;
        font-size: 15px;
        margin-left: $spacingMedium;
        margin-top: 8px; // vertical centering of label
        text-align: left;
        text-decoration: none;
    }

    &:last-child {
        margin-right: 0;
    }

    a:hover {
        text-decoration: none;
    }
}
