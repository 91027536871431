
@use "client/css/globals" as *;

.payroll-expert-page {
    padding: 5px;
}

.banner {
    margin-bottom: 20px;
}

.payroll-rules-table {
    ::v-deep tr > *:first-child {
        padding-left: 20px;
    }

    ::v-deep tr > *:last-child {
        padding-right: 20px;
    }

    .condition {
        width: 220px;
    }

    .overflow {
        width: 60px;
    }

    .conflict-description {
        align-items: center;
        color: $red1;
        display: grid;
        font-weight: 600;
        grid-template-columns: auto 1fr;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .rule-conflict {
        border-radius: $borderRadiusMedium;

        // using box shadow instead of border lets us use border radius on table elements
        box-shadow:
            0 0 3px $red1,
            0 2px 8px rgba(206, 24, 54, 0.1);
    }

    .spacer {
        padding: 2px;
    }
}
