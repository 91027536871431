// to align headers with first text column:
// checkbox column width (40px) + td padding (10px)
$alignmentPadding: 50px;

.align-padding-left {
    padding-left: $alignmentPadding;
}

::v-deep table {
    table-layout: auto;

    tbody tr {
        border-top: none;

        td {
            padding-bottom: 6px;
            padding-top: 15px;
            vertical-align: top;

            &.tick-and-tie-comments,
            &.tick-and-tie-overflow {
                padding-top: 6px;
            }

            &.tick-and-tie-overflow {
                padding-left: 0;
            }
        }
    }

    .tick-and-tie-x {
        padding-left: 0;
        padding-right: 0;
    }

    .tick-and-tie-checkbox,
    .tick-and-tie-x,
    .tick-and-tie-overflow {
        max-width: 40px;
    }

    .tick-and-tie-date {
        min-width: 12em;
    }

    .tick-and-tie-vendor {
        width: 12em;
    }

    .tick-and-tie-memo {
        min-width: 100px;
    }

    .tick-and-tie-debit,
    .tick-and-tie-credit {
        width: 8em;
    }

    .tick-and-tie-reconciled {
        width: 6.7em;
    }

    .tick-and-tie-comments {
        max-width: 400px;
    }

    .tick-and-tie-undo {
        width: 20em;
    }
}
