
@use "client/css/globals" as *;

.table {
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusLarge;
}

.instructions {
    max-width: 60em;
    padding-right: 2em;
}
