
@use "sass:math";
@use "client/css/globals" as *;

.user-avatar {
    align-items: center;
    border-radius: 50%;
    color: $brandColorPrimaryContrast;
    cursor: pointer;
    display: flex;
    font-size: $fontSizeSmall;
    height: $userAvatarSize;
    justify-content: center;
    line-height: $fontSizeSmall;
    width: $userAvatarSize;

    &.customer-bg {
        background: $blue1;
    }

    &.default-bg {
        background: $borderColorLight;
        color: $contentColorPrimary;
    }

    &.pilot-purple-bg {
        background: $brandColorPrimary;
    }

    &.user-avatar-small {
        font-size: math.div($fontSizeBase, 2);
        height: math.div($userAvatarSize, 2);
        line-height: math.div($fontSizeBase, 2);
        width: math.div($userAvatarSize, 2);
    }
}
