
.margin-top {
    margin-top: 30px;
}

.status-banner {
    margin-bottom: 20px;
}

.reconciliation-table-custom-margin {
    margin: 25px 30px;
}

::v-deep .tab {
    font-weight: bold; // override Tabs component default font-weight
}

.top-transactions-table,
.footer {
    margin-top: 20px;
}
