
@use "client/css/globals" as *;

ol {
    // Override default browser styles
    margin: 0;
    padding: 0;
}

.form-errors {
    margin-bottom: 20px;
}

.attribute-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-template:
        "attribute-checkbox attribute-label" auto
        "................   attribute-input" auto
        / auto 1fr;
    padding-top: 20px;
}

.attribute-checkbox {
    align-items: center;
    display: inline-flex;
    grid-area: attribute-checkbox;
}

::v-deep .pilot-checkbox-big {
    // Remove the top styling affecting centering calculations.
    top: 0;
}

.attribute-label {
    align-items: center;
    display: flex;
}

.attribute-label-tooltip {
    // Remove extra height from icon
    line-height: 0;
}

::v-deep .tooltip-content {
    width: 350px;
}

.attribute-input {
    grid-area: attribute-input;
    margin-top: 10px;
}

.additional-context {
    display: block;
    padding-top: 40px;
}

.secondary-text {
    color: $contentColorSecondary;
}

// Remove default browser styles. JS-246
.fieldset-no-chrome,
.legend-no-chrome {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}
