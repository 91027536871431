
@use "client/css/globals" as *;

.instructions {
    max-width: 60em; // arbitrary line-length
    padding-right: 2em;
}

.validation-desc {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: right;
    padding-right: $spacingMedium;
}
