
@use "client/css/globals" as *;

$drawer-header-height: 123px;
$drawer-footer-height: 101.5px;
$drawer-body-height: calc(100vh - $drawer-header-height - $drawer-footer-height);

::v-deep .drawer-content {
    padding: 0;
}

::v-deep .drawer-header {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 45px 40px 30px;
}

::v-deep .container-mayday {
    display: none;
}

::v-deep .pilot-tag {
    border-radius: 4px;

    .tag-content {
        font-size: $fontSizeBase;
    }
}

::v-deep .post-icon.isAdmin {
    margin: 0;
}

.drawer-header-text {
    line-height: 48px;
    top: 0;
}

.drawer-body {
    border-top: 1px solid $borderColorLight;
    min-height: $drawer-body-height;
    padding: 20px 40px 30px;

    .instructions {
        margin: 20px 0;
    }

    .request-details-label {
        margin-bottom: 0;
    }

    .business-day-container {
        display: flex;
        margin-bottom: 20px;

        .business-day-dropdown {
            margin-right: 10px;
            width: 277px;
        }

        .day-of-the-month {
            & > p {
                line-height: 20px;
                margin-bottom: 0;
                margin-top: 30px;
            }
        }
    }

    .message-preview {
        border: 1px solid $borderColorLight;
        border-radius: 4px;
    }

    .next-scheduled-request-container {
        margin-top: 40px;

        .request-toggle-and-calendar {
            margin: 15px 0;
        }

        .date-picker {
            margin: 2px 4px 0 55px;
        }
    }
}

.drawer-footer {
    align-items: center;
    background-color: $brandColorPrimaryContrast;
    border-top: 1px solid $borderColorLight;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    position: sticky;
    width: 100%;
}
