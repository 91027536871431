
@use "client/css/globals" as *;

.account-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.account-info {
    align-items: center;
    display: flex;
}

.flex-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

h2 {
    margin: 0;
}

.institution-img {
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 40px;

    &.default {
        border: 1px solid $borderColorLight;
        border-radius: 5px;
    }

    .default-height {
        height: 40px;
    }
}

.account-name {
    font-weight: bold;
}

.title {
    font-weight: bold;
    margin-bottom: 20px;
}

.accounts-list {
    margin-bottom: 30px;
    margin-top: 30px;
}

hr {
    margin: 0;
}

::v-deep .drawer-content {
    padding: 0;
}

.drawer-body {
    padding: 30px 40px;
}

::v-deep .drawer-header {
    height: auto;
    padding: 45px 40px 30px;
}
