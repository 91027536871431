
.date {
    width: 7em;
}

.vendor {
    width: 12em;
}

.amount {
    width: 8em;
}

.comment {
    width: 30%;
}
