
@use "client/css/globals" as *;

.default-color {
    stroke: currentColor;

    &:hover {
        stroke: currentColor;
    }
}

.external-link-gray {
    &:hover {
        stroke: $contentColorPrimary;
    }
}

.external-link-purple {
    &:hover {
        stroke: $pilotText;
    }
}
