
@use "client/css/globals" as *;

.search-filter {
    label {
        display: block;
        font-size: $fontSizeBase;
        margin: 0;
    }
}

.search-icon-wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: auto;

    .search-icon {
        grid-area: 1 / 1 / 1 / 1;
        margin-left: 5px;
    }

    .search-input {
        grid-area: 1 / 1 / 1 / 2;
        padding-left: 30px;
        width: 480px;

        // SMALL_SCREEN_MAX_WIDTH
        @media (max-width: 559px) {
            width: 100%;
        }

        &.full-width {
            width: 100%;
        }
    }
}
