
@use "client/css/globals" as *;

.accordion {
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    margin: 0 0 $spacingMedium;
    padding: 0;
    width: $fieldWidthBase;

    &.accordion-has-radio {
        .accordion-header {
            align-items: center;
            display: flex;

            > label {
                margin-left: $spacingSmall;
            }
        }

        input[type="radio"] {
            // Chrome and Firefox both have a user agent stylesheet that gives radio
            // buttons 3px of margin on the top and none on the bottom, which leads to
            // a visible misalignment with the label text.
            margin-bottom: 3px;
            margin-top: 3px;
            pointer-events: none;
        }
    }

    .accordion-tooltip {
        // Remove extra height from icon
        line-height: 0;
    }
}

.accordion-item {
    border-bottom: 1px solid $borderColorLight;
    list-style-type: none;
    margin: 0;
    padding: 0;

    &:last-child {
        border-bottom: none;
    }

    &.item-disabled {
        background-color: $backgroundTonalGrey;
    }
}

.accordion-header {
    cursor: pointer;
    padding: $spacingSmall;

    label {
        cursor: inherit;
    }

    .accordion-item:first-child & {
        border-radius: 4px 4px 0 0;
    }

    .accordion-item:last-child & {
        border-radius: 0 0 4px 4px;
    }

    .accordion-item:hover,
    .accordion-active & {
        background-color: $backgroundColorSecondary;
    }
}

.accordion-disabled,
.item-disabled {
    color: $contentColorSecondary;

    .accordion-header {
        cursor: default;
    }
}

.accordion-body {
    padding: 0 $spacingSmall;
    transition: all 0.2s;

    .accordion-has-radio & {
        padding: 0 $spacingMedium 0 $spacingLarge;
    }

    > *:first-child {
        margin-top: $spacingSmall;
    }

    > *:last-child {
        margin-bottom: $spacingSmall;
    }

    &.accordion-slide-enter,
    &.accordion-slide-leave-to {
        height: 0 !important;
        padding-bottom: 0;
        padding-top: 0;
    }

    &.accordion-slide-enter-active,
    &.accordion-slide-leave-active {
        overflow-y: hidden;
    }
}
