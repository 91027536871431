@use "globals" as *;

.spinner {
    display: inline-block;
    position: relative;
    top: 4px;

    .buttons > & {
        align-self: center;
        top: 0;
    }

    @keyframes rotation {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(359deg);
        }
    }

    &::after,
    &::before {
        display: block;
    }

    &::after {
        animation: rotation 0.75s linear infinite;
        border: solid 5px $contentColorSecondary;
        border-left-color: transparent;
        border-radius: 50%;
        content: "";
        height: 50px;
        width: 50px;
    }

    &.spinner-light::after {
        border-color: $backgroundDarkPrimaryContrast;
        border-left-color: transparent;
    }

    &.spinner-half::after {
        border-right-color: transparent;
    }

    &.spinner-reverse::after {
        animation-direction: reverse;
    }

    &.spinner-small::after {
        border-width: 2px;
        height: 22px;
        width: 22px;
    }

    .button.primary &,
    .button.danger &,
    .button:not(:disabled):hover &,
    .button:active & {
        &::after {
            border-color: $brandColorPrimaryContrast;
            border-left-color: transparent;
        }

        &.spinner-half::after {
            border-right-color: transparent;
        }
    }

    .button.primary:disabled &,
    .button.danger:disabled & {
        &::after {
            border-color: $darkGrey;
            border-left-color: transparent;
        }

        &.spinner-half::after {
            border-right-color: transparent;
        }
    }
}
