
@use "client/css/globals" as *;
@use "client/modules/portal-activity/css/cash-flow-and-spend-report-table";

::v-deep .pilot-table-header-cell.sticky-header.medium {
    height: 60px;
    z-index: 2; // allows the hover bubble to appear above the column header
}

// lock the upper leftmost cell at the top
::v-deep .pilot-table-header-cell.sticky-header.medium.first {
    left: 0;
    position: sticky;
    z-index: 4;
}

.bold {
    font-weight: bold;
}

.column-header {
    // to lock the leftmost column
    left: 0;
    position: sticky;
    z-index: 1;
}

.vendor-logo {
    flex: 0 0 32px;
    margin-right: 10px;
}

.no-data {
    padding-left: 16px;
}

.first {
    width: 310px;
}

::v-deep .pilot-table-row-cell.medium.number.clickable-row-cell:hover {
    background-color: $yellow1Background;
    cursor: pointer;
    text-decoration-line: underline;
}
