
@use "client/css/globals" as *;

$wrappingBreakpoint: 1100px; // arbitrary width

.main-section {
    display: flex;
    padding-bottom: $spacingMediumLarge * 2;

    @media (max-width: $wrappingBreakpoint) {
        & {
            flex-wrap: wrap;
        }
    }

    .home-onboarding-content {
        flex: 1 1 600px; // arbitrary width
        margin-bottom: $spacingMediumLarge;

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
            }
        }
    }

    .home-side-content {
        flex: 0 0 380px; // arbitrary width
        margin-left: $spacingMediumLarge;

        @media (max-width: $wrappingBreakpoint) {
            & {
                flex: 1 1 auto;
                margin-left: 0;
            }
        }
    }
}

.home-onboarding-card:not(:last-child) {
    margin-bottom: $spacingMediumLarge;
}

.loading-error-container {
    width: 100%;
}
