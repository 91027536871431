
.vendor-mapping-checkbox {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr;

    .disabled {
        opacity: 0.3;
    }
}

.name {
    font-weight: bold;
}
