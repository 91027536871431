
@use "client/css/globals" as *;

::v-deep.report-download.card {
    border-bottom: 1px solid $pastelViolet;
    border-radius: 0;
}

.report-download h3 {
    margin: 0;
}
