@use "client/css/globals" as *;

::v-deep .apexcharts-tooltip {
    hr {
        margin: 0;
    }

    .balance {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .date {
        color: $contentColorSecondary;
        padding: 10px 20px;
    }

    .financial-item {
        align-items: left;
        display: flex;
    }

    .financial-item-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .financial-item-line-container {
        align-items: left;
        display: inline-flex;
        margin-right: 15px;
    }

    .financial-item-name {
        display: inline-flex;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
    }

    .financial-item-balance {
        font-size: 14px;
        text-align: right;
    }

    .margin-bottom {
        margin-bottom: 10px;
    }

    .line {
        border-radius: 2px;
        height: 20px;
        margin-right: 10px;
        width: 4px;
    }

    .green-line {
        background-color: $green1;
    }

    .red-line {
        background-color: $red1;
    }

    .blue-line {
        background-color: $blue2;
    }

    .info-container {
        padding: 15px 20px;
    }

    .red-text {
        color: $red1;
    }
}

// Control hover states via CSS; also need to disable state filters in the chart options
// (see APEX_OPTIONS in chart-utils.ts)
::v-deep .apexcharts-bar-series > .apexcharts-series[rel="1"] .apexcharts-bar-area:hover {
    fill: $red1;
}

::v-deep  .apexcharts-bar-series > .apexcharts-series[rel="1"] path:last-of-type {
    fill: #ffb7c1;
    fill-opacity: 0.5;
}

::v-deep  .apexcharts-bar-series > .apexcharts-series[rel="2"] .apexcharts-bar-area:hover {
    fill: $green1;
}

::v-deep  .apexcharts-bar-series > .apexcharts-series[rel="2"] path:last-of-type {
    fill: #D3E8AE;
    fill-opacity: 0.5;
}
