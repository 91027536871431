
@use "client/css/globals" as *;

.card-row {
    align-items: center;
    display: flex;

    &:focus,
    &:hover {
        background-color: $backgroundTonalGrey;
    }
}

// row is a link but we don't want link styling
a.card-row,
a.card-row:hover {
    color: inherit;
    text-decoration: none;
}

// large and centered number in square container
// should expand for larger numbers
// set min width to allow alignment of row descriptions
.highlight-number {
    flex-basis: $spacingLarge; // min width
    font-size: $fontSizeLarger;
    font-weight: bold;
    line-height: $spacingLarge; // line height
    text-align: center;
}

// description should fill space between number (left) and arrow icon (right)
.row-description {
    flex: 1;
    font-size: $fontSizeMedium;
    margin: 0 $spacingSmall;
}
