
@use "client/css/globals" as *;

$caretHeight: 7px;

.coachmark-container {
    position: relative;
    width: min-content;
}

.coachmark-container-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.coachmark-content {
    align-items: center;
    background: $brandColorPrimaryDark;
    border-radius: $borderRadiusMedium;
    box-shadow: $flyoutShadow;
    color: $brandColorPrimaryContrast;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    min-width: 325px;
    padding: 10px 15px;
    position: absolute;
    text-align: left;
    transition: all 0.12s;
    z-index: $zIndexFlyout;

    .coachmark-directive {
        align-items: center;
        display: flex;
        font-weight: bold;
        justify-content: flex-start;
        margin-right: 10px;
        width: max-content;

        #coachmark-icon {
            display: flex;
            padding-right: 5px;
        }
    }

    .dismiss-coachmark {
        // override default HTML button styles
        background: $brandColorPrimaryDark;
        border: none;
        cursor: pointer;
        height: 14px; // match icon height
        padding: 0 10px;
    }

    &.coachmark-bottom {
        left: 50%;
        top: calc(100% + #{$caretHeight} + 6px);
        transform: translateX(-50%);
    }

    &.coachmark-left {
        right: calc(100% + #{$caretHeight} + 6px);
        top: 50%;
        transform: translateY(-50%);
    }
}

.coachmark-content-light {
    align-items: center;
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    box-shadow: $flyoutShadow;
    color: $brandColorPrimaryDark;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    min-width: 325px;
    padding: 10px 15px;
    position: absolute;
    text-align: left;
    transition: all 0.12s;
    z-index: $zIndexFlyout;

    .coachmark-directive {
        align-items: center;
        display: flex;
        font-weight: bold;
        justify-content: flex-start;
        margin-right: 10px;
        width: max-content;

        #coachmark-icon {
            display: flex;
            padding-right: 10px;
        }
    }

    .dismiss-coachmark {
        // override default HTML button styles
        background: $backgroundColorPrimary;
        border: none;
        cursor: pointer;
        height: 14px; // match icon height
        padding: 0 10px;
    }

    &.coachmark-bottom {
        left: 50%;
        top: calc(100% + #{$caretHeight} + 6px);
        transform: translateX(-50%);
    }

    &.coachmark-left {
        right: calc(100% + #{$caretHeight} + 6px);
        top: 50%;
        transform: translateY(-50%);
    }
}

.caret {
    position: absolute;

    &::before,
    &::after {
        border-style: solid;
        border-width: $caretHeight;
        content: "";
        display: block;
        height: 0;
        position: relative;
        width: 0;
    }

    &::before {
        border-color: transparent transparent $borderColorLight;
        top: -1px;
    }

    &::after {
        border-color: transparent transparent $brandColorPrimaryDark;
        position: absolute;
        top: 0;
    }
}

.caret-light {
    position: absolute;

    &::before,
    &::after {
        border-style: solid;
        border-width: $caretHeight;
        content: "";
        display: block;
        height: 0;
        position: relative;
        width: 0;
    }

    &::before {
        border-color: transparent transparent $brandColorPrimaryDark;
        top: -1px;
    }

    &::after {
        border-color: transparent transparent $brandColorPrimaryContrast;
        position: absolute;
        top: 0;
    }
}

.caret-up {
    left: 50%;
    top: -13px;
    transform: translateX(-50%);
}

.caret-right {
    right: -13px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

.cm-from-left-enter,
.cm-from-left-leave-active,
.cm-from-bottom-enter,
.cm-from-bottom-leave-active {
    transition: opacity, 300ms, ease-in;
}

.cm-from-left-enter,
.cm-from-left-leave-active {
    transform: translateX(-10px);
}

.cm-from-bottom-enter,
.cm-from-bottom-leave-active {
    transform: translateY(10px);
}

.cm-from-left-enter,
.cm-from-left-leave-to,
.cm-from-bottom-enter,
.cm-from-bottom-leave-to {
    opacity: 0;
}
