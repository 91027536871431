
@use "client/css/globals" as *;
@use "client/css/button";
@use "client/modules/categorize/css/categorize";

.hidden {
    visibility: hidden;
}

.deposit {
    color: $green1;
}

.note-input {
    height: 40px;
    min-height: initial;
    overflow: hidden;
    resize: none;
}

// if attributions are being shown, align all stacked inputs along the same grid
.attribution-spacing {
    align-items: center;
    display: grid;
    grid-gap: 14px;
    grid-template-columns: auto 16px; // 16px is the size of the AttributionIcon
}

// for vue-text-highlight
::v-deep .highlight {
    background: $yellow1Background;
    border-radius: 0;
}

.account-suggestions-hover {
    .header {
        font-size: $fontSizeMedium;
        font-weight: bold;
    }

    .accounts-container {
        background-color: $backgroundTonalGrey;
        border-radius: $borderRadiusMedium;
        margin-top: 10px;
        padding: 15px;
    }

    .account-name {
        padding-bottom: 5px;
    }
}

.sparkle-icon {
    display: inline-block;
    min-width: 24px;
}

.categorization-comments {
    width: calc(100% - 30px); // 30px is the width of the attribution icon
}

.categorization-comments,
.metadata-insights {
    .card:first-of-type {
        margin-top: 10px;
    }
}

.combined {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;

    // remove the top border radius from the bottom card, so it appears as a single card
    ::v-deep .insights-card {
        border-radius: 0 0 $borderRadiusLarge $borderRadiusLarge;
        border-top: 1px solid $borderColorLight;
    }

    // remove the bottom border radius from the top card, so it appears as a single card
    ::v-deep .categorization-card {
        border-bottom: 1px solid $borderColorLight;
        border-radius: $borderRadiusLarge $borderRadiusLarge 0 0;
    }
}
