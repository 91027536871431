
@use "client/css/globals" as *;

#ship {
    section {
        margin-bottom: $spacingMediumLarge;
    }

    .textarea {
        min-height: 240px;
    }

    .prefill-questions {
        align-items: center;
        display: flex;
        margin-bottom: $spacingMedium;

        &.v2 {
            margin-bottom: 0;
        }
    }

    .tip {
        font-size: $fontSizeSmall;
        max-width: $fieldWidthWide;

        &.tip-markdown {
            padding-top: $spacingSmall;
            text-align: right;
        }
    }

    .row-container,
    .recipients-container,
    .notes-container,
    .questions-container,
    .email-container,
    .preview-container,
    .gmail-container,
    .save-container,
    .report-options-container {
        font-size: $fontSizeBase;
        margin-bottom: 30px;

        .header {
            font-weight: 600;
        }

        h3 {
            font-size: inherit;
            margin: 0;
        }
    }

    .recipients-container,
    .recipients {
        .link {
            color: $contentColorSecondary;
            font-size: $fontSizeSmall;

            a {
                color: $pilotPurple;
            }
        }
    }

    .checklist-container {
        margin-bottom: 30px;
        position: relative;

        .pointer {
            left: 0%;
            margin: auto 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .row-top {
        align-items: center;
        border-bottom: 1px solid $borderColorLight;
        display: flex;
        justify-content: space-between;
        padding: 20px 30px 15px;

        h3 {
            margin: 0;
        }

        .row-header-info {
            display: flex;
            flex-direction: column;
        }
    }

    .row {
        align-items: center;
        border-bottom: 1px solid $borderColorLight;
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        width: 100%;

        &.column {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .row-bottom {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;

        &.column {
            align-items: flex-start;
            flex-direction: column;
        }

        a {
            color: $pilotPurple;
        }
    }

    .ship-close-dates .field {
        /* NB: matches width in ship-address-list component; possible candidate for
        refactoring? */
        width: 340px;
    }

    .include-burn-rate-setting {
        align-items: center;
        display: flex;

        .include-burn-rate-setting-label {
            margin-left: 10px;

            &.v2 {
                margin-left: 0;
                margin-right: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    #ship-download-iframe {
        display: none;
    }

    .message-icon {
        margin-right: 5px;
    }

    .button-container {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }

    .button-error-text {
        margin-right: 20px;

        &.top-text {
            margin-top: 10px;
        }
    }
}
