
@use "client/css/globals" as *;

.checklist-filters {
    display: flex;

    > * {
        margin-right: $spacingMedium;
    }
}

.clear-ams {
    flex-shrink: 0;
}

.checklist-filters,
.progress-bar {
    margin-bottom: $spacingLarge;
}
