
@use "client/css/globals" as *;

#new-rule-override {
    min-height: 580px;
    width: 640px;
}

#primary-rule-json {
    background: $backgroundColorSecondary;
    margin: 0;
    max-height: 240px;
    overflow-y: scroll;
    padding: $spacingSmall;
}

#override-template-account-container {
    position: relative;

    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }

    .spinner {
        position: absolute;
    }
}

#edit-override-secondary-header {
    margin: 0 0 10px;
}

#link-to-original-rule {
    font-size: 15px;
    font-weight: normal;
    margin: 0 0 30px;
}
