
// Note that these settings override the global style in _typography.scss
.markdown {
    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    pre,
    code {
        white-space: pre-wrap;
    }

    // The non-top-level <p>s and <ul>s in the markdown
    ::v-deep p,
    ::v-deep ul {
        margin: 0.1em 0;
    }

    // The top-level <p>s and <ul>s in the markdown
    & > ::v-deep p {
        margin: 0.6em 0 0.2em;
    }

    & > ::v-deep ul {
        margin: 0.2em 0 0.6em;
    }
}
