
.collapsible {
    height: auto;
    margin-bottom: -1px;
    margin-top: -1px;

    /* Negative margins and positive paddings to prevent margin collapse on child elements */
    padding-bottom: 1px;
    padding-top: 1px;

    &.collapsible-enter,
    &.collapsible-leave-to {
        opacity: 0;
    }

    &.collapsible-leave,
    &.collapsible-enter-active {
        width: 100%;
    }
}
