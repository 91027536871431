
@use "client/css/globals" as *;
@use "./tick-and-tie";

p {
    margin: 0;
}

.tnt-tips {
    padding: 15px;

    &.hidden {
        padding-bottom: $spacingSmall;
    }
}

.tnt-tips-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacingSmall;

    .tnt-show-hide {
        color: $pilotPurple;
    }
}

.tnt-tips-body {
    display: grid;
    gap: $spacingSmall;
    grid-template-columns: repeat(3, 1fr);

    &.hidden {
        height: 0;
        overflow: hidden;
    }
}

.tnt-tip {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    box-shadow:
        0 8px 16px rgba(62, 60, 61, 0.08),
        0 0 1px rgba(101, 99, 99, 0.16);
    padding: $spacingMedium;

    .tnt-tip-title {
        color: $pilotPurple;
        font-weight: bold;
        margin-bottom: $spacingSmall;
    }
}
