
h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 25px;
}

.center-text {
    text-align: center;
}

.fit {
    display: inline-block;
    justify-content: center;
}
