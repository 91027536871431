
@use "client/css/globals" as *;

.invited-user-preferred-name {
    width: 140px;
}

.invited-user-custom-url {
    width: 110px;
}

.invited-user-date,
.registered-user-date {
    width: 120px;
}

.invited-user-delete,
.registered-user-delete {
    width: 40px;
}

.invited-user-registration-url {
    width: 180px;
}

.invitation-link-wrapper {
    border: 1px solid $borderColorLight;
    border-radius: 3px;
    font-family: sans-serif;
    font-size: 13px;
    padding: 8px 14px;
    text-align: center;
}

.content-tertiary {
    padding: 30px;
}

#customer-users table td {
    word-wrap: break-word;
}
