
.empty-state-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.icon-check {
    margin-bottom: 20px;
}

h2.heading-text {
    font-weight: normal;
    margin-bottom: 20px;
}

p.sub-text {
    margin-bottom: 20px;
}

a {
    text-decoration: underline;
}
