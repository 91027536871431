
@use "client/css/globals" as *;

.customer-reconcile-institution {
    max-width: $flowbotTaskMaxWidth;

    ::v-deep p {
        margin: 0;
    }
}

.reconcile-institution-header {
    margin-bottom: $spacingMediumLarge;
}

.institution-level {
    margin-bottom: $spacingMediumLarge;

    h4 {
        margin-bottom: 0;
        position: relative;
    }

    h5 {
        color: $contentColorTertiary;
        font-weight: normal;
        margin-bottom: 0;
    }
}

.credentials {
    align-items: center;
    display: flex;
    height: 76px;
    justify-content: space-between;

    .button {
        // same as $actionWidth in reconcile-institution-account
        width: 145px;
    }
}

.flex-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.data-access-error-banner {
    margin-bottom: 30px;
}

.data-access {
    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    & ::v-deep p {
        margin: 0; // override global style
    }
}
