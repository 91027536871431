
@use "client/css/globals" as *;

.attachments-as-thumbnails {
    display: flex;
    flex-wrap: wrap;

    .attachment {
        margin-bottom: $spacingSmall;
        margin-right: $spacingMedium;
        max-height: 120px;
        width: 120px;
    }
}
