
@use "client/css/globals" as *;

.widget-broken-amortization {
    padding-left: 0;
    padding-right: 0;
}

h3 {
    margin-bottom: $spacingMediumLarge;
    margin-top: $spacingSmall;
}

p {
    margin-bottom: 0;
}
