
@use "client/css/globals" as *;

.txn-search-container {
    position: relative;

    .search-icon {
        left: 10px;
        position: absolute;
        top: 8px;
        z-index: 1;
    }

    .cancel-search {
        position: absolute;
        right: 10px;
        top: 12px;
        z-index: 1;
    }
}

#txn-search {
    padding-left: 38px;
    width: 100%;
}

thead th {
    padding: $spacingMediumSmall $spacingSmall $spacingSmall;
}

td {
    padding: $spacingMediumSmall $spacingSmall;
}

.amortizations-transaction-row {
    background: rgba($brightPurple, 0.4);

    &.unamortized {
        border-color: rgba($brightPurple, 0.8);

        &:hover {
            background: rgba($brightPurple, 0.6);
        }
    }

    &.amortized {
        background: $backgroundColorPrimary;
    }
}

.amortizations-transaction-checkbox {
    vertical-align: top;
    width: 3em;

    thead & {
        vertical-align: bottom;
    }
}

.amortizations-transaction-date {
    width: 8em;

    tbody & {
        vertical-align: top;
    }
}

.amortizations-transaction-type {
    width: 8em;
}

.amortizations-transaction-amount {
    width: 8em;
}

.amortizations-transaction-status {
    width: 8em;
}

.amortizations-transaction-overflow {
    width: 4em;

    button {
        min-width: 200px;
    }
}

.amortizations-transaction-delete {
    width: 5em;

    .x {
        position: relative;
        top: 2px; // align with overflow button
    }
}

.bulk-amortize-container {
    background: $backgroundColorPrimary;
    padding-bottom: 40px;
    position: sticky;
    top: 0;
    z-index: 2;
}

.warning {
    margin-bottom: $spacingMedium;
}

.cancel {
    margin-left: $spacingMedium;
}

.clear-filters {
    color: $blue1;
    cursor: pointer;
    font-size: $fontSizeSmall;
    font-weight: normal;
}
