
.criterion {
    align-items: center;
    display: flex;
    gap: 10px;
    min-width: 400px;

    .logical-operator {
        min-width: 50px;
    }

    .criterion-field {
        min-width: 0;
        width: 250px;
    }

    .criterion-operator {
        min-width: 0;
        width: 250px;
    }

    .criterion-value {
        min-width: 200px;
        width: 450px;
    }
}
