
@use "client/css/globals" as *;

.file-row {
    align-items: center;
    display: flex;
}

.file-icon {
    margin-right: 10px;
}

.file-name {
    margin: 0 auto 0 0;
}

.file-input {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-height: 40px;
}

.success-icon {
    margin-right: 25px;
}

.success-message {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    font-weight: 400;
    margin: 0;
}

.undo-button {
    font-size: $fontSizeSmall;
    margin-left: 20px;
}
