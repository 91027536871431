
@use "client/css/globals" as *;

.discount-setting {
    border-top: 1px solid $borderColorLight;
    margin-top: 20px;
    padding-top: 10px;

    .choose-title {
        font-weight: normal;
        margin-bottom: 10px;
    }
}

.input-label {
    align-items: flex-start;
    display: flex;
    gap: 10px;

    ::v-deep input[type="radio"] {
        pointer-events: initial;
    }
}
