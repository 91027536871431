
@use "client/components/upload/upload-styles";

.update-team-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include upload-styles.header-row-vertical-spacing;
}

.publish-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.update-role-row {
    @include upload-styles.row-vertical-spacing;
}
