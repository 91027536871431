
@use "client/components/upload/upload-styles";

.allocation-close-title {
    @include upload-styles.header-row-vertical-spacing;
}

// Define top/bottom padding relative to name so that height does
// not change based on what is rendered in the right-aligned content,
// i.e. we don't want overall row's height to expand when the plump FileCard appears.
.upload-name {
    padding-bottom: 15px;
    padding-top: 15px;
}

.team-label-and-dropdown {
    @include upload-styles.row-vertical-spacing;
}
