
@use "client/css/globals" as *;

$drawerHeaderHeight: 120px;
$buttonsHeight: 80px;
$labelWidth: 165px;

::v-deep .drawer-content {
    padding: 0;
}

::v-deep .drawer-header {
    height: $drawerHeaderHeight;
    padding: $spacingLarge;
}

.section-wrapper {
    background-color: $backgroundTonalGrey;
    border-bottom: solid 1px $borderColorLight;
    border-top: solid 1px $borderColorLight;
    height: calc(100% - (#{$drawerHeaderHeight} + #{$buttonsHeight}));
    overflow-y: auto;
}

section {
    margin-bottom: 0;
    padding: $spacingMedium $spacingLarge;

    &:first-child {
        border-bottom: solid 1px $borderColorLight;
    }
}

.field {
    align-items: center;
    display: flex;
    margin-bottom: $spacingMedium;

    label {
        font-weight: bold;
        margin-right: $spacingMedium;
        width: $labelWidth;
    }
}

.buttons {
    background: $backgroundColorPrimary;
    bottom: 0;
    height: $buttonsHeight;
    justify-content: flex-end;
    padding: $spacingMedium $spacingLarge;
    position: absolute;
    width: 100%;

    .delete {
        margin-right: auto;
    }
}

.txn-memo {
    margin-bottom: $spacingSmall;
}

.form-errors {
    margin: 0 $spacingLarge $spacingMedium;
}
