
@use "client/css/globals" as *;

.rows-container {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;

    // needed so slot contents width can be set relative to parent container of SeparatedRows
    width: 100%;

    > *:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
    }
}
