@use "sass:color";

// Colors
// If you are adding or renaming colors here you should also update
// _exports/_colors.scss which defines how colors are exported to JavaScript.
$activeGreen: #3da291;
$brightBlue: #4f9bf3;
$brightPurple: #c8b6fc;
$brighterPurple: color.adjust($brightPurple, $lightness: 5%);
$ctaGreen: #47bfa4;
$darkGrey: #999;
$lightGrey: #f8f8f8;
$matteBlue: #423d71;
$mattePurple: #473c70;
$offBlack: #3f3c3d;
$pastelBlue: #f9fafc;
$pastelUltramarine: #f3f5fa;
$pastelRed: #edd0d4;
$pastelViolet: #edeef5;
$highlightPurple: #e3dafd;
$pilotPurple: #5f2ee5;
$pilotText: #3c2d89;
$blue1: $brightBlue;
$blue1Background: color.adjust($blue1, $lightness: 28%, $saturation: -30%);
$blue2: #06d;
$blue2Background: #edf5fe;
$green1: #7ab317;
$green1Background: color.adjust($green1, $lightness: 48%, $saturation: -25%);
$green1Dark: color.adjust($green1, $lightness: -20%);
$powerButtonRed: #e1393f;
$red1: #ce1836;
$red1Background: color.adjust($red1, $lightness: 42%, $saturation: -35%);
$red1Desaturated: color.adjust($red1, $lightness: 25%, $saturation: -50%);
$red1Light: #ff879a;
$urgentOrange: #e96d3f; // used in dashboard card
$yellow1Background: #fff8de;
$yellow1: color.adjust($yellow1Background, $lightness: -20%);
$yellow1Border: #dcc46d;
$yellow1Text: #756012;
$warningYellow: #f4b13e;
$purpleSelectionBackground: $highlightPurple;
$purpleSelectionHoverBackground: #d8d1f5;
$pilotTypeYellow: #FFE278;

// Brand colors
$brandColorPrimary: $pilotPurple;
$brandColorPrimaryContrast: #fff;
$brandColorPrimaryDark: $pilotText;
$brandColorPrimaryDesaturated: color.adjust($brandColorPrimary, $lightness: 20%, $saturation: -30%);
$brandColorPrimaryLight: color.adjust($pilotPurple, $lightness: 10%);
$brandColorSecondary: $ctaGreen;
$contentColorPrimary: $offBlack; // Content - Primary
$contentColorSecondary: #656363; // Content - Secondary; 0.8 opacity on $contentColorPrimary
$contentColorTertiary: $darkGrey; // Content - Tertiary
$backgroundColorPrimary: #fff;
$backgroundColorSecondary: $pastelUltramarine; // BG - Secondary
$backgroundColorTertiary: $pastelViolet; // BG - Tertiary
$backgroundDarkPrimary: $matteBlue;
$backgroundDarkPrimaryContrast: #fff;
$backgroundDarkSecondary: $contentColorSecondary;
$backgroundDarkSecondaryContrast: #fff;
$backgroundTonalGrey: $lightGrey;
$backgroundColorGrey: #e0e0e0;
$borderColorLight: #e0e0e0; // Border - Input
$borderColorLighter: #eff0f3;
$borderColorDark: color.adjust($borderColorLight, $lightness: -27%);
$linkColorPrimary: $activeGreen;
$linkColorSecondary: $brandColorPrimaryLight;
$backgroundColorDarkRed: #7c0e20;
