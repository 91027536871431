
@use "client/css/globals" as *;

.submission-result-line {
    margin: 0 0 $spacingSmaller;
}

.submission-subresult-line {
    margin: 0 0 $spacingSmallest;
}
