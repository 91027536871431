
@use "client/css/globals" as *;

@keyframes growshrink {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }

    33% {
        opacity: 1;
        transform: scale(1.2);
    }

    66% {
        opacity: 0.5;
        transform: scale(1);
    }

    100% {
        opacity: 0.5;
        transform: scale(1);
    }
}

.dots {
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: space-between;
    width: 60px;
}

.dot {
    animation-delay: calc(var(--animation-order) * 300ms);
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-name: growshrink;
    animation-timing-function: ease-in-out;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.fancy-loading-error {
    color: $red1;
    height: 20px;
}
