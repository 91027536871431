
@use "client/css/globals" as *;

.pagination-controller {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    user-select: none;
}

.item-count {
    font-size: 18px;
    font-weight: 600;
}
