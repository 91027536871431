
@use "client/css/globals" as *;

$caretSize: 8px;

.container {
    width: fit-content;

    &.expanded-right {
        // Needed to expand the hitbox so you can extend the hover onto the tooltip.
        margin-right: -10px;
        padding-right: 10px;
    }

    &.expanded-left {
        // Needed to expand the hitbox so you can extend the hover onto the tooltip.
        margin-left: -10px;
        padding-left: 10px;
    }

    .button-target {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
    }

    .popover-position {
        background: none;
        border: none;
        inset: unset;
        margin: 0;
        opacity: 1;
        overflow: visible; // Bubble theme border arrow is offset -1px which creates scrollbars without overflow: visible.
        position: fixed;
        transition-duration: 0.12s;
        transition-property: opacity; // must be opacity only to hide the popover defaulting to the middle of the screen before we move it

        &:popover-open {
            // So the popover transitions on scroll and resize
            transition-property: all;
        }

        @starting-style {
            // This represents the starting state for the transition from close to open.
            opacity: 0;
        }

        .tooltip-content {
            border-radius: 5px;
            box-sizing: border-box;
            padding: 10px;

            &.text-align-left {
                text-align: left;
            }

            &.text-align-center {
                text-align: center;
            }

            &.text-align-right {
                text-align: right;
            }

            &.bubble-theme {
                background: $backgroundColorPrimary;
                border: 1px solid $borderColorLight;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                color: $contentColorPrimary;
            }

            &.tooltip-theme {
                background-color: $offBlack;
                color: $backgroundDarkPrimaryContrast;
                font-size: $fontSizeSmall;
                font-weight: normal;
            }

            // this adds spacing to account for the arrow
            &.top {
                margin: 0 0 3px;
            }

            &.bottom {
                margin: 3px 0 0;
            }

            &.left {
                margin: 0 3px 0 0;
            }

            &.right {
                margin: 0 0 0 3px;
            }
        }

        .arrow {
            border-style: solid;
            content: "";
            position: absolute;

            &.arrow-bottom {
                border-width: $caretSize $caretSize 0 $caretSize;
                bottom: 0;

                &.bubble-theme-arrow {
                    border-color: $backgroundColorPrimary transparent transparent transparent;

                    &.bubble-theme-arrow-border {
                        border-color: $borderColorLight transparent transparent transparent;
                        bottom: -1px;
                    }
                }

                &.tooltip-theme-arrow {
                    border-color: $offBlack transparent transparent transparent;
                }

                &.horizontal-align-left {
                    left: $caretSize;
                }

                &.horizontal-align-center {
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.horizontal-align-right {
                    right: $caretSize;
                }
            }

            &.arrow-top {
                border-width: 0 $caretSize $caretSize;
                top: 0;

                &.bubble-theme-arrow {
                    border-color: transparent transparent $backgroundColorPrimary;

                    &.bubble-theme-arrow-border {
                        border-color: transparent transparent $borderColorLight;
                        top: -1px;
                    }
                }

                &.tooltip-theme-arrow {
                    border-color: transparent transparent $offBlack;
                }

                &.horizontal-align-left {
                    left: $caretSize;
                }

                &.horizontal-align-center {
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.horizontal-align-right {
                    right: $caretSize;
                }
            }

            &.arrow-left {
                border-width: $caretSize $caretSize $caretSize 0;
                left: 0;

                &.bubble-theme-arrow {
                    border-color: transparent $backgroundColorPrimary transparent transparent;

                    &.bubble-theme-arrow-border {
                        border-color: transparent $borderColorLight transparent transparent;
                        left: -1px;
                    }
                }

                &.tooltip-theme-arrow {
                    border-color: transparent $offBlack transparent transparent;
                }

                &.vertical-align-top {
                    top: $caretSize;
                }

                &.vertical-align-center {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.vertical-align-bottom {
                    bottom: $caretSize;
                }
            }

            &.arrow-right {
                border-width: $caretSize 0 $caretSize $caretSize;
                right: 0;

                &.bubble-theme-arrow {
                    border-color: transparent transparent transparent $backgroundColorPrimary;
                    border-style: solid;

                    &.bubble-theme-arrow-border {
                        border-color: transparent transparent transparent $borderColorLight;
                        right: -1px;
                    }
                }

                &.tooltip-theme-arrow {
                    border-color: transparent transparent transparent $offBlack;
                }

                &.vertical-align-top {
                    top: $caretSize;
                }

                &.vertical-align-center {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.vertical-align-bottom {
                    bottom: $caretSize;
                }
            }
        }
    }
}
