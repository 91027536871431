
@use "client/css/globals" as *;

.textbox-with-error-container {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    width: 100%;
}

.name-textbox {
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-start: 1;
}

.name-error {
    align-items: center;
    display: flex;
    grid-column-start: 1;
    grid-row-start: 2;
}

.error-outline {
    border: 1px solid $red1Light;
}

.changes-unsaved {
    background-color: $green1Background;
}

.error-message {
    font-size: $fontSizeMicro;
    margin: 0;
    padding: 0;
}

.about-me-section {
    margin-bottom: 30px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.about-me-heading {
    border-bottom: 1px solid $borderColorLighter;
    font-size: $fontSizeBase;
    margin: 0;
    padding: 20px 30px;
}

.about-me-rows {
    border: 0;

    > *:not(:last-child) {
        border-bottom: 1px solid $borderColorLighter;
    }
}
