
@use "client/css/globals" as *;

.table-container {
    max-width: 95vw;
    overflow-x: scroll;
}

.overrides-table {
    th {
        vertical-align: middle;
        width: 250px;
    }

    .edit-or-delete {
        align-content: center;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: minmax(0, 1fr) auto;
    }
}

.payroll-department {
    padding: 5px;
}

.departments-list {
    font-style: italic;
}

.selected-department:not(:last-child) {
    // show commas between selected items when multiselect is closed
    &::after {
        content: ", ";
    }
}

.card-header {
    padding: 15px 30px;
}

.add-new-group-button {
    align-items: flex-end;
    display: flex;
    height: 40px;

    .plus-icon {
        margin-bottom: 3px; // to match gap from line-height on text
        margin-right: 5px;
    }
}

.checkbox-multiselect ::v-deep {
    .multiselect__option {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;

        .option-checkbox {
            margin-right: 5px;
        }
    }

    // override styles from multiselect-wrapper to accommodate checkbox colors
    .multiselect__option--selected {
        background: $brandColorPrimaryLight;

        &.multiselect__option--highlight {
            background: $brandColorPrimary;
        }
    }
}

.tooltip-container {
    width: 100%;
}
