
@use "client/css/globals" as *;

ul,
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.wrapper {
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusLarge;
    width: 400px;

    > li:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
    }
}

.customer-link {
    align-items: center;
    border: none;
    display: flex;
    padding: 15px 25px 15px 15px;
    transition: all 0.3s;

    .customer-name {
        border-bottom: 1px solid transparent;
        color: $contentColorPrimary;
        margin-right: auto;
        padding: 0 0 0 20px; // For longer customer names, the padding will force the text to wrap.
        text-align: left;
    }

    &:active,
    &:focus,
    &:hover {
        background: $lightGrey;

        > .icon-chevron {
            margin-right: -5px;
        }
    }
}

.icon-background {
    align-items: center;
    background-color: $backgroundColorTertiary;
    border-radius: $borderRadiusLarge;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-right: 15px;
    width: 48px;
}
