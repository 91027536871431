
@use "client/css/globals" as *;

.display-insights-container {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: $spacingMedium;
    padding: 0;

    .loading {
        padding: $spacingMedium;
    }

    ul {
        padding: 0 25px $spacingMedium;

        .display-insights-insight {
            font-size: $fontSizeBase;
            list-style-type: disc;
            margin-bottom: $spacingSmall;
        }
    }
}
