
@use "client/css/globals" as *;

$modalPadding: $spacingMediumLarge;
$modalBorderRadius: $borderRadiusLarge;
$modalFadeEffect: scale(1.02);

.modal-center-container {
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: $zIndexModal - 10;

    @include modalOverlay;
}

.modal-header {
    align-items: center;
    background: $backgroundColorPrimary;
    border-top-left-radius: $modalBorderRadius;
    border-top-right-radius: $modalBorderRadius;
    display: flex;
    justify-content: space-between;
    padding: $modalPadding $modalPadding $spacingMedium;

    ::v-deep h3 {
        margin: 0;
    }
}

.modal-body {
    background: $backgroundColorSecondary;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.none {
        padding: 0;
    }

    &.default {
        padding: 25px 30px 30px;
    }
}

.modal-body-header,
.modal-body-footer {
    background: $backgroundColorSecondary;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin: 0;

    &.none {
        padding: 0;
    }

    &.default {
        padding: 0 30px;
    }
}

.modal-body-footer-no-actions {
    border-bottom-left-radius: $modalBorderRadius;
    border-bottom-right-radius: $modalBorderRadius;
    padding-bottom: 20px;
}

.modal-body-no-header {
    border-top-left-radius: $modalBorderRadius;
    border-top-right-radius: $modalBorderRadius;
    padding-top: $modalPadding;
}

.modal-body-no-trailer {
    border-bottom-left-radius: $modalBorderRadius;
    border-bottom-right-radius: $modalBorderRadius;
}

.modal {
    border-radius: $modalBorderRadius;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    max-width: 95%;
    opacity: 1;
    text-align: left;
    z-index: $zIndexModal;

    @include modalContent;

    > h2 {
        margin-top: 0;
    }
}

.click-to-hide-overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modal-enter,
.modal-leave-active {
    opacity: 0;
    transform: $modalFadeEffect;
}

.modal-actions {
    background: $backgroundColorSecondary;
    border-bottom-left-radius: $modalBorderRadius;
    border-bottom-right-radius: $modalBorderRadius;
    display: grid;
    grid-template-columns: auto auto;
    padding: $modalPadding;

    &.highlight {
        background: $backgroundColorTertiary;
        padding: $spacingMedium $modalPadding;
    }

    .left-align {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
    }

    .right-align {
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
    }
}

.custom-request-hide-button {
    :hover {
        cursor: pointer;
    }
}
