
@use "client/css/globals" as *;

.user-dropdown {
    &.multiselect {
        font-size: $fontSizeMedium;
        height: 44px;
        padding: 0;
        width: 100%;
    }

    ::v-deep .multiselect__tags {
        height: 100%;
        padding-top: $spacingSmall;
    }

    ::v-deep .multiselect__input,
    ::v-deep .multiselect__content-wrapper {
        font-size: $fontSizeMedium;
    }

    ::v-deep .multiselect__element {
        font-size: $fontSizeBase;
    }
}
