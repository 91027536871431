
.duration-picker {
    align-items: center;
    display: flex;
    gap: 10px;
    width: 400px;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .toggle-container {
        white-space: nowrap;
    }

    .duration-amount {
        width: 75px;
    }
}
