
@use "client/css/globals" as *;

p:last-child {
    margin: 0; // reset global style
}

.welcome-card {
    align-items: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;

    .welcome-text {
        flex: 1 1 200px;
    }

    .welcome-graphic {
        flex: 0 0 auto;
        margin-left: $spacingMedium;

        @media (max-width: 1120px) {
            display: none;
        }
    }
}

.navigation-btns {
    margin-top: 35px;
}
