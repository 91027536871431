
@use "client/css/globals" as *;

ul {
    padding-left: 20px;
}

.header-text {
    font-weight: bold;
    margin-bottom: 12px;
}

.header-container {
    margin-bottom: 20px;
}

.groups-container {
    background-color: $pastelViolet;
    margin-top: 30px;
    overflow-x: auto;
    padding: 10px 20px;
}

.categorize-txn-admin-groups-container {
    background-color: white;
    margin: 0;
}

.container {
    margin: 10px 30px;
}

::v-deep .expander-cuff {
    background-color: white;
}

.auto-categorization-results-content {
    font-weight: bold;
}

.auto-categorization-results-button {
    margin-top: 20px;
}

.empty-state-container {
    margin-top: 30px;
}
