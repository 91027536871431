
@use "client/css/globals" as *;

.tax-section-card {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    color: $contentColorPrimary;
    max-width: 670px;
    padding: $spacingLarge $spacingLarger;

    ::v-deep .section-card-header {
        text-align: center;
    }

    ::v-deep .inline-full,
    ::v-deep .full-width {
        margin-bottom: $spacingSmall;
    }

    ::v-deep .tax-field {
        margin-top: $spacingMediumLarge;
    }

    ::v-deep .tax-label-text {
        font-weight: bold;
        margin-bottom: $spacingSmall;
    }

    // yes, this looks ridiculous, but this is how you make an element only available for
    // screen readers.
    // https://webaim.org/techniques/css/invisiblecontent/
    ::v-deep .hidden-label-text {
        height: 1px;
        left: -10000px;
        overflow: hidden;
        position: absolute;
        top: auto;
        width: 1px;
    }

    ::v-deep .validation-error {
        color: $red1;
    }

    // this hack is required because some heading are precisely the width of their container
    // this causes just the accompanying info icon to overflow onto its own line
    // if this were text, we'd use a non-breaking space, but the icon being an element prevents this
    ::v-deep .prevent-improper-overflow {
        width: 480px;
    }
}
