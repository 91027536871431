@use "globals" as *;

//  DRAGULA
@use "pkg:dragula/dist/dragula.css";

//  CHART.JS
@use "pkg:chart.js/dist/Chart.min.css";

//  FLATPICKR, MONTH SELECT PLUGIN, AND OVERRIDES
@use "pkg:flatpickr/dist/flatpickr.css";
@use "pkg:flatpickr/dist/plugins/monthSelect/style.css";

$flatpickrBarHeight: 40px;

// make current selection use our brand colors
.flatpickr-day.selected,
.flatpickr-monthSelect-month {
    &.selected,
    &.selected:hover {
        background-color: $ctaGreen;
        border-color: $ctaGreen;
    }
}

.flatpickr-months {
    // we want the top bar to use Pilot colors and be a little taller
    background-color: $pilotPurple;
    border-radius: 5px 5px 0 0;
    height: $flatpickrBarHeight;

    .flatpickr-month {
        height: $flatpickrBarHeight; // match the new height
    }

    .flatpickr-current-month {
        height: $flatpickrBarHeight; // match the new height
        line-height: $flatpickrBarHeight; // helps us center the items
        padding: 0; // remove extraneous padding

        // make the year selection contrast with bg
        .cur-year,
        .cur-year:disabled {
            color: $brandColorPrimaryContrast;
        }

        // match up and down year arrows to spec
        .numInputWrapper span {
            opacity: 0.8; // make them always visible

            // reduce their size, round the corners, and add contrast to hover
            &.arrowUp,
            &.arrowDown {
                border-radius: 2px;
                height: 10px;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }

            // position them symmetrically with contrast coloring
            &.arrowUp {
                top: 20%;

                &::after {
                    border-bottom-color: $brandColorPrimaryContrast;
                    top: 2px;
                }
            }

            &.arrowDown {
                bottom: 20%;

                &::after {
                    border-top-color: $brandColorPrimaryContrast;
                    top: 2px;
                }
            }
        }
    }

    // make the next/previous month arrows contrast with bg
    .flatpickr-prev-month,
    .flatpickr-next-month {
        fill: $brandColorPrimaryContrast;
        height: $flatpickrBarHeight;

        &:hover svg {
            fill: $ctaGreen;
        }
    }

    .flatpickr-monthDropdown-months {
        // make the selected month text contrast with the bg
        color: $brandColorPrimaryContrast;

        // but don't color the actual options and return their size to normal
        .flatpickr-monthDropdown-month {
            color: initial;
            font-size: initial;
        }
    }
}

//  VUE TEXT HIGHLIGHT
.text__highlight {
    background: #fc0;
    border-radius: 3px
}
