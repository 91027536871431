
@use "client/css/globals" as *;

$checkboxSize: 14px;
$checkboxBigSize: 18px;

.pilot-checkbox-container {
    display: inline-block;
    position: relative;
}

.pilot-checkbox {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    height: $checkboxSize;
    position: relative;
    transform: rotate(0);
    transition: all 0.2s;
    width: $checkboxSize;

    &::before,
    &::after {
        background-color: $backgroundColorPrimary;
        bottom: calc(50% - 4px);
        content: "";
        display: block;
        left: 50%;
        opacity: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: all 0.2s;
        width: 2px;
    }

    &::before {
        height: 5px;
        margin-left: -3px;
        transform: rotate(-38deg);
    }

    &::after {
        height: 8px;
        transform: rotate(38deg);
    }

    &:hover {
        border-color: $darkGrey;
    }

    &.pilot-checkbox-big {
        height: $checkboxBigSize;
        top: 3px;
        width: $checkboxBigSize;

        &::before {
            margin-left: -4px;
        }

        &::after {
            height: 9px;
            margin-left: 0;
        }
    }

    &.pilot-checkbox-checked,
    &.pilot-checkbox-indeterminate {
        background: $brandColorPrimaryDark;
        border-color: $brandColorPrimaryDark;

        &::before,
        &::after {
            opacity: 1;
        }
    }

    &.pilot-checkbox-indeterminate {
        &::before,
        &::after {
            bottom: calc(50% - 5px);
            height: 9px;
            left: calc(50% - 1px);
            margin: 0;
            transform: rotate(90deg);
        }
    }

    &.pilot-checkbox-saving {
        animation: rotation 0.75s linear infinite;
        background: transparent;
        border-left-color: transparent;
        border-radius: 50%;
        border-right-color: transparent;
        border-width: 2px;

        &::before,
        &::after {
            opacity: 0;
        }
    }

    &.pilot-checkbox-disabled {
        background: $backgroundTonalGrey;
        border-color: $borderColorLight;
        cursor: not-allowed;

        &.pilot-checkbox-checked {
            background: $brandColorPrimaryDark;
            border-color: $brandColorPrimaryDark;
            opacity: 0.3;
        }
    }
}

.pilot-checkbox-checkbox {
    bottom: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
