
@use "client/css/globals" as *;

.label {
    font-weight: bold;
}

.group-info .label {
    display: inline-block;
    width: 75px; // we want the labels and their content to line up
}

.recategorization-section {
    margin: $spacingSmall 0;
}

.draft-message-input {
    resize: vertical;
}
