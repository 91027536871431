
@use "client/css/globals" as *;

.tile-content {
    font-size: $fontSizeSmall;
    padding: $spacingMediumSmall $spacingMediumLarge;
}

.tile-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: $spacingMediumSmall;

    .tile-search-source {
        align-items: center;
        color: $contentColorSecondary;
        display: flex;
        justify-content: flex-start;
        margin: 0;
    }

    .tile-bubble {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        ::v-deep .bubble-up {
            right: -3px;
            width: 360px;
        }

        ::v-deep .tag-content {
            align-items: center;
            display: flex;
        }

        .date,
        .details {
            font-size: 15px;
            margin: 0;
        }
    }
}

::v-deep .tile-action {
    border-top: 1px solid $borderColorLighter;
    padding: $spacingMediumSmall 0;

    ::v-deep .button {
        padding: $spacingMediumSmall $spacingMediumLarge;
    }
}
