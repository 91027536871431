
@use "client/css/globals" as *;

thead {
    background-color: $backgroundColorPrimary;
    box-shadow: 0 5px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 0;
}

th {
    padding-top: $spacingSmall;
}

.am-heading {
    vertical-align: top;
}

.customer-link {
    color: $brandColorPrimary;
}

.new-checklist-group {
    border-top: 3px solid $borderColorLight;
}

.section-complete {
    background: $brandColorPrimary;
    color: $brandColorPrimaryContrast;
}
