
@use "client/css/globals" as *;

.duration-setting-modal ::v-deep .modal-body {
    background: $backgroundColorPrimary;
    border-top: 1px solid $borderColorLight;
}

.prerequisites-list {
    height: 400px;
}

.prerequisite {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr auto;
    margin-bottom: 20px;
}

.sparkle {
    margin-right: 5px;
}

.duration-setting {
    margin-top: 20px;
    width: auto;
}
