
@use "client/css/globals" as *;

.tick-and-tie-note-container {
    max-width: $fieldWidthBase;
    width: 100%;
    word-wrap: break-word;

    .tick-and-tie-note-text {
        height: 40px;
        min-height: unset;
        overflow-y: hidden;
        resize: none;
        width: 100%;
    }
}

.tick-and-tie-note {
    background: $backgroundColorSecondary;
    border: 1px solid $borderColorLight;
    border-radius: 6px;
    padding: 8px $spacingSmall;
}

.tick-and-tie-note-content {
    pre,
    code {
        white-space: pre-wrap;
    }

    ::v-deep *:last-child {
        margin-bottom: 0;
    }
}

.tick-and-tie-note-header {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    margin-bottom: $spacingSmaller;
}

.tick-and-tie-note-author {
    font-weight: bold;
    word-break: normal;
}

.tick-and-tie-note-date {
    color: $contentColorSecondary;
    min-width: 60px;

    .tick-and-tie-note-author + & {
        margin-left: $spacingSmall;
    }
}

.tick-and-tie-note-action {
    color: $contentColorSecondary;
    cursor: pointer;
    margin-left: $spacingSmall;

    &:hover {
        text-decoration: underline;
    }
}

.task-add-customer-note {
    opacity: 0;
    transition: opacity 0.2s;

    .task:hover & {
        opacity: 1;
    }
}
