
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.group-table {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.narrowed-account-suggestion-edit-button {
    max-width: categorize.$categorizeInputWidth;
    width: 100%;
}
