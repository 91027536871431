
@use "client/css/globals" as *;

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.reason-context-field {
    margin: 0 0 $spacingMedium;
    position: relative;
}

.additional-context {
    background: $backgroundColorPrimary;
    min-height: 158px;
    min-width: 100%;
}
