
$amortizationViewVerticalMediumMargin: 30px;
$amortizationViewVerticalMediumPadding: 30px;

h2 {
    margin: 0;
    padding-top: $amortizationViewVerticalMediumPadding;
}

.no-transaction-banner {
    margin: 0;
}

.bulk-delete {
    width: max-content;
}

.view-amortization-wrapper {
    display: grid;
    row-gap: 30px;
}

.horizontal-scroll {
    overflow-x: auto;
}
