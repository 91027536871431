
@use "client/css/globals" as *;

.invisible-placeholder {
    opacity: 0.01;
    pointer-events: none;
}

.tnt-account {
    align-items: center;

    &:hover {
        background: $yellow1Background;
    }
}

.tnt-account-name {
    &.level-1 {
        padding-left: $spacingLarge;
    }

    &.level-2 {
        padding-left: $spacingLarge * 2;
    }

    &.level-3 {
        padding-left: $spacingLarge * 3;
    }
}

.tnt-row-error {
    background: $red1Light;
}

.tnt-account-date {
    text-align: center;
}

.tnt-state-matched {
    color: $green1;
}
