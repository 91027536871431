.icon-size {
    &.small {
        height: 16px;
        width: 16px;
    }

    &.default {
        height: 24px;
        width: 24px;
    }

    &.medium {
        height: 32px;
        width: 32px;
    }

    &.large {
        height: 64px;
        width: 64px;
    }

    &.larger {
        height: 96px;
        width: 96px;
    }
}
