
@use "client/css/globals" as *;

.toggle {
    &::after {
        border-color: $contentColorTertiary transparent transparent transparent;
        border-style: solid;
        border-width: 7px 6px;
        content: "";
        display: inline-block;
        position: relative;
        top: 7px;
    }

    &.point-up::after {
        top: 0;
        transform: rotate(180deg);
    }
}

tr {
    border-bottom: 1px solid $borderColorLight;
}

thead {
    th {
        color: $contentColorSecondary;

        &:nth-of-type(1) {
            width: 165px;
        }

        &:nth-of-type(2) {
            width: 156px;
        }

        &:nth-of-type(4) {
            width: 200px;
        }

        &:nth-of-type(5) {
            width: 123px;
        }

        &:nth-of-type(6) {
            width: 180px;
        }

        button {
            background: none;
            border: none;
            color: inherit;
            cursor: pointer;
            display: inline-block;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            margin: 0;
            outline: none;
            padding: 0;
            text-align: inherit;
            width: 100%;
        }
    }
}

.description {
    color: $contentColorTertiary;
    display: block;
    font-size: $fontSizeSmall;
    word-break: break-word;
}

.view-activity-button {
    opacity: 0;

    .activity-table-row:hover & {
        opacity: 1;
    }
}
