
.file-upload-zap {
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;

    > p {
        margin-bottom: 5px;
    }
}

.spinner-container {
    margin: 0 auto; // to center the spinner without moving error div
    min-height: 25px;
}

.error-container {
    display: flex;
    margin-bottom: 10px;
    min-height: 55px; // to keep content from shifting on error state
}
