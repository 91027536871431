
@use "client/css/globals" as *;

$verticalPadding: 95px;

.onboarding-form-wrapper {
    color: $contentColorPrimary;
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.completion-message {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    min-width: 670px;
    padding: 50px $verticalPadding;
}

.onboarding-form {
    min-width: 670px;
}

.onboarding-section-status,
.left-gutter {
    // CSS grid would be nice
    display: flex;
    justify-content: center;
    width: 100%;
}

.onboarding-section-status {
    margin-left: $spacingLarge;
}
