
.complex-task-modal-buttons {
    display: flex;
    justify-content: flex-end;
    max-width: 580px;
}

.confirm-button {
    width: 120px;
}
