
.all-required-zaps {
    display: grid;
    grid-row-gap: 30px;
}

.required-file-section {
    display: grid;
    grid-row-gap: 15px;
}

.requirement-list {
    padding-bottom: 15px;
}

.upload-button {
    margin-left: 5px;
}
