
@use "client/css/globals" as *;

::v-deep .subheading {
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

.field {
    margin: 0 0 $spacingMediumLarge;

    &:last-of-type {
        margin: 0;
    }
}

.field-label {
    font-weight: bold;
    margin-bottom: $spacingSmaller;
}
