
@use "client/css/globals" as *;
@use "./tick-and-tie";

.tick-and-tie-overflow-wrapper {
    // make "Expand groups" button right aligned and let it overflow to the left
    display: flex;
    justify-content: flex-end;

    ::v-deep .button {
        white-space: nowrap;
    }
}
