
@use "client/css/globals" as *;

p.pilot-text.instructions-text {
    margin-bottom: 1em;
}

a.mercury-link {
    color: $contentColorPrimary;
    text-decoration: underline;
}

li {
    list-style-type: disc;
}

ul {
    padding-left: $spacingMedium;
}
