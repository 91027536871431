
@use "client/css/globals" as *;

$secondaryNavLeftPadding: 40px;

#customer-checklist {
    display: grid;
    grid-template-columns: 1fr auto;
    height: 100%;
    width: auto;
}

#customer-checklist-top {
    margin-bottom: $spacingMedium;
}

#checklist-picker-title {
    position: relative;
    top: 11px;
}

.multiselect.checklist-picker-big {
    display: inline-block;
    width: 100%;

    .multiselect__input {
        font-size: 24px;
        font-weight: 500;
    }
}

.checklist-top-grid {
    display: grid;
    grid-column-gap: 40px;
    margin-right: 30px;
}

.checklist-context {
    margin-top: 30px;
}

.checklist-align-comments {
    grid-area: checklist-align-comments;
}

.checklist-picker-container {
    display: flex;
}

.checklist-overflow-actions {
    padding-left: 10px;
}

.checklist-delete-button {
    padding-right: 1em;
}

.checklist-row {
    display: flex;
    justify-content: space-between;
}

.conflicting-checklists {
    .multiselect__tags {
        background: $red1Background;
        color: $red1;
    }
}

.checklist-warning {
    padding-bottom: $spacingSmall;
}

.italic-shipped {
    color: $darkGrey;
    font-style: italic;
}

#show-generate-new-checklist {
    margin-bottom: $spacingMedium;
    margin-right: 0;
}

.warning {
    margin-bottom: $spacingSmall;
}

// Extends global .notification-banner style
.notification-banner {
    align-items: center;
    background-color: $pilotText;
    border-radius: $borderRadiusLarge;
    color: $backgroundColorPrimary;
    display: flex;
    justify-content: space-between;

    p {
        margin-bottom: 0;
        margin-right: $spacingMedium + $spacingLarge;
    }
}

.above-checklist-picker {
    margin-bottom: $spacingMediumLarge;
}

.below-checklist-picker {
    margin-top: $spacingMediumLarge;
}

.loading-scope {
    overflow-y: auto;

    // left padding must line up with secondary nav
    padding-left: $secondaryNavLeftPadding;
    padding-top: 30px;

    // main checklist does not grow to fill browser width without 100% width
    width: 100%;
}

::v-deep .checklist-align {
    margin: 0;

    // needed so that hover style stretches full width
    margin-left: -$secondaryNavLeftPadding;
}

::v-deep .task-insert-new {
    margin-right: -$secondaryNavLeftPadding;
}
