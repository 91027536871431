
.pilot-title {
    margin-bottom: 10px;
}

.welcome-card {
    align-items: center;
    display: flex;
    margin-bottom: 30px;
}

.welcome-icon-container {
    display: flex;
    margin-right: 15px;
    width: 80px;
}
