
@use "client/css/globals" as *;
@use "client/css/cards";

::v-deep .tooltip-content {
    width: 150px;
}

.table-title {
    margin: 22px 0 12px 30px;
}

.actions-overflow-btn {
    border: none;
    color: $offBlack;
    margin: 0;
    padding: 15px 0 15px 15px;
    text-align: left;
    width: 290px;

    &:hover {
        color: $offBlack;
    }
}

.institution-card {
    background-color: $backgroundColorPrimary;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: 0 0 24px;
    height: 24px;
    margin-right: 5px;
    transition: box-shadow 0.2s;
    width: 24px;
}
