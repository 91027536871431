
@use "client/css/globals" as *;
@use "client/components/css/mixins";

.no-search-results-message {
    font-size: $fontSizeSmall;
    padding: 12px;
}

.last-item-sticky {
    @include mixins.last-item-sticky;
}

// VueMultiselect sets .multiselect__element to `display: inline-block` via JS
// in Firefox, this adds a horizontal scrollbar to the dropdown
// making the wrapper display flex appears to be the only way to remove this scrollbar
::v-deep .multiselect__content-wrapper {
    display: flex;
}
