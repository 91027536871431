
@use "client/css/globals" as *;

::v-deep .drawer-header {
    height: 60px;
}

::v-deep .table-bordered-content {
    height: 100%;
}

::v-deep .table-bordered {
    margin: 0;
}

.dashboard-table {
    height: auto;
    margin-bottom: $spacingMediumSmall;

    .dashboard-header {
        color: #3f3c3d;
    }

    .dashboard-row {
        border-top: 1px solid #e0e0e0;
    }
}

.status-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 5px;

    h3 {
        margin: 0;
    }

    &.more {
        margin-bottom: $spacingMedium;
        margin-top: 0;
    }
}

.drawer-link {
    color: $linkColorSecondary;
    display: flex;

    &.top {
        align-items: center;
    }

    & > span {
        margin-right: 2px;
    }
}

.filing-row-container {
    align-items: center;
    display: flex;
    font-size: $fontSizeSmall;
    justify-content: space-between;
}

.small-date {
    color: $contentColorTertiary;
    font-size: $fontSizeSmall;
}

.states-container {
    color: $brandColorPrimary;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.r-and-d-container {
    margin: $spacingMediumLarge 0;

    .upper {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
        margin-bottom: $spacingSmall;

        .left {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .sub-heading {
                color: $contentColorSecondary;
                font-size: $fontSizeSmall;
                margin: 0;
            }
        }

        .right {
            padding: 0 $spacingSmaller 0 $spacingMedium;
        }
    }
}

.bookkeeping-poc {
    margin: $spacingMediumLarge 0;

    .team-lead {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        .sub-heading {
            color: $contentColorSecondary;
            font-size: $fontSizeBase;
            height: 22.5px;
            margin: 0;
        }
    }

    .email {
        align-items: flex-end;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacingSmall;

        .left {
            width: 360px;

            .email-address {
                margin: 0;
            }
        }

        .right {
            padding: 0 $spacingSmallest 0 $spacingSmall;
        }
    }
}

.ten-ninety-nine-vendors-container {
    margin: $spacingMediumLarge 0;

    .upper {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacingSmall;

        .left {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .sub-heading {
                color: $contentColorSecondary;
                font-size: $fontSizeBase;
                height: 22.5px;
                margin: 5px 0;
            }
        }
    }
}

::v-deep .loading.fade-in {
    height: 85.5px;
    margin: $spacingMediumLarge 0;
    padding: 0;
}

::v-deep .loading p {
    margin: 0;
}

.textarea {
    textarea {
        padding: 20px 25px;
        white-space: pre;
    }
}
