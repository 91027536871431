
@use "client/css/globals" as *;

.options {
    display: flex;

    .option-input {
        display: none;
    }
}

// label of the selected option
.option-input:checked + .option-label {
    background: $brandColorSecondary;
    border-color: $brandColorSecondary;
    color: $brandColorPrimaryContrast;

    // the next label
    & + .option-input + .option-label {
        border-width: 1px 0;

        &:last-child {
            border-width: 1px 1px 1px 0;
        }
    }
}

.option-label {
    align-items: center;
    background: $backgroundColorPrimary;
    border: 0 solid $borderColorLight;
    border-radius: 0;
    border-width: 1px 0 1px 1px;
    box-sizing: border-box;
    color: $contentColorPrimary;
    cursor: pointer;
    display: flex;
    flex: 1 1 80px;
    font-size: $fontSizeSmall;
    justify-content: center;
    margin: 0;
    outline: none;

    &:first-of-type {
        border-radius: $borderRadiusMedium 0 0 $borderRadiusMedium;
    }

    &:last-of-type {
        border-radius: 0 $borderRadiusMedium $borderRadiusMedium 0;
        border-width: 1px;
    }

    &.disabled {
        background: $backgroundColorSecondary;
        cursor: default;
    }
}
