
.payroll-config {
    padding-right: 10px;
    position: relative;
}

.skyway-card {
    margin-bottom: 30px;
}

.card-header {
    padding: 15px 30px;
}

.header-row {
    padding: 15px 30px; // from LabeledInputRow
    h4 {
        margin-bottom: 0;
    }
}

.two-column {
    align-items: center;

    // to match LabeledInputRow
    column-gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.four-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
