
@use "client/css/globals" as *;

.revenue-waterfall-page {
    > *:not(:last-child) {
        margin-bottom: 30px;
    }
}

.no-ship-date,
.no-data {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    .empty-message {
        margin-top: 20px;
    }
}

.loading-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.header-container {
    align-items: center;
    display: grid;
    gap: 40px;
    grid-template-columns: auto 1fr;

    h3 {
        font-size: 18px;
        justify-self: flex-start;
    }

    .revenue-waterfall-filters {
        align-items: flex-end;
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        justify-self: flex-end;
        margin-bottom: 20px;

        * {
            flex-grow: 0;
        }
    }

    .revenue-waterfall-tooltip {
        margin-top: -5px;

        ::v-deep .bubble-down > p {
            margin: 0;

            &:first-of-type {
                margin-bottom: 20px;
            }
        }
    }
}

.revenue-waterfall-account-picker {
    min-width: 400px;
    width: fit-content;

    ::v-deep .multiselect__option--disabled {
        background-color: $backgroundColorPrimary;
    }
}

.month-and-year-picker {
    width: 130px;
}

.revenue-waterfall-table {
    margin-top: 20px;
}
