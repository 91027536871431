
@use "client/css/globals" as *;

.wrapper {
    align-items: flex-end;
    display: inline-grid;
    gap: 10px;
    grid-template-columns: auto auto auto;

    .date-input {
        margin-right: 0;
        max-width: 140px;

        label {
            display: block;
            font-size: $fontSizeBase;
            margin: 0;
        }
    }

    .to {
        margin-bottom: 10px;
    }
}
