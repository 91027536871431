
@use "client/css/globals" as *;

$hitboxExpansion: $spacingSmall;
$caretSize: 8px;

.tooltip-container {
    display: inline-block;
    position: relative;

    &.sticky,
    &:hover {
        .tooltip-content {
            opacity: 1;
            transition: opacity 0.1s 0.2s;
            visibility: visible;
        }
    }

    .tooltip-content {
        background-color: $offBlack;
        border-radius: 5px;
        bottom: 100%;
        color: $backgroundDarkPrimaryContrast;
        display: inline-block;
        font-size: $fontSizeSmall;
        font-weight: normal;
        left: 50%;
        line-height: 1.5;
        opacity: 0;
        padding: 6px 10px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        transform: translateX(-50%) translateY(-12px);
        visibility: hidden;
        z-index: $zIndexFlyout;

        // tooltip arrow
        &::before {
            border-color: $offBlack transparent transparent transparent;
            border-style: solid;
            border-width: $caretSize $caretSize 0 $caretSize;
            content: "";
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translateX(-50%);
        }
    }

    &.tooltip-no-wrap .tooltip-content {
        white-space: nowrap;
    }

    &.tooltip-position-bottom {
        .tooltip-content {
            bottom: initial;
            top: 100%;
            transform: translateX(-50%) translateY(12px);

            &::before {
                border-color: transparent transparent $offBlack;
                border-style: solid;
                border-width: 0 $caretSize $caretSize;
                bottom: 100%;
                top: initial;
            }
        }

        &.tooltip-align-left .tooltip-content {
            transform: translateX(-20px) translateY(12px);
        }

        &.tooltip-align-right .tooltip-content {
            transform: translateX(20px) translateY(12px);
        }
    }

    &.tooltip-position-left {
        .tooltip-content {
            bottom: initial;
            left: initial;
            right: 100%;
            top: 50%;
            transform: translateX(-$caretSize - 10px) translateY(-50%);

            &::before {
                border-color: transparent transparent transparent $offBlack;
                border-style: solid;
                border-width: $caretSize 0 $caretSize $caretSize;
                left: 100%;
                top: 50%;
                transform: translateY(-$caretSize);
            }
        }
    }

    &.tooltip-position-right {
        .tooltip-content {
            bottom: initial;
            left: 100%;
            top: 50%;
            transform: translateX($caretSize + 10px) translateY(-50%);

            &::before {
                border-color: transparent $offBlack transparent transparent;
                border-style: solid;
                border-width: $caretSize $caretSize $caretSize 0;
                left: initial;
                right: 100%;
                top: 50%;
                transform: translateY(-$caretSize);
            }
        }
    }

    &.tooltip-container-expanded-hitbox {
        margin: -$hitboxExpansion;
        padding: $hitboxExpansion;

        .tooltip-content {
            margin-bottom: -$hitboxExpansion;
        }
    }

    &.tooltip-align-left {
        .tooltip-content {
            left: 20px - $caretSize;
            transform: translateX(-20px) translateY(-12px);

            &::before {
                left: 20px;
            }
        }
    }

    &.tooltip-align-right {
        .tooltip-content {
            left: auto;
            right: 20px - $caretSize;
            transform: translateX(20px) translateY(-12px);

            &::before {
                left: auto;
                right: 20px - ($caretSize * 2);
            }
        }
    }
}
