
@use "client/css/globals" as *;

.split-card {
    display: flex;
    padding: 30px;
}

.split-card-item {
    flex: 1 1 100%;
    text-align: left;

    &.right {
        border-left: 1px solid $backgroundColorTertiary;
        padding-left: 30px;
    }
}

.chart-title {
    padding-bottom: 25px;
    padding-left: 5px;
}

.chart-container {
    padding: 20px 30px;
}

.page-title-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 20px 30px;
}

::v-deep .bubble-container {
    height: 24px;
}

.text-with-icon {
    align-items: center;
    display: flex;
}

.positive-tag {
    margin-left: 10px;
}
