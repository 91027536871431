
@use "client/css/globals" as *;

.progress-bar {
    margin-bottom: $spacingMediumLarge;
    margin-top: $spacingSmall;
}

.onboarding-section-status {
    align-items: flex-start;
}

.section-status-box {
    min-width: 204px;
    position: sticky;
    position: -webkit-sticky;
    top: $nonAdminTitleHeight + $nonAdminHeaderHeight;
}

.sections {
    margin-bottom: $spacingMediumLarge;
}

.completion-time {
    color: $contentColorTertiary;
}

.sections-complete {
    color: $brandColorPrimaryContrast;
    font-size: $fontSizeLarge;
}

.hidden {
    display: none;
}

.checkmark {
    left: -20px;
    position: absolute;
    top: 0.5px;
}

.section-link {
    position: relative;
}

::v-deep .button.primary {
    background-color: $brandColorSecondary;
    border-color: transparent;

    &:hover,
    &:focus {
        background: $activeGreen;
    }
}
