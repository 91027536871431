
@use "client/css/globals" as *;

@mixin tab-underline($color) {
    &::after {
        background-color: $color;
        bottom: -2px;
        content: "";
        height: 2px;
        left: 15px;
        position: absolute;
        right: 15px;
    }

    &:first-of-type::after {
        left: 0;
    }
}

.tabs {
    display: flex;
    justify-content: flex-start;
    position: relative;

    &::after {
        border-bottom: 2px solid $borderColorLighter;
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        z-index: 0;
    }
}

.tab {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    padding: 0 15px 15px;
    position: relative;
    text-align: center;
    transition: border-bottom-color 0.1s ease-in-out;
    z-index: 1;

    &:first-of-type {
        padding-left: 0;
    }

    &.tab-active {
        @include tab-underline($brandColorPrimary);
    }

    &.tab-disabled {
        color: $contentColorTertiary;
        cursor: default;

        &:hover {
            border-bottom-color: transparent;
        }
    }

    &:hover:not(.tab-active, .tab-disabled) {
        @include tab-underline($brandColorPrimary);
    }
}

.icon-ellipse {
    margin-left: 10px;
}
