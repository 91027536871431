
@use "client/css/globals" as *;

$screenBreakpoint: 800px;
$grid-column-gap: 30px;

.progress-rollup {
    display: grid;
    grid-column-gap: $grid-column-gap;
    grid-template-columns: repeat(3, 1fr);
    max-height: 1000px; // arbitrary height
    padding-bottom: $spacingSmall;
    transition: all 0.4s ease-in-out;

    &.fold {
        max-height: 0;
        overflow: hidden;
        padding: 0;
    }
}

.progress-bar-container {
    display: flex;
    grid-column: span 3;
    padding: $spacingMedium 0 0;
}

.progress-rollup-section {
    display: flex;
    flex-direction: column;
}

.progress-rollup-section-title {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.progress-rollup-content {
    color: $contentColorPrimary;
    flex: 1 1 auto;
    font-size: $fontSizeMedium;
    font-weight: 600;

    .progress-bar-container & {
        align-items: center;
        display: flex;
    }
}

.progress-rollup-stats {
    display: flex;
    grid-column: span 3;
    justify-content: space-between;
}

@media screen and (min-width: $screenBreakpoint) {
    .progress-rollup {
        grid-template-columns: repeat(9, 1fr);
    }

    .progress-rollup-title {
        display: none;
    }

    .progress-bar-container {
        grid-column: span 6;
        padding: 0;
    }
}
