
@use "client/css/globals" as *;

.rule-conditions-json {
    font-family: monospace;
    min-height: 240px;
}

.rule-overrides-container {
    background: $backgroundColorSecondary;
    padding: $spacingMedium;
}

.add-override {
    margin-left: auto;
}

.rule-full-conditions,
.rule-flat-conditions {
    padding: $spacingMedium 0;
}

.notice {
    padding-bottom: 30px;
}
