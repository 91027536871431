
@use "client/css/globals" as *;
@use "client/components/css/mixins";

.pilot-table {
    display: grid;
    margin: 0; // override _table.scss

    .pilot-table-header,
    .pilot-table-body,
    .pilot-table-footer,
    .pagination {
        @include mixins.grid-table-row;
    }

    .empty-row {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: 1fr;
        justify-items: center;
        padding-bottom: 20px;

        td {
            align-items: center;
            background: $backgroundColorSecondary;
            border-radius: $borderRadiusMedium;
            color: $contentColorSecondary;
            display: flex;
            justify-content: center;
            padding: 20px;

            // if on a card with no side padding, show card background color on either side
            width: calc(100% - 2rem);
        }
    }

    // SMALL_SCREEN_MAX_WIDTH
    @media (max-width: 559px) {
        overflow-x: scroll;
    }
}
