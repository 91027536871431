
@use "client/css/globals" as *;

.match-card-trust {
    display: flex;
    font-weight: bold;
    grid-area: subheader;
    padding: $spacingMedium $spacingLarge;

    p {
        margin: 0;
    }

    .match-card-sparkle {
        margin-right: 5px;
    }
}

::v-deep .expander-cuff-done {
    display: none;
}
