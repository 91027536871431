
@use "client/css/globals" as *;
@use "client/css/table";
@use "client/modules/customer-transactions/css/transaction-tables";

.review-vendor-row {
    display: flex;
    flex-direction: column;
    padding: $spacingMedium $spacingMediumLarge;

    // starting with the third row, separate rows with a line
    &:nth-of-type(n + 3) {
        border-top: 1px solid $borderColorLight;
    }

    &:hover {
        background-color: $backgroundTonalGrey;
    }

    &:focus {
        background-color: $backgroundTonalGrey;
        outline: none;
    }
}

.row-vendor {
    align-items: center;
    display: flex;

    .new-vendor-tag {
        margin-left: 15px;

        ::v-deep .tooltip-content {
            width: 120px;
        }
    }
}

// similar to .tag from global styles in connections.scss
.pending-tag {
    background-color: $yellow1Background;
    border-radius: 4px;
    color: $yellow1Text;
    font-size: $fontSizeSmall;
    margin-left: $spacingSmaller;
    padding: $spacingSmallest $spacingSmaller;
}

.cell-content {
    display: flex;
    margin-bottom: 10px;

    &:last-of-type {
        padding: 0;
    }

    .row-title {
        margin-right: 20px;
        width: 70px;
    }

    .value {
        display: inline;
        margin-bottom: 5px;
    }

    .category-tooltip {
        display: inline-block;
    }
}
