@use "client/css/globals" as *;

$rowSlideDuration: 0.2s;
$categorizeInputWidth: 400px;
$amortizeInputWidth: 400px;

// table header and row wrapper
.pending-txn-row {
    align-items: baseline;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    padding: $spacingMediumSmall 0;
    transition: all 0.2s;
    width: 100%;
}

// table header
.pending-txns-header {
    align-items: flex-end;
    font-weight: bold;
}

// most outer class on categorize-txn.vue
.pending-txn {
    &.pending-txn-highlighted {
        background: $yellow1Background;
    }

    .multiselect {
        transition: opacity 0.2s;
        width: 100%;

        &.pending-txn-multiselect-hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .attachments {
        margin-top: $spacingMedium;
    }
}

// each table cell
.pending-txn-cell {
    padding: 0 $spacingSmall;

    &.number {
        text-align: right;
    }
}

// checkbox column (on Customer portal > Responses tab)
.pending-txn-checkbox {
    flex: 0 0 40px;
}

.pending-txn-date {
    flex: 0 0 110px;
}

.pending-txn-visibility {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    margin-right: 30px;
    width: 130px;
}

// memo column
.pending-txn-memo {
    flex: 2 0 100px;
    overflow-x: hidden;
    word-wrap: break-word;
}

// account column
.pending-txn-account {
    flex: 1 0 100px;
    overflow-x: hidden;
}

// amount column
.pending-txn-amount {
    flex: 0 0 100px;
    overflow-x: hidden;
}

// vendor/qb account/class/department/misc column
.pending-txn-set-container {
    flex: 0 1 ($spacingSmall + $fieldWidthBase + $spacingSmall);
}

// make rule / accept prefill button column
.pending-txn-primary-action {
    // kept in sync with .make-rule-column in pnv-response-row.vue
    flex: 0 0 160px;

    .button {
        width: 100%;
    }
}

// overflow button column
.pending-txn-overflow {
    flex: 0 0 60px;

    button {
        width: 240px;
    }
}

// transaction type under amount
.pending-txn-type {
    color: $pilotText;
    font-size: $fontSizeSmall;
}

// Investigate button under memo
.pending-txn-investigate {
    align-items: center;
    color: $brandColorPrimary;
    display: flex;
    font-size: $fontSizeSmall;
    margin-top: $spacingSmaller;

    .icon-search {
        height: 16px;
        width: 16px;
    }
}

// show/hide attachments button
.pending-txn-toggle-attachments {
    color: $linkColorPrimary;
    display: inline-block;

    &.button:focus,
    &.button:hover {
        color: $linkColorPrimary;
    }
}

// children of .pending-txn-set-container
.pending-txn-stacked {
    margin-top: $spacingSmall;

    .note-input {
        width: 100%;
    }
}

// statement with sparkle under vendor/qb account
.pending-txn-suggestion-info {
    display: flex;

    .icon-sparkle {
        display: inline-block;
        margin-right: $spacingSmaller;
        min-width: 16px;
    }

    // for markdown
    ::v-deep p {
        margin-bottom: 0;
    }
}

// edit suggestion button, similar to the global input style
.narrowed-account-suggestion-edit-button {
    align-items: center;
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    color: $brandColorPrimary;
    cursor: pointer;
    display: flex;
    font-size: $fontSizeBase;
    outline: none;
    overflow: hidden;
    padding: $inputPaddingTop $inputPaddingHorizontal $inputPaddingBottom;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .icon {
        margin-right: 5px;
    }
}
