
@use "client/css/globals" as *;

$dotSize: 10px;
$checkSize: 16px;
$verticalGap: 10px;

.title {
    font-size: 15px;
    font-weight: bold;
}

ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ol li {
    position: relative; // Anchor step line positioning calculation
}

ol li:last-child {
    margin-bottom: 0;
}

ol li:not(:first-child)::before,
ol li:not(:last-child)::after {
    // Line between the dots
    border-left: 2px solid $borderColorLight;
    content: "";
    left: 7px;
    position: absolute;
    width: 2px;
}

ol li:not(:first-child)::before {
    bottom: calc(50% + ($dotSize / 2));

    // top portion of line connecting dots
    // 50% here refers to the height of the text, however many lines of text there are
    // this line starts at the bottom of the first dot and extends to the midpoint of the gap
    height: calc(50% - ($dotSize / 2) + ($verticalGap / 2));
}

ol li:not(:last-child)::after {
    // bottom portion of line connecting dots
    // 50% here refers to the height of the text, however many lines of text there are
    // this line starts at the top of the second dot and extends to the midpoint of the gap
    height: calc(50% - ($dotSize / 2) + ($verticalGap / 2));
    top: calc(50% + ($dotSize / 2));
}

ol li.with-dot.completed::after {
    // Line under completed icon is shorter so it doesn't connect to the check mark
    height: calc(50% - ($checkSize / 2) + ($verticalGap / 2));
    top: calc(50% + ($checkSize / 2));
}

.with-dot {
    align-items: center;
    display: flex;
}

.completed-step {
    bottom: 2px;
    margin-right: 12px;
    overflow: visible;
    position: relative;
    right: 2px;
}

.dot {
    border: 2px solid $borderColorLight;
    border-radius: 50%;
    color: $contentColorSecondary;
    display: inline-block;
    height: $dotSize;
    margin-left: 3px;
    margin-right: 23px;
    min-width: $dotSize;
    width: $dotSize;
}

.step-name {
    color: $contentColorSecondary;
    display: inline-block;
    margin: 0;
}

.pending {
    .dot {
        border: 2px solid $pilotPurple;
    }

    .step-name {
        color: $pilotPurple;
    }
}

.completed {
    .step-name {
        color: $offBlack;
    }
}

.steps-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $verticalGap;
}
