
@use "client/css/globals" as *;

.page-wrapper {
    // Set on outer element to get 80px bottom spacing w/ filled background
    padding-bottom: 80px;
}

.fiscal-year {
    font-size: $fontSizeMedium;
    font-weight: normal;
    padding-top: 3px;
}

.tax-greeting-card {
    margin-bottom: 15px;
}

.filings-title {
    margin-top: 45px;
}

.filing-summary {
    margin-top: 15px;
}

.no-filings-card {
    align-items: center;
    padding-bottom: 51px;
    padding-top: 51px;
    text-align: center;
}

.not-pilot-tax-card {
    align-items: center;
    padding-bottom: 51px;
    padding-top: 51px;
    text-align: center;
}

.not-pilot-tax-text {
    font-size: $fontSizeBase;
    line-height: 150%;

    ::v-deep .bubble-up {
        min-width: 288px;
    }
}

.not-pilot-tax-link {
    color: $brandColorPrimary;
}

.not-pilot-tax-button {
    margin-top: 8px;
}

.period-picker {
    align-items: center;
    display: flex;
}

.single-tax-period {
    display: flex;
    font-size: $fontSizeMedium;
    padding: 13px 0 12px;

    .tax-period {
        padding-left: 7px;
    }
}
