
@use "client/css/globals" as *;

::v-deep(.container.expanded-right) {
    padding-right: 0;
}

.compliance-container {
    width: 100%;

    .compliance {
        border-radius: $borderRadiusMedium;
        cursor: default;
        display: block;
        font-weight: bold;
        padding: 10px;
        width: 100%;

        &:hover {
            background-color: $backgroundColorTertiary;
        }
    }
}
