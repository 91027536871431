
@use "client/css/globals" as *;

table {
    margin: 0;

    // The table-layout CSS property sets the algorithm used to lay out <table> cells, rows, and columns.
    // By setting table-layout to auto the widths of the table and its cells are adjusted to fit the content.
    table-layout: auto;
}

tr:not(:first-child) {
    border-color: $pastelViolet;
}

tr:first-child {
    border: none;
}

th {
    vertical-align: top;
    white-space: nowrap;
}

th,
td {
    padding: $spacingSmall;
    text-align: left;
}

.amortizations-transaction-broken {
    background: $red1Background;
}

.amortization-memo {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.amortization-title {
    font-size: 18px;
    white-space: nowrap;
}

.amortizations-table {
    display: grid;
    row-gap: 30px;
}

.capitalize-calculation-style {
    text-transform: capitalize;
}

.select-all-checkbox {
    display: block;
    font-size: $fontSizeMedium;
    text-align: center;
}

.view-amortizations-checkbox {
    vertical-align: top;
}
