
@use "client/css/globals" as *;

.reconcile-state-reconciled {
    color: $green1;
}

input {
    width: 150px;
}

input:invalid {
    border-color: $warningYellow;
}

::v-deep .error-background td {
    background-color: $pastelRed;
    color: $contentColorPrimary;

    .first {
        background-color: $pastelRed;
        color: $contentColorPrimary;
    }
}
