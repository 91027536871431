
@use "client/css/globals" as *;

// Shared default & light styles
.switcher,
.no-switcher {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    display: inline-flex;
    font-size: $fontSizeMedium;
    font-weight: 600;
    justify-content: center;
    min-width: 125px;
    padding: 10px 15px;
    transition:
        background-color 0.1s,
        box-shadow 0.1s;
    user-select: none;

    &.light {
        :first-child {
            color: $brandColorPrimaryContrast;
        }
    }
}

.switcher {
    cursor: pointer;

    &:active,
    &.open {
        box-shadow: $purpleShadow;
    }

    &:hover,
    &.open {
        background-color: $backgroundColorPrimary;
    }

    &.open {
        > .active-item {
            color: $contentColorPrimary;
        }
    }

    &.light {
        &:hover {
            :first-child {
                color: $contentColorPrimary;
            }
        }
    }
}

.no-switcher {
    cursor: default;

    > h3 {
        margin: 0; // reset global style
    }
}

// dropdown items when the switcher is open
.dropdown-items {
    margin: 0;
    padding: 0;

    li {
        border: none;
        list-style-type: none;
        padding: 0;
    }

    > .header {
        border: none;
        color: $pilotPurple;
        font-size: $fontSizeSmall;
        font-weight: 600;
        padding: 15px;
    }

    .dropdown-item {
        align-items: center;
        border: none;
        border-radius: 0;
        color: $contentColorPrimary;
        cursor: pointer;
        display: flex;
        font-size: $fontSizeBase;
        justify-content: space-between;
        margin: 0;
        min-width: 312px;
        padding: 15px;
        text-align: left;
        text-decoration: none;

        &.active {
            background-color: $pilotPurple;
            color: $backgroundColorPrimary;

            /*
                Prevent clicking on a customer that is already active,
                it throws a router error -> NavigationDuplicated: Avoided
                redundant navigation to current location: "/c/<shortid>/path"
            */
            pointer-events: none;

            &:hover {
                background-color: $pilotPurple;
            }
        }

        &:hover,
        &:focus,
        &:active {
            background: $pastelUltramarine;
        }
    }
}

.chevron-container {
    align-items: end;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

::v-deep .popover {
    border: 0.5px solid $borderColorLight;
    box-shadow: none;
}
