
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

svg {
    padding-right: 5px;
}

.accept-category-container {
    background-color: $yellow1Background;
    border: 1px solid $pilotTypeYellow;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    max-width: categorize.$categorizeInputWidth;
    padding: 15px 30px 15px 15px;
    position: relative;
    width: 100%;
}

.button-line {
    margin-left: auto;
}
