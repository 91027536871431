
@use "sass:color";
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.txn-categorization-select-cell {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;

    ::v-deep .multiselect,
    .wrapper {
        max-width: categorize.$categorizeInputWidth;
        width: 100%;
    }

    .narrowed-account-suggestion-edit-button {
        margin-right: auto;
        max-width: categorize.$categorizeInputWidth;
        width: 100%;
    }

    ::v-deep .note-wrapper {
        margin-right: auto;
        max-width: categorize.$categorizeInputWidth;
        width: 100%;
    }

    ::v-deep input::placeholder {
        color: $contentColorTertiary;
    }

    ::v-deep button.editable {
        color: $contentColorPrimary;
        width: 100%;
    }
}

.category-suggestion {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

// styling comes from connections/client/components/veilable.vue
.veilable {
    align-items: center;
    background: none;
    border: none;
    border-radius: 4px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    padding: 10px;
    position: absolute;
    text-align: initial;
    top: 0;
    width: 100%;

    .pencil {
        opacity: 0;
    }

    &:hover {
        background: $pastelUltramarine;
        cursor: pointer;

        .pencil {
            color: $contentColorSecondary;
            opacity: 1;
        }

        .icon-sparkle {
            display: none;
        }
    }
}

.with-suggestion {
    background-color: $yellow1Background;
}

.with-success {
    background-color: color.adjust($green1Background, $lightness: -10%);
}
