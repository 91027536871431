
@use "client/css/globals" as *;

#modal-grid {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: $borderRadiusMedium;
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    height: 499px;
    max-height: 499px;
    max-width: 814px;
    min-height: 499px;
    overflow: hidden;
}
