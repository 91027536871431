
@use "client/css/globals" as *;

.margin-bottom {
    margin-bottom: 30px;
}

.header {
    margin-bottom: 30px;
    margin-top: 30px;
}

.bold-text {
    font-weight: bold;
}

.instructions-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

.instruction {
    padding: 30px;
    word-wrap: anywhere;
}

.modal-body {
    margin-bottom: 20px;
}

::v-deep .modal-actions {
    border-top: 1px solid $borderColorLighter;
}
