
@use "client/css/globals" as *;

.prepaid-detective-date {
    width: 100px;
}

.prepaid-detective-vendor {
    width: 120px;
}

.prepaid-detective-type {
    width: 80px;
}

.prepaid-detective-amount {
    text-align: right;
    width: 100px;
}

.prepaid-detective-reason {
    font-size: $fontSizeSmall;
}
