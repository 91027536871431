
@use "client/css/globals" as *;

input {
    background: $backgroundColorPrimary;
    margin-right: $spacingSmall;
    min-width: 250px;
}

p {
    margin-bottom: $spacingSmallest;
}

.contact-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.customer-avatar-container {
    height: 56px;
    margin-bottom: $spacingMediumSmall;
    width: 56px;

    & > .customer-avatar {
        border-radius: 50%;
        height: 100%;
        width: 100%;

        ::v-deep circle {
            fill: $backgroundColorPrimary;
            stroke: $backgroundColorPrimary;
        }
    }
}

.contact-name {
    font-size: $fontSizeLarge;
    margin-bottom: 3px;
}

.contact-service {
    color: $contentColorSecondary;
    font-size: $fontSizeBase;
}

.contact-email-container {
    display: flex;
    margin: $spacingMedium 0 $spacingMediumLarge 0;
}

.disabled-input {
    color: $contentColorPrimary;

    /*
        Additional style declaration needed for Safari
        Style the text in a disabled input
    */
    -webkit-text-fill-color: $contentColorPrimary;
}

.email-copied-snack-bar {
    // override default snackbar positioning
    bottom: 10px;
    overflow: hidden;
}
