
@use "client/css/globals" as *;

.page {
    background-color: $backgroundColorSecondary;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    .main {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 90px 60px auto;

        .header {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 40px 0;
            width: 100%;

            .link {
                height: 50px;
            }

            .logo {
                height: 50px;
                width: auto;
            }
        }

        .download-card,
        .failed-card {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 30px 0;
            width: 500px;

            .card-heading {
                line-height: 27px;
            }

            .card-icon {
                align-items: center;
                display: flex;
                height: 24px;
                justify-content: center;
                margin-top: 15px;
                width: 24px;

                .download-icon {
                    align-items: center;
                    display: flex;
                    height: 24px;
                    justify-content: center;
                    width: 24px;

                    ::v-deep .loading {
                        padding: 0;
                    }
                }
            }

            .card-text {
                line-height: 23px;
                margin-bottom: 0;
                text-align: center;
            }
        }
    }
}
