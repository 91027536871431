
@use "client/css/globals" as *;

.previous-comments-container {
    background: $backgroundColorSecondary;
    margin-bottom: $spacingSmall;
    padding: $spacingSmall;
    padding-bottom: 0;

    .previous-checklist-comments {
        border-bottom: 1px solid $borderColorLight;
        margin: 0 - 0.3em;
        padding: 1em;
        padding-top: 0;
    }
}
