
.granularity-card {
    align-items: flex-start;
    display: flex;
    gap: 20px;

    ::v-deep .info-tooltip {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.granularity-card-title {
    min-width: 115px;
}

.granularity-actions {
    display: flex;
    gap: 20px;
    justify-content: end;
}

.product-granularity-accordion {
    accent-color: #5f2ee5;
    margin-left: auto;
    max-width: 600px;

    ::v-deep .accordion-header label {
        font-weight: bold;
    }
}

.tt-content {
    min-width: 475px;
}

.input-label {
    align-items: flex-start;
    display: flex;
    gap: 10px;
}

.icon-align {
    vertical-align: middle;
}
