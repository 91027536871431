
@use "client/css/globals" as *;

.wrapper {
    max-width: 600px;

    button {
        margin-bottom: $spacingMedium;
    }
}
