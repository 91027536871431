
@use "client/css/globals" as *;

/**
 * Override the default multiselect disabled styles
 *
 * Copied over from narrow-account-picker.vue
 */
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

/**
 * Override the default multiselect display styles
 *
 * Copied over from narrow-account-picker.vue
 */
::v-deep .multiselect__content-wrapper {
    display: flex;
}

.group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}

.user-item {
    display: flex;
    justify-content: space-between;

    .user-role {
        color: $contentColorTertiary;
        font-size: $fontSizeSmall;
        font-style: italic;
    }
}
