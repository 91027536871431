
@use "client/css/globals" as *;

.field {
    margin: 0 0 $spacingMediumLarge;

    &:last-of-type {
        margin: 0;
    }
}
