
@use "client/css/globals" as *;

h2 {
    margin-bottom: $spacingSmall;
}

.section {
    margin-bottom: $spacingLarge;
}

.empty-banner {
    padding: $spacingLarge;

    p {
        color: $contentColorSecondary;
    }
}
