
@use "client/css/globals" as *;

ul {
    list-style-type: none; // remove default style
    margin: 0; // remove default style
    padding: 0; // remove default style
}

a {
    color: unset; // remove default style
    text-decoration: none; // remove default style
}

.icon {
    height: 22px;
    width: 22px;
}

.disabled-tooltip-text {
    font-size: 11px !important;
}

.link {
    align-items: center;
    color: $contentColorPrimary;
    display: grid;
    font-weight: bold;
    gap: 5px;
    grid-auto-flow: column;
    justify-content: start;

    &.router-link-active, // this class is automatically added by `<router-link>`
    &.highlighted {
        color: $brandColorPrimary;
    }
}

.nav {
    &.primary {
        width: 215px; // hardcoded for now; the caller could define this

        .link {
            font-size: $fontSizeMedium;
            gap: 20px;

            &.focused,
            &:focus,
            &:hover {
                outline: none;

                .title {
                    // underline the title but not the icon/badge
                    text-decoration: underline $brandColorPrimary;
                }
            }
        }
    }

    &.primary-icons {
        .icon {
            border: 2px solid transparent; // always have a border (add color when highlighted)
            border-radius: $borderRadiusSmall; // smooth out the corners cuz it's pretty
            box-sizing: content-box; // size of container should include border and padding
            padding: 5px; // add space between content and border
        }

        .title,
        .notification-count {
            display: none;
        }

        .link {
            &.focused,
            &:focus,
            &:hover {
                outline: none;

                .icon {
                    border-color: $brandColorPrimary;
                }
            }
        }
    }

    &.secondary {
        border-bottom: 2px solid $borderColorLight;

        .icon {
            display: none;
        }

        .link {
            font-size: $fontSizeBase;
            padding-bottom: 10px;
            position: relative;
            white-space: nowrap; // don't wrap links with words

            &.router-link-active,
            &.highlighted {
                &::after {
                    background-color: currentColor;
                    bottom: -2px;
                    content: "";
                    display: block;
                    height: 2px;
                    position: absolute;
                    width: 100%;
                }
            }

            &.focused,
            &:focus,
            &:not(.disabled):hover {
                outline: none;

                .title {
                    text-decoration: underline $brandColorPrimary;
                }
            }

            &.disabled {
                color: $darkGrey;
                cursor: default;
                text-decoration: none;

                &::after {
                    display: none;
                }

                &:hover,
                &:focus,
                &.focused {
                    .title {
                        text-decoration: none; /* No underline on disabled links even on focus */
                    }
                }
            }
        }
    }

    &.tertiary {
        // duplicate of PilotCard styling
        background-color: $backgroundColorPrimary;
        border-radius: $borderRadiusLarge;
        height: fit-content;
        padding: 15px;
        width: fit-content;

        .icon {
            display: none;
        }

        .link {
            border-radius: $borderRadiusMedium;
            padding: 10px;
            width: 185px; // we need to hardcode this so highlighting + outlining work on links

            &.router-link-active,
            &.highlighted {
                background-color: $backgroundColorSecondary;
            }

            &.focused,
            &:focus,
            &:hover {
                outline: 1px solid $brandColorPrimary;
            }
        }
    }
}
