
@use "sass:color";
@use "client/css/globals" as *;

.table-filter {
    align-items: center;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    &--active {
        width: 100%;
    }

    .popover-content {
        font-weight: normal;

        .multiselect {
            min-width: 16em;
            width: auto;
        }

        // search input box should not have bottom corners rounded
        .multiselect--active:not(.multiselect--above) .multiselect__tags {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        // options should not have top corners rounded
        .multiselect__content-wrapper {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: none;
            margin-top: 0;
            position: relative; // needed to have it calculated as part of the popover dropshadow
        }

        // light border separating search box and options
        .multiselect__tags {
            border-color: $borderColorLight;
            border-width: 0 0 1px;
        }

        .multiselect__option--highlight {
            background: $pilotPurple;
            color: $brandColorPrimaryContrast;

            &::after {
                display: none;
            }
        }

        .multiselect__option--selected {
            background: $backgroundColorPrimary;
            color: $contentColorPrimary;

            &.multiselect__option--highlight {
                background: $backgroundColorSecondary;
                color: $contentColorPrimary;

                &::after {
                    display: none;
                }
            }
        }
    }

    .clear,
    .filter-option {
        color: $blue1;
        font-size: $fontSizeSmall;
        font-weight: normal;
    }

    .filter-option {
        color: $blue1;
        display: inline-block;

        // subtracting width of filter icon + padding
        max-width: calc(90% - #{$spacingSmall} - #{$spacingSmaller} - 8px);
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
    }

    .filter-icon {
        padding-left: $spacingSmall;
        padding-right: $spacingSmaller;

        svg.active {
            fill: $blue1;
        }

        svg.inactive {
            fill: color.adjust($blue1, $lightness: 20%);

            &.empty {
                fill: $darkGrey;
            }
        }
    }
}
