
@use "client/css/globals" as *;

#register {
    max-width: $fieldWidthBase;
}

.register-text {
    color: $contentColorSecondary;
}

.help-text {
    color: $contentColorSecondary;
    font-size: $fontSizeMicro;
}

.inputField {
    margin-bottom: 20px;
}

.submit-button {
    margin-top: 20px;
}

.error-message {
    font-size: $fontSizeMicro;
    margin: 0;
    padding: 0;
}

.name-error {
    align-items: center;
    display: flex;
    grid-column-start: 1;
    grid-row-start: 2;
}

.preferred-name-with-infotooltip {
    align-items: center;
    display: flex;
}
