
@use "client/css/globals" as *;

.nothing-to-do {
    padding: 25px;
    text-align: center;
}

.card-info {
    padding: 25px;
}

.secondary-text {
    font-size: $fontSizeSmall;
}

.optional-text {
    color: $contentColorTertiary;
    font-size: $fontSizeBase;
}
