
@use "client/css/globals" as *;

.warning-container {
    background-color: $yellow1Background;
    border: 1px solid $pilotTypeYellow;
    border-radius: 5px;
    line-height: 150%;
    padding: 30px;
    position: relative;
    width: 100%;

    .warning-text,
    .mapping-text {
        font-weight: bold;
    }

    .new-mapping {
        text-decoration: line-through;
    }
}
