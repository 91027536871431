
@use "client/css/globals" as *;

/*
    This file contains several non-standard measurements. From Design,
    > Hm, it seems like the way front-end thinks about our spacing doesn't
    > align with how Design thinks about it.
    >
    > Our principle is that we use a 5px grid, so anything on that grid is
    > on the table for Designers to use.
    We're using hardcoded values here until we find a longterm technical
    solution
*/

.loading-container {
    align-items: center;
    background: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;
}

.customer-onboarding {
    background: $brandColorPrimaryDark;
    min-height: 100vh;
    padding-bottom: 60px;
    padding-left: $spacingMedium + $spacingLarge;
    padding-right: $spacingMedium + $spacingLarge;
}

.onboarding-header {
    padding: $spacingLarge 0;

    .page-heading {
        color: $brandColorPrimaryContrast;
        font-weight: normal;
        margin: 0;
        text-align: center;
    }

    .portal-navigation {
        padding-bottom: $spacingLarge;
    }
}

.page-content {
    display: flex;
    justify-content: center;

    .nav {
        flex: 0 0 180px;
        margin-left: 80px;
        margin-top: 30px;
    }

    .main {
        flex: 1;
        max-width: 670px;
        padding: 0 $spacingMediumLarge;
    }

    .aside {
        flex: 0 0 260px;
        margin-right: 35px;
    }
}

.tax-progress {
    padding-left: 30px;
    padding-top: 30px;
    position: sticky;
    top: 0;
}

::v-deep .sections-complete {
    font-size: $fontSizeMedium;
}
