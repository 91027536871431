
@use "client/css/globals" as *;

.je-importing-selected-file-row {
    vertical-align: text-top;
}

.je-importing-validated-file-overview {
    margin: 0 0 $spacingSmallest;
}

.je-importing-validated-file-details {
    font-size: $fontSizeSmall;
    font-style: italic;
}

.je-importing-step {
    &.disabled {
        color: $contentColorSecondary;
    }

    &.disabled .error {
        color: $red1Desaturated;
    }
}
