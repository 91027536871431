
@use "client/css/globals" as *;

// override the default styles for the vendor inspector modal
::v-deep .modal-center-container {
    cursor: default;
}

::v-deep .bubble-container {
    width: 100%;
}

::v-deep .bubble-down {
    left: 25%;
}

td.report-negative {
    color: $red1;
}

td.number {
    background-color: $lightGrey;
}

td.has-insights {
    background: $brighterPurple;
}

td.has-insights:hover {
    background: $brightPurple;

    ::v-deep .bubble-target {
        text-decoration: underline;
    }
}

.no-insights {
    height: 22.5px; // set height so that the empty cells are clickable
    width: 100%;
}

td.has-vendor-inspector:hover {
    cursor: pointer;
    text-decoration: underline;
}
