
@use "client/css/globals" as *;

.activity-detail-table-wrapper {
    border-radius: $borderRadiusLarge;
    height: 575px;
    overflow: auto;
}

.table-padder {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    padding: $spacingMedium $spacingMediumLarge;
}

.activity-detail-table {
    background: $backgroundColorPrimary;
    margin-bottom: 0;

    thead {
        tr {
            border-bottom: 1px solid $borderColorLight;
        }

        th:nth-of-type(1) {
            width: 235px;
        }

        th:nth-of-type(3) {
            width: 203px;
        }

        th:nth-of-type(4) {
            width: 42px;
        }
    }

    tbody {
        tr:hover {
            background: $backgroundTonalGrey;
            cursor: pointer;
        }

        td {
            padding: $spacingMediumLarge $spacingSmaller;
            vertical-align: top;

            &.user-cell.admin {
                padding: 22px $spacingSmaller;
            }
        }
    }

    .row-detail {
        display: none;
    }

    .user-cell {
        align-items: center;
        display: flex;
    }

    .user-customer {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .avatar {
        flex: 0 0 auto;
        margin-right: $spacingSmall;
    }

    .expanded {
        .row-detail {
            display: block;
            line-height: 1.5;
            margin-top: $spacingMediumSmall;
        }

        .note-attached-message {
            display: none;
        }
    }

    @mixin secondary-content {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .row-detail-item {
        @include secondary-content;
    }

    .note-attached-message {
        @include secondary-content;

        font-style: italic;
    }

    .note {
        @include secondary-content;

        background: $yellow1Background;
        display: inline-block;
        margin-top: $spacingMedium;
        padding: $spacingMediumSmall;

        .note-label {
            font-weight: bold;
        }
    }
}

footer {
    padding: $spacingMedium $spacingMediumLarge;

    a {
        color: $brandColorPrimary;
    }
}
