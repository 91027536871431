
@use "client/css/globals" as *;

.institution-field {
    list-style-type: none;

    .permissions {
        background: $backgroundColorSecondary;
        width: 100%;
    }

    &.collapsed {
        cursor: move;
    }
}
