
.resolution-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 25px 1fr;
    padding: 0 0 30px 30px;
}

.resolution-container {
    .bold {
        font-weight: bold;
    }
}
