
@use "client/css/globals" as *;

$tertiaryNavWidth: 240px;

.tertiary-nav-container {
    // This is display flex so the tertiary-nav and the tertiary-nav-content create
    // two columns.
    display: flex;

    // carefully set min/max height to make the container actually fit into the space left
    // over after the header and secondary navigation (presumably there aren't any uses of
    // TertiaryNav on pages that don't have Secondary Nav?)
    max-height: calc(100vh - #{$pageHeaderHasSecondaryNavHeight} - (#{$spacingLarge} * 2));
    min-height: calc(100vh - #{$pageHeaderHasSecondaryNavHeight} - (#{$spacingLarge} * 2));

    // We hide overflow in the parent, then set each column to overflow: scroll;
    // that allows for two independently scrollable columns.
    overflow: hidden;
}

.tertiary-nav {
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 $spacingMedium 0 0;
    width: $tertiaryNavWidth;

    ::v-deep h2 {
        font-size: $fontSizeMedium;
        margin-bottom: $spacingSmall;
    }

    ::v-deep a {
        border-radius: 4px;
        color: $contentColorPrimary;
        display: block;
        font-weight: bold;
        margin-bottom: $spacingSmaller;
        padding: 10px;

        &:hover {
            background: $backgroundColorTertiary;
            text-decoration: none;
        }

        &.router-link-active {
            background: $backgroundColorTertiary;
            color: $pilotPurple;
            text-decoration: none;
        }
    }
}

.tertiary-nav-content {
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: $spacingMedium;
    width: 100%;
}
