
@use "client/css/globals" as *;

.header-name {
    font-size: $fontSizeMedium;
    font-weight: bold;
}

.header-category {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    width: 140px;
}

.monthly-amount-header-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.transaction-container {
    align-items: center;
    display: flex;
    margin: 0 -20px; // extend container into parent's padding
    padding: 0 20px;
    padding-top: 20px;

    &:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
        padding-bottom: 20px;
    }

    & > svg {
        margin-right: 20px;
    }

    & > .inner-container {
        flex-grow: 1;

        & > .transaction-details-container {
            display: flex;
        }
    }
}

.transaction-date {
    font-size: $fontSizeBase;
    margin-bottom: 3px;
}

.transaction-payment-method,
.transaction-note {
    color: $contentColorSecondary;
    font-size: 12px; // not in globals.scss
}

.transaction-note {
    align-items: center;
    display: flex;
}

.transaction-bullet {
    background-color: $contentColorPrimary;
    border: 1px solid $contentColorPrimary;
    border-radius: 50%;
    display: block;
    height: 3px;
    margin: 0 5px;
    width: 3px;
}

.transaction-amount {
    font-size: $fontSizeBase;
}

@media (max-width: 559px) {
    svg {
        display: none;
    }
}

.header-left {
    align-items: center;
    display: flex;
}

.vendor-logo {
    margin-right: 15px;
}
