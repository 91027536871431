
@use "client/css/globals" as *;
@use "client/components/css/mixins";

.add-new-vendor {
    color: $brandColorPrimary;

    .icon {
        margin-right: $spacingSmaller;
        vertical-align: -2px;
    }
}

.includes-none-of-the-above {
    @include mixins.last-item-sticky;
}

// Style the "All vendors" label similarly to how we style the category labels in NarrowedAccountPicker
// (We don't actually need those categories here; we just want to mimic the styling)
.all-vendors-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}

// Disabled options have a gray background by default, which is not what we want for our label (which is
// technically a disabled option). Also, the default when opening the selector is to hover over the first item,
// which turns it dark gray by default, which is also not what we want. Remove all background highlighting!
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--highlight {
    background: transparent !important; // use !important because multiselect is using !important for the gray background too D:
}
