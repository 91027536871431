
@use "client/css/globals" as *;

.rating-explanation:invalid {
    border-color: $red1Light;
    box-shadow: none; // Firefox default style for invalid
}

.happiness-options {
    border: 0;
    border-radius: $borderRadiusMedium;
    display: flex;
    margin-bottom: $spacingSmall;
    padding: 0;

    &.disabled .happiness-rating {
        cursor: default;

        > * {
            opacity: 0.5;
        }
    }
}

.rating-input {
    display: none;
}

.happiness-rating {
    background-color: #fff;
    border: 1px solid $borderColorLight;
    cursor: pointer;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    height: 11em;
    justify-content: center;
    padding: $spacingMedium;
    text-align: center;
    user-select: none;

    &:hover {
        background-color: #fdfdfd; // custom color
    }

    &:not(:last-child) {
        border-right-width: 0;
    }

    // ideally, we could infer the selected choice using the elements/classes
    // that we have available; however, ensuring that we don't double any borders
    // and correctly apply color around the selection is easiest this way
    &.selected {
        border-color: $brandColorPrimary;

        + .happiness-rating {
            border-left-color: $brandColorPrimary;
        }
    }

    .rating-example {
        color: $contentColorTertiary;
        display: block;
        font-size: $fontSizeSmall;
    }

    .rating-emoji {
        font-size: $fontSizeLarge;
    }

    // normally we'd add a border radius to the parent container, but here, the
    // border is on the children; we simulate rounded corners on gestalt
    &:first-child {
        // top left, bottom left
        border-radius: $borderRadiusMedium 0 0 $borderRadiusMedium;
    }

    &:last-child {
        // top right, bottom right
        border-radius: 0 $borderRadiusMedium $borderRadiusMedium 0;
    }
}

.explanation-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $spacingMedium;

    .explanation-note {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .rating-explanation {
        flex-basis: 100%;
        margin-top: $spacingSmaller;
    }
}
