
@use "client/css/globals" as *;

#customer-nav {
    .subheader-container {
        min-height: 115px;
    }

    .legal-entity-name {
        font-size: $fontSizeMedium;
        margin-left: $spacingSmall;
        margin-right: $spacingSmall;
    }
}
