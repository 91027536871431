
@use "client/css/globals" as *;

#fatal {
    color: $contentColorSecondary;
    text-align: center;
    width: 100%;

    h1 {
        font-size: 160px;
        margin: $spacingLarge 0;
    }
}
