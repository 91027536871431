
@use "client/css/globals" as *;

.card {
    margin-bottom: 30px;
}

.card-header {
    border-bottom: 1px solid $borderColorLight;
    padding: 15px 30px;
}

.card-body {
    padding: 15px 30px;
}

.duration-setting {
    margin-right: 10px;
}

.prerequisites-list {
    margin-bottom: 20px;
}

.products-header {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto auto auto;
}
