
@use "client/css/globals" as *;

p {
    color: $contentColorSecondary;
}

section {
    margin-bottom: 0;
}

.header {
    margin: 15px 0;
}

.comment {
    font-size: $fontSizeSmall;
    margin: 20px 0 0;
}

.section-header {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: 0;
    position: relative;
    transition: all 0.2s;

    .chevron {
        left: -26px;
        position: absolute;
    }

    .expanded & {
        font-weight: bold;
        margin-bottom: 20px;
    }
}

.sections {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: -40px;
}

.expandable-section {
    background-color: $backgroundTonalGrey;
    border-top: solid 1px $borderColorLight;
    overflow-y: auto;
    padding: 20px 40px;
}

.expanded {
    flex: 1;
}

.original-txn {
    padding: 40px 40px 30px;
}

.num-suggestions {
    color: $contentColorTertiary;
    font-size: $fontSizeBase;
    font-weight: normal;
}

.suggestion-card {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.warning {
    border: 1px solid $borderColorLight;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 20px 30px;

    .icon {
        margin-right: 5px;
    }
}

.troubleshooting-content {
    padding-top: 10px;

    h4 {
        margin-bottom: 20px;
    }

    .troubleshooting-instruction {
        list-style-type: disc;
        margin-bottom: 15px;
    }
}
