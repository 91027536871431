
.split-layout {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}

.title {
    font-weight: bold;
}

.header-title-container {
    align-items: baseline;
    display: flex;
    gap: 5px;
}
