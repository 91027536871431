
.empty-activity-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
}

h2.empty-activity-title {
    margin-bottom: 5px;
    margin-top: 15px;
}

.empty-activity-description {
    display: flex;
    max-width: 50%;
    text-align: center;
}
