
@use "client/css/globals" as *;

.amortization-period-picker {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -10px; // undo bottom margins added by children
    width: 100%;
}

.amortization-type-picker {
    flex: 0 0 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.date-fields {
    align-items: center;
    display: flex;
    flex: 1 0 300px;
    flex-direction: row;
    margin-bottom: 10px;
}

.input-separator {
    margin: 0 $spacingSmaller;
}

// terrible hack: multiselect isn't applying the correct class to the group labels
// instead of multiselect__option--group, it applies both the --disabled and --selected classes
// when an option is selected, the --selected class is removed but the --disabled class stays
// we never expect to have disabled options in this component, so I am targeting them
// because they those elements are under control of VueMultiselect, I must use ::v-deep
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

.dropdown-group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}
