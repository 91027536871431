
@use "client/css/globals" as *;

$expanderTransitionDuration: 0.4s;

.expander {
    background: $backgroundColorSecondary;
    box-shadow: $primaryNavAdjacentBoxShadow;
    margin: $spacingSmall 0 (-($flowbotItemPadding));
    padding: $spacingMedium $pageMargin 120px;
    position: relative;
    transition: all $expanderTransitionDuration;

    &.no-cuff {
        padding-bottom: $spacingMedium;
    }

    > .x {
        position: absolute;
        right: $pageMargin;
        top: $spacingMedium;
    }

    &.expander-slide-enter,
    &.expander-slide-leave-to {
        height: 0 !important; // Override the height set by v-slide
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
    }

    &.expander-slide-enter-active,
    &.expander-slide-leave-to {
        overflow-y: hidden;
    }

    &.white {
        background: $backgroundColorPrimary;
    }

    &.skyway-border {
        border: 2px solid $borderColorLight;
        border-radius: 5px;
        box-shadow:
            0 0 3px $pilotPurple,
            0 2px 8px rgba(95, 46, 229, 0.1);
    }
}

.expander-header {
    align-items: baseline;
    display: flex;

    h2 {
        font-size: $fontSizeLarge;
    }
}

.expander-header-right {
    margin-left: auto;
}

.expander-cuff {
    background: $backgroundColorTertiary;
    bottom: 0;
    box-shadow: $primaryNavAdjacentBoxShadow;
    display: flex;
    left: 0;
    padding: $spacingMedium $pageMargin;
    position: absolute;
    right: 0;
    z-index: $zIndexFlyout - 10;

    &.white {
        background: $backgroundColorPrimary;
    }
}

.expander-cuff-right {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    margin-left: auto;
}
