@use "globals" as *;

.columns {
    display: flex;
    margin: 0 (-($spacingMedium));
    padding: 0;

    .column {
        display: block;
        flex: 1 1 auto; // For IE11
        margin-left: 0;
        max-width: 100%;
        padding: $spacingMedium;
        width: 100%;

        &.one-fourth {
            flex: 0 0 25%;
            max-width: 25%;
        }

        &.one-third {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }

        &.one-twelfth {
            flex: 0 0 8.33%;
            max-width: 8.33%;
        }

        &.five-twelfths {
            flex: 0 0 41.67%;
            max-width: 41.67%;
        }

        &.half {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &.two-thirds {
            flex: 0 0 66.67%;
            max-width: 66.67%;
        }

        &.three-fourths {
            flex: 0 0 75%;
            max-width: 75%;
        }

        &.five-eighths {
            flex: 0 0 62.5%;
            max-width: 62.5%;
        }

        &.three-eighths {
            flex: 0 0 37.5%;
            max-width: 37.5%;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 799px) {
    .columns:not(.break-mobile) {
        flex-direction: column;

        .column.column {
            flex: 0 1 auto;
            max-width: 100%;
        }
    }
}

@media (max-width: 559px) {
    .columns {
        flex-direction: column;
        margin-bottom: 0;

        .column.column {
            flex: 0 1 auto;
            max-width: 100%;
            padding-top: 0;
        }
    }
}
