
@use "client/css/globals" as *;

.tax-filing-summary {
    padding: 25px 30px;

    &.without-subtitle {
        display: flex;
        justify-content: space-between;
    }

    &.with-subtitle {
        display: grid;
        grid-template:
            "title      align-right" auto
            "subtitle   align-right" auto
            / auto 1fr;

        .tax-filing-align-right {
            align-items: center;
            display: flex;
            grid-area: align-right;
            justify-content: flex-end;
        }
    }
}

.last-edited {
    color: $contentColorTertiary;
    font-size: $fontSizeSmall;
    padding-right: 20px;
}

.filing-bottom {
    padding: 20px 30px;
}

.filing-todo {
    color: $red1;
}

.filing-complete {
    color: $green1;
}

.download-link {
    line-height: 0;
    margin-right: 30px;
}

.mail-link {
    color: $brandColorPrimary;
}

ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ol li {
    margin-bottom: 5px;
    position: relative; // Anchor step line positioning calculation
}

ol li:last-child {
    margin-bottom: 0;
}

ol li::after {
    border-left: 2px solid $borderColorLight;
    content: "";
    height: 13px;
    left: 7px;
    position: absolute;
    top: 18px;
    width: 2px;
}

ol li:last-child::after {
    border: 0;
}

ol li.with-dot::after {
    // Adjust the line for the smaller dot.
    height: 18px;
    top: 16px;
}

.filing-check {
    bottom: 2px;
    margin-right: 15px;
    position: relative;
    right: 2px;
}

.filing-check-container {
    display: flex;
}

.dot {
    border: 2px solid $pilotText;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-left: 3px;
    margin-right: 26px;
    width: 10px;
}

.filing-processing-done {
    color: $pilotText;
}

.filing-processing-pending {
    color: $contentColorSecondary;

    .dot {
        border-color: $borderColorLight;
    }
}
