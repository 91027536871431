
@use "client/css/globals" as *;

.header {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 45px;
}

.add-button {
    align-items: center;
    display: grid;
    grid-gap: 8px;
    grid-template: 22px / auto 1fr;
}

.empty-state {
    align-items: center;
    display: flex;
    font-weight: 600;
    height: 400px;
    justify-content: center;
}

.template-account-row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px 30px;

    .template-account-type {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }
}

.checkbox-and-label {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr;

    ::v-deep .pilot-checkbox {
        top: 0;
    }
}

.nested-question {
    margin-left: 30px;
}

.optional-text {
    color: $contentColorSecondary;
}

::v-deep .modal-body {
    padding-bottom: 0;
}

.form-field {
    margin-bottom: 20px;
}
