
@use "client/css/globals" as *;

.button {
    background: transparent;
    border: transparent;
    outline: none;
    padding: 0;
}

.nav-items {
    list-style-type: none;
    margin: 0;
    margin-left: 100px;
    padding: 0;
}

.nav-item {
    color: $brandColorPrimaryContrast;
    display: block;
    font-size: $fontSizeMedium;
    margin-bottom: $spacingMedium;
    opacity: 0.7;

    &:hover {
        cursor: pointer;
        opacity: 1;
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.active {
        font-weight: bold;
        opacity: 1;
        position: relative;

        &::before {
            background-color: $activeGreen;
            border-radius: $borderRadiusSmall;
            content: "";
            display: block;
            height: $fontSizeMedium * 2;
            left: -15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
        }
    }

    &.disabled {
        cursor: default;

        &:hover {
            opacity: 0.7;
        }
    }

    .subtitle {
        display: block;
        font-size: $fontSizeSmall;
    }
}
