
p {
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
}

.add-user-to-customer-wrapper {
    padding-bottom: 80px;
}
