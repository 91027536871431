
@use "client/css/globals" as *;

$atRiskTrackerWidth: 520px;

#category-reason-tracker {
    $atRiskTrackerBaseDimension: 16px;

    align-items: center;
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    box-sizing: border-box;
    display: flex;
    height: $atRiskTrackerBaseDimension * 4;
    justify-content: space-between;
    padding: $atRiskTrackerBaseDimension;
    padding-right: $atRiskTrackerBaseDimension * 2;
    position: relative;
    width: $atRiskTrackerWidth;

    .at-risk-tracker-title {
        flex: 1 1 auto;
        padding-left: 10px;
    }

    .at-risk-tracker-category {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .at-risk-tracker-reason {
        color: $contentColorSecondary;
        font-size: $fontSizeBase;
    }

    .loading-dots {
        padding-left: 115px;
    }
}
