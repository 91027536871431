
@use "client/css/globals" as *;

.checklist-done-replay {
    background: $backgroundColorSecondary;
    padding: $spacingLarge;
    text-align: center;

    h2 {
        margin-bottom: $spacingLarge;
    }
}
