
@use "client/css/globals" as *;

.instructions {
    max-width: 60em; // arbitrary line-length
    padding-right: 2em;
}

.validation-desc {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: right;
    padding-right: $spacingMedium;
}

.trouser-adjustments {
    // $spacingLarger = height of overlain trouser cuff
    margin-bottom: $spacingLarger + $spacingMedium;
}

.trouser-header {
    margin: 0;
}

.trouser-subheader {
    margin-top: $spacingMedium;
}
