
@use "client/css/globals" as *;

section {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    margin-bottom: 30px;
    padding: 15px 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

h3 {
    margin-bottom: 0;
}
