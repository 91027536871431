
@use "client/css/globals" as *;

td {
    max-width: $fieldWidthBase;
    padding: $spacingMediumSmall $spacingSmall;
    vertical-align: top;
}

.comment-column {
    max-width: $fieldWidthWide;
}

.rule-card {
    margin-top: $spacingSmall;
}

.comment-thread {
    margin-bottom: 0;
    margin-top: $spacingSmall;
}

.txn-errors {
    margin-top: $spacingSmall;
}

.make-rule-column {
    // kept in sync with .pending-txn-primary-action in categorize.scss
    width: 160px;
}
