@forward "colors";
@use "colors";

// Border radius ramp
$borderRadiusSmall: 3px;
$borderRadiusMedium: 4px;
$borderRadiusLarge: 5px;

// Box shadows
$flyoutShadow: 0 2px 8px rgba(0, 0, 0, 0.25);
$purpleShadow: 0 2px 8px 0 rgba(colors.$brandColorPrimaryLight, 0.1), 0 0 3px 0 colors.$pilotPurple;

// Font size ramp
$fontSizeMicro: 12px;
$fontSizeSmall: 13px;
$fontSizeBase: 15px;
$fontSizeMedium: 18px;
$fontSizeLarge: 24px;
$fontSizeLarger: 32px;
$fontSizeHuge: 48px;
$defaultLineHeightScalar: 1.5;

// Spacing ramp
$spacingSmallest: 2px;
$spacingSmaller: 6px;
$spacingSmall: 10px;
$spacingMediumSmall: 15px;
$spacingMedium: 20px;
$spacingMediumLarge: 30px;
$spacingLarge: 40px;
$spacingLarger: 80px;
$spacingHuge: 120px;
$spacingMassive: 180px;
$spacingSuperMassive: 240px;

// Z-index ramp
$zIndexBase: 1;
$zIndexFlyout: 100;
$zIndexNav: 200;
$zIndexSecondaryView: 300;
$zIndexDrawer: 400;
$zIndexModal: 500;
$zIndexSpeedbump: 600;
$zIndexToast: 700;

// Misc sizing
$pageMargin: $spacingLarge;
$pageMarginMobile: $spacingMedium;
$pagePaddingTop: $spacingMedium;
$pagePaddingBottom: $spacingMediumLarge;
$pageWidthMax: 1440px;
$pageHeaderDefaultHeight: 110px;
$pageHeaderHasSecondaryNavHeight: 150px;
$topForStickyPosition: $pageHeaderHasSecondaryNavHeight + $spacingMedium;
$nonAdminTitleHeight: 34px;
$nonAdminHeaderHeight: 144px;
$expandedNavWidth: 240px;
$collapsedNavWidth: 70px;
$collapseNavBreakpoint: 2000px;
$inputPaddingTop: 9px;
$inputPaddingBottom: 10px;
$inputPaddingHorizontal: 12px;
$fieldWidthBase: 480px;
$fieldWidthWide: 720px;
$fieldHeight: 71px;
$fieldValueHeight: 40px;
$defaultButtonHeight: $fieldValueHeight;
$flowbotItemPadding: 6px;
$flowbotTaskMaxWidth: 920px;
$flowbotTaskLeftGutterWidth: 160px;
$flowbotTaskRightGutterWidth: 400px;
$flowbotCheckboxWidth: 32px;
$flowbotLineMaxWidth: $flowbotTaskMaxWidth + $flowbotTaskRightGutterWidth +
    $flowbotTaskLeftGutterWidth + $flowbotCheckboxWidth;
$primaryNavAdjacentBoxShadow: inset 2px 0 0 rgba(66, 61, 113, 0.06);
$pilotBackgroundTopHeight: 270px;
$adminPageHeaderWithSecondaryNavAndPaddingHeight: $pagePaddingTop + $spacingMediumLarge +
    $pageHeaderDefaultHeight + $defaultButtonHeight + $spacingMedium + $spacingMediumLarge +
    $spacingMediumLarge + $spacingMediumSmall;
$pagePaddingBottom: $spacingMediumLarge;
$userAvatarSize: 34px;

// Mixins
@mixin checkmark(
    $offset: (
        -$spacingMedium,
    )
) {
    position: relative;

    &::before,
    &::after {
        background-color: colors.$green1;
        bottom: calc(100% - 16px);
        content: "";
        display: block;
        left: $offset;
        position: absolute;
        width: 3px;
    }

    &::before {
        height: 8px;
        margin-left: -4px;
        transform: rotate(-45deg);
    }

    &::after {
        height: 14px;
        margin-left: 3px;
        transform: rotate(45deg);
    }
}

$modalAppearDuration: 0.25s;

@mixin modalOverlay() {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all $modalAppearDuration;
}

@mixin modalContent() {
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
    color: colors.$contentColorPrimary;
    position: fixed;
    transition: all $modalAppearDuration;
}

@mixin nonselectable() {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
