
@use "client/css/globals" as *;

.container {
    margin-bottom: 30px;
    text-align: center;
}

.heading,
.subheading {
    margin: 0;
}

.heading {
    font-size: $fontSizeLarge;
    line-height: 30px;

    &.hasSubheading {
        margin-bottom: 5px;
    }
}

.subheading {
    font-size: $fontSizeBase;
    line-height: 19px;
}
