@use "client/css/globals" as *;

$collapsedHeaderColumnWidth: 150px;
$collapsedLayoutBreakpoint: 965px;

//  GENERAL TABLE STYLES

// display cells horizontally; separate them lines and space them out
@mixin table-row-separation {
    padding: $spacingMedium 0;

    // remove leading spacing of first row
    &:first-of-type {
        padding-top: 0;
    }

    // starting with the second row, separate rows with a line
    &:nth-of-type(n + 2) {
        border-top: 1px solid $borderColorLight;
    }
}

@mixin table-header-font {
    color: $contentColorSecondary;
    display: none; // display depends on expanded/contracted mode
    font-weight: bold;
}

@mixin table-column-spacing {
    padding: 0 $spacingSmall;
}

@mixin expanded-row-layout {
    display: flex;
}

@mixin expanded-table-header {
    border-bottom: 1px solid $borderColorLight;
    box-sizing: content-box;
    display: block;
    height: $fontSizeBase * $defaultLineHeightScalar; // give height to blank overflow header
    margin-bottom: $spacingMedium; // space between heading separator and content
    padding-bottom: $spacingSmall; // space between heading and separator
}

@mixin collapsed-table-header {
    display: flex;
    flex: 0 0 $collapsedHeaderColumnWidth;
    font-size: $fontSizeSmall;
    line-height: $fontSizeBase * $defaultLineHeightScalar; // match line-height of regular content
}

@mixin collapsed-table-cell {
    display: flex;
    padding-bottom: $spacingSmaller;
}

//  PORTAL TRANSACTION SPECIFIC

@mixin expanded-date-column-sizing {
    flex: 0 0 110px;
}

@mixin expanded-desc-column-sizing {
    flex: 1 0 150px;
}

@mixin expanded-amount-column-sizing {
    flex: 0 0 110px;
}

@mixin expanded-input-column-sizing {
    flex: 2 0 200px;
}

@mixin expanded-overflow-column-sizing {
    flex: 0 0 60px;
}

@mixin secondary-text-font {
    color: $contentColorTertiary;
    font-size: $fontSizeSmall;
    line-height: 1.3; // reduce spacing between lines for better grouping (body default is 1.5)
    margin-top: $spacingSmaller;
}
