
@use "client/css/globals" as *;

.info-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    svg {
        margin-bottom: 10px;
    }
}

.card {
    margin-bottom: 30px;
}

.card-header {
    border-bottom: 1px solid $borderColorLight;
    padding: 15px 30px;
}

.card-section {
    border-bottom: 1px solid $borderColorLight;
    padding: 15px 25px;
}

.duration-setting {
    margin-right: 10px;
    max-width: 30%;
    width: fit-content;
}

.prerequisites-list {
    margin-bottom: 20px;
}

.products-header {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto auto auto;
}

.bulk-actions {
    padding-bottom: 10px;
    padding-top: 40px;

    .bulk-action-button {
        margin-right: 20px;
    }

    .bulk-account {
        margin-top: -10px;
    }
}

.collapsible-card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
}

.tt-content {
    min-width: 300px;
}

.section-title {
    min-width: 115px;
}

.icon-align {
    vertical-align: middle;
}

.clear-button {
    background: none;
    border: none;
}
