
@use "client/css/globals" as *;

.container {
    display: grid;
    gap: 30px;
    grid-template-rows: auto;

    .sender-selector-wrapper {
        display: flex;
        justify-content: flex-start;
    }

    .from-text {
        align-content: center;
        font-weight: 600;
        margin-bottom: 15px;
        margin-left: 30px;
        margin-top: 15px;
    }

    .sender-selector {
        margin-bottom: 15px;
        margin-left: 10px;
        margin-top: 15px;
    }
}

.table-wrapper {
    background: $brandColorPrimaryContrast;
    border-radius: $borderRadiusLarge;
}

.wrapper {
    min-height: 5px; // adding some height allows dragging to an empty table
}

.header {
    display: flex;
    justify-content: space-between;

    .title {
        font-weight: 600;
        margin: 15px 30px;
    }

    .styling {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
        margin: 15px 30px;
    }

    .markdown {
        color: $pilotPurple;
    }
}

ul {
    margin: 0;
    padding-inline-start: 0;
}

li {
    list-style-type: none;

    /**
     * I don't want a double border from the `PilotCard::separatedRows` prop. I do want to
     * continue render these in a `ul` because that is semantically correct. To solve for both
     * cases, just remove the last border.
     */
    &:not(:last-of-type) {
        border-bottom: 1px solid $pastelViolet;
    }
}

.add-question {
    align-items: center;
    color: $pilotPurple;
    display: flex;
    margin: 15px 30px;

    .icon {
        align-items: center;
        display: flex;
        margin-right: 10px;
    }
}

.preview-questions-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
