
@use "client/css/table";

.transaction-list-container {
    // prevent table contents bleed outside the container
    display: table;
}

::v-deep .search-icon-wrapper {
    margin-bottom: 15px;

    .search-input {
        // set the search bar width to the transactions table width
        width: 840px;
    }
}
