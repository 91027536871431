
@use "client/css/globals" as *;
@use "client/components/css/mixins";

.pilot-table-row {
    @include mixins.grid-table-row;

    &.clickable-row {
        cursor: pointer;

        &:hover td.pilot-table-row-cell {
            background: $backgroundTonalGrey;
        }
    }
}

.full-row ::v-deep > * {
    grid-column: 1/-1;
}
