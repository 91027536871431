
@use "client/css/globals" as *;

#residual-balances-wizard {
    background-color: $backgroundColorPrimary;
    display: grid;
    grid-template-columns: 360px 1fr;
    min-height: 560px;

    .side {
        height: 100%;
    }

    .left-side {
        background-color: $lightGrey;
    }

    .right-side {
        padding: 110px 40px;
    }

    .wizard-title {
        padding: 30px 30px 20px;
    }

    .lets-get-started {
        padding: 0 20px;

        > * {
            margin-bottom: 20px;
        }
    }

    .current-account {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        gap: 3rem;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .debit-vs-credit-tooltip {
        min-width: 275px;
    }

    .SQIP-button,
    .make-je-button {
        margin-bottom: 15px;
    }

    .SQIP-preview {
        color: $brandColorPrimary;
        text-decoration: underline;
    }

    .je-success {
        padding-left: 5px;
    }
}
