
@use "client/css/globals" as *;

::v-deep .drawer-content {
    padding: 0;
}

::v-deep .drawer-header {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 40px;
}

.drawer-header-text {
    line-height: 48px;
    top: 0;
}

::v-deep .container-mayday {
    display: none;
}

.request-details-container {
    border-top: 1px solid $borderColorLight;
    padding: 25px 40px;
}

.request-details-text {
    margin-bottom: 30px;

    & > p {
        margin-bottom: 0;
    }
}

.request-details-form {
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    margin-bottom: 30px;
}

.current-details {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.current-entity-type,
.current-fiscal-year {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > p {
        margin-bottom: 0;
    }
}

.change-request {
    border-bottom: 1px solid $borderColorLight;
    padding: 20px;

    &:last-of-type {
        border-bottom: none;
    }
}

.request-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    & > p {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.required-text {
    color: $contentColorSecondary;
    font-size: $fontSizeMicro;
    line-height: 24px;
    margin-bottom: 0;
}

.request-response,
.tax-periods-card {
    margin-bottom: 30px;
}

.btn-container {
    align-items: center;
    background-color: $brandColorPrimaryContrast;
    border-top: 1px solid $borderColorLight;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 20px 40px;
    position: sticky;
    width: 100%;
}
