
#pilot-chart-of-accounts {
    table-layout: auto; // Fixed layout and specified widths breaks copying to excel

    pre {
        font-family: Euclid, sans-serif;
        margin: 0;
    }
}

.pilot-chart-of-accounts-subtype {
    word-break: break-word;
}

.pilot-chart-of-accounts-new-type-row {
    border-top: none;
}
