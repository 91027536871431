
@use "client/css/globals" as *;

#customer-flags {
    margin-top: $spacingMedium;

    .field > label {
        flex-basis: 12em;
    }
}
