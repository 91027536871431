
@use "client/css/globals" as *;

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > h1 {
        font-size: $fontSizeMedium;
        font-weight: bold;
        margin: 0; // remove global styling
    }

    > *:not(:first-child) {
        margin-left: 20px;
    }

    // SMALL_SCREEN_MAX_WIDTH
    @media (max-width: 965px) {
        flex-flow: column-reverse nowrap;

        > h1 {
            align-self: flex-start;
        }

        > *:not(:first-child) {
            align-self: flex-end;
        }
    }
}
