
@use "client/css/globals" as *;

.modal-body {
    width: 100%;
}

.buttons {
    justify-content: flex-end;
    margin-top: $spacingMediumLarge;
}

.button {
    padding-left: $spacingLarge;
    padding-right: $spacingLarge;
}

.header-text {
    margin: 0;
}
