
@use "client/css/globals" as *;

.home-onboarding-card:not(:last-child) {
    margin-bottom: $spacingMediumSmall;
}

.loading-error-container {
    width: 100%;
}

// to override the global section margin
.no-margin {
    margin-bottom: 0;
}
