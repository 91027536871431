
@use "client/css/globals" as *;

$borderRadius: 5px;
$padding: 15px 30px;
$solidGrayBorder: 1px solid $pastelViolet;

.link-list-header-container,
.list-row {
    display: flex;
    justify-content: space-between;
}

.link-list-header-container {
    padding: $padding;
}

.add-link-container {
    align-items: center;
    display: flex;
}

.icon-circle {
    margin-right: 10px;
}

p,
ul,
.with-inline-editor {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
    text-align: left;
}

ul {
    box-sizing: border-box;
    width: 100%;
}

.no-inline-editor {
    border-bottom: $solidGrayBorder;
    border-radius: $borderRadius;
}

li {
    padding: $padding;

    &:last-child {
        border-bottom: none;
    }
}

.with-inline-editor .edit-inline-editor-container {
    border-bottom: $solidGrayBorder;
    border-radius: $borderRadius;
    border-top: $solidGrayBorder;
    padding: $padding;
}

.inline-editor-container {
    border-bottom: $solidGrayBorder;
    border-radius: $borderRadius;
}

.with-inline-editor .list-row {
    padding: $padding;
}

.link-display-name {
    word-wrap: anywhere;
}

.input-fields {
    margin-bottom: 10px;
    width: 100%;
}

.link-list-card {
    margin-bottom: 15px;
}

::v-deep .small-text {
    margin-bottom: 10px;
    margin-top: 0;
}

::v-deep a.base-button {
    margin: 0;
    text-align: left;
}
