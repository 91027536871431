
@use "client/css/globals" as *;

.link {
    color: $linkColorSecondary;
    text-decoration: underline;
}

fieldset {
    // reset global styles
    border: 0;
    margin: 0;
    padding: 0;
}

.question {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px; // 30px from the paragraph, which by default has bottom margin 20px
}
