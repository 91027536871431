
@use "client/css/globals" as *;

.plaid-auth {
    padding-top: 30px;
}

.connection-fields-modal ::v-deep .institution-fields {
    // override display: flex so all input types span full width
    display: block;
}

section {
    // override global styling
    margin-bottom: 0;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

.connections-header,
.credential-section {
    margin-bottom: $spacingMediumLarge;
}

h2 {
    font-size: 18px;
    margin: 0;
}

h2,
h3 {
    padding: 20px 30px;
}

h3 {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
}

hr {
    margin: 0;
}

.onboarding-need-help-section {
    margin-bottom: $spacingMediumLarge;
    margin-top: 30px;
}

.footer-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: $spacingMediumLarge;
}

.padding-style {
    padding: 30px;
}

.instruction {
    margin-bottom: 0;
    padding: 20px 30px 0;
    word-wrap: anywhere;
}
