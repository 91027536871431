
@use "client/css/globals" as *;
@use "client/modules/portal-activity/css/cash-flow-and-spend-report-table";

.bold {
    font-weight: bold;
}

.header-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

::v-deep .tabs {
    padding-left: 30px;
}

::v-deep .tab {
    font-weight: bold;
}
