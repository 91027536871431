
@use "client/css/globals" as *;

$headerHeight: 80px;

.trouser {
    background-color: $backgroundColorPrimary;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: ($headerHeight + $spacingMedium) $spacingMedium;
    position: fixed;
    right: 0;
    text-align: left;
    top: 0;
    transition: all 0.4s;
    z-index: $zIndexSecondaryView;

    > h1:first-child,
    > h2:first-child {
        margin: 0 0 $spacingMedium;
        padding: 0;
        text-align: left;
    }
}

.trouser-enter,
.trouser-leave-active {
    transform: translateY(100%);
}

.trouser-header {
    align-items: center;
    background: $backgroundColorTertiary;
    box-shadow: 0 16px 24px rgba(14, 13, 18, 0.1);
    display: flex;
    height: $headerHeight;
    left: 0;
    padding: $spacingMedium;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $zIndexSecondaryView + 1;

    .report-issue-icon {
        top: -3px; // fix positioning for vertical alignment
    }
}

.trouser-cuff {
    background: $backgroundColorTertiary;
    bottom: 0;
    box-shadow: 0 -8px 24px rgba(14, 13, 18, 0.1);
    display: flex;
    height: $headerHeight;
    left: 0;
    padding: $spacingMedium;
    position: fixed;
    right: 0;
    z-index: $zIndexSecondaryView + 1;
}

.trouser-cuff-right {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    margin-left: auto;
}

.trouser.alt {
    background-color: $backgroundColorSecondary;
    padding-top: $spacingLarger;

    .trouser-header {
        background-color: $backgroundColorPrimary;
    }

    .trouser-header,
    .trouser-cuff {
        box-shadow: none;
    }
}

.skyways {
    .trouser-header {
        background: $backgroundColorPrimary;
    }

    .trouser-header,
    .trouser-cuff {
        box-shadow: none;
    }
}
