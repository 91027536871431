
@use "sass:color";
@use "client/css/globals" as *;
@use "client/css/table";
@use "client/modules/customer-transactions/css/transaction-tables";

.match-obligation-table {
    overflow: auto;
    padding: $spacingMedium 0;
}

.match-detail-type {
    font-weight: bold;
    margin-bottom: 0;
}

.ap-ar-match-recommendation {
    color: $pilotPurple;
    font-size: $fontSizeSmall;
    font-weight: bold;
    padding: $spacingSmall 0 0 $spacingSmall;
    position: absolute;
}

.ap-ar-match-header {
    @include transaction-tables.table-header-font;
    @include transaction-tables.expanded-row-layout;

    border-bottom: 1px solid $borderColorLight;

    .cell-content {
        height: 100%;
    }

    .ap-ar-match-status {
        padding-left: $spacingMediumLarge;
    }

    .entity-highlight {
        background: color.adjust($yellow1Background, $alpha: -0.5);
    }

    .ap-ar-match-obligation-amount {
        background: color.adjust($green1Background, $alpha: -0.5);
    }
}

.cell-content {
    padding: $spacingMedium $spacingSmaller;
}

.expanded-row .cell-content {
    padding-bottom: $spacingMediumLarge;
    padding-top: $spacingMediumLarge;
}

.ap-ar-match-row {
    @include transaction-tables.expanded-row-layout;

    border-bottom: 1px solid $borderColorLight;
    padding: 0;
    position: relative;

    &:last-child {
        border: none;
    }
}

.ap-ar-match-status {
    flex: 2 0 150px;

    .cell-content {
        align-items: center;
        display: grid;
        gap: $spacingSmall;
        grid-template-columns: $spacingMedium auto;
    }
}

.ap-ar-match-payment-date {
    flex: 1 0 100px;
}

.ap-ar-match-obligation-date {
    flex: 1 0 100px;
}

.ap-ar-match-obligation-number {
    flex: 1 0 100px;
}

.ap-ar-match-obligation-entity {
    flex: 2 0 220px;
    font-weight: bold;
}

.entity-highlight {
    background: $yellow1Background;
    height: 100%;
}

.ap-ar-match-obligation-amount {
    background: $green1Background;
    flex: 1 0 120px;
    font-weight: bold;
    text-align: right;
}
