
@use "client/css/globals" as *;

.about-tab-nav {
    padding-left: 30px;
    padding-right: 30px;

    button {
        background: transparent;
        border: none;

        // copied from admin-secondary-nav
        border-bottom: 2px solid transparent;
        color: $contentColorPrimary;
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        margin-right: 30px;
        padding-bottom: 15px;

        &.active,
        &:hover {
            border-bottom-color: $brandColorPrimary;
            color: $brandColorPrimary;
            text-decoration: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

hr {
    margin: 0;
}

.about-tab-content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
}
