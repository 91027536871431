
@use "client/css/globals" as *;

// TODO: pull styles that are shared with category dropdown into a mixin

.dropdown {
    background: inherit;
    border-radius: $borderRadiusLarge;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: visible;
    width: 520px;

    .reason-list {
        background: $backgroundColorPrimary;
        border-bottom-left-radius: $borderRadiusLarge;
        border-bottom-right-radius: $borderRadiusLarge;

        li {
            cursor: pointer;
        }
    }

    .reason-name,
    .reason-header {
        align-items: center;
        display: flex;
        font-size: $fontSizeBase;
        justify-content: space-between;
        padding: 10px 15px;
        position: relative;

        .item-action-text {
            color: $brandColorPrimary;
            white-space: nowrap;
        }

        // hides the radio buttons
        input {
            opacity: 0;
            pointer-events: none;
            position: fixed;
        }

        .name {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 24px; // height of IconInformationCircle
        }
    }

    .reason-header {
        color: $brandColorPrimary;
        font-weight: bold;
        padding-top: 15px;
    }

    .pre-selected span {
        background: $backgroundColorSecondary;
    }

    .back-to-reasons {
        border-bottom: 1px solid $borderColorLight;
        justify-content: flex-start;

        .back-icon {
            padding-right: 10px;
        }
    }
}
