
.interaction-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
}

.label-text {
    margin-right: 10px;
}

.filter {
    margin-left: 20px;
}

// make the category filter wider so the options are more readable
::v-deep .filter .multiselect {
    min-width: 350px;
    width: auto;
}

::v-deep .tabs {
    font-weight: bold;
    padding: 20px 30px 0;
}
