
@use "client/css/globals" as *;

.new-text {
    color: $pilotPurple;
    font-weight: bold;
}

.form-link,
.mail-link {
    margin-top: $spacingMedium;
}

.card-info {
    padding: 25px;
}
