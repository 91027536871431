
@use "client/css/globals" as *;

::v-deep .tooltip-content {
    width: 172px;
}

.status-tag {
    align-items: center;
    background: $pastelViolet;
    border-radius: 20px;
    color: $pilotText;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4px 10px;
    width: fit-content;

    &.complete {
        background: $green1Background;
        color: $green1Dark;
    }

    &.in-progress {
        background: $pastelViolet;
        color: $pilotText;
    }

    &.onboarding {
        background: $lightGrey;
        color: $darkGrey;
    }

    &.due-soon {
        background-color: $yellow1Background;
        color: $yellow1Text;
    }

    &.overdue {
        background-color: $red1Background;
        color: $red1;
    }

    &.cancelled {
        background-color: $backgroundColorPrimary;
        color: $darkGrey;
    }
}

.tooltip-label {
    margin: 0;
}
