
@use "client/css/globals" as *;

.reports-container {
    font-size: $fontSizeBase;

    .header {
        font-weight: 600;
    }
}

.report-row-top {
    align-items: center;
    border-bottom: 1px solid $pastelViolet;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 15px;
}

.report-row {
    align-items: center;
    border-bottom: 1px solid $pastelViolet;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}

.report-row-bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;

    a {
        color: $pilotPurple;
    }
}

.status-tag {
    margin: 0;
}

.status-w-eye {
    align-items: center;
    display: flex;
}

.status-dropdown {
    width: 170px;
}

.eye {
    height: 24px;
    margin-left: 5px;
    width: 24px;
}

.small-text {
    align-self: center;
    color: $offBlack;
    font-size: $fontSizeSmall;
}

.report-page-link-container {
    display: flex;
    justify-content: center;
}

.link-text {
    align-self: center;
    font-size: $fontSizeBase;
}

.button-icon {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    padding: 0;
}
