
@use "client/css/globals" as *;

.notification-badge {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    line-height: 0px;

    &--info {
        background-color: $highlightPurple;
        color: $pilotPurple;
    }

    &--warning {
        background-color: $red1;
        color: $brandColorPrimaryContrast;
    }
}
