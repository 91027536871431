
@use "client/css/globals" as *;

.wrapper {
    // interrupt margin collapse
    border: 1px solid transparent;
    position: relative;
    width: fit-content;

    &.wide {
        width: 100%;
    }
}

.hidden {
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
}

.editable {
    align-items: center;
    background: none;
    border: none;
    border-radius: 4px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: absolute;
    text-align: initial;
    top: 0;
    width: 100%;

    .pencil {
        opacity: 0;
    }

    &:hover,
    &:focus {
        background: $pastelUltramarine;
        cursor: pointer;

        .pencil {
            color: $contentColorSecondary;
            opacity: 1;
        }
    }
}
