
@use "client/css/globals" as *;

::v-deep .bubble-down {
    left: 25px;
    min-width: 312px;

    .tooltip-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .tooltip-label {
        margin: 0;
    }
}

.status-tag {
    align-items: center;
    background-color: $yellow1Background;
    border-radius: 20px;
    color: $yellow1Text;
    display: flex;
    font-size: $fontSizeSmall;
    justify-content: center;
    padding: 4px 10px;
}
