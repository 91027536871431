
@use "client/css/globals" as *;

.perma-dismissable-checkbox {
    align-items: center;
    display: flex;
    gap: $spacingSmaller;
}

.confirmation-input {
    margin-top: $spacingMediumSmall;
}

h3 {
    margin-bottom: 0;
}
