
@use "client/css/globals" as *;

p:last-child {
    margin: 0; // reset global style
}

.section-action {
    margin-top: $spacingMediumSmall;
}

.welcome-card {
    align-items: start;
    display: flex;
    flex-direction: row;

    .welcome-text {
        flex: 1 1 200px;
    }

    .welcome-graphic {
        flex: 0 0 auto;
        margin-left: $spacingMedium;

        @media (max-width: 1120px) {
            display: none;
        }
    }
}

.document-list-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacingMedium;

    .document-list-item {
        align-items: center;
        border: 1px solid $borderColorLight;
        border-bottom: none;
        display: flex;
        padding: $spacingMediumSmall $spacingMedium;

        &:last-child {
            border-bottom: 1px solid $borderColorLight;
        }

        .document-item-icon {
            margin-right: $spacingSmall;
        }
    }
}
