
@use "client/css/globals" as *;

.existing-reconciliation-set {
    tr {
        border-color: $backgroundColorPrimary;
    }

    td {
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;

        &:not(.tick-and-tie-x, .existing-reconciliation-set-cell-undo) {
            background-color: $backgroundColorSecondary;
        }
    }

    .existing-reconciliation-set-cell-undo {
        background-color: $backgroundColorPrimary;
        padding: 0 $spacingMediumLarge;
    }

    .existing-reconcilable-memo,
    .existing-reconcilable-vendor {
        word-break: break-word;
    }
}

.button-tooltip {
    width: 100%;
}

.match-not-undoable-warning {
    width: 175px;
}
