
@use "client/css/globals" as *;

// TODO: pull styles that are shared with support dropdown into a mixin

$inputBoxHeight: 81px;
$headerHeight: 38px;
$dropdownListItemHeight: $fieldValueHeight;
$dropdownMaxItemCount: 6;
$listItemHeight: $fieldValueHeight + 2;
$otherItemHeight: 45px;
$otherItemCount: 1;
$dropdownMaxHeight: $listItemHeight * $dropdownMaxItemCount + $otherItemHeight * $otherItemCount +
    $inputBoxHeight + $headerHeight;
$fixedHeaderHeight: $inputBoxHeight + $headerHeight;

.dropdown {
    background: inherit;
    border-radius: $borderRadiusLarge;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-height: $dropdownMaxHeight;
    width: 385px;

    .input-wrapper {
        border-bottom: 1px solid $borderColorLight;
        padding: 20px;
    }

    .customer-list {
        border-bottom-left-radius: $borderRadiusLarge;
        border-bottom-right-radius: $borderRadiusLarge;

        li {
            cursor: pointer;
        }
    }

    .customer-name,
    .customer-header {
        align-items: center;
        display: flex;
        font-size: $fontSizeBase;
        justify-content: space-between;
        padding: 10px 15px;
        position: relative;

        .item-action-text {
            color: $brandColorPrimary;
        }

        .item-description-text {
            color: $contentColorTertiary;
            font-style: italic;
        }

        .name-and-tooltip {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 24px; // height of IconInformationCircle

            ::v-deep .tooltip-container {
                height: 24px;
                margin-right: 5px;
            }

            ::v-deep .tooltip-content {
                max-width: 220px;
                width: max-content;
            }

            // $_safeRenderMarkdown wraps in <p> tag
            ::v-deep p {
                margin-bottom: 0;
            }
        }
    }

    .scrollable-center {
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 0; // for firefox compatibility
        overflow-y: auto;
    }

    .customer-header {
        color: $brandColorPrimary;
        font-weight: bold;
        padding-bottom: 6px;
    }

    .divider {
        border-top: 1px solid $borderColorLight;
        margin-top: 6px;
        padding: 3px 0;
        width: 100%;
    }

    .pre-selected span {
        background: $backgroundColorSecondary;
    }

    .no-customers {
        padding: 40px;
    }

    .no-filtered-customers {
        color: $contentColorSecondary;
        padding-bottom: 25px;
        padding-top: 25px;
        text-align: center;
    }

    .fixed-customer-header {
        flex: 0 0 auto;
        height: $fixedHeaderHeight;
        top: 0;
    }

    .fixed-footer {
        flex: 0 0 auto;
        height: $otherItemHeight;
    }

    .first-other-item {
        border-top: 1px solid $borderColorLight;
    }
}
