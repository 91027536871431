
@use "client/css/globals" as *;

.product-content-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    /*
        Will create a shared component in the future to handle the height
        of the header of the modal
    */
    min-height: 100px;
    padding: $spacingLarge $spacingLarge $spacingMedium $spacingLarge;

    h2 {
        color: $pilotText;
        font-size: $fontSizeLarger;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.product-content {
    flex: 1;
    overflow-y: auto;
    padding: $spacingMedium $spacingLarge $spacingLarge $spacingLarge;
}

.contact-us-form {
    column-gap: $spacingSmall;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .stretch {
        grid-column: 1 / 3;
    }
}

.label {
    font-weight: 600;
}

.buttons {
    margin-top: $spacingSmall;

    .button {
        min-width: 140px;
    }
}

.has-error {
    border-color: $red1Light;
}

.error-message {
    color: $red1;
    font-size: $fontSizeSmall;
}
