
@use "client/css/globals" as *;

.stripe-button-container {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.connect-with-stripe-button {
    cursor: pointer;
    width: 200px;
}

.spinner {
    flex: 100px;
    padding-left: 10px;
    top: 0;
}

.url-loading-error {
    background: $red1Background;
    border-radius: $borderRadiusMedium;
    color: $red1;
    margin-left: 10px;
    padding: 10px;
}
