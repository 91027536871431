
@use "client/css/globals" as *;

.table-wrapper {
    display: grid;
    grid-template-rows: auto;
}

.table-row {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 15px 30px;
}

.header {
    font-size: $fontSizeMedium;
    font-weight: 600;
}

.title {
    font-weight: 600;

    &.center {
        display: flex;
        margin-top: 5px; // align icon with content
    }
}

.hover-bubble {
    font-weight: 500;
}

.icon {
    margin-left: 5px;
}
