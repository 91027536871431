
@use "client/css/globals" as *;

.amortizations-filters {
    margin-bottom: $spacingMediumLarge;
}

.field.amortizations-filter-date {
    max-width: 160px;
}
