
// widths of date row elements
$dateRangeWidth: 185px;
$dateSelectWidth: 150px;
$toWidth: 15px;
$gridGap: 10px;

.date-row {
    align-items: flex-start;
    display: grid;
    grid-gap: $gridGap;
    grid-template-columns: $dateRangeWidth $dateSelectWidth $toWidth $dateSelectWidth;

    span {
        padding-top: 30px;
    }
}
