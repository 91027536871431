
@use "client/css/globals" as *;

.txn-description-cell {
    display: block;
}

.dot {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
    width: 10px;
}

a {
    color: $pilotPurple;
    font-size: $fontSizeSmall;
}
