
.input-wrapper {
    position: relative;
}

.capitalization-indent {
    padding-left: 45px;
}

.currency-symbol {
    left: 10px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
}

.fx-help-text {
    display: block;
    margin-top: 5px;
    opacity: 0.6;
}
