
@use "client/css/globals" as *;

#customer-categorize {
    section {
        margin: $spacingMediumLarge 0;
    }

    .notification-banner {
        padding: $spacingLarge;

        p {
            color: $contentColorSecondary;
        }
    }
}

#start-date,
#end-date {
    width: 120px;
}

.rule-card-borderless ::v-deep .automagical-rule-card {
    border: none;
}

.rules-count {
    display: grid;
    margin-right: $spacingMedium;
    place-items: center;
}
