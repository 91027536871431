
@use "client/css/globals" as *;

.tnt-comment-review-row {
    border-top: none;
}

td {
    vertical-align: top;
}

.read-only-comment {
    background: $lightGrey;
    border: 1px solid $borderColorLight;
    border-radius: 3px;
    padding: $spacingSmall;
}

input {
    width: 100%;
}
