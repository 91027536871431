
@use "client/css/globals" as *;

table {
    display: flex;
    flex-direction: column;
}

thead {
    border-bottom: 1px solid $borderColorLight;
    padding: 15px 30px;

    tr {
        border: 0;
    }
}

tr {
    display: flex;
}

th {
    padding: 0; // padding controlled by children
    vertical-align: top;

    &:nth-child(2) {
        padding-left: 30px;
    }

    &:nth-child(3) {
        padding-left: 30px;
    }
}

td {
    padding: 25px;
    vertical-align: top;

    &:first-child {
        padding-left: 30px;
    }

    &:nth-child(2) {
        padding-left: 0;
    }

    &:nth-child(3) {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 30px;
    }
}

.table-summary {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 30px 0;

    .table-title {
        font-size: $fontSizeMedium;
        font-weight: bold;
        text-align: left;
    }

    .table-extras {
        align-items: center;
        display: flex;

        p {
            margin: 0; // reset global style
        }

        .table-updated {
            color: $contentColorSecondary;
            font-size: $fontSizeSmall;
            text-align: right;
        }

        .download-btn {
            align-items: center;
            display: flex;
            margin-left: 2em;
        }
    }
}

.col-insights {
    flex: 1 1 auto;
    text-align: left;
}

.col-trend {
    flex: 0 0 150px;
    text-align: left;
}

.col-prev-month {
    flex: 0 0 100px;
    text-align: left;
}

.col-curr-month {
    flex: 0 0 130px;
    text-align: right;
}

.insights-list {
    color: $brandColorPrimary;
    list-style-type: circle;
    padding-left: 1em;

    .insight-text {
        color: $contentColorPrimary;
        font-size: 14px;
        padding-bottom: 0.35em;
    }
}

.category-tooltip {
    display: inline-block;
}

.row {
    &:hover {
        background: $backgroundTonalGrey;
        cursor: pointer;
    }
}
