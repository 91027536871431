.cash-and-spend-table-container {
    overflow: auto;
    position: relative;
    width: 100%;
}

.column-header.scrolled {
    left: 0;
    z-index: 1;

    &::after {
        bottom: 0;
        box-shadow: 4px 0 6px -1px rgba(40, 39, 39, 0.15);
        content: "";
        position: absolute;
        right: -1px;
        top: 0;
        width: 5px;
    }
}
