
@use "sass:color";
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

td {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 20px 0; // remove base style from _table.scss.
    width: 100%;

    ::v-deep .multiselect,
    .wrapper {
        width: 100%;
    }

    ::v-deep .multiselect {
        max-width: categorize.$categorizeInputWidth;
    }

    ::v-deep input::placeholder {
        color: $contentColorTertiary;
    }

    ::v-deep button.editable {
        color: $contentColorPrimary;
        font-weight: 600;
        width: 100%;
    }
}

.header-row {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: contents;
}

.informational-cell {
    align-items: flex-end;
    flex-direction: column;
    gap: 0;
}

.vendor-cell,
.category-cell {
    justify-content: flex-start;
}

.category-cell,
.informational-cell {
    padding-left: 10px;
    padding-right: 10px;
}

.vendor-cell {
    display: grid; // logo shrinks at narrow widths if using flex
    grid-template-columns: auto minmax(auto, categorize.$categorizeInputWidth);
    padding-left: 30px;
    padding-right: 10px;
}

.slot-cell {
    padding-left: 10px;
    padding-right: 30px;
}

.number-txns {
    color: $contentColorSecondary;
    font-size: 13px;
}

.total {
    font-weight: 600;
}

// styling comes from connections/client/components/veilable.vue
::v-deep .veilable-with-suggestion {
    align-items: center;
    background-color: $yellow1Background;
    border: none;
    border-radius: 4px;
    bottom: 0;
    color: $contentColorPrimary;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    max-width: categorize.$categorizeInputWidth;
    padding: 10px;
    position: absolute;
    text-align: initial;
    top: 0;
    width: 100%;

    .pencil {
        opacity: 0;
    }

    &:hover {
        background: $pastelUltramarine;
        cursor: pointer;

        .pencil {
            color: $contentColorSecondary;
            opacity: 1;
        }

        .icon-sparkle {
            display: none;
        }
    }
}

::v-deep .with-success {
    background-color: color.adjust($green1Background, $lightness: -10%);
}
