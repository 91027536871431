
@use "client/css/globals" as *;

.tag-icon-container {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.actions-overflow {
    text-align: left;

    &:hover {
        border: none;
    }
}

.actions-overflow-btn {
    border: none;
    color: $offBlack;
    padding: 8px 32px 8px 8px;
    text-align: left;

    &:hover {
        color: $offBlack;
    }
}

// TODO: remove as part of JS-811.
.pilot-tag.icon-checkmark {
    ::v-deep .tag-icon {
        margin-right: 5px;
    }
}
