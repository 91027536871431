@use "sass:color";
@forward "globals";
@use "globals" as *;
@forward "base";
@forward "cards";
@forward "grid";
@forward "shake";
@forward "spinners";
@forward "third-party";

@font-face {
    font-family: Euclid;
    src: url("../static/fonts/EuclidCircularB-Regular-WebXL.woff2") format("woff2"),
        url("../static/fonts/EuclidCircularB-Regular-WebXL.woff") format("woff");
}

@font-face {
    font-family: Euclid;
    font-weight: bold;
    src: url("../static/fonts/EuclidCircularB-Semibold-WebXL.woff2") format("woff2"),
        url("../static/fonts/EuclidCircularB-Semibold-WebXL.woff") format("woff");
}

section {
    margin-bottom: $spacingLarger;
}

.full-screen {
    // For lock-page-scroll directive
    overflow-y: scroll;

    #zapgram {
        position: fixed;
        width: 100%;
    }
}

.browser-not-supported {
    background-color: $yellow1;
    font-size: 24px;
    padding: $spacingLarger;
}

.notification-banner {
    background: $backgroundColorSecondary;
    margin: 0 0 $spacingMedium;
    padding: $spacingMedium;

    &.success {
        background: $green1Background;
    }

    &.warning {
        background: $yellow1Background;
    }

    &.error {
        background: $red1Background;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.list-success {
    list-style-type: none;

    @include checkmark;
}

.list-warning {
    list-style-type: none;
    position: relative;

    &::before,
    &::after {
        border-style: solid;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &::before {
        border-color: transparent transparent color.adjust($yellow1, $lightness: -25%);
        border-width: 0 8px 12px;
        left: -($spacingMedium) - 4px;
        top: 4px;
    }

    &::after {
        border-color: transparent transparent $backgroundColorPrimary;
        border-width: 0 4px 6px;
        left: -($spacingMedium);
        top: 8px;
    }
}

.list-error {
    list-style-type: none;
    position: relative;

    &::before,
    &::after {
        background-color: $red1;
        content: "";
        display: block;
        left: -($spacingMedium) + 4px;
        position: absolute;
        top: 2px;
        width: 3px;
    }

    &::before {
        height: 14px;
        margin-left: -4px;
        transform: rotate(-45deg);
    }

    &::after {
        height: 14px;
        margin-left: -4px;
        transform: rotate(45deg);
    }
}

.spaced-list {
    margin: 0;
    padding: 0;

    > li {
        list-style-type: none;
        margin: 0 0 1px;
    }

    &:not(.spaced-list-expandable) > li {
        background: $backgroundColorSecondary;
        padding: $spacingMedium $spacingMedium;
    }
}

.lined-list {
    margin: 0;
    padding: 0;

    & > * {
        border-top: 1px solid $borderColorLight;
        list-style-type: none;
        padding: $spacingSmall 0;
        position: relative;

        &:first-child {
            border-top: none;
        }
    }
}

.tags {
    min-height: 2.2em;
    width: 100%;

    .tag {
        margin-bottom: $spacingSmaller;
    }
}

.tag,
a.tag {
    background: $pastelViolet;
    border-radius: 4px;
    color: $pilotText;
    display: inline-flex;
    font-size: $fontSizeSmall;
    margin-right: $spacingSmaller;
    padding: 4px $spacingSmaller;

    &.large {
        font-size: $fontSizeBase;
        padding: 8px $spacingSmaller;

        .tag-action {
            margin: (-($spacingSmaller)) (-($spacingSmall)) (-($spacingSmaller)) $spacingSmaller;
            padding: $spacingSmaller $spacingSmall;
        }
    }

    &.yellow {
        background-color: $yellow1Background;
        color: $yellow1Text;
    }

    &.green {
        background: $green1Background;
        color: $green1Dark;

        .tag-action {
            &:hover {
                background: $green1;
                color: $backgroundDarkPrimaryContrast;
            }
        }
    }

    &.red {
        background-color: $red1Background;
        color: $red1;
    }

    &.add {
        background: $green1Background;
        color: $green1Dark;
        cursor: pointer;
        visibility: hidden;

        tr:hover & {
            visibility: visible;
        }

        &:hover {
            background: $green1;
            color: $backgroundDarkPrimaryContrast;
        }
    }

    .tag-action {
        align-items: center;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        display: flex;
        margin: 0 (-($spacingSmaller)) 0 $spacingSmaller;
        padding: 0 $spacingSmaller;

        &:hover {
            background: $brandColorPrimaryLight;
            color: $backgroundDarkPrimaryContrast;
        }

        img {
            max-height: 21px;
            padding: 2px;
        }
    }

    .x {
        &::before,
        &::after {
            background: $pilotText;
        }
    }
}

.filters {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacingMedium;

    > input {
        flex: 1 1 auto;
        margin-right: $spacingMedium;
    }

    .buttons {
        margin: 0;
    }

    .actions {
        margin-left: auto;
    }
}

.fade-in {
    animation: fadein 0.5s linear 0s 1 forwards;
    opacity: 0;

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    &.fade-in-delayed {
        animation: fadein 0.25s linear 1s 1 forwards;
    }
}

.follower-container {
    position: relative;
}

.follower {
    left: 0;
    max-height: 100vh;
    overflow-y: auto;
    padding: $spacingMedium;
    position: absolute;
    right: 0;
}

.collapse-caret {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    height: 24px;
    left: -32px;
    position: absolute;
    top: $spacingSmall;
    transition: transform 0.2s;
    width: 24px;
    z-index: $zIndexBase + 1;

    &::before,
    &::after {
        background: $borderColorLight;
        content: "";
        display: block;
        height: 11px;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 3px;
    }

    &::before {
        transform: translateX(50%) translateY(-50%) rotate(45deg);
    }

    &::after {
        left: auto;
        right: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:hover::before,
    &:hover::after {
        background: $borderColorDark;
    }

    &.collapse-caret-collapsed {
        transform: rotate(-90deg);
    }
}

.container-mayday {
    margin-left: auto;
    margin-right: $spacingMedium;
    position: relative;
    top: 7px;

    svg {
        position: relative;
        top: 3px;
    }
}

// Make Sentry's user feedback modal invisible
.sentry-error-embed-wrapper {
    height: 1px !important;
    left: -9999px !important;
    overflow: hidden !important;
    position: absolute !important;
    width: 1px !important;
}

.markup-inline {
    ul,
    p {
        display: inline;
        padding: 0;
    }
}
