
@use "sass:color";
@use "client/css/globals" as *;

.loading-skeleton {
    animation: skeleton-loading 0.8s linear infinite alternate;
    border-radius: 5px;
}

@keyframes skeleton-loading {
    0% {
        background-color: $backgroundColorTertiary;
    }

    100% {
        background-color: color.adjust($backgroundColorTertiary, $lightness: -5%);
    }
}
