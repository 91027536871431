
@use "client/css/globals" as *;

.delta {
    align-items: flex-end;
    display: flex;

    &.increase {
        color: $red1;

        .arrow {
            transform: rotate(-90deg);
        }
    }

    &.decrease {
        color: $green1;

        .arrow {
            transform: rotate(90deg);
        }
    }

    &.neutral {
        .arrow {
            display: none;
        }
    }
}
