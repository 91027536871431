
@use "client/css/globals" as *;
@use "client/css/layout";

$borderHeight: 2px;

.admin-secondary-nav-container {
    background: $backgroundColorPrimary;
    margin: 0 $spacingLarge;
    overflow-x: auto;
    padding: $borderHeight 0 0;
    position: sticky;
    position: -webkit-sticky;
    top: layout.$pageHeaderHeight;
    z-index: $zIndexNav;

    nav {
        white-space: nowrap;
    }

    a {
        border-bottom: $borderHeight solid transparent;
        color: $contentColorPrimary;
        display: inline-block;
        font-weight: bold;
        margin-right: 30px;
        padding-bottom: 15px;

        &.router-link-active,
        &:hover {
            border-bottom-color: $brandColorPrimary;
            color: $brandColorPrimary;
            text-decoration: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.migrated {
        align-content: start;
        background-color: unset;
        height: min-content;
        margin: 0;
        padding: 0;
        position: static; // override sticky positioning for now
        z-index: unset;
    }
}
