
@use "client/css/globals" as *;

.active-settings-page {
    border-bottom-color: $brandColorPrimary !important;
    color: $brandColorPrimary !important;
}

.navigation {
    // TODO: Make the separator match the spec
    border-bottom: 1px solid $borderColorLight;
    overflow-x: auto;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        font-size: $fontSizeBase;
        font-weight: bold;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .settings-link {
            align-items: center;
            border-bottom: 3px solid transparent; // color the border when active (un-scoped style block)
            display: flex;
            flex-wrap: nowrap;
            gap: 5px;
            padding-bottom: 15px;
        }

        a {
            color: inherit;
            white-space: nowrap;

            &:hover {
                color: $brandColorPrimary;
                text-decoration: none;
            }
        }
    }
}

.page-content {
    margin-top: 30px;
}
