
@use "client/css/globals" as *;

$attributionWidth: 32px;

.checklist-align {
    display: grid;
    grid-template:
        "left         attribution       body                                primaryAction secondaryAction commentsend  right" auto
        "left         attribution       comments                            comments      .               .            right" auto
        / $pageMargin $attributionWidth minmax(300px, $flowbotTaskMaxWidth)
        50px 50px 0 minmax($pageMargin, 1fr);
    margin: 0 (-$attributionWidth);

    .config-page & {
        // Narrower right margin tailored for checklist task configuration pages, such as Institution Tasks and Configuration > Tasks
        grid-template-columns:
            [left] $pageMargin [attribution] $attributionWidth [body] minmax(
                300px,
                $flowbotTaskMaxWidth
            )
            [primaryAction] 50px [secondaryAction] 50px [comments] 0 [commentsend] 0 [right];
    }
}

.checklist-align-left {
    grid-column: attribution;
}

.checklist-align-left-body {
    grid-column: attribution / primaryAction;
}

.checklist-align-body {
    grid-column: body / primaryAction;

    &.checklist-body-full-width {
        grid-column: body / comments;
    }
}

.checklist-align-comments {
    grid-area: comments;
}

.checklist-align-full {
    grid-column: left / right;
}

.checklist-align-full-padding {
    grid-column: attribution / commentsend;
}
