
@use "client/css/globals" as *;

.card-product > li > button {
    background: none;
    border: none;
    border-radius: 5px;
    color: $contentColorPrimary;
    display: flex;
    flex-direction: column;
    padding: $spacingMediumSmall;
    text-align: left;
    width: 100%;

    & > p {
        margin-bottom: 0;
    }

    & > h3 {
        font-size: 15px;
        margin-bottom: 5px;
    }

    &:hover,
    &:focus {
        background-color: $backgroundColorSecondary;
        cursor: pointer;
    }
}

.product-name {
    font-weight: bold;
    padding-bottom: 5px;
}

.product-desc {
    margin: 0;
}

.view-all.button {
    padding: 15px;
}

.narrow {
    padding: 10px !important;
}
