
@use "client/css/globals" as *;

.submit-button {
    margin-top: $spacingMediumSmall;
    width: 100%;
}

p:last-child {
    margin: 0; // reset global style
}
