@use "client/css/globals" as *;
@use "client/css/layout";

$contentColumnPadding: $spacingLarge;

hr {
    margin: 0 $contentColumnPadding;
    position: sticky;
    position: -webkit-sticky;
    z-index: $zIndexNav;

    .primary-nav-layout & {
        top: layout.$pageHeaderHeight;
    }

    .secondary-nav-layout & {
        top: layout.$pageHeaderHeight + layout.$secondaryNavHeight;
    }
}

.admin-container {
    display: grid;
    grid-template-columns: auto 1fr;

    &.primary-nav-layout {
        grid-template-rows: auto auto 1fr;

        .primary-nav {
            grid-row: span 3;
        }
    }

    &.secondary-nav-layout {
        grid-template-rows: auto auto auto 1fr;

        .primary-nav {
            grid-row: span 4;
        }
    }

    &.no-padding {
        .admin-page-content {
            padding: 0;
        }
    }
}

.admin-page-content {
    height: 100%;
    padding: $contentColumnPadding;
}

.admin-nav-alerts {
    background: $yellow1Background;
    border: 2px solid $yellow1;
    border-radius: 0 0 5px 5px;
    border-top: none;
    left: 50%;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    z-index: $zIndexToast;

    li {
        border-bottom: 1px solid $yellow1;
        margin: 0 $spacingMedium;
        padding: $spacingSmall 0;

        &:last-child {
            border-bottom: none;
        }
    }
}
