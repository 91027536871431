
@use "client/css/globals" as *;

.relationship-header {
    border-bottom: 1px solid $borderColorLight;
    padding: 15px;
}

.relationship-title {
    font-size: $fontSizeBase;
    font-weight: bold;
    margin: 0 10px;
    margin-right: 120px;
}

.relationship-body {
    padding: 20px;
}

.sub-header {
    align-items: center;
    display: flex;
}

.due-date {
    padding-left: 10px;
}

.profile-line-break {
    margin: 0;
}

.about-section {
    margin-bottom: 15px;
}

.fancy-load {
    margin: 30px auto;
}
