
@use "client/css/globals" as *;

.date {
    width: 9em; // includes large padding
}

.type {
    width: 8em;
}

.amount {
    width: 10em; // includes large padding
}

.subtotal,
.subheader {
    font-weight: bold;
}

.subtotal-row td,
.subheader-row td {
    padding-bottom: $spacingSmall;
}
