
@use "client/css/globals" as *;

::v-deep .drawer-content {
    padding: 0;
}

::v-deep .drawer-header {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 30px 40px;
}

::v-deep .drawer-exit-button.x.larger {
    height: 32px;
    width: 32px;

    &::before,
    &::after {
        background: #999;
        height: 20px;
        left: 15px;
        top: 6px;
    }
}

::v-deep .container-mayday {
    display: none;
}

::v-deep .multiselect__content-wrapper {
    width: 300px;
}

.drawer-header-text {
    font-size: 18px;
    line-height: 48px;
    top: 0;
}

.transaction-details-container {
    border-bottom: 1px solid $borderColorLight;
    border-top: 1px solid $borderColorLight;

    .bank-details {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 20px 40px 15px;

        .bank-info {
            align-items: center;
            display: flex;

            .bank-logo {
                align-items: center;
                display: flex;
                height: 30px;
                justify-content: center;
                margin-right: 10px;
                width: 30px;
            }

            .bank-icon {
                height: 35px;
                margin-left: -5px;
                margin-right: 5px;
                width: 35px;
            }

            .bank-name {
                font-size: 15px;
            }
        }

        .transaction-date {
            color: $contentColorSecondary;
            font-size: 15px;
        }
    }
}

.transaction-amount {
    padding: 0 40px;

    .amount-status {
        align-items: center;
        display: flex;

        .amount {
            font-size: 32px;
            font-weight: 600;
            margin: 0;
            margin-right: 10px;
        }
    }
}

.section-container {
    padding: 10px 40px 20px;

    .details-section {
        margin-top: 15px;
    }

    .transaction-detail:last-of-type {
        margin-bottom: 0;
    }
}

.categorization-section-container {
    border-top: 1px solid $borderColorLight;
    padding: 20px 40px;

    .header-container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 15px;
        }
    }

    .action-buttons {
        margin-top: 20px;
    }

    .transaction-detail:last-of-type {
        margin-bottom: 0;
    }
}

.history-section-container {
    padding: 20px 40px;

    h2 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 25px;
    }

    .event {
        color: $offBlack;
        display: block;
        font-size: 13px;
        line-height: 1.4;
    }

    .date {
        color: $contentColorSecondary;
        font-size: 13px;
        margin-top: 3px;
    }
}

.vendor-mapping-checkbox,
.vendor-mapping-consistency-warning {
    margin-bottom: 15px;
}
