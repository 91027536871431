
@use "client/css/globals" as *;

.date {
    width: 8em;
}

.amount {
    width: 9em;
}

.actions {
    text-align: right;
    width: 12em;
}

.tooltip-container {
    margin-right: $spacingSmall;
}
