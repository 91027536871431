
@use "client/css/globals" as *;

.tax-filing-summary {
    .tax-filing-info {
        align-items: center;
        display: flex;
    }

    &.without-subtitle {
        display: flex;
        justify-content: space-between;

        .tax-filing-title {
            align-items: center;
            display: flex;
            margin: 0;
        }
    }

    &.with-subtitle {
        .tax-filing-title {
            align-items: center;
            display: flex;
            grid-area: title;
            margin: 0;
        }

        .tax-filing-subtitle {
            color: $contentColorSecondary;
            font-size: $fontSizeBase;
            grid-area: subtitle;
            padding-top: 5px;
        }
    }
}

.document-item-icon {
    margin-right: 20px;
}

.icon-check {
    margin-right: 20px;
    margin-top: 5px;
}
