
@use "client/css/globals" as *;

.zap-response {
    h3 {
        font-size: $fontSizeLarge;
    }

    code {
        white-space: pre-wrap;
    }
}
