
@use "client/css/globals" as *;

.pilot-banner {
    align-items: center;
    background: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: space-between;
    min-height: 50px;
    padding: 10px 30px;

    &.detailed {
        border-radius: $borderRadiusLarge $borderRadiusLarge 0 0;
    }

    .banner-icon {
        line-height: 0;
    }

    .banner-title--without-description {
        font-weight: normal;
    }
}

.banner-details {
    border: 1px solid $borderColorLight;
    border-radius: 0 0 $borderRadiusLarge $borderRadiusLarge;
}

.default {
    // passed through slot
    ::v-deep a {
        color: $brandColorPrimary;
        text-decoration: underline;
    }
}

.loud {
    background: $pilotText;
    color: $backgroundColorPrimary;

    // passed through slot
    ::v-deep a {
        color: $backgroundColorPrimary;
        text-decoration: underline;
    }
}

.error {
    background: $red1Background;
    color: $red1;

    // passed through slot
    ::v-deep a {
        color: $red1;
        text-decoration: underline;
    }
}
