
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.metadata-card {
    background-color: $backgroundColorSecondary;
    width: 100%;

    .title {
        background-color: $backgroundColorSecondary;
        font-weight: bold;
    }
}
