
@use "client/css/globals" as *;
@use "client/css/table";

.number {
    @include table.tabular-numbers;
}

.has-insight {
    background-color: $brighterPurple;

    &:hover {
        background-color: $brightPurple;
    }
}

.last-month {
    background-color: $pilotPurple;
    color: $brandColorPrimaryContrast;

    &:hover {
        background-color: $pilotText;
    }
}

.comments-list {
    background: $backgroundColorSecondary;
    border-radius: 6px;
    margin: $spacingMediumSmall 0;
    padding: $spacingMediumSmall;
}

.insights-list {
    margin: $spacingSmall 0;
}

.inspect-account {
    display: block;
    margin: $spacingMediumSmall 0;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.hover-bubble ::v-deep .bubble {
    padding-top: $spacingMediumLarge;
    width: 540px;
}

.summary {
    margin-bottom: $spacingMediumSmall;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    & ::v-deep p {
        margin: 0; // override global style
    }
}

.cell-with-bubble {
    position: relative;

    .bubble-container {
        display: block;
        width: 100%;
    }
}
