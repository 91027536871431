
@use "client/css/globals" as *;

svg {
    padding-right: 5px;
}

tr {
    align-items: center;
    background-color: $yellow1Background;
    border-bottom: 1px solid $pilotTypeYellow;
    border-top: 1px solid $pilotTypeYellow;
    display: grid;
    grid-column: 1 / -1; // take up the entire row, from first to last column
    grid-template-columns: auto auto;
    justify-content: space-between;
}

td {
    padding: 15px 30px;
}
