
@use "client/modules/categorize/css/categorize";

.vertical-align {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.amortization-period-picker {
    margin-bottom: 10px;

    ::v-deep .multiselect {
        flex: 0 0 auto;
        margin: 0;
        max-width: categorize.$amortizeInputWidth;
        width: 100%;
    }
}

.amortization-note {
    max-width: categorize.$amortizeInputWidth;
    width: 100%;
}
