
@use "client/css/globals" as *;

.troubleshooting-container {
    background: $highlightPurple;
    border: 1px solid $brightPurple;
    border-radius: 6px;
    margin-top: 10px;
    padding: 15px;
}

.troubleshooting-header {
    display: flex;
    justify-content: space-between;
}

.troubleshooting-expand {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.troubleshooting-title {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}
