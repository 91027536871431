
::v-deep .pilot-card-header {
    display: flex;
    padding: 20px 30px 10px;
}

.error-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;

    h2.title {
        margin-bottom: 5px;
        margin-top: 15px;
    }

    .description {
        display: flex;
        max-width: 50%;
        text-align: center;
    }
}

.categorization-section {
    margin-bottom: 20px;
}

.card-header-text {
    line-height: 150%;
}

.bubble-text,
.bubble-list {
    margin: 0;
}

.bubble-list {
    padding-left: 20px;
}

.underline {
    text-decoration: underline;
}

.bubble-text--applied {
    margin-top: 10px;
}
