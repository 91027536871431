
.tax-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.loading-error-container {
    width: 100%;
}

.no-margin {
    margin-bottom: 0;
}
