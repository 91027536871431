
@use "client/css/globals" as *;

.tax-input-wrapper {
    margin-top: 30px;
}

.label {
    font-weight: bold;
    width: 100%;
}

// for screen readers: https://webaim.org/techniques/css/invisiblecontent/
.hidden {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}

.input {
    border-radius: $borderRadiusSmall;
    font-weight: normal;
    margin-top: 10px;

    &.warning {
        border: 1px solid #f4b13e;
    }
}
