
.skyway-card {
    margin-bottom: 30px;
}

.card-header {
    padding: 15px 30px;
}

.amount-input {
    text-align: right;
}
