
@use "client/css/globals" as *;

$dispositionIconSize: 32px;

.disposition-title {
    font-weight: bold;

    // Vertical-align text with icon, which is in a different grid column
    line-height: $dispositionIconSize;
    margin: 0;
}

.disposition-component {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr max-content;
}

.disposition-last-reported-msg {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    padding-top: 10px;
}

.evidence-link-hidden {
    // Still take up space if there is no link
    visibility: hidden;
}

.disposition-icon {
    align-items: center;
    background-color: rgba($urgentOrange, 0.2);
    border-radius: 50%;
    display: flex;
    height: $dispositionIconSize;
    justify-content: center;
    width: $dispositionIconSize;

    &.not-sensitive {
        background-color: $lightGrey;
    }
}

.disposition-label {
    font-weight: bold;
    padding-bottom: 5px;
}

.error-banner {
    margin-bottom: 20px;
    margin-top: 10px;
}

.bullet-list {
    margin-block-start: 10px;

    // Override default ul styling
    // Left align with title
    padding-inline-start: 18px;
}

.bullets {
    list-style-type: circle;
}

.attributes {
    align-items: flex-start;
    display: flex;
}

.sensitive-title {
    align-items: center;
    display: flex;
    line-height: $dispositionIconSize;
}

::v-deep .tooltip-content {
    width: 255px;
}

.edit-button {
    // This results in proper vertical alignment with icon/ text
    height: $dispositionIconSize;
}

.need-to-know {
    font-weight: normal;
}

.icon-info-circle {
    line-height: 0;
}
