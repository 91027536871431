
@use "client/css/globals" as *;

.explanation-textarea {
    margin-bottom: $spacingMedium;
    resize: vertical;

    // applies when required attribute is present on empty textarea
    &:invalid {
        border-color: $red1Light;
        box-shadow: none; // Firefox default style for invalid (like focus outline)
    }
}
