
@use "sass:color";
@use "client/css/globals" as *;

#close-dashboard {
    padding-bottom: 400px; // Make room for the reassignment bubbles

    section {
        margin-bottom: $spacingLarge;
    }

    h2 {
        margin-bottom: $spacingSmall;
    }
}

#close-buckets {
    display: flex;
    justify-content: stretch;

    > li {
        flex: 1 1 auto;
        overflow: hidden;
        padding: $spacingMedium;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.close-status-bucket {
    min-width: 10%;
    transition: min-width 0.25s ease-in-out;

    &:hover {
        cursor: pointer;
        min-width: 200px;
    }
}

.close-bucket-value {
    display: block;
    font-size: $fontSizeLarger;
    line-height: 1.05;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bucket-close-pct {
    font-size: $fontSizeMedium;
}

.close-status-not_started {
    background: $backgroundColorTertiary;
}

.close-status-in_progress {
    background: $brighterPurple;
}

.close-status-in_review {
    background: $brightPurple;
}

.close-status-respond_to_review {
    background: color.adjust($brandColorPrimary, $lightness: 25%);
}

.close-status-ready_to_ship {
    background: color.adjust($brandColorPrimary, $lightness: 15%);
    color: $brandColorPrimaryContrast;
}

.close-status-complete {
    background: $brandColorPrimary;
    color: $brandColorPrimaryContrast;
}

.close-tasks {
    display: grid;
    grid-gap: $spacingSmall;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.close-dashboard-for-user {
    section {
        display: flex;
        flex-direction: row;
        padding-top: $spacingMedium;

        h3 {
            align-self: stretch;
            flex: 0 0 150px;
            font-size: $fontSizeMedium;
            margin: 0;
            margin-right: $spacingSmall;
            padding: $spacingSmall;
            padding-left: 0;
        }

        .close-tasks {
            flex: 1 1 auto;
        }
    }
}

.close-dashboard-time-tracker {
    display: inline-block;
    margin-bottom: $spacingMedium;
}
