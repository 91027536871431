
@use "client/css/globals" as *;
@use "client/css/table";

.select-all-banner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.pagination-controller {
    margin-bottom: 15px;
}

.change-txns-table {
    table-layout: auto;

    th {
        white-space: nowrap;
    }
}

tr.result-row:hover {
    background-color: $purpleSelectionHoverBackground;
    cursor: pointer;
}

.result-row td {
    padding: 10px;
}

tr.selected {
    background-color: $purpleSelectionBackground;
}

.checkbox {
    width: 40px;
}

.date {
    white-space: nowrap;
    width: 110px;
}

.description {
    width: 290px;
}

.vendor {
    width: 210px;
}

.account {
    width: 180px;
}

.class {
    width: auto;
}

.department {
    width: auto;
}

.source {
    width: 185px;
}

.amount {
    text-align: right;
}

.private-note,
.ref-number {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}
