
@use "client/css/globals" as *;

.collapsible-row {
    display: grid;

    // Work around the way PilotTable doesn't align table rows correctly if there is something in between (collapsible)
    // by creating another grid and copying the gridTemplateColumns setup. With this hack, there is a risk of
    // the header/ cells falling out of alignment because they are two separate grids. Feel free to remove the Collapsible animation
    // if there are issues.
    grid-column: 1 / -1;
}

.portal-txns-group {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    margin-top: 20px;
}

.vendor-mapping-inconsistency-warning {
    margin-top: 15px;
}
