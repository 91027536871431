
@use "client/css/globals" as *;

::v-deep .categorize-vendors-header.card.none {
    border-bottom: 1px solid $borderColorLight;
}

.categorize-vendors-table {
    // Since the table cells have hardcoded min-widths, the rows will overflow their parent at smaller widths without this
    overflow: auto;
}

::v-deep th:first-of-type {
    border-top-left-radius: 5px;
}

::v-deep th:last-of-type {
    border-top-right-radius: 5px;
}

tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 5px;
}

tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 5px;
}

.categorize-vendor-row {
    ::v-deep .pilot-table-row-cell:last-of-type {
        // overrides PilotTable's default left alignment for columns with type number
        justify-content: end;
        padding-right: 30px;
    }
}

.vendor-cell {
    align-items: center;
    display: flex;
}

.vendor-logo {
    margin-right: 15px;
}

.new-vendor-tag {
    margin-left: 15px;

    ::v-deep .tooltip-content {
        width: 120px;
    }
}

.pending-recategorization-bubble {
    ::v-deep .bubble-up {
        left: 0;
        min-width: 260px;
    }
}

.category-tag {
    margin-left: 5px;
}

.category-cell,
.total-cell {
    display: flex;
    flex-direction: column;
}

.category-info {
    align-items: center;
    display: flex;
    height: 30px;
    white-space: nowrap;
}

.total-text {
    // overrides PilotTable's default left alignment for columns with type number
    text-align: end;
}
