
.title {
    margin-bottom: 40px;
    text-align: center;
}

.icon {
    margin-left: 5px;
}

.buttons {
    display: flex;
    justify-content: center;
}
