
@use "client/css/globals" as *;

.insights-header-card {
    background-color: $backgroundColorPrimary;
    border-bottom: 1px solid $backgroundColorTertiary;
    border-top: 1px solid $backgroundColorTertiary;
    padding: 15px 35px;

    .subheader-text {
        margin-bottom: 15px;
    }

    .insights-form {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;

        .insights-search-field {
            margin-right: 10px;
        }

        .base-button {
            width: 100px;

            ::v-deep .spinner {
                right: 40px;
            }
        }

        ::v-deep .multiselect {
            width: 380px;
        }
    }
}

.insights-search-results {
    padding: 20px 35px;

    .no-results-card {
        box-shadow:
            0 0 1px 0 rgba(101, 99, 99, 0.16),
            0 8px 16px 0 rgba(62, 60, 61, 0.08);
        text-align: center;
    }

    .results-header-text {
        margin-bottom: 15px;
    }
}
