
@use "client/css/globals" as *;

h3 {
    margin: 0;
}

.status-tag {
    font-weight: normal;
    margin-left: 10px;
}

.sync-section > * {
    padding: 15px 30px;
}

p.pilot-text.instructions-text {
    margin-bottom: 1em;
}

.api-key-input {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
}

input {
    margin-top: 10px;
    width: 100%;
}

.title-section {
    margin: 30px 0;
}

.title {
    padding-bottom: 5px;
}
