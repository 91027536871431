
@use "client/css/globals" as *;

.inline-input-container {
    display: flex;
    margin-bottom: 20px;

    .avatar-container {
        margin-right: 10px;
        margin-top: 3px;
    }

    .input-active-container {
        border: 1px solid $borderColorLight;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        transition: all 0.3s ease;
        width: 100%;

        .text-input {
            border: 1px solid transparent;
            min-height: 78px;
            overflow: auto;
            padding: 10px 12px 40px;
            resize: none;
            transition: all 0.3s ease;
            width: 100%;

            &::placeholder {
                color: $darkGrey;
            }
        }

        .attachments-container {
            padding: 0 0 10px 12px;

            .file-card {
                padding-bottom: 10px;
                width: 350px;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        .input-btn-container {
            bottom: 10px;
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 12px;

            .attachment-btn-container {
                align-items: center;
                cursor: pointer;
                display: flex;
                padding-right: 15px;
            }

            .spinner-container {
                margin-bottom: 5px;
            }
        }
    }

    .active-input {
        border: 1px solid $brandColorPrimary;
    }
}

.avatar-container {
    margin-right: 10px;

    .add-question-avatar {
        background: rgba(79, 155, 243, 0.4);
        transition: all 0.3s ease-in-out;
    }
}

.add-question-message {
    display: flex;

    &:hover,
    &:focus,
    &:active {
        .add-question-avatar {
            background: rgba(79, 155, 243, 0.6);
            transition: all 0.1s ease-in-out;
        }

        .add-question-btn {
            border: 1px solid $borderColorLight;
            border-radius: 4px;
            color: $contentColorTertiary;
        }
    }

    .add-question-btn {
        border: 1px solid transparent;
        color: $contentColorTertiary;
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        transition: all 0.1s ease-in-out;
        width: 100%;

        &:disabled {
            cursor: wait;
        }
    }
}
