
@use "client/css/globals" as *;

.bank-sync-config-error-container {
    background-color: white;
    margin: 30px;
}

.header-text {
    font-weight: bold;
    margin-bottom: 10px;
}

a {
    color: $pilotPurple;
}

::v-deep .expander-cuff {
    align-items: center;
}

.header-container {
    margin-bottom: 35px;
}

::v-deep .pilot-table-header-cell {
    &.first:not(.xsmall) {
        padding-left: 0; // override default padding
    }
}

::v-deep .pilot-table-row-cell {
    &.first:not(.xsmall) {
        padding-left: 0; // override default padding
    }
}
