
@use "client/css/globals" as *;

$screenBreakpoint: 800px; // 80px more than half of MacBook Air's width
$grid-row-gap: 20px;

#dashboard {
    display: grid;
    grid-row-gap: $grid-row-gap;
    grid-template-columns: 1fr;
}

.dashboard-top-row {
    display: grid;
    grid-row-gap: $grid-row-gap;
    grid-template-rows: repeat(2, 1fr);
}

.user-dropdown-wrapper {
    .spinner {
        top: 0;
        transform: scale(0.88); // it's normally 50px x 50px. This brings it down to 44px x 44px
    }
}

.role-picker {
    height: 44px;
    min-width: 300px;
}

.stage {
    margin: 0 auto;
    width: 100%;
}

.stage-title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.stage-cards {
    display: grid;
    grid-gap: $spacingSmall;

    // Magic number that doesn't show horizontal scroll bar
    grid-template-columns: minmax(320px, 1fr);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.is-shown {
        max-height: none;
    }
}

.no-cards {
    align-items: center;
    border: 1px solid $pastelViolet;
    border-radius: $borderRadiusMedium;
    color: $contentColorTertiary;
    display: flex;
    justify-content: center;
    min-height: 111px;
    padding: $spacingMediumSmall;
}

@media screen and (min-width: $screenBreakpoint) {
    $grid-column-gap: 30px;
    $grid-column-width: calc((100% - #{$grid-column-gap} * 2) / 3);

    #dashboard {
        grid-column-gap: $grid-column-gap;
        grid-row-gap: 37px;
        grid-template-columns: repeat(3, $grid-column-width);
    }

    .dashboard-top-row {
        grid-auto-flow: column;
        grid-column: span 3;
        grid-column-gap: 30px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
    }

    .user-dropdown-wrapper {
        grid-column: span 2;
    }

    .stage-loading-icon {
        grid-column: span 3;
    }

    .progress-rollup {
        grid-column: span 3;
    }

    .toggle-chevron {
        display: none;
    }

    .stage-title:hover {
        text-decoration: none;
    }

    .stage-cards {
        max-height: none;
    }
}
