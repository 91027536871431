
@use "client/css/globals" as *;

.dashboard-card {
    background: $backgroundColorPrimary;
    border: 1px solid $backgroundColorTertiary;
    border-radius: $borderRadiusMedium;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    font-size: $fontSizeSmall;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: minmax(48px, auto) auto auto;
    padding: $spacingMediumSmall;

    &.darkened {
        background: $lightGrey;
    }

    .customer-display-name {
        color: $contentColorPrimary;
        font-size: $fontSizeBase;

        a {
            color: inherit;

            &:hover {
                color: $pilotPurple;
            }
        }
    }
}

.checklist-link:hover {
    text-decoration: none;
}

.card-details {
    align-items: center;
    display: flex;
    height: 18px;
    justify-content: flex-end;

    .name-wrapper {
        color: $contentColorSecondary;
        margin-right: $spacingSmall;
    }

    .popover-content {
        min-width: 130px;
    }

    .popover-role {
        color: white;
        font-size: $fontSizeSmall;
        opacity: 0.75;
    }
}

.close-status-title {
    line-height: 16px;
}

.close-status-title,
.remaining-time {
    color: $contentColorSecondary;
}

.remaining-time {
    text-align: right;
}

.urgent {
    color: $urgentOrange;
}

.dashboard-progress-bar {
    grid-column: span 2;
    margin-top: $spacingSmall;
}

.multi-checklists-error {
    grid-column: span 2;
}
