
@use "client/css/globals" as *;

.at-risk-field {
    margin: 0 0 $spacingMedium;
    position: relative;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.comment {
    min-height: 158px;
    min-width: 100%;
}
