
@use "client/css/globals" as *;

.question-header {
    align-items: top;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    width: 100%;
}

.tag-icon-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.topic {
    padding: 30px 30px 0;

    ::v-deep .post-icon {
        height: 40px;
        width: 40px;
    }

    ::v-deep .post-text-container {
        display: contents; // removes the wrapper div around the post
    }

    ::v-deep .post-body {
        grid-column: 1 / 3; // post body spans two columns/whole row
        margin-left: 5px;
        margin-top: 10px;
    }

    ::v-deep .post-header {
        margin: 5px 5px 0 0;
    }
}

::v-deep .responses-container {
    margin-top: 0;
}

.input-wrapper {
    padding: 0 30px 30px 0;
}

.spacing {
    padding-left: 30px;
}

.icon {
    margin-left: 5px !important;
}

.collapse-expand-section {
    border-top: 1px solid $pastelViolet;
    padding: 20px 30px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.responses {
    max-height: 1000px;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
    transition:
        max-height 0.5s ease-in-out,
        opacity 0.5s ease-in-out;

    &:not(:last-child) {
        // <transition-group/> removes row gap, so we need to add the spacing back.
        margin-bottom: 20px;
    }

    &.custom-spacing:last-child {
        margin-bottom: 30px;
    }
}

.padding-bottom {
    padding-bottom: 30px;
}

.collapsed-enter-active,
.collapsed-leave-active {
    transition:
        max-height 0.5s ease-in-out,
        opacity 0.5s ease-in-out;
}

.collapsed-enter,
.collapsed-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}
