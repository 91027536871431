
@use "client/css/globals" as *;

th {
    padding: $spacingSmall 0;
}

.table-wrapper {
    overflow-x: auto;
    padding-top: $spacingLarge;
    scrollbar-color: #5f2ee5;
    width: calc(100vw - #{$expandedNavWidth} - #{$spacingLarge} - #{$spacingLarge});

    @media screen and (max-width: $collapseNavBreakpoint) {
        width: calc(100vw - #{$collapsedNavWidth} - #{$spacingLarge} - #{$spacingLarge});
    }

    &::-webkit-scrollbar {
        height: 0.5em;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4px;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #5f2ee5;
        border-radius: 4px;
        outline: 0.5px solid #5f2ee5;
    }
}

.cell-customer-name {
    width: 280px;
}

.cell-year-end-entity {
    width: 160px;
}

.cell-tax-firm {
    width: 160px;
}

.cell-workpaper {
    width: 160px;
}

.cell-book-status {
    width: 160px;
}

.cell-has-r-and-d {
    width: 160px;
}

.cell-filing-status {
    width: 160px;
}
