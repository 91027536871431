
@use "client/css/globals" as *;

.flex {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.input-password {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;

    &:focus {
        ~ .show-hide {
            border-color: $pilotPurple;
            outline: none;
        }
    }
}

.show-hide {
    border: 1px solid #e0e0e0;
    border-left: 0;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: $pilotPurple;
    cursor: pointer;
    font-size: $fontSizeSmall;
    min-width: 60px;
    outline: none;
    padding: 9px 12px 10px;
    user-select: none;
}
