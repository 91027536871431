
@use "client/css/globals" as *;

#customer-tools-run-recognizer {
    .buttons,
    .description {
        max-width: $fieldWidthBase;
        position: relative;
    }
}

.response {
    margin-top: 20px;
}

.dryRun {
    align-items: center;
    background: $yellow1;
    border-radius: 5px;
    color: $offBlack;
    display: flex;
    justify-content: center;
    padding: 10px;
    width: max-content;
}

.error {
    align-items: center;
    background: $pastelRed;
    border-radius: 5px;
    color: $red1;
    display: flex;
    justify-content: left;
    padding: 10px;
}

.success {
    align-items: center;
    background: $green1Background;
    border-radius: 5px;
    color: $offBlack;
    display: flex;
    justify-content: left;
    padding: 10px;
}
