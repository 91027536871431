
@use "client/css/globals" as *;

.container {
    font-size: $fontSizeBase;
    height: fit-content;
}

p {
    margin: 0;
}

.history-button {
    align-items: center;
    display: flex;
}

.icon-arrow {
    margin-left: 80px;
}
