
@use "client/css/globals" as *;

.container {
    margin-top: 30px;
}

.label {
    display: block;
    font-weight: bold;
    margin-bottom: $spacingSmaller;
    width: 100%;
}

.radio-group {
    margin: 0;
    width: 100%;
}
