
@use "client/css/globals" as *;

.transaction-container {
    align-items: center;
    display: flex;

    .transaction-details {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-width: 0;
    }

    .transaction-category {
        color: $contentColorSecondary;
        font-size: 15px;
        margin: 0;
    }

    .transaction-category-tooltip {
        white-space: no-wrap;

        ::v-deep .bubble-down {
            min-width: 250px;
        }
    }
}
