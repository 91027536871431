
@use "client/css/globals" as *;

.totp-seed {
    font-size: $fontSizeMedium;
    font-weight: bold;
    text-align: center;
}

.showqrcode {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.hideqrcode {
    display: none;
}

.center-text {
    text-align: center;
}

.other-modal-link {
    color: $pilotPurple;
    cursor: pointer;
    margin-top: 30px;
}

.totp-form {
    margin: 25px auto;
}

.totp-form input {
    text-align: center;
}

.buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
