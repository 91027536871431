
.sort-arrow::after {
    border: 4px solid transparent;
    border-top-color: currentColor;
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
}

.sort-arrow.desc::after {
    vertical-align: -2px;
}

.sort-arrow.asc::after {
    transform: rotate(180deg);
    vertical-align: 2px;
}
