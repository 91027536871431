
@use "client/css/globals" as *;

.banner-container {
    align-items: center;
    background-color: $pilotText;
    color: $brandColorPrimaryContrast;
    display: grid;
    grid-template:
        "content close" auto
        / 1fr auto;
    height: 75px;
    width: 100%;

    .banner-content {
        align-items: center;
        display: flex;
        grid-area: content;
        justify-content: center;
        margin: 0;
        margin-right: 20px;
        padding: 0;
        width: 100%;

        .icon-dot-pattern {
            &:first-of-type {
                margin-right: 20px;
            }

            &:last-of-type {
                margin-left: 20px;
            }
        }
    }

    .icon-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        grid-area: close;
        line-height: 0;
        margin: 0;
        outline: none;
        padding-right: 15px;
    }
}
