
@use "sass:math";
@use "client/css/globals" as *;
@use "client/modules/checklist/_components/checklist-task";

$taskLineHeight: 26px;
$transitionDuration: 0.2s;
$zIndexAboveOverlay: $zIndexBase + 2;
$attributionWidth: 32px;

.checklist-relative-position {
    position: relative;
}

.task {
    .task-insert-new-wrapper {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        .checklist-align-left {
            grid-row: 1 / 3;
            position: relative;
        }
    }

    .drag-status {
        left: -35px;
        position: absolute;
        top: -2px;
    }

    .drag-loader {
        position: relative;
        right: 4px; // center spinner in handle's spot

        * {
            padding: 0;
        }
    }

    .drag-and-drop-handle-wrapper--disabled {
        cursor: not-allowed;
    }

    .drag-and-drop-handle {
        cursor: grab;
        opacity: 1;
        transition: opacity 0.2s;

        &:active {
            cursor: grabbing;
        }

        &.drag-and-drop-handle--disabled {
            opacity: 0.25;
            pointer-events: none;
        }
    }
}

.task-body {
    display: flex;
    position: relative;
    transition: all $transitionDuration;

    > .resize-toggle-container {
        width: 100%;
    }

    .task-edit-container {
        z-index: $zIndexAboveOverlay;
    }
}

.task-view {
    display: grid;
    grid-template:
        "task-tag task-title" auto
        ".......   task-instructions" auto
        / auto 1fr;
    transition: all $transitionDuration;

    .task-title {
        grid-area: task-title;
    }

    .task-title--clickable {
        cursor: pointer;
    }

    .task-title-tag {
        grid-area: task-tag;

        // Manually align with bullet/ checkbox. The checkbox container can't be a sibling element
        // because the main checklist body needs to be wrapped by ResizeToggle
        margin-top: 1px;

        div:last-child {
            margin-right: 10px;
        }
    }

    .task-instructions {
        grid-area: task-instructions;
        overflow-y: auto;

        li {
            overflow-wrap: break-word;
            word-break: break-word;
        }

        p {
            margin: $spacingSmallest 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        pre {
            background: $backgroundColorSecondary;
            border-left: 3px solid $borderColorDark;
            color: $contentColorSecondary;
            overflow: auto;
            padding: $spacingMediumSmall;
            position: relative;
        }
    }

    &.task-skipped {
        .task-title {
            color: $contentColorSecondary;
            text-decoration: line-through;
        }
    }
}

.task-edit {
    position: relative;
    transition: all $transitionDuration;
}

.task-skipped-tooltip-header {
    margin: 0;
}

.task-title {
    display: block;
    flex: 1 1 auto;
    line-height: $taskLineHeight;
    margin: 0;

    > * {
        display: inline;
    }

    .task-is-next-up & {
        color: $pilotPurple;
        font-weight: bold;
    }
}

.task-primary-action {
    height: ($taskLineHeight + 2px);
    position: relative;
    width: ($taskLineHeight + 2px);

    @keyframes rotation {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(359deg);
        }
    }

    button {
        background: $brightPurple;
        border: 1px solid $pilotPurple;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 100%;
        transition: all $transitionDuration;
        width: 100%;

        &:focus {
            outline: none;
        }

        &:hover {
            background: $brighterPurple;
        }

        &:disabled {
            background: $backgroundColorTertiary;
            border-color: $borderColorLight;
            cursor: default;
        }

        &.pending {
            animation: rotation 0.75s linear infinite;
            background: transparent;
            border-bottom-color: transparent;
            border-left-color: $pilotPurple;
            border-right-color: $pilotPurple;
            border-top-color: transparent;
            border-width: 2px;
        }

        .task-is-next-up &.task-primary-action-zap {
            background: $pilotPurple;

            &:hover {
                background: $brandColorPrimaryLight;
            }

            &:disabled {
                background: $brandColorPrimaryDesaturated;
            }

            &.pending {
                background: $pilotPurple;
            }
        }
    }

    svg {
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: all 0.5s;
    }
}

.hidden-task-id {
    border: none;
    height: 0;
    padding: 0;
    width: 3px; // minimum width where we can still copy the task id
}

.overflow-button {
    margin-top: -10px; // magic number to align with zap
}

.task-zap-multiple {
    @keyframes fade {
        0% {
            margin-left: -10px;
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            margin-left: 10px;
            opacity: 0;
        }
    }

    &:nth-child(1) {
        margin-left: -5px;
        opacity: 0.5;
    }

    &:nth-child(3) {
        margin-left: 5px;
        opacity: 0.5;
    }

    &.pending {
        &:nth-child(1) {
            animation: fade 1s ease-in-out 0s infinite;
        }

        &:nth-child(2) {
            animation: fade 1s ease-in-out 0.33s infinite;
        }

        &:nth-child(3) {
            animation: fade 1s ease-in-out 0.67s infinite;
        }
    }
}

.task-primary-action-status {
    background: transparent;
    border-radius: 50%;
    height: 12px;
    pointer-events: none;
    position: absolute;
    right: -2px;
    top: -2px;
    width: 12px;

    &.task-primary-action-status-success {
        background: $green1;
    }

    &.task-primary-action-status-error {
        background: $red1;
    }
}

$addButtonSize: 31px;

.task-insert-new-container {
    opacity: 0;
    position: absolute;
    width: 100%;

    &:hover {
        opacity: 1;
    }

    &.task-insert-before {
        bottom: 50%;
        top: 0;

        .task-insert-new {
            top: 0;
        }
    }

    &.task-insert-after {
        bottom: 0;
        top: 50%;

        .task-insert-new {
            top: 100%;
        }
    }
}

.task-insert-new {
    background: $green1;
    border: 1px solid $green1;
    border-radius: 50%;
    color: $brandColorPrimaryContrast;
    cursor: pointer;
    font-size: 32px;
    height: $addButtonSize;
    line-height: 26px;
    margin-top: math.div(-$addButtonSize, 2);
    opacity: 0.4;
    position: absolute;
    right: 8px;
    text-align: center;
    transition: opacity 0.2s;
    width: $addButtonSize;

    &:hover {
        opacity: 1;
    }

    &::after {
        border-color: transparent transparent transparent $green1;
        border-style: solid;
        border-width: $addButtonSize * 0.44 0 $addButtonSize * 0.44 $addButtonSize * 0.6;
        content: "";
        display: block;
        height: 0;
        left: 76%;
        position: absolute;
        top: 4%;
        width: 0;
    }

    .checklist-editing & {
        display: none;
    }
}

.task-zap-run {
    margin-bottom: $spacingLarge;

    &:last-child {
        margin-bottom: 0;
    }
}

.checklist-task-checkbox-override-top .pilot-checkbox-big {
    top: 0;
}

.task-details-expand {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;

    &.task-details-expand--up {
        top: 2px;
    }
}
