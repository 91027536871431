
@use "client/css/globals" as *;

.label {
    margin-bottom: 40px;
}

.bordered-line {
    border-color: $borderColorLight;
    border-top-style: dashed;
    border-width: 1px;
    padding-top: 40px;
}

::v-deep .checkbox-and-label {
    align-items: start;
}
