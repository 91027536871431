
@use "client/css/globals" as *;

.dot-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dot-list-item {
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;

    // Creates the vertical line
    &::after {
        background-color: $borderColorLight;
        bottom: -21px; // extends the line down to the next dot
        content: "";
        left: 10px;
        position: absolute;
        top: 14px; // to start below the first dot
        width: 2px;
    }

    // Stops the line at the last dot
    &:last-child::after {
        width: 0;
    }
}

.dot {
    // Ensures the line never covers the dot
    background-color: $backgroundColorPrimary;
    border: 2px solid #999;
    border-radius: 50%;
    height: 10px;
    left: 6px;
    position: absolute;
    top: 4px;
    width: 10px;
    z-index: 1;
}

.content {
    margin-left: 23px;
}
