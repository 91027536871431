
@use "client/css/globals" as *;

.container {
    align-items: center;
    display: flex;

    > .icon-wrapper {
        &.base {
            margin-right: 10px;
        }

        &.normal {
            margin-right: 20px;
        }

        &.extended {
            margin-right: 30px;
        }
    }
}

.title-wrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-weight: 600;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        /*
            Font size ramp for the title
        */
        &.micro {
            font-size: $fontSizeMicro;
        }

        &.small {
            font-size: $fontSizeSmall;
        }

        &.base {
            font-size: $fontSizeBase;
        }

        &.medium {
            font-size: $fontSizeMedium;
        }

        &.large {
            font-size: $fontSizeLarge;
        }

        &.larger {
            font-size: $fontSizeLarger;
        }

        &.huge {
            font-size: $fontSizeHuge;
        }

        /*
            Spacing ramp for the margin between the title & subtitle
        */
        &.default {
            margin-bottom: 2px;
        }

        &.none {
            margin-bottom: 0;
        }

        &.xs {
            margin-bottom: 5px;
        }

        &.s {
            margin-bottom: 10px;
        }
    }

    .subtitle-wrapper,
    .subtitle {
        font-size: $fontSizeSmall;
        font-weight: 400;
    }

    .subtitle {
        display: inline-block;
    }
}
