
textarea,
input {
    width: 100%;
}

.add-issue-container {
    display: grid;
    grid-row-gap: 15px;
}

.comment-textarea {
    resize: vertical;
}
