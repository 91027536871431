
@use "client/css/globals" as *;

::v-deep .tax-section-card {
    max-width: 885px;
}

::v-deep div.multiselect--disabled {
    background: $backgroundColorSecondary;
}

.centered-heading {
    display: flex;
    justify-content: center;
}
