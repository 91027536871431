
@use "client/css/globals" as *;

.page {
    background-color: $pilotText;
    color: $brandColorPrimaryContrast;
    display: grid;
    gap: 20px;
    grid-template:
        "header" auto
        "content" 1fr
        / auto;
    min-height: 100vh;
    padding: 40px;
}

.header {
    align-items: center;
    display: grid;
    gap: 30px;
    grid-auto-flow: column;
    justify-content: space-between;

    .controls {
        align-items: center;
        display: grid;
        gap: 45px;
        grid-auto-flow: column;
    }
}

.content {
    /*
        How to read `grid-template`:

        - "area1 area2" = names of grid areas in row
        - "___ ___" height = height of row
        - repeated "___" ___ = rows
        - / width1 width2 = widths of columns

        This translates to:

        - first row (heading) is determined by height of content (auto)
        - second row (main, sidebar) takes the remaining height (1fr)
        - second column (heading, main) is 670px wide
        - first and third columns (sidebar) split the remaining horizontal space (1fr / 1fr)
    */

    display: grid;
    gap: 40px 30px;
    grid-template:
        ". heading ." auto
        "aside main sidebar" 1fr
        / 1fr 670px 1fr;

    .heading {
        grid-area: heading;

        .primary,
        .secondary {
            font-weight: normal;
            margin: 0;
            text-align: center;
        }

        .secondary {
            font-size: $fontSizeBase;
            margin-top: 10px;
        }
    }

    .main {
        grid-area: main;
    }

    .aside {
        grid-area: aside;

        .sticky {
            margin-top: 50px;
            position: sticky;
            top: 25px;
        }
    }

    .sidebar {
        grid-area: sidebar;

        .sticky {
            position: sticky;
            top: 25px;
        }
    }
}

.save-state {
    color: $highlightPurple;

    &::after {
        /*
            We want to use the `::after` psuedoelement to render a small circle. To do this, we
            first set `content: ""` and `display: inline-block`. We then set `height` and `width` to
            our desired dimensions and apply `border-radius: 50%` to make it circular. We set
            `background-color: currentColor` to make the circle take on the text color of its
            parent element. To get vertical alignment, we set `vertical-align: middle` and make a
            slight adjustment via `transform: translateY(-50%)` so that it aligns on its own center,
            not its top. Finally, we space it from its neighbors with `margin` instead of `padding`
            (since that would increase the size of the circle). We make the right margin slightly
            smaller to account for extra space added by the sibling element.
        */
        background-color: currentColor;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 2px;
        margin: 0 7px 0 10px;
        position: relative;
        transform: translateY(-50%);
        vertical-align: middle;
        width: 2px;
    }
}
