
@use "client/css/globals" as *;

.assignee-item {
    display: flex;
    justify-content: space-between;

    .assignee-name {
        margin-right: $spacingMedium;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .assignee-kind-description {
        color: $contentColorTertiary;
        flex-grow: 0;
        font-size: $fontSizeSmall;
        font-style: italic;
        max-width: 175px;
        overflow: hidden;
        padding-right: 2px; // so italics don't get cut off
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

// terrible hack: multiselect isn't applying the correct class to the group labels
// instead of multiselect__option--group, it applies both the --disabled and --selected classes
// when an option is selected, the --selected class is removed but the --disabled class stays
// we never expect to have disabled options in this component, so I am targeting them
// because they those elements are under control of VueMultiselect, I must use ::v-deep
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

.group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}
