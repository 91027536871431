
@use "client/css/globals" as *;

.pilot-page-content {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    box-shadow: 0 4px 14px rgba(66, 61, 113, 0.08);
    padding: $spacingMediumLarge;

    .buttons {
        margin-top: $spacingMediumLarge;
    }

    .pilot-page-centered & {
        margin: 0 auto;
        max-width: $fieldWidthBase;
        padding: $spacingLarge;
    }
}

@media (max-width: 559px) {
    .pilot-main {
        padding: 0 $spacingMedium;
    }

    .pilot-page-content {
        .buttons {
            flex-wrap: wrap;

            .button {
                margin: 0 0 10px;
            }
        }
    }
}
