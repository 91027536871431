
.margin-top {
    margin-top: 10px;
}

::v-deep .tab {
    font-weight: bold; // override Tabs component default font-weight
}

.dashboard-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.bold-text {
    font-weight: bold;
}

.icon-info {
    vertical-align: middle;
}

.title p {
    font-weight: normal;
}

.flex-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.icon-ellipse {
    margin-right: 5px;
}

.account-container {
    display: flex;
    gap: 15px;
}

.top-transactions-table {
    margin-top: 20px;
}
