
@use "client/css/globals" as *;

$verticalPadding: 95px;

::v-deep .section-status-box {
    min-width: 260px;
}

.tax-profile-form-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.onboarding-form {
    min-width: 670px;
}

.onboarding-section-status,
.left-gutter {
    // CSS grid would be nice
    display: flex;
    justify-content: center;
    width: 100%;
}

.onboarding-section-status {
    margin-left: $spacingLarge;
}
