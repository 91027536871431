
@use "client/css/globals" as *;

$extraStuckPadding: 20px;

.sticky-container {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    position: relative;

    .content {
        padding: 0 25px $spacingMediumLarge;
        transition: none;
    }
}

.sticky_sentinel {
    left: 0;
    position: absolute;
    right: 0;
    visibility: hidden;
    z-index: 1000;

    &--top {
        height: 40px;
        top: -30px;
    }
}

.sticky-header {
    align-items: center;
    background: $backgroundColorPrimary;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    position: sticky;
    top: 0;
    z-index: 3;

    &.stuck {
        border-bottom: 1px solid $borderColorLight;
        border-top: 1px solid $borderColorLight;
        left: 0;
        margin-bottom: 0;
        padding-bottom: 25px;
        right: 0;
    }
}
