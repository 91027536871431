
@use "client/css/globals" as *;

.header {
    margin-bottom: $spacingMediumLarge;
}

.header-text-container {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.header-sub-text {
    color: $contentColorSecondary;
    margin: 0;
}

.mdr-card-wrapper {
    align-items: center;
    background-color: rgba($pastelUltramarine, 0.5);
    display: flex;
    flex-direction: column;
    padding: 20px;

    .icon {
        margin-bottom: 10px;
    }

    .heading {
        margin-bottom: 8px;
    }

    .text {
        color: $contentColorPrimary;
        margin-bottom: 35px;
        text-align: center;
        width: 395px;
    }
}
