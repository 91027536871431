
@use "client/css/globals" as *;

::v-deep #vendor-inspector {
    min-height: 620px; // to reduce chance of jitter from one page to the next

    .actions {
        margin-top: auto;
    }
}

.table-bordered-wrapper {
    flex-grow: 1;
}

.vendor-inspector-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacingMediumSmall;
}

.account-current-month {
    background-color: $brightPurple;
}

.top-bar {
    background-color: $backgroundColorPrimary;
}

.missing {
    color: $contentColorTertiary;
    padding: $spacingLarge;
    text-align: center;
}

.message {
    flex-grow: 1;
    margin-right: $spacingMediumSmall;
}

.failure-comments {
    margin-right: $spacingMediumSmall;
}
