
$mobileMaxWidth: 559px; // SMALL_SCREEN_MAX_WIDTH

.header-text {
    font-weight: bold;
}

.filters-container {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .filter {
        flex: 1;

        @media (max-width: $mobileMaxWidth) {
            margin-bottom: 10px;
        }
    }

    @media (max-width: $mobileMaxWidth) {
        display: block;
    }
}

::v-deep .split {
    @media (max-width: $mobileMaxWidth) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

::v-deep .multiselect {
    width: 100%;

    @media (max-width: $mobileMaxWidth) {
        padding: 0;
    }
}
