
@use "client/css/globals" as *;

.billing-section {
    margin-bottom: 30px;
}

.billing-note {
    font-style: italic;
}

.billing-action-button {
    margin-bottom: 10px;
    margin-top: 10px;
}

.warning {
    color: $red1;
    font-weight: bold;
}
