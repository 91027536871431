
@use "client/css/globals" as *;

.no-sync-modal-content {
    align-items: flex-start;
    align-self: stretch;
    background-color: $backgroundColorPrimary;
    display: flex;
    gap: 20px;
    padding: 20px 30px;
}

.icon-documentation {
    flex: 0 0 80px; /* flex-grow: 0, flex-shrink: 0, flex-basis: 80px */
    height: 80px;
}

.content-header {
    font-weight: 600;
}
