
@use "client/css/globals" as *;
@use "client/modules/institutions-modal";

.save-and-close-button {
    background: none;
    border: none;
    color: $pilotPurple;

    &:hover,
    &:focus {
        color: $matteBlue;
        color: $pilotText;
        text-decoration: underline;
    }
}

.wizard-step-title {
    margin-bottom: $spacingMedium;
    margin-top: $spacingSmaller;
}
