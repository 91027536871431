
@use "client/css/globals" as *;

.transition-card-section {
    margin-bottom: 30px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.other-user-header {
    display: flex;
    justify-content: space-between;

    .icon {
        margin-right: 5px;
    }

    h2 {
        font-size: $fontSizeBase;
        margin: 0;
    }
}

.transition-card-heading {
    border-bottom: 1px solid $borderColorLight;
    font-size: $fontSizeBase;
    margin: 0;
    padding: 15px 20px;
}

.auto-managed-text {
    color: $darkGrey;
    padding-left: 20px;
    padding-top: 15px;
}

.buttons {
    display: flex;
    justify-content: right;
}

.new-user-form {
    margin: 15px 25px 15px 15px;

    .textboxes {
        margin-bottom: 5px;
    }

    p {
        font-size: $fontSizeSmall;
    }
}

.subtitle-styling {
    display: flex;
    flex-direction: column;

    p {
        margin-bottom: 5px;
    }
}

.external-user-rows {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        padding: 15px;
    }
}

li.should-show-border {
    border-bottom: 1px solid $borderColorLight;
}
