
.body-text {
    font-weight: normal;
    line-height: 150%;
}

.padding-bottom {
    padding-bottom: 20px;
}

.heading {
    margin-bottom: 10px;
    padding: 0 30px;
}
