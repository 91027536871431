
.file-attachment-card {
    // Reasonable-looking number to prevent cards from spanning whole screen when screen is very wide.
    max-width: 800px;
}

.download:hover {
    cursor: pointer;
}

.file-upload-attachments {
    display: grid;
    grid-gap: 10px 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}
