
@use "client/css/globals" as *;

.reports-container {
    font-size: $fontSizeBase;
    padding: 0;

    .header {
        font-weight: 600;
    }
}

.reports-wrapper {
    align-items: center;
    border-bottom: 1px solid $pastelViolet;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 15px;
}

ul {
    margin: 0;
    padding-inline-start: 0;
}

li {
    list-style-type: none;
}

.report-list-item {
    align-items: center;
    border-bottom: 1px solid $pastelViolet;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;

    .icon-w-title {
        display: flex;
        justify-content: center;

        .icon {
            margin-right: 17px;
        }
    }

    .updatedOn {
        color: $offBlack;
        font-size: $fontSizeSmall;
    }

    .item-right-container {
        align-items: center;
        display: flex;
    }
}

.add-report-button {
    align-items: center;
    display: flex;
    gap: 10px;
}

.add-report-button-row {
    padding: 15px 30px;
}

.file-icon {
    margin-right: 20px;
}

.delete-icon {
    color: $contentColorSecondary;
    margin-left: 25px;
}

.disabled-delete-icon {
    color: $contentColorTertiary !important;
    pointer-events: none;
}
