
.under-maintenance-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 60px;
    padding-top: 60px;

    h2 {
        margin: 0;
        text-align: center;
    }
}
