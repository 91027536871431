
@use "client/css/globals" as *;

.container {
    align-items: flex-start;
    display: flex;
}

.icon {
    flex: 0 0 24px;
    margin-right: 10px;
}

.heading {
    font-weight: bold;
    margin-bottom: 5px;
}

.body {
    margin-bottom: 0; // reset global style
}

.internal-link {
    color: $brandColorPrimary;
}
