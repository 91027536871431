
@use "client/css/globals" as *;

$cardSpacing: 25px; // spacing used in designs but not defined in global

.onboarding-contact {
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusLarge;
}

.contact-header {
    align-items: center;
    border-bottom: 1px dashed $borderColorLight;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: $spacingMediumSmall $cardSpacing;
}

.contact-body {
    font-size: $fontSizeSmall;
    padding: $cardSpacing;
}

.email-button {
    font-size: inherit;
    margin-top: $spacingSmall;
}
