
@use "client/css/globals" as *;

.clear-button {
    cursor: pointer;
    width: 100%;

    &:disabled {
        border-color: $borderColorLight;
        cursor: default;

        &:focus,
        &:hover {
            box-shadow: none;
        }
    }
}

.data-requests-task {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    flex-direction: column;
    padding: $spacingMedium;
    text-align: left;
    transition: 0.15s ease-out box-shadow border;
    width: 100%;

    &:hover {
        box-shadow: $purpleShadow;
    }

    .onboarding-card-upload-side {
        align-items: center;
        display: flex;
        gap: 20px;
        justify-content: center;

        .disable-focus {
            // override default base button style so it doesn't stay highlighted after being clicked
            &:focus {
                background: transparent;
                color: $brandColorPrimary;
            }
        }
    }
}

.data-requests-task-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.data-requests-task-uploads {
    border-top: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;

    .deep-card-styling {
        ::v-deep .file-card-compact {
            background: inherit;
        }

        ::v-deep .remove-button {
            margin-right: 5px;
            transform: scale(1.33);
        }
    }
}
