
::v-deep .header {
    justify-content: space-between;

    .text {
        max-width: 60%;
    }
}

.insights-page {
    > *:not(:last-child) {
        margin-bottom: 30px;
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
    padding: 25px 30px 0;
}

.vendor-count {
    font-weight: bold;
}

.new-vendors-header-container {
    display: flex;
}

.navigation-header {
    align-self: center;
}

h1.banner-header-text {
    margin-bottom: 0;
}

.dropdown-item {
    font-weight: normal;
}

::v-deep .header.medium {
    text-decoration: underline;
}
