@use "globals" as *;
@forward "typography";
@forward "table";
@forward "form";
@forward "list";
@forward "button";

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body {
    background-color: $backgroundColorSecondary;
    min-height: 100vh;
}

html.brand-color-background,
body.brand-color-background {
    background-color: $brandColorPrimaryDark;
}

body {
    box-sizing: border-box;
    color: $contentColorPrimary;
    font-family: Euclid, sans-serif;
    font-size: $fontSizeBase;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: $defaultLineHeightScalar;
    margin: 0;
}

#zapgram {
    min-height: 100vh;
}

img {
    display: inline-block;
    height: auto;
    max-width: 100%;
}

.error,
.errors {
    color: $red1;
}

.content-secondary {
    color: $contentColorSecondary;
}

.content-tertiary {
    color: $contentColorTertiary;
}

.invisible {
    height: 1px;
    left: -9999px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

.invisible-inline {
    height: 1px;
    opacity: 0.01;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

.invisible-placeholder {
    opacity: 0.01;
    pointer-events: none;
}

.checkmark {
    height: 14px;
    width: 14px;

    @include checkmark(0);
}

// Mobile

@media (max-width: 559px) {
    h1 {
        font-size: $fontSizeLarge;
    }

    h2 {
        font-size: $fontSizeMedium;
    }

    h3 {
        font-size: $fontSizeBase;
    }

    ul {
        padding-left: $spacingMedium;
    }

    td,
    th {
        table.more-spacing & {
            padding-bottom: $spacingSmall;
            padding-top: $spacingSmall;
        }
    }

    .button,
    input,
    textarea,
    select,
    .multiselect {
        width: 100%;
    }

    input,
    textarea,
    select,
    .multiselect {
        font-size: $fontSizeSmall;
        padding: 14px 12px;

        &.large {
            font-size: $fontSizeBase;
        }
    }
}
