
@use "client/css/globals" as *;

.tax-help-container {
    background-color: $lightGrey;
    border: 1px solid $borderColorLight;
    padding: 20px;

    .tax-help-link {
        line-height: 0;
        margin-top: 15px;
    }
}
