
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

hr {
    margin: 0;
}
