
@use "client/css/globals" as *;
@use "client/modules/portal-activity/css/cash-flow-and-spend-report-table";

.date {
    color: $contentColorSecondary;
}

.vendor-logo {
    flex: 0 0 32px;
    margin-right: 10px;
}

.categories {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tag {
    border-radius: 0;
    justify-content: center;
    margin: 0;
    padding: 0;
}
