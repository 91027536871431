
@use "client/css/globals" as *;
@use "./tick-and-tie";

.tick-and-tie-txn {
    transition: background-color 0.15s ease-in-out;
}

.future-txn {
    color: $contentColorSecondary;
}

.selectable-txn:hover {
    background: $backgroundTonalGrey;
    cursor: pointer;
}

.selected-txn {
    background: $purpleSelectionBackground;

    &:hover {
        background: $purpleSelectionHoverBackground;
    }
}

.date-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    .date-tooltip {
        margin-left: 5px;
    }
}

.tick-and-tie-memo,
.tick-and-tie-vendor {
    word-break: normal;
}

.show-on-hover {
    opacity: 0;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 1;
    }
}
