
@use "client/css/globals" as *;

section {
    margin-bottom: 25px;
}

.onboarding-section {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
    padding: $spacingLarge;

    &.intro-section {
        font-size: $fontSizeMedium;
    }
}

.section-heading {
    color: $pilotText;
}
