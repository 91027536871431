
.button-container {
    display: flex;
    justify-content: flex-end;
}

::v-deep .button.small {
    padding-left: 30px;
    padding-right: 30px;
}
