
@use "client/css/globals" as *;

.permissions {
    background: $backgroundColorSecondary;
}

#institution-instructions {
    min-height: 160px;
}

#institution-details > .columns > .column {
    padding-top: 0;
}

#institution-fields {
    margin-bottom: $spacingMedium;
}

$previewSize: 92px;

#logo-url-container {
    input {
        width: calc(100% - #{$previewSize + $spacingSmall});
    }

    .preview {
        position: absolute;
        right: 0;
        top: 0;
    }
}

#institution-preview-container {
    flex: 1 1 auto;
    margin-left: $spacingLarger;

    h2 {
        color: $contentColorSecondary;
    }
}

.institution-preview-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: $topForStickyPosition;
}

#institution-preview {
    border: 2px solid $borderColorDark;
    padding: $spacingMedium;
    position: relative;
    word-wrap: anywhere;

    &::after {
        background: rgba(255, 255, 255, 0.5);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
