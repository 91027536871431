
@use "client/css/globals" as *;

.settings-text {
    font-weight: bold;
}

::v-deep .tab {
    font-weight: bold;
}

.navigation {
    // TODO: Make the separator match the spec
    border-bottom: 1px solid $borderColorLight;
    margin-bottom: 30px;
    overflow-x: auto;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        font-size: 18px;
        font-weight: bold;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .nav-link {
            align-items: center;
            border-bottom: 3px solid transparent; // color the border when active (un-scoped style block)
            display: flex;
            flex-wrap: nowrap;
            gap: 5px;
            padding-bottom: 15px;
        }

        a {
            color: inherit;
            white-space: nowrap;

            &:hover {
                color: $brandColorPrimary;
                text-decoration: none;
            }
        }
    }

    @media (max-width: 1100px) {
        li {
            font-size: calc(12px + 0.5vw);
        }
    }
}

// This can't be in a style block with module because that changes the name of the
// class of the rendered style + component. GatedRouterLink requires an exact class
// name. We must use !important due to the unspecific selector
.active-tab {
    border-bottom-color: $brandColorPrimary !important;
    color: $brandColorPrimary !important;
}

.icon-ellipse {
    margin-left: 5px;
}
