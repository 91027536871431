
@use "client/css/globals" as *;

#import-paypal-csv {
    .date-range {
        max-width: $fieldWidthBase;
    }
}

.response-error {
    background: $red1Background;
    border-radius: $borderRadiusLarge;
    color: $red1;
    margin-top: $spacingMedium;
    padding: $spacingSmall $spacingMediumSmall;
}

.selected-file-row {
    vertical-align: text-top;
}

.csv-importing-step {
    &.disabled {
        color: $contentColorSecondary;
    }

    &.disabled .error {
        color: $red1Desaturated;
    }
}
