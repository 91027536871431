
@use "sass:math";
@use "client/css/globals" as *;

$buttonSize: 14px; // copied from base-close-button.vue

::v-deep .input {
    margin-top: 5px;
}

::v-deep .tax-input-wrapper {
    margin-top: 0;
}

.tax-field {
    margin-bottom: 20px;

    &.tax-id-number {
        position: relative;
    }

    .tax-label {
        font-weight: bold;
    }

    .tax-multiselect {
        margin-top: 5px;

        &.warning > ::v-deep .multiselect__tags {
            border: 1px solid #f4b13e;
        }
    }

    .optional-field {
        margin-top: 20px;
    }

    .remove-btn {
        margin-top: 10px;
    }

    .tax-field-row {
        align-items: flex-start;
        display: flex;
        margin-bottom: 5px;

        .full {
            width: 100%;
        }

        .half {
            width: calc(100% / 2);

            &:nth-of-type(n + 2) {
                margin-left: 10px;
            }
        }

        .third {
            width: calc(100% / 3);

            &:nth-of-type(n + 2) {
                margin-left: 10px;
            }
        }
    }

    // for screen readers: https://webaim.org/techniques/css/invisiblecontent/
    .hidden-label-text {
        height: 1px;
        left: -10000px;
        overflow: hidden;
        position: absolute;
        top: auto;
        width: 1px;
    }

    .clear-tax-id-number-btn {
        bottom: math.div($fieldValueHeight - $buttonSize, 2);
        position: absolute;
        right: math.div($fieldValueHeight - $buttonSize, 2);
    }
}

.required-value {
    color: $darkGrey;
    font-size: $fontSizeBase;
    margin: 0;
}

.btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
