
@use "client/css/globals" as *;

.add-connection-modal > ::v-deep.modal {
    background-color: $backgroundColorPrimary;
}

// modal.vue typically does some magic to determine whether to dynamically insert overflow-y: auto or not
// For some reason, it does not trigger when the transactions table rows load in, resulting in no scrollbar
// Always set overflow-y: auto for this usage
::v-deep .modal-body {
    overflow-y: auto;
}

p:last-child {
    margin: 0; // reset global style
}

.section-action {
    margin-top: $spacingMediumSmall;
}

.skip-text-container {
    padding-top: 10px;
}

p.modal-section-step-display {
    color: $contentColorSecondary;
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 40px;
    margin-top: 40px;
}

h1.modal-section-title {
    font-size: $fontSizeLarge;
    line-height: 1.4;
    margin-bottom: 15px;
    margin-left: 40px;

    &.wizard-complete {
        margin-top: 40px;
    }
}

h2.single-connection-title {
    margin-top: 20px;
}

.modal-section-subheader {
    color: $contentColorSecondary;
    font-weight: 300;
    text-align: center;
}

.modal-body-container {
    margin-left: 40px;
    margin-right: 40px;
}

.search-icon-wrapper {
    margin: 5px 40px 15px;
    position: relative;

    .search-icon {
        left: 10px;
        position: absolute;
        top: 20%;
    }

    .search-input {
        padding-left: 40px;
        padding-top: 10px;
    }
}

::v-deep textarea {
    width: 590px; // magic number the same width as input
}
