
@use "client/css/globals" as *;

.tick-and-tie-group {
    margin: $spacingLarge 0;
}

.tick-and-tie-column-name {
    width: 40%;
}

.tick-and-tie-column-date {
    text-align: center;
    width: 15%;
}

.tick-and-tie-column-button {
    width: 15%;
}
