
@use "client/css/globals" as *;

.label-container {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: auto 1fr;

    .institution-img {
        align-items: center;
        border-radius: 5px;
        display: flex;
        width: 40px;

        &.default {
            border: 1px solid $borderColorLight;
            border-radius: 5px;
        }
    }

    .default-height {
        height: 40px;
    }

    .account-name-section {
        flex-direction: column;
    }
}
