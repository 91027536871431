
@use "client/css/globals" as *;

$popoverRadius: 5px;

.popover-container {
    display: inline-block;
    position: relative;
}

.popover-target {
    cursor: pointer;
    display: inline-block;

    > *:last-child {
        /* Get proper alignment if the target is a buttons */
        margin-right: 0;
    }
}

.popover {
    background: $backgroundColorPrimary;
    border-radius: $popoverRadius;
    box-shadow: $flyoutShadow;
    left: 0;
    margin: 10px 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    z-index: $zIndexFlyout;

    .popover-align-right & {
        left: auto;
        right: 0;
    }

    .popover-position-top & {
        bottom: 100%;
        top: auto;
    }

    &.popover-shown {
        pointer-events: all;
    }

    a {
        cursor: pointer;
    }

    > ul {
        margin: 0;
        padding: 0;

        > li {
            border-bottom: 1px solid $borderColorLight;
            list-style-type: none;
            margin: 0;
            padding: 15px;

            &:last-child {
                border-bottom: none;
            }

            $popoverLinkSpacing: 5px;

            > a {
                color: $contentColorPrimary;
                display: block;
                margin: (-15px + $popoverLinkSpacing) (-15px);
                padding: (15px - $popoverLinkSpacing) 15px;
                white-space: nowrap;

                &:hover,
                &:focus {
                    background: $backgroundColorSecondary;
                    text-decoration: none;
                }
            }
        }
    }
}
