
@use "client/css/globals" as *;

// also used in tick-and-tie-suggestion.vue
.tnt-txn-metadata {
    color: $contentColorSecondary;
    display: flex;
    font-size: $fontSizeSmall;
    line-height: 21px;

    &:last-child {
        margin-bottom: 0;
    }

    label {
        flex-shrink: 0;
        font-weight: bold;
        width: 6em;
    }
}
