
@use "client/css/globals" as *;

.layout {
    display: grid;
    gap: 30px;
    height: 100%;
}

a {
    cursor: pointer;

    &:hover {
        color: $pilotPurple;
    }
}
