
.header {
    margin-bottom: 10px;
    margin-left: 30px;
}

.divider {
    margin-bottom: 0;
    margin-top: 15px;
}

.list-wrapper {
    align-items: flex-start;
    display: grid;
    gap: 15px;
    grid-template-rows: auto;
    justify-content: flex-start;

    .account-list {
        display: grid;
        gap: 15px;
        grid-template-columns: 400px;
        grid-template-rows: auto;
    }
}
