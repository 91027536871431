
@use "client/css/globals" as *;

.burn-rate-table {
    border-bottom-left-radius: $borderRadiusLarge;
    border-bottom-right-radius: $borderRadiusLarge;
    overflow: auto;
}

.row-header-text {
    white-space: nowrap;
}

.table-cell {
    justify-content: flex-end;
}

::v-deep .pilot-table-header-cell {
    justify-content: flex-end;
}

::v-deep .pilot-table-row-cell {
    border-top: 1px solid $backgroundColorTertiary;
}
