@use "globals" as *;

form {
    text-align: left;
}

fieldset {
    border: 1px solid $borderColorLight;
    margin: 0 0 $spacingMedium;
    padding: $spacingSmall;
}

.fields {
    &.inline {
        // for rows with multiple inputs, one input may have an error message while others don't
        // align at flex start (the top) so that inputs are aligned along a consistent axis
        align-items: flex-start;
        display: flex;
        flex-direction: row;

        > .button,
        > .buttons {
            align-self: flex-end;
        }
    }
}

.field {
    margin: 0 0 $spacingMedium;
    position: relative;

    &.radio,
    &.checkbox {
        input {
            display: inline;
            margin: 0;
        }

        label,
        .label {
            display: inline;
            margin: 0;

            & + input {
                margin-left: $spacingMedium;
            }
        }
    }

    &.compressed {
        margin: 0 0 $spacingSmall;

        label {
            margin-bottom: 0;
        }
    }

    .fields.inline & {
        margin: 0 $spacingMedium 0 0;

        &:last-child {
            margin-right: 0;
        }

        &.radio,
        &.checkbox {
            align-items: center;
            align-self: flex-end;
            display: flex;
            height: $fieldValueHeight;

            input {
                margin-right: $spacingSmaller;
            }
        }
    }

    .fields.inline-full & {
        flex: 1 1 auto;
        width: 50px; //  Force inputs to a smaller size to allow growth
    }

    .fields.inline.more-spacing & {
        margin: 0 $spacingHuge 0 0;
    }

    .fields.adjacent & {
        align-items: baseline;
        display: flex;

        > label,
        > .label {
            flex: 0 0 10em;
            font-weight: bold;
            margin-right: $spacingMedium;
        }
    }

    .error-message {
        color: $red1;
        font-size: $fontSizeBase;
    }

    label,
    .label {
        display: block;
        font-size: $fontSizeBase;
        margin: 0;
    }

    .tip {
        color: $contentColorSecondary;
        display: block;
        margin: $spacingSmallest 0;
    }
}

label,
.label {
    .required,
    &.required {
        &::after {
            color: $red1;
            content: "*";
            position: relative;
            right: -2px;
            top: -3px;
        }
    }
}

button {
    font-family: Euclid, sans-serif;
    font-size: $fontSizeBase;
}

input,
textarea,
select,
.input {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    color: $contentColorPrimary;
    font-family: Euclid, sans-serif;
    font-size: $fontSizeBase;
    padding: $inputPaddingTop $inputPaddingHorizontal $inputPaddingBottom;

    &.large {
        font-size: $fontSizeMedium;
        padding: 15px 17px 16px;
    }

    .field.has-error & {
        border-color: $red1Light;
    }
}

input,
textarea,
select,
.multiselect,
.input {
    max-width: 100%;
    width: $fieldWidthBase;

    &:focus,
    &.is-focused,
    &.multiselect--active {
        border-color: $brandColorPrimary;
        outline: none;
    }

    &.wide {
        width: $fieldWidthWide;
    }

    .field.full-width &,
    .modal & {
        width: 100%;
    }
}

input[type="radio"],
input[type="checkbox"] {
    height: auto;
    width: auto;

    .modal & {
        width: auto;
    }
}

input[type="range"] {
    background: transparent;
    border: none;
    padding: 0;
}

input:disabled,
select:disabled,
textarea:disabled {
    background: $backgroundTonalGrey;
}

textarea {
    display: block;
    min-height: 60px;
}
