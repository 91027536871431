
@use "client/css/globals" as *;

.question-list-container {
    padding: 30px 30px 20px;
}

.footer-container {
    align-items: center;
    border-top: 1px solid $backgroundColorTertiary;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px 15px 73px;

    .auto-save-text {
        color: $contentColorSecondary;
        margin: 0;
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;

        button {
            padding: 4px 10px;
        }

        .cancel-questions-btn,
        .submit-questions-btn {
            & ::v-deep .spinner {
                right: calc(50% - 11px);
            }
        }
    }
}
