
@use "client/css/globals" as *;

.no-credentials-message {
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding: 30px;
}

.select-and-notes {
    max-width: 480px;
}

.account-notes {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    font: inherit;
    margin-top: 10px;
    padding: 10px;
}

.group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: 600;
}

.edit-button {
    margin: 0;
}

/* See client/modules/categorize/_components/account-picker/narrowed-account-picker.vue for explanation */
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

/* This selects the special "None Hidden Group" multiselect group, documented
   in comments above. */
::v-deep .multiselect__element:first-of-type {
    display: none;
}

.label-and-button {
    align-content: stretch;
    align-items: baseline;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 55px;
}

.label-and-button label {
    font-weight: 600;
}

.tip {
    color: gray;
    font-size: 10;
    font-weight: 400;
}

hr {
    margin: 0;
    padding: 0;
}
