
@use "client/css/globals" as *;

// reset ul styles
ul.contact-list {
    margin: 0;
    padding: 0;
}

.contact-info {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: $spacingMedium;
    }
}

.contact-name {
    font-weight: bold;
}

.contact-service {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

// similar to .tag in `client/css/connections.scss`
// this is a global style which we can't access due to `module` style attr
.contact-tag {
    background: $pastelViolet;
    border: none;
    border-radius: 4px;
    color: $pilotText;
    font-size: $fontSizeSmall;
    padding: $spacingSmallest $spacingMediumSmall;
}

@media (max-width: 559px) {
    .button {
        width: auto; // Disable style in _base.scss
    }
}

.card-info {
    padding: 25px;
}
