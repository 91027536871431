
@use "client/css/globals" as *;

.rule-changes-container {
    .page-loading-error {
        opacity: 0.5;
    }
}

.rule-change {
    background: $backgroundColorSecondary;
    margin-bottom: $spacingMedium;
}

.rule-change-metadata {
    background: $backgroundColorTertiary;
    padding: $spacingSmall $spacingMedium;

    > * {
        margin-right: $spacingLarge;
    }
}

.rule-change-timestamp {
    font-weight: bold;
}

.diff-added {
    background: $green1Background;
    color: $green1;
}

.diff-removed {
    background: $red1Background;
    color: $red1;
}

.rule-change-diff {
    background: $backgroundColorSecondary;
    font-family: monospace;
    white-space: pre-wrap;

    > * {
        padding: 0 $spacingMedium;
    }
}
