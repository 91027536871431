
@use "client/css/globals" as *;

.checklist-comments-container {
    background: $backgroundColorSecondary;
    border-radius: 6px;
    margin-bottom: $spacingMedium;
    padding: $spacingMediumSmall;

    .checklist-comments {
        padding-top: 0;
    }
}

.checklist-edit-comment-container {
    padding-bottom: $spacingSmall;
    transition: all 0.2s;

    .checklist-comments + & {
        border-top: 1px solid $borderColorLight;
        padding-top: $spacingSmall;
    }

    &.new-comment-slide-enter,
    &.new-comment-slide-leave-to {
        height: 0 !important; // override v-slide
        margin-bottom: 0;
        margin-top: 0;
        overflow-y: hidden;
        padding-bottom: 0;
        padding-top: 0;
    }

    &.new-comment-slide-enter-active {
        overflow-y: hidden;
    }
}
