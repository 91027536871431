
@use "client/css/globals" as *;

.onboarding-progress-bar {
    margin-bottom: $spacingMediumLarge;
}

.flex-container {
    align-items: center;
    display: flex;
}

.institution-img {
    border-radius: 5px;
    margin-right: 10px;
    width: 40px;

    &.default {
        border: 1px solid $borderColorLight;
        border-radius: 5px;
    }
}
