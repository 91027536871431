
@use "sass:math";
@use "client/css/globals" as *;

$trackHeight: 5px;
$goalMarkDiameter: 13px;
$goalMarkRadius: math.div($goalMarkDiameter, 2);
$goalMarkInnerDiameter: 7px;

.progress-bar {
    align-items: center;
    display: flex;
    height: $trackHeight;
    position: relative;
    width: 100%;

    &.has-goal {
        height: $goalMarkDiameter;
    }
}

.track {
    background: $backgroundColorTertiary;
    border-radius: $trackHeight;
    height: $trackHeight;
    width: 100%;

    .has-goal & {
        width: calc(100% - $goalMarkRadius);
    }

    &.full-width {
        border-radius: 0;
    }
}

.meter {
    border-radius: 10px;
    height: $trackHeight;
    width: 0;

    .primary & {
        background: $brandColorPrimary;
    }

    .secondary & {
        background: $brandColorSecondary;
    }

    &.full-width {
        border-radius: 0 10px 10px 0;

        &.complete {
            border-radius: 0;
        }
    }
}

.goal-mark,
.end-goal-mark {
    border-radius: 100%;
    display: none;
    height: $goalMarkDiameter;
    position: absolute;
    width: $goalMarkDiameter;
}

.goal-mark-wrapper {
    height: 100%;
    left: -$goalMarkRadius;
    position: absolute;
    top: 0;
    width: calc(100% - $goalMarkRadius);
}

.goal-mark {
    align-items: center;
    background: $backgroundColorPrimary;
    cursor: pointer;
    justify-content: center;
    z-index: $zIndexBase;

    .has-goal & {
        display: flex;
    }

    .primary & {
        box-shadow:
            0 2px 8px rgba(0, 0, 0, 0.1),
            0 0 3px $brandColorPrimary;
    }

    .secondary & {
        box-shadow:
            0 2px 8px rgba(0, 0, 0, 0.1),
            0 0 3px $brandColorSecondary;
    }

    &::after {
        border-radius: 100%;
        content: "";
        height: $goalMarkInnerDiameter;
        position: absolute;
        width: $goalMarkInnerDiameter;

        .primary & {
            background: $brandColorPrimary;
        }

        .secondary & {
            background: $brandColorSecondary;
        }
    }
}

.goal-text-popup {
    min-width: 60px;
}

.end-goal-mark {
    background: $backgroundColorTertiary;
    cursor: pointer;
    right: 0;

    .has-goal & {
        display: block;
    }

    .reached-goal.primary & {
        background: $brandColorPrimary;
    }

    .reached-goal.secondary & {
        background: $brandColorSecondary;
    }
}
