
@use "client/css/globals" as *;

.submit-button {
    margin-top: 30px;
}

::v-deep .tax-section-card {
    margin: $spacingMedium 0;

    &:nth-child(1) {
        margin-top: 0;
    }
}
