
@use "client/css/globals" as *;

.step-header {
    padding-bottom: $spacingSmall;
}

.document-options {
    display: flex;
    flex-direction: column;

    .document-option {
        background: $brandColorPrimaryContrast;
        border: 1px solid $borderColorLight;
        border-bottom: none;
        display: flex;
        height: 44px;
        width: 100%;

        &:first-child {
            border-radius: 5px 5px 0 0;
        }

        &:last-child {
            border-bottom: 1px solid $borderColorLight;
            border-radius: 0 0 5px 5px;
        }

        .document-option-label {
            align-content: center;
            height: 100%;
            padding-left: 10px;
            width: 100%;

            .radio-button {
                accent-color: $pilotPurple;
            }
        }
    }
}

.step-2-content {
    background-color: $backgroundColorPrimary;
    margin: $spacingSmall 45px;
    padding: $spacingSmall $spacingMediumSmall;

    .header-document-type {
        border-bottom: 1px solid $borderColorLight;
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;
    }

    .field-wrapper {
        padding-top: $spacingSmall;
    }

    .date-picker-wrapper {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        &::v-deep .calendar-icon-wrapper {
            flex: none;
        }

        .month-year-picker-container {
            padding-top: $spacingSmall;
        }

        .to-text {
            align-content: end;
            margin-bottom: 10px;
        }
    }
}
