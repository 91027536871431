
@use "client/css/globals" as *;

.container {
    font-size: $fontSizeBase;
    height: fit-content;
    margin-bottom: 30px;
}

p {
    margin: 0;
}

.txn-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.content {
    margin-bottom: 20px;
}

.next-up {
    margin-bottom: 5px;
}
