
@use "client/css/globals" as *;

// TODO: delete styles in _button.scss when this component is used everywhere.
.x {
    background: transparent;
    border: none;
    border-radius: 50%;
    color: $darkGrey;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 14px;

    &.no-hover {
        color: $contentColorPrimary;
    }

    &:focus {
        outline: 1px solid currentColor;
    }

    &::after,
    &::before {
        background: currentColor;
        content: "";
        display: block;
        height: 10px;
        left: 6px;
        position: absolute;
        top: 2px;
        width: 2px;
    }

    &::after {
        transform: rotate(45deg);
    }

    &::before {
        transform: rotate(-45deg);
    }

    &:not(.no-hover):hover {
        background: $red1;

        &::after,
        &::before {
            background: $backgroundColorPrimary;
        }
    }

    &.large {
        height: 32px;
        width: 32px;

        &::before,
        &::after {
            height: 20px;
            left: 15px;
            top: 6px;
        }
    }

    &.larger {
        height: 48px;
        width: 48px;

        &::before,
        &::after {
            height: 30px;
            left: 23px;
            top: 10px;
        }
    }
}
