
@use "client/css/globals" as *;

// Needed so that wrapped inputs can specify relative widths based on parent container
.validation-wrapper {
    width: 100%;
}

// JS-401

.has-error {
    ::v-deep input,
    ::v-deep textarea,
    ::v-deep .multiselect__tags {
        border-color: $red1Light;
    }
}

.has-warning {
    ::v-deep input,
    ::v-deep textarea,
    ::v-deep .multiselect__tags {
        border-color: $warningYellow;
    }
}

.bottom-text {
    margin-top: 5px;
}
