
@use "client/css/globals" as *;

::v-deep .modal {
    min-height: 396px;
}

::v-deep .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

table {
    margin: 0;
    width: 779px;
}

tbody {
    display: block;
    max-height: 341px;
    overflow: auto;
}

thead,
tbody > tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

tr {
    border-color: $pastelViolet;
}

tbody > tr {
    border: 0;
    border-bottom: 1px solid $pastelViolet;
    line-height: 26px;

    &:last-of-type {
        border: 0;
    }
}

.table-wrapper {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
}

.cell-vendor {
    padding: 15px 10px 15px 30px;
}

.cell-amount {
    padding: 15px 30px 15px 0;
}
