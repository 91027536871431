
@use "client/css/globals" as *;

header {
    display: flex;
    margin: 0 0 $spacingMedium;
    padding: 0 $spacingMediumLarge;

    .heading-column {
        flex: 1 1 auto;
    }

    .date-column,
    .amount-column {
        display: flex;
        flex-direction: column;
        font-size: $fontSizeMedium;
        margin: 0 $spacingMediumLarge;
    }

    .memo {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }

    .label {
        color: $contentColorTertiary;
        font-size: $fontSizeSmall;
    }

    .value {
        white-space: nowrap;
    }

    h4 {
        font-size: $fontSizeLarge;
        font-weight: normal;
    }
}
