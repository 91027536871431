
@use "client/css/globals" as *;

.text {
    line-height: 1.5;
}

.search-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.search-bar {
    position: relative;
    width: 100%;
}

input {
    width: 100%;
}

.clear-btn {
    display: flex;
    flex: none;
    position: absolute;
    right: 20px;
    top: 8.5px;

    @media (max-width: 560px) {
        display: none;
    }
}

.search-btn {
    margin-left: 20px;
    position: relative;
}

.info-text {
    color: $contentColorSecondary;
}

.layout {
    display: grid;
    gap: 30px;
    grid-template-rows: auto;
}

.checklist-container {
    position: relative;

    .pointer {
        left: 0%;
        margin: auto 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.searching {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loading-dots {
    margin-bottom: 26px;
}

.search-result-text {
    align-items: center;
    background: $backgroundColorSecondary;
    bottom: 0;
    color: $contentColorTertiary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 13px;
    position: sticky;
    width: 100%;
}

p {
    margin: 0;
}

h2 {
    margin: 0;
}
