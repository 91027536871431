
.revenue-waterfall-modal {
    // modal.vue calculates its overflow behavior on mount. Because we first render a
    // short loading indicator, the modal doesn't realize it needs to be scrollable once
    // it gets its real content.
    ::v-deep .modal {
        max-height: 90%;

        .modal-body {
            overflow-y: auto;
        }
    }

    .body-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .revenue-waterfall-modal-table {
        // prevent text from appearing above the stuck header as it scrolls by
        ::v-deep .sticky-header {
            top: -25px;
        }

        ::v-deep .pilot-table-header-cell {
            align-items: end;
            border-bottom: 1px solid #e0e0e0;
            height: 100%;
        }

        ::v-deep .pilot-table-body .pilot-table-row:first-child .pilot-table-row-cell {
            border-top: none;
        }

        ::v-deep .pilot-table-row-cell {
            word-break: break-word;
        }
    }
}
