
@use "client/css/globals" as *;

.checklist-progress-item-status {
    border-radius: 100%;
    height: 13px;
    width: 13px;

    .check-mark,
    .check-mark-circle {
        display: none;
    }

    &.in-progress {
        background: $backgroundDarkPrimaryContrast;
        box-shadow:
            0 2px 8px rgba(0, 0, 0, 0.1),
            0 0 3px $brandColorPrimary;

        circle {
            display: initial;
            fill: $brandColorPrimary;
        }
    }

    &.complete {
        circle {
            display: none;
        }

        .check-mark {
            display: initial;
        }

        .check-mark-circle {
            background: $backgroundDarkPrimaryContrast;
            border-radius: 100%;
            display: initial;
            height: 19px;
            left: -3px;
            position: absolute;
            top: -3px;
            width: 19px;
        }
    }

    svg {
        position: absolute;
        top: 0;
    }

    circle {
        fill: $contentColorTertiary;
    }
}
