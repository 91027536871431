
@use "client/css/globals" as *;

.drawer-content {
    @include modalContent;

    background: $backgroundColorPrimary;
    bottom: 0;
    max-width: 100%;
    overflow: auto;
    right: 0;
    top: 0;
    z-index: $zIndexDrawer;
}

.drawer-padded {
    padding: $spacingLarge;
}

.header-padded {
    margin-bottom: $spacingLarge;
    padding: $spacingLarge;
}

.drawer-content-enter,
.drawer-content-leave-active {
    transform: translateX(100%);
}

.drawer-overlay {
    z-index: $zIndexDrawer - 10;

    @include modalOverlay;
}

.drawer-overlay-enter,
.drawer-overlay-leave-active {
    opacity: 0;
}

.drawer-header {
    align-content: flex-end;
    display: flex;
    height: 80px;

    & > h2 {
        height: fit-content;
        margin-bottom: 0;
        position: relative;
        top: 12px;
    }
}
