
@use "client/css/globals" as *;

.hyperlink {
    color: $pilotPurple;
}

.header {
    font-weight: normal;
    text-align: center;
}

.icon-lock {
    // Match color of BaseButton text
    fill: currentColor;
    margin-right: 5px;
}

.button-icon {
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.additional-margin {
    margin-bottom: 40px;
}
