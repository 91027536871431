
@use "client/css/globals" as *;

$modalBorderRadius: $borderRadiusLarge;
$modalPaddingTop: $spacingMedium;
$modalPaddingSide: $spacingMediumLarge;

.dialog {
    // Override some default styling
    // This also represents the ending state of the transition from open to close.
    border: none;
    border-radius: $modalBorderRadius;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
    color: $contentColorPrimary;
    opacity: 0;
    padding: 0;
    transform: scale(1.02);

    /* Note: Transitions can appear differently in different browsers. Chrome works.
    Firefox does not yet have full support for the backdrop transition or transitioning
    to the closed state. */

    transition: all $modalAppearDuration allow-discrete;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0);
        transition: all $modalAppearDuration allow-discrete;
    }

    // This represents the open state of the dialog
    &[open] {
        // This represents the ending state for the transition from close to open.
        opacity: 1;
        transform: scale(1);

        &::backdrop {
            background-color: rgba(0, 0, 0, 0.5);
        }

        // Vetur warning about `Unknown at rule @starting-style` can be ignored.
        @starting-style {
            // This represents the starting state for the transition from close to open.
            opacity: 0;
            transform: scale(1.02);

            &::backdrop {
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}

.modal {
    background: $backgroundColorSecondary;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    flex-direction: column;

    &.border {
        border-bottom: 1px solid $pastelViolet;
    }

    .modal-header-text {
        align-items: center;
        background: $backgroundColorPrimary;
        display: flex;
        gap: $spacingSmall;
        justify-content: space-between;
        padding: $modalPaddingTop $modalPaddingSide;

        h1,
        h2,
        h3,
        h4 {
            // override default header margins to retain centered
            margin: 0;
        }

        &.no-padding {
            padding: 0;
        }
    }

    .custom-close-button {
        // Prevent the component from shrinking when combined with a wrapping header
        flex-shrink: 0;

        :hover {
            cursor: pointer;
        }
    }

    .base-close-button {
        // Prevent the component from shrinking when combined with a wrapping header
        flex-shrink: 0;
    }

    .modal-progress-bar {
        width: 100%;
    }
}

.modal-body {
    display: flex;
    flex-direction: column;

    .modal-body-header,
    .modal-body-footer {
        display: flex;
        flex-direction: column;
        padding: $modalPaddingTop $modalPaddingSide;

        &.no-padding {
            padding: 0;
        }
    }

    .modal-body-content {
        display: flex;
        flex-direction: column;
        min-height: 0;
        overflow: auto;
        overscroll-behavior: none;
        padding: $modalPaddingTop $modalPaddingSide;

        &.no-padding {
            padding: 0;
        }
    }
}

.modal-footer {
    display: grid;
    gap: $spacingSmall;
    grid-template-columns: auto auto;
    padding: $modalPaddingTop $modalPaddingSide;

    &.no-padding {
        padding: 0;
    }

    &.highlight {
        background: $backgroundColorTertiary;
    }

    &.border {
        border-top: 1px solid $pastelViolet;
    }

    .left-align {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
    }

    .right-align {
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
    }
}
