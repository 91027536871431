
#customer-tool-payroll {
    .date-input {
        margin-right: 5px;
        width: unset;
    }

    .parameters {
        margin-bottom: 30px;
    }
}
