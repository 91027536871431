
@use "client/css/globals" as *;

.link {
    color: $linkColorSecondary;
    text-decoration: underline;
}

.input-details {
    margin-top: 30px;

    p:first-child {
        margin-bottom: 0;
    }
}

.list {
    padding-left: $spacingMediumLarge;
}

.pin-input {
    display: flex;
}

.clear-pin-button {
    margin-top: 75px;
}
