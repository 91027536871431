@use "globals" as *;

a,
a:visited,
a:hover,
a:active {
    color: $linkColorPrimary;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.blue-link {
    color: #15c;
}

h1 {
    font-size: $fontSizeLarger;
    font-weight: bold;
    line-height: 1.07;
    margin: 0 0 $spacingMedium;
}

h2 {
    font-size: $fontSizeLarge;
    font-weight: bold;
    line-height: 1.05;
    margin: 0 0 $spacingMedium;
}

h3 {
    font-size: $fontSizeMedium;
    font-weight: bold;
    line-height: 1.05;
    margin: 0 0 $spacingSmall;
}

h4 {
    line-height: 1.06;
    margin: 0 0 $spacingSmall;
}

.font-size-small {
    font-size: $fontSizeSmall;
}

.font-size-base {
    font-size: $fontSizeBase;
}

.font-size-medium {
    font-size: $fontSizeMedium;
}

.font-size-large {
    font-size: $fontSizeLarge;
}

.font-size-larger {
    font-size: $fontSizeLarger;
}

.font-size-huge {
    font-size: $fontSizeHuge;
}

hr {
    border: 0 solid $borderColorLight;
    border-top-width: 1px;
    margin: $spacingMedium 0;
}

p {
    margin: 0 0 $spacingMedium;
}

b,
strong {
    font-weight: bold;
}
