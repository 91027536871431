
@use "client/css/globals" as *;

.customer-portal-link-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: $spacingLarge;

    a {
        color: $brandColorPrimary;
    }

    img {
        margin-right: $spacingSmall;
    }
}
