
.chart-container {
    height: 262px;
    padding: 20px 30px;
}

.months-multiselect {
    width: 160px;
}

.legend-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.icon-ellipse {
    margin-right: 5px;
}

.legend-key {
    display: inline-flex;
    gap: 20px;
}
