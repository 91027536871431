
@use "client/css/globals" as *;

table {
    margin: 0;
}

tbody {
    display: block;
    height: auto;
    overflow: auto;
}

thead,
tbody > tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

tr {
    border-color: $pastelViolet;
}

tbody > tr {
    border: 0;
    line-height: 26px;

    &:nth-child(-n + 5) {
        border-bottom: 1px solid $pastelViolet;
    }

    &:nth-child(n + 7) {
        border-top: 1px solid $pastelViolet;
    }
}

.table-wrapper {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
}

.first-column {
    padding: 15px 10px 15px 30px;
    width: 20%;
}

.inner-column {
    padding: 15px 30px 15px 0;
    width: 20%;
}
