
@use "client/css/globals" as *;

// This can't be in a style block with module because that changes the name of the
// class of the rendered style + component. GatedRouterLink requires an exact class
// name. We must use !important due to the unspecific selector
.active-portal-page {
    border-bottom-color: $brandColorPrimary !important;
    color: $brandColorPrimary !important;
}

.navigation {
    // TODO: Make the separator match the spec
    border-bottom: 1px solid $borderColorLight;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        font-size: $fontSizeMedium;
        font-weight: bold;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .portal-link {
            border-bottom: 3px solid transparent; // color the border when active (un-scoped style block)
            display: block; // this is an <a> so it's inline by default and negates the padding
            font-size: $fontSizeBase;
            padding-bottom: 15px;
        }

        // TODO: maybe make this better
        a {
            color: inherit;

            &:hover {
                color: $brandColorPrimary;
                text-decoration: none;
            }
        }
    }
}

.content {
    margin-top: 30px;
}
