
@use "client/modules/portal-activity/css/portal-activity-chart";
@use "client/css/globals" as *;

// Need to override default hover states defined in portal-activity-chart.scss because
// bars are reversed in this chart
::v-deep .apexcharts-bar-series > .apexcharts-series[rel="1"] .apexcharts-bar-area:hover {
    fill: $green1;
}

::v-deep .apexcharts-bar-series > .apexcharts-series[rel="1"] path:last-of-type {
    fill: #d3e8ae;
    fill-opacity: 0.5;
}

::v-deep .apexcharts-bar-series > .apexcharts-series[rel="2"] .apexcharts-bar-area:hover {
    fill: $red1;
}

::v-deep .apexcharts-bar-series > .apexcharts-series[rel="2"] path:last-of-type {
    fill: #ffb7c1;
    fill-opacity: 0.5;
}
