
@use "client/css/globals" as *;

.information-header-container {
    align-items: center;
    border-radius: 0;
    display: grid;
    gap: 10px;
    grid-template-columns: auto 1fr auto;
    position: relative;

    .institution-img {
        align-items: center;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        width: 60px;

        &.default {
            border: 1px solid $borderColorLight;
            border-radius: 5px;
        }
    }

    .account-name {
        font-weight: bold;
    }

    .default-height {
        height: 60px;
    }
}
