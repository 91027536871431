
@use "client/css/globals" as *;

.product-list-container {
    border-right: 1px solid $borderColorLight;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .product-list-item {
        border-bottom: 1px solid $borderColorLight;
        font-size: $fontSizeBase;

        button {
            background: none;
            border: none;
            color: inherit;
            cursor: pointer;
            padding: 20px;
            text-align: left;
            width: 100%;
        }

        &.active {
            background-color: $pilotText;
            color: #fff;
            font-weight: 600;
        }

        &:not(.active):hover,
        &:not(.active):focus {
            background-color: $lightGrey;
        }
    }
}
