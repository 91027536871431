
.lightbox-container {
    cursor: pointer;

    .modal {
        img {
            max-height: 80vh;
            max-width: 90vw;
        }
    }
}
