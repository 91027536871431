
@use "client/css/globals" as *;

$snackbarWidth: 420px;
$snackbarBottom: 110px;

.snack-bar {
    bottom: $snackbarBottom;
    left: 50%;
    margin: 0;
    min-width: $snackbarWidth;
    padding: 0;
    position: fixed;
    transform: translateX(-50%);
    z-index: $zIndexToast; // fine for now

    &.right {
        left: auto;
        right: 0;
    }
}

$slideY: 20px;
$slideDuration: 0.6s;

.snack {
    align-items: center;
    background: $yellow1;
    border-radius: $borderRadiusLarge;
    box-shadow:
        0 0 1px rgba(101, 99, 99, 0.16),
        0 8px 16px rgba(62, 60, 61, 0.08);
    display: grid;
    grid-template:
        "icon message cta" auto
        / min-content auto auto;
    list-style-type: none;
    margin: $spacingMedium auto 0;
    padding: $spacingMedium;
    position: relative;
    transition: all $slideDuration;
    width: 100%;
    z-index: 2;

    &.snack-slide-enter,
    &.snack-slide-leave-to {
        opacity: 0;
    }

    &.snack-slide-enter {
        transform: translateY($slideY);
    }

    &.snack-slide-leave-to {
        transform: translateY(-$slideY);
    }

    &.snack-slide-leave-active {
        position: absolute;
        z-index: 1;
    }

    &.success,
    &.loading {
        background: $brandColorSecondary;
        color: $brandColorPrimaryContrast;
        text-align: inherit;
    }

    &.error {
        background: $red1;
        color: $brandColorPrimaryContrast;
    }

    &.info {
        background: $brandColorPrimaryDark;
        color: $brandColorPrimaryContrast;
    }

    .snack-bar-icon {
        grid-area: icon;
    }

    .snack-bar-text {
        grid-area: message;

        & ::v-deep p {
            margin: 0; // override global style on paragraphs that markdown generates
        }
    }

    .cta-button {
        color: $brandColorPrimaryContrast !important; // override base button text color
        font-weight: bold;
        grid-area: cta;
        justify-content: flex-start;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &.space-between {
        grid-template:
            "icon message cta" auto
            / min-content 1fr auto;
    }

    &.vertical {
        grid-template:
            "icon message" auto
            "icon cta" auto
            / min-content 1fr;
    }

    .loading-icon {
        margin-bottom: 8px;
        margin-right: 10px;
    }
}

.snack-bar-icon-check,
.snack-bar-icon-alert-warning {
    margin-right: 10px;
    vertical-align: text-bottom;
}
