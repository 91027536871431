
@use "client/css/globals" as *;

.automagical-rule-card {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    max-height: 200px;
    padding: $spacingMediumSmall;
    width: 100%;
}

.card-header {
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    justify-content: space-between;

    .card-header-text {
        align-items: center;
        display: inline-flex;
        margin-bottom: $spacingMediumSmall;

        h4 {
            margin: 0;
        }
    }

    .remove-button {
        margin-bottom: $spacingMediumSmall;
    }
}

.card-body {
    display: grid;
    grid-gap: $spacingSmaller;
    grid-template-columns: auto 1fr;
    width: 100%;

    .additional-conditions {
        grid-column-start: 2;
    }
}

.card-info {
    color: $contentColorSecondary;
    font-weight: bold;
}

.drafted-by-pilot-tooltip {
    width: 240px;
}

.icon.information {
    vertical-align: middle;
}

.highlight {
    background: $yellow1Background;
}
