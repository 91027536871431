
@use "client/css/globals" as *;

.admin-page-header {
    align-items: center;
    background: $backgroundColorPrimary;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr auto auto;
    height: $pageHeaderDefaultHeight;
    padding: 24px $spacingLarge 21px; // magic number
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: $zIndexNav + 1; // + 1 so TimeTracker dropdown is above secondary nav
}

h1 {
    margin: 0;
}

h1,
.time-tracker-container,
.header-popover {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
}

.header-popover,
.time-tracker-container {
    justify-content: flex-end;
}

.header-popover-content {
    min-width: 200px;
}
