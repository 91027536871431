
@use "client/css/globals" as *;

.search-header-card {
    background-color: $backgroundColorPrimary;
    border-bottom: 1px solid $backgroundColorTertiary;
    border-top: 1px solid $backgroundColorTertiary;
    padding: 15px 35px;

    .subheader {
        color: $contentColorSecondary;
        margin: 0;
    }

    .search-field {
        margin: 10px 0;
        width: 100%;
    }
}

.buttons {
    margin: 0;

    .search {
        flex: 1;
        margin-right: $spacingSmall;
    }

    .google {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        padding: 0;
        width: 40px;
    }
}

.search-results {
    padding: 20px 35px;
}

.results-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .results-header-text {
        margin: 0;
    }

    .no-results-action & {
        margin-bottom: $spacingSmaller;
    }
}

.refresh-results {
    float: right;

    .icon-information {
        vertical-align: middle;
    }
}

.refresh-results-tooltip {
    line-height: 1.5;
}

.no-results-tile {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    box-shadow:
        0 8px 16px rgba(62, 60, 61, 0.08),
        0 0 1px rgba(101, 99, 99, 0.16);
    padding: $spacingMediumSmall $spacingMediumLarge $spacingMediumLarge;
}

.no-results-action {
    background: $backgroundColorSecondary;
    border: none;
    color: $contentColorPrimary;
    display: flex;
    margin: 0 0 $spacingSmall;
    padding: $spacingSmall $spacingMediumSmall;
    text-align: left;
    width: 100%;

    &::after {
        color: $contentColorTertiary;
        content: "→";
        font-size: $fontSizeMedium;
        font-weight: bold;
        position: absolute;
        right: $spacingMediumSmall;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        background: $brandColorPrimaryDark;
        color: $brandColorPrimaryContrast;

        .help-text,
        &::after {
            color: $brandColorPrimaryContrast;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    .button-content {
        width: 100%;
    }
}

.help-text {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    margin-bottom: $spacingMediumSmall;

    .no-results-action & {
        margin-bottom: 0;
        width: 80%;
    }
}

.icon-information {
    vertical-align: bottom;
}

::v-deep .bubble {
    min-width: 270px;
}

// for screen readers: https://webaim.org/techniques/css/invisiblecontent/
.hidden-label-text {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}
