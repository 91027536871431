
@use "client/css/globals" as *;

::v-deep .drawer-content {
    padding: 0;
}

::v-deep .drawer-header {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 40px;
}

.drawer-header-text {
    line-height: 48px;
    top: 0;
}

.request-details-heading {
    font-size: 15px;
}

::v-deep .container-mayday {
    display: none;
}

.change-history-container {
    border-top: 1px solid $borderColorLight;
    padding: 25px 40px;
}

.status-reason-text {
    margin-bottom: 15px;

    & > p {
        margin-bottom: 0;
    }
}

.sub-heading {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .change-type {
        margin: 0;
        margin-right: 5px;
    }
}

.status-tag {
    align-items: center;
    border-radius: 20px;
    display: flex;
    font-size: $fontSizeSmall;
    justify-content: center;
    padding: 3px 10px;
    width: fit-content;

    &.accepted {
        background: $green1Background;
        color: $green1Dark;
    }

    &.rejected {
        background-color: $red1Background;
        color: $red1;
    }
}

.change-status-reasoning {
    padding: 5px 0;
}

.date-signature {
    color: $darkGrey;
    display: flex;
    font-size: $fontSizeSmall;

    .change-date-ops {
        margin: 0;
    }

    .ops-name {
        margin-left: 3px;
    }
}

.request-details-form {
    border: 1px solid $borderColorLight;
    border-radius: 4px;
    margin-bottom: 30px;
}

.current-details {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.current-entity-type,
.current-fiscal-year {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > p {
        margin-bottom: 0;
    }
}

.change-request {
    border-bottom: 1px solid $borderColorLight;
    padding: 20px;

    &:last-of-type {
        border-bottom: none;
    }
}

.request-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    & > p {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.tax-help-container {
    background-color: $lightGrey;
    border: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
