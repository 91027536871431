
@use "client/css/globals" as *;

.card {
    margin-bottom: 15px;
}

.modal-body {
    padding: 20px 30px;
}

.attachments-card-list {
    list-style-type: none;
    margin: 0;
    padding-left: 0;

    .expanded-close-button {
        margin-bottom: 15px;

        ::v-deep .remove-button {
            transform: scale(1.33);
        }
    }
}

.file-picker {
    // we need to add top: 0 to the invisible class to put the hidden
    // browse button at the top so that it doesn't create empty space
    // at the bottom of the modal with an unnecessary scrollbar
    ::v-deep .invisible {
        top: 0;
    }
}

ul {
    padding-left: 20px;
}

hr {
    margin: 10px 0;
}

.bold {
    font-weight: bold;
}

.upload-instructions-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.inspect-cabinet-img {
    flex-shrink: 0;
    width: 80px;
}
