
@use "client/css/globals" as *;

$radioButtonWidth: $spacingMediumLarge;
$transition: all 0.2s linear;

.expanding-option-card {
    border-radius: $borderRadiusSmall;
    box-shadow: 0 4px 14px rgba(66, 61, 113, 0.08);
    padding: $spacingMedium;
}

.radio-button-wrapper {
    display: flex;

    input {
        flex: 0 0 $radioButtonWidth;
    }
}

label {
    cursor: pointer;
    flex: 1 1 auto;
    transition: $transition;
}

.card-content {
    font-size: $fontSizeSmall;
    margin-left: $radioButtonWidth;
    max-height: 0;
    overflow: hidden;

    &.separator {
        border-top: 1px solid $backgroundColorTertiary;
        margin-top: $spacingMedium;
        padding-top: $spacingMedium;
    }

    &.expand {
        max-height: 500px;
        overflow: visible;
        padding: $spacingMedium 0 $spacingMediumSmall;
    }
}
