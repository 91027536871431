
@use "client/css/globals" as *;

rect:first-child {
    stroke: $contentColorSecondary;

    .red & {
        stroke: $red1;
    }
}

rect:not(:first-child) {
    fill: $contentColorSecondary;

    .red & {
        fill: $red1;
    }
}
