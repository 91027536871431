
@use "client/css/globals" as *;

.container {
    align-items: center;
    background: #fff8de;
    border: 2px solid $pilotTypeYellow;
    border-radius: 4px;
    display: flex;
    padding: 30px;

    &.vertical {
        align-items: flex-start;
        flex-direction: column;
    }

    .icon {
        height: 26px;
        margin-right: 10px;
        width: 26px;
    }

    > b {
        display: inline;
        margin: 0 5px;
    }

    .header {
        margin-bottom: 5px;
        margin-left: 0;
        margin-right: 0;
    }

    // for p in markdown
    ::v-deep p {
        margin-bottom: 0;
    }
}
