
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.categorization-card {
    background-color: $backgroundColorSecondary;
    width: 100%;

    .row {
        display: flex;

        .title {
            margin-right: 10px;
            width: 100px;
        }
    }
}
