
@use "client/css/globals" as *;

.activities {
    margin: $spacingMedium 0;
}

.no-transactions {
    background: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    padding: $spacingMedium;

    p {
        margin: 0;
    }
}

.date-picker-wrapper {
    width: 150px;
}
