
.loading-container {
    align-items: center;
    background: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    width: 100vw;
}
