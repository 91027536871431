
.connections-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
}

h2 {
    font-size: 18px;
    margin-bottom: 5px;
}
