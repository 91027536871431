
@use "client/css/globals" as *;

.zap-request {
    background: $backgroundColorSecondary;
    margin-bottom: $spacingLarge;
    padding: $spacingMedium;

    .field:last-child {
        margin-bottom: 0;
    }
}
