
@use "client/css/globals" as *;

/** Remove default li styles */
li {
    list-style-type: none;
}

/** Must specify .borderless in order to override base styles */
.external-link.borderless,
.footer-link {
    color: $contentColorSecondary;
    font-size: 13px;
    transition: all 0.3s;

    &:hover {
        color: $contentColorSecondary;
        text-decoration: underline;
        transition: all 0.3s;
    }
}

.parent-item-wrapper {
    position: relative; // for secondary nav positioning
}

.collapsed-link.borderless,
.collapsed-link {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 35px;
    justify-content: center;
    margin: 0;
    padding: 0 10px;
    transition: all 0.3s;
    width: 100%;

    &.active,
    &.focused,
    &:hover {
        background-color: $backgroundColorTertiary;
    }

    &:hover {
        transition: all 0.3s;
    }
}

.collapsed-icon {
    color: $contentColorSecondary;
    height: 16px;
    width: 16px;

    &.active {
        color: $brandColorPrimary;
    }
}

.collapsed-secondary-nav {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    box-shadow:
        0 8px 16px 0 rgba(62, 60, 61, 0.08),
        0 0 1px 0 rgba(101, 99, 99, 0.16);
    display: flex;
    flex-direction: column;
    left: 50px;
    position: absolute;
    text-align: left;
    top: 0;
    width: 175px;
    z-index: $zIndexFlyout;

    .collapsed-secondary-nav-item {
        color: $contentColorPrimary;
        font-size: 13px;
        padding: 8px 15px;
        text-decoration: none;
        transition: all 0.3s;
        width: 100%;

        &.active {
            background-color: $brandColorPrimary;
            color: $brandColorPrimaryContrast;

            &:hover {
                background-color: $brandColorPrimary;
                transition: all 0.3s;
            }
        }

        &:hover {
            background-color: $backgroundColorTertiary;
            transition: all 0.3s;
        }

        /** Make sure that the active/hover background aligns with the card border radius */
        &:first-of-type {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

.expanded-icon {
    color: $contentColorSecondary;
    height: 16px;
    width: 16px;

    &.active {
        color: $brandColorPrimary;
    }
}

.expanded-link {
    align-items: center;
    border-radius: 5px;
    color: $contentColorPrimary;
    display: flex;
    font-size: $fontSizeBase;
    gap: 10px;
    height: 35px;
    justify-content: start;
    padding: 0 10px;
    text-decoration: none;
    transition: all 0.3s;
    width: 100%;

    .expanded-link-title {
        flex: 1;
        text-align: left;
    }

    &.active,
    &.focused,
    &:hover {
        background-color: $backgroundColorTertiary;
    }

    &:hover {
        color: $contentColorPrimary;
        transition: all 0.3s;
    }

    &.active {
        color: $brandColorPrimary;
    }
}

.expanded-secondary-nav {
    display: flex;
    flex-direction: column;
    padding-left: 30px;

    .expanded-secondary-nav-item {
        border-radius: 5px;
        color: $contentColorPrimary;
        font-size: 15px;
        margin-top: 5px;
        padding: 6px 10px;
        text-decoration: none;
        transition: all 0.3s;
        width: 100%;

        &.active {
            background-color: $backgroundColorTertiary;
            color: $brandColorPrimary;
        }

        &:hover {
            background-color: $backgroundColorTertiary;
            transition: all 0.3s;
        }
    }
}

.chevron.borderless {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 22px;
    justify-content: center;
    transition: all 0.3s;
    width: 22px;

    &:hover {
        background-color: $brandColorPrimaryContrast;
        transition: all 0.3s;
    }
}

.expanded-link-badge {
    background-color: $brandColorPrimaryContrast;
    border: 1px solid $borderColorLight;
    border-radius: 16px;
    color: $contentColorPrimary;
    font-size: 12px;
    height: 21px;
    text-align: center;
    width: 35px;
}

.expanded-link-title {
    align-items: center;
    display: flex;
    flex: 1;
    text-align: left;
}

.new-text {
    font-weight: bold;
    margin-left: 1px;
}

.new-text-parent {
    font-weight: bold;
    margin-left: 5px;
}
