
@use "client/css/globals" as *;

#rules-audit-filters {
    margin-bottom: $spacingMedium;

    input {
        width: 180px;
    }
}

.rule-audit-metadata {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

.rule-audit-name {
    flex: 0 0 250px; // define "row cell" min widths using flex-basis. ideally should use <table> elements
    font-weight: bold;
}

.rule-audit-num-changes {
    color: $contentColorSecondary;
    flex: 0 0 85px;
    font-weight: bold;
}

.rule-ruleset {
    flex: 0 0 240px;
}

.rule-audit-state {
    flex: 1 1 auto;
}

.rule-audit .rule-changes .rule-audit-num-changes {
    margin-top: $spacingSmall;
}
