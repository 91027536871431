
@use "client/css/globals" as *;

$actionsWidth: 400px;

.validation-failure {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacingLarge;
    padding: $flowbotItemPadding 0;

    .validation-failure-actions {
        flex: 0 0 $actionsWidth;
        margin-top: -($spacingSmall);
        max-width: $actionsWidth;
        padding-left: $spacingMedium;

        .comments-wrapper {
            background: $backgroundColorSecondary;
            flex: 0 0 $actionsWidth;
            margin-bottom: $spacingSmall;
            margin-top: 0;
            max-width: $actionsWidth;
            padding: 0 $spacingSmall $spacingSmall;
            padding-top: 0;
        }
    }

    .fixup-succeeded {
        color: $contentColorTertiary;
    }

    .fixup-items {
        margin-bottom: $spacingSmall;
        margin-top: $spacingSmall;

        .list-fixup-items {
            .fixup-item {
                display: flex;
                justify-content: space-between;

                .fixup-item-column {
                    padding: $spacingSmall;
                }
            }
        }
    }
}

.table-fixup-item {
    margin-top: $spacingSmall;
}

.table-fixup-item-column-memo {
    overflow-wrap: break-word;
    width: 40%;
}

.table-fixup-item-column-type {
    width: 10%;
}

.table-fixup-item-column-amount {
    text-align: right;
    width: 10%;
}

.table-fixup-item-column-vendor {
    width: 15%;
}

.table-fixup-item-column-account {
    width: 15%;
}

.table-fixup-item-column-button {
    width: 15%;
}

.table-fixup-item-actions {
    width: 25%;
}

.failure-message {
    position: relative;
}
