
@use "client/css/globals" as *;

.header-text-container {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}

.text {
    line-height: 1.5;
}

.info-text {
    color: $contentColorSecondary;
}

.layout {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &.main-container {
        margin-right: 30px;
    }
}

p {
    margin: 0;
}

h2 {
    margin: 0;
}
