
@use "client/css/globals" as *;
@use "client/css/typography";
@use "client/modules/categorize/css/categorize";

.container {
    border: 0.5px solid $brightPurple;
    border-radius: 5px;
    box-shadow:
        0 0 3px #5f2ee5,
        0 2px 8px rgba(95, 46, 229, 0.1);
}

h2 {
    margin: 10px 0 30px 10px;
}

p {
    margin: 0 0 10px 10px;
}

.categorize-headers {
    margin-top: 20px;
}

.left-column {
    align-items: center;
    display: flex;
    margin: 0 0 15px 10px;
}

.right-column {
    display: flex;
    gap: 15px;
    margin: 0 10px 15px;
}

.cancel-button {
    margin-right: 0; // override margin of 20px
}
