
.date-pickers {
    align-items: last baseline; // align with the baseline of the inputs
    display: flex;
    gap: 10px;
}

.picker {
    flex-grow: 1;
    font-weight: bold;
}
