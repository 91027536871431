@use "client/css/globals" as *;

.possible-amortizables-table {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    width: 100%;

    thead,
    tbody {
        align-items: stretch;
        display: flex;
        flex-direction: column;
    }

    tr {
        display: flex;
        flex-direction: row;
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;
    }

    thead > tr {
        padding-bottom: $spacingSmall;
        padding-top: $spacingSmall;
    }
}

td,
th {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
}

.checkbox-column {
    width: 50px;
}

.date-column {
    width: 120px;
}

.vendor-column {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 20;
    width: 200px;
}

.amount-column {
    display: block;
    flex-grow: 5;
    text-align: right;
    width: 95px;
}

.account-column {
    flex-grow: 20;
    width: 128px;
}

.service-period-column {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 600px;
}

.period-header-text {
    padding-left: $spacingSmaller;
    padding-right: $spacingSmaller;
}

.menu-column {
    width: 60px;
}
