
.indiv-report {
    margin-bottom: 80px;
    margin-top: 40px;
}

.report-description {
    margin-bottom: 10px;
}

label {
    font-weight: bold;
}

.field {
    padding-top: 10px;
}
