
@use "client/css/globals" as *;
@use "client/css/spinners";

.gusto-banner-container {
    margin-top: $spacingMedium;
}

.gusto-banner {
    align-items: center;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: grid;
    grid-gap: $spacingSmall;
    grid-template-columns: 80px 1fr minmax(min-content, auto);
    min-height: 100px;
    padding: $spacingSmall $spacingMediumSmall $spacingSmall $spacingMedium;

    .recommendation-text {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
        margin-bottom: 0;
    }
}

.gusto-logo {
    height: 80px;
    width: 80px;
}

.gusto-recommendation {
    max-width: 355px;
    min-width: 245px;
}

.gusto-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}

.gusto-button,
.gusto-button:last-child {
    margin: 5px;
    max-height: 40px;
    width: 270px;
}

.account-created {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    &__check {
        margin-right: 5px;
    }
}
