
@use "client/css/globals" as *;

.bold-text {
    font-weight: bold;
}

.balance-explainer {
    align-items: center;
    display: flex;
    gap: 10px;

    .more-info {
        margin-top: 10px;
    }
}

ul {
    margin: 0 0 10px;
    padding: 0 20px;
}

::v-deep #portal-link > p > a {
    color: $pilotPurple;
}
