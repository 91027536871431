
@use "client/css/globals" as *;

.loading {
    color: $contentColorSecondary;
    padding: $spacingLarge;
    text-align: center;

    div {
        margin: 0 10px;
    }

    p {
        margin: $spacingMedium 0 0;
    }

    &.horizontal {
        display: inline-flex;
        flex-direction: row;
        padding: 0;

        p {
            margin-top: 5px;
        }

        &.flip {
            flex-direction: row-reverse;
        }
    }
}

.loading-error {
    background: $red1Background;
    border: 1px solid $red1;
    border-radius: 4px;
    color: $red1;
    padding: $spacingMedium;
    text-align: center;

    .view > & {
        padding: $spacingLarger;
    }

    p:last-child {
        margin-bottom: 0;
    }

    .loading-error-id {
        font-size: $fontSizeBase;
    }
}

.loading-overlay,
.loading-fit {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.loading-overlay {
    background: rgba(255, 255, 255, 0.5);
}

.loading-scope {
    min-height: 160px;
    position: relative;
}

.loading-text {
    margin-left: 10px;
}
