
@use "client/css/globals" as *;

.comment-thread {
    background-color: $backgroundColorSecondary;
    border-radius: 6px; // non-standard size requested by design
    font-size: $fontSizeSmall;
    list-style-type: none;
    padding: 0;
}

.user-comment {
    display: flex;
    padding: $spacingSmall;

    &:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.user-avatar {
    cursor: default;
    flex-shrink: 0; // item becomes a flex item; would otherwise shrink if squished
}

.comment-main {
    display: flex;
    flex-wrap: wrap; // author and date on one line; content to wrap to the next
    margin-left: $spacingSmaller;

    p {
        margin-bottom: 0;
    }
}

.comment-author {
    font-weight: bold;
}

.comment-date {
    color: $contentColorSecondary;
    cursor: help;
    margin-left: $spacingSmaller;
}

.comment-content {
    flex-basis: 100%; // make content fill container's full width; overflow to its own row
    margin-top: $spacingSmallest;

    > span {
        overflow-wrap: anywhere;
        white-space: pre-wrap;
    }
}

.comment-author,
.comment-date {
    line-height: 17px; // height of small UserAvatar; needed for vertical alignment
}
