
@use "client/css/globals" as *;

.panel-container {
    display: flex;
    overflow-y: auto;
    width: auto;
}

.hidden-horizontal-scroll {
    overflow-x: hidden;
}

.expand-collapse-btn {
    background: transparent;
    border: none;
    border-bottom: 1px solid $pastelViolet;
    cursor: pointer;
    line-height: 0;
    margin-bottom: 30px;
    padding: 30px 10px;

    &:hover ::v-deep path {
        fill: $pilotPurple;
        stroke: none;
    }
}

.navbar-container {
    background-color: $backgroundColorPrimary;
    display: flex;
    flex-direction: column;
    left: 0;
    position: sticky;
    top: 0;
    z-index: $zIndexBase;
}

.panel-border-right {
    border-right: 1px solid $pastelViolet;
}

.panel-content {
    min-width: 450px;
    width: 35vw;
}

.icon-button {
    margin-bottom: 30px;
    padding: 10px;
}

.panel-header {
    padding: 30px;
}

.customer-name {
    margin-right: 10px;
}

.icon-label {
    display: block;
    margin-top: 15px;
}

.collapse-expand-icon {
    height: 30px;
    width: 30px;
}
