
@use "client/css/globals" as *;

.preview {
    align-items: stretch;
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: $spacingMediumLarge;
    max-height: 500px;
    min-height: 360px;
    overflow-y: auto;

    &.preview--empty {
        justify-content: center;
    }

    ul {
        // override browser default of 40px to align bullets with text
        padding-left: 20px;
    }
}

.preview-header {
    align-self: center;
    margin: $spacingMedium;
    text-align: center;
}

.preview-section-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 35px;
}

.preview-failure-container {
    border-top: 1px solid $borderColorLight;
}

.suggestions {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusMedium;
}

.preview-section-icon {
    flex-shrink: 0;
    margin-right: 15px;
}

.preview-section-icon-sparkle {
    flex-shrink: 0;
    margin-right: 10px;
}

.preview-transactions-header {
    font-size: 15px;
    margin-bottom: 25px;
}

.suggestion-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: $spacingMedium;
}

.suggestion-contents {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.suggestion-title {
    margin-bottom: 0;
}

.suggestion-item {
    color: #656363;
    font-size: 15px;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

::v-deep p {
    margin-bottom: 0;
}
