
@use "client/css/globals" as *;

#add-customer-field {
    background: $green1Background;

    .multiselect__tags {
        background: transparent;
        border-color: $green1;
    }
}

#customer-flags {
    margin-top: $spacingMedium;

    .field > label {
        flex-basis: 12em;
    }
}
