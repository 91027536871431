
@use "client/css/globals" as *;

h3 {
    align-items: center;
    display: flex;
    font-size: $fontSizeBase;
    margin-bottom: 0;
    position: relative;

    .button,
    .button:hover,
    .button:focus {
        color: $contentColorPrimary;
    }

    &.bold {
        margin-bottom: $spacingMedium;

        .button {
            font-weight: bold;
        }
    }
}

.chevron {
    left: -26px;
    position: absolute;
}
