
@use "client/css/globals" as *;

.transactions-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

::v-deep .drawer-header {
    border-bottom: 1px solid $borderColorLight;
    height: auto;
}

::v-deep .header-padded {
    margin-bottom: 0;
    padding: 30px;
}

::v-deep h3 {
    margin-bottom: 0; // overrides default margin
}

::v-deep .drawer-exit-button.x.larger {
    height: 32px;
    width: 32px;

    &::before,
    &::after {
        background: $darkGrey;
        height: 20px;
        left: 15px;
        top: 6px;
    }
}
