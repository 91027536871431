
@use "client/css/globals" as *;

.tax-profile-summary {
    padding: 25px 30px;

    &.without-subtitle {
        display: flex;
        justify-content: space-between;

        .tax-profile-title {
            margin: 0;
        }
    }

    &.with-subtitle {
        display: grid;
        grid-template:
            "title      align-right" auto
            "subtitle   align-right" auto
            / auto 1fr;

        .tax-profile-title {
            align-items: center;
            display: flex;
            grid-area: title;
            margin: 0;
        }

        .tax-profile-subtitle {
            grid-area: subtitle;
            padding-top: 5px;

            &.subtitle-todo {
                color: $red1;
            }

            &.subtitle-complete {
                color: $green1;
            }
        }

        .tax-profile-align-right {
            align-items: center;
            display: flex;
            grid-area: align-right;
            justify-content: flex-end;
        }
    }
}

.last-edited {
    color: $contentColorTertiary;
    font-size: $fontSizeSmall;
    padding-right: 20px;
}
