
@use "client/css/globals" as *;

.capitalizable-import-date,
.capitalizable-import-life {
    width: 6em;
}

.capitalizable-import-amount {
    width: 7em;
}

.capitalizations-import-error {
    margin-top: $spacingMedium;
}
