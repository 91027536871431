
@use "client/css/globals" as *;

.important-notice-profile-agree-text {
    margin: $spacingMediumLarge 0;

    p {
        margin-bottom: $spacingMediumLarge;
    }
}
