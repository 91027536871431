
@use "client/css/globals" as *;

// yes, this looks ridiculous, but this is how you make an element only available for
// screen readers.
// https://webaim.org/techniques/css/invisiblecontent/
.hidden-label-text {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}

.field {
    margin-bottom: $spacingSmall;
}
