
@use "client/css/globals" as *;

ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.topic-header {
    font-size: $fontSizeSmall;
    justify-content: space-between;
    line-height: 1.5;
}

.topic-author {
    align-items: center;
    gap: 10px;
}

.topic-text {
    font-size: $fontSizeBase;
    line-height: 1.5;
    margin-top: 10px;
    padding-left: 35px;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    & ::v-deep p {
        margin: 0; // override global style
    }
}

.responses-container {
    display: grid;
    margin-top: 20px;
    row-gap: 20px;
}

.input-wrapper {
    margin-top: 20px;
    padding-left: 35px;
    width: 100%;
}
