
@use "client/css/globals" as *;

.tax-period-item {
    display: flex;
    justify-content: space-between;
    width: 320px;

    .year-end-month {
        color: $contentColorPrimary;
        font-size: $fontSizeBase;
    }

    .tax-period {
        color: $contentColorSecondary;
        font-size: $fontSizeSmall;
    }
}

.tax-period-select {
    width: auto;

    ::v-deep .multiselect,
    ::v-deep .multiselect__input,
    ::v-deep .multiselect__single {
        border: #1e1e1e;
        font-size: $fontSizeBase;
        font-weight: normal;
    }

    ::v-deep .multiselect__tags {
        background: transparent;
        font-weight: initial;
        margin-bottom: 0;
        padding-top: 10px;
    }

    ::v-deep .multiselect__option {
        padding: 10px 15px;
    }

    ::v-deep .multiselect__single {
        background: transparent;
        font-weight: normal;
        margin-bottom: 0;
        padding-left: 0;
    }
}
