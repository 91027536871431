
@use "client/css/globals" as *;

$snackBottom: -90px;

.product-content-container {
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.product-content-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    /*
        Will create a shared component in the future to handle the height
        of the header of the modal
    */
    min-height: 100px;
    padding: $spacingLarge $spacingLarge $spacingMedium $spacingLarge;

    h2 {
        color: $pilotText;
        font-size: $fontSizeLarger;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.product-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: $spacingLarge;
    padding-left: $spacingLarge;
    padding-right: $spacingLarge;

    &.contact-padding {
        padding-top: $spacingSmall;
    }

    .product-container {
        margin-bottom: 45px;
    }

    .product-title {
        font-size: $fontSizeMedium;
        font-weight: 600;
        margin-bottom: $spacingSmall;
    }

    .product-description {
        line-height: 22.5px;
    }

    .product-disabled {
        color: $pilotPurple;
        font-size: $fontSizeBase;
        font-weight: 600;
        margin-bottom: $spacingSmall;
    }

    .product-enabled {
        color: $linkColorPrimary;
        font-size: $fontSizeBase;
        font-weight: 600;
        margin-bottom: $spacingSmall;
    }
}

.inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.submit-notice {
    align-items: center;
    background-color: #f3f5fa;
    border-radius: $borderRadiusMedium;
    color: $pilotText;
    display: flex;
    margin-bottom: $spacingMedium;
    max-height: 44px;
    padding: 12.5px;

    ::v-deep & > svg {
        height: 16px;
        margin-right: $spacingSmall;
        width: 16px;
    }
}

::v-deep .snack-bar {
    bottom: $snackBottom;
}

// for transition and transition-group
.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s;
}

.slide-move {
    transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.slide-leave-active {
    position: absolute;
}
