
@use "client/css/globals" as *;

.residual-balance,
.labels {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 3rem;
    justify-content: space-between;
    padding: 0.5rem 30px;
}

.labels {
    font-weight: bold;
}

.residual-balance.active {
    background: $purpleSelectionBackground;
}
