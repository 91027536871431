
@use "client/css/globals" as *;

// Padding that child elements should add up to
$totalPadding: 15px;
$verticalPadding: 5px;

.issue-header {
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    color: $pilotText;
    display: flex;
    padding: $verticalPadding $totalPadding;
}

.header-right-align {
    align-items: center;
    display: flex;
    margin-left: auto;
}

.issue-saving {
    padding-bottom: $totalPadding - $verticalPadding;
    padding-top: $totalPadding - $verticalPadding;
    top: 0;
}

.edit-issue-overflow {
    padding-right: 5px;
}

.issue-content {
    padding: $totalPadding;
}

.issue-description {
    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    & ::v-deep p {
        margin: 0; // override global style
    }
}

.evidence {
    border-left: 5px solid $borderColorLight;
    color: $pilotPurple;
    padding-left: 15px;
}

// Needed for link to truncate with ellipses based on width of container
.responsive-link-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.evidence-link {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.resolve-by {
    color: $brightBlue;
    padding-bottom: 5px;
    padding-top: 5px;

    &.overdue {
        color: $red1;
    }
}

::v-deep .button.resolve-button {
    align-items: center;
    border-radius: 50%;
    display: flex;
    margin: 0;
    padding: 0; // overrides BaseButton compact padding

    &:hover,
    &:focus {
        path {
            stroke: $brandColorPrimaryContrast;
        }
    }
}
