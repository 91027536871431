
@use "client/css/globals" as *;

.status-checklist {
    background: #fff;
    width: 100%;

    ::v-deep .accordion-header {
        padding: 15px 10px;
    }
}

.status-modal-buttons {
    display: flex;
    justify-content: flex-end;
    max-width: 580px;
}

.confirm-button {
    width: 120px;
}
