
.table-header {
    display: flex;
    justify-content: space-between;
}

.vendor-count {
    font-weight: bold;
}

.last-updated-text {
    align-self: center;
}
