
@use "client/css/globals" as *;

fieldset {
    // reset global styles
    border: 0;
    margin: 0;
    padding: 0;
}

.field-label {
    font-weight: bold;
    margin-bottom: $spacingSmaller;
}
