
@use "client/css/globals" as *;

.checklist {
    align-items: center;
    background-color: transparent;
    border-radius: $borderRadiusMedium;
    cursor: pointer;
    display: inline-flex;
    font-size: $fontSizeLarge;
    font-weight: 600;
    justify-content: center;
    min-width: 125px;
    padding: 10px 15px;
    transition: all 0.1s;
    user-select: none;
}

.checklist-items {
    margin: 0;
    min-width: 428px;
    padding: 0;

    li {
        border: none;
        list-style-type: none;

        &:hover {
            background: $pastelUltramarine;
        }
    }

    .checklist-item {
        border-bottom: none;
        padding: 10px 15px;

        button {
            align-items: center;
            background-color: transparent;
            border: none;
            color: $offBlack;
            cursor: pointer;
            display: flex;
            font-weight: 600;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.chevron-container {
    align-items: center;
    display: flex;
    margin-left: 10px;
}
