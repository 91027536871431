
@use "client/css/globals" as *;

.update-footer {
    align-items: center;
    background: $backgroundColorPrimary;
    background: linear-gradient(180deg, #fff 0%, #fff 66.67%, #f3f5fa 100%);
    border-radius: 5px;
    bottom: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: row nowrap;
    height: 80px;
    justify-content: space-between;
    padding: 20px 30px;
    position: sticky;

    .change-count--multiple {
        font-weight: bold;
    }
}
