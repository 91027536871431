
@use "client/css/globals" as *;

.tile-markdown {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusMedium;
    font-size: $fontSizeBase;
    padding: $spacingMediumSmall;
}

::v-deep p {
    margin-bottom: 0;
}
