
@use "client/css/globals" as *;

.page {
    background-color: $backgroundColorSecondary;
    display: grid;
    gap: 30px; // consistent spacing between grid areas
    height: 100vh; // fit viewport
    overflow: hidden; // overflow should occur in the children
    padding: 20px 30px 30px; // non-uniform spacing used for visual balancing
    position: relative; // overlays should be relative to this
    width: 100vw; // fit viewport

    &.primary {
        grid-template:
            "home    header" min-content
            "sidebar content" minmax(0, 1fr) /
            min-content minmax(0, 1fr);

        .secondary-nav,
        .tertiary-nav {
            display: none;
        }
    }

    &.secondary {
        grid-template:
            "home    header" min-content
            "sidebar secondary" min-content
            "sidebar content" 1fr /
            min-content minmax(0, 1fr);

        .tertiary-nav {
            display: none;
        }
    }

    &.tertiary {
        grid-template:
            "home    header    header" min-content
            "sidebar secondary secondary" min-content
            "sidebar tertiary  content" 1fr /
            min-content min-content minmax(0, 1fr);
    }

    &.small-screen {
        gap: 15px;
        grid-template:
            "home" min-content
            "sidebar" min-content
            "header" min-content
            "sidebar" min-content
            "secondary" min-content
            "tertiary" min-content
            "content" 1fr /
            minmax(0, 1fr);
        padding: 15px;
    }
}

/** Individual elements are assigned to their grid area via their class, except the overlay */

.home {
    grid-area: home;
}

.sidebar {
    grid-area: sidebar;
    overflow-x: auto; // shouldn't have any overflow
    overflow-y: auto;

    /**
     * Scrollbars are inconsistent across browsers. Classic scrollbars are placed in a gutter and
     * will consume available space. Overlay scrollbars are placed over content and don't change the
     * container size; they're usually translucent.
     *
     * On my computer (Mac + Firefox), I have an accessibility feature turned on that always shows
     * the scrollbar if there is one. My browser is placing a translucent scrollbar over the thin
     * icon container, and my accessibility feature means that it's always visible.
     *
     * We keep the scrollbar gutter size stable so that the browser leaves a gutter for a scrollbar.
     * For users with transparent scrollbars, this will add a bit of room (the transparent bg of the
     * scroll gutter), but it's already next to a gutter and the delta is barely noticeable. On my
     * browser, it ensures that I can see the full nav bar even when my viewport height is small and
     * the sidebar needs to scroll.
     *
     * As of this this commit, this feature is unsupported by IE (which we don't support) and Safari
     * (which is very opinioned about its UI and might do something different with scrollbars and
     * accessibility features).
     *
     * https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
     */
    scrollbar-gutter: stable;
}

.header {
    grid-area: header;
}

.secondary-nav {
    grid-area: secondary;
    overflow-x: auto;
}

.tertiary-nav {
    grid-area: tertiary;
}

.content {
    grid-area: content;
    margin-bottom: -30px;
    margin-right: -30px;
    overflow: auto;
    padding-right: 30px;
}

.overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

/** Additional adjustments */

::v-deep .header h1 {
    margin: 0; // reset global styles for any h1 in the head
}

.home,
.header {
    align-self: center; // center align home (logo) and header elements
}

.logo {
    &.collapsed {
        transform: translateY(4px) scale(2); // scale and adjust logo image beyond container
        width: 25px; // limit the width of the logo
    }

    &:not(.collapsed) {
        transform: translateY(4px); // align logo baseline by adjusting for descenders
    }
}
