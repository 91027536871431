
@use "sass:color";
@use "client/css/globals" as *;

.display-report-cell-bubble .bubble {
    padding-top: $spacingMediumLarge;
    width: 540px;
}

.display-report-commentable {
    border-bottom: 1px solid $borderColorLight;
    margin-bottom: $spacingMedium;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.display-report-comentable-message {
    margin: 0 #{-($spacingMedium)} 0;
    padding: 0 $spacingMedium $spacingSmall;
}

.display-report-commentable-comments {
    background: $backgroundColorSecondary;
    border-radius: 5px;
    padding: $spacingSmall;
}

.display-report-cell-negative {
    color: $red1;
}

.display-report-cell-commentable {
    &:hover {
        background: rgba($backgroundDarkPrimary, 0.1);
        cursor: pointer;
    }
}

.display-report-cell-has-info {
    position: relative;

    .bubble-container {
        display: block;
    }

    &.display-report-cell-has-info-and-not-missing-comments {
        background: $green1Background;

        &:hover {
            background: color.adjust($green1Background, $lightness: -12%);
        }
    }

    &.display-report-cell-has-info-and-missing-comments {
        background: $brighterPurple;

        &:hover {
            background: $brightPurple;
        }
    }
}
