
.input-carat-icon {
    &.right {
        transform: rotate(-90deg);
    }

    &.up {
        transform: rotate(-180deg);
    }
}
