
@use "client/css/globals" as *;

.transaction-container {
    align-items: center;
    display: flex;
    width: 100%;
}

.vendor-logo {
    margin-right: 10px;
}

.mapping-arrow {
    margin-right: $spacingMedium;
}

.transaction-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}

.vendor-name {
    color: $offBlack;
    font-size: 15px;
    margin: 0;

    &.pending {
        color: $contentColorTertiary;
    }
}

.description-container {
    display: flex;
}

.transaction-description {
    color: $contentColorSecondary;
    font-size: 12px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 599px) {
        max-width: 120px;
    }

    @media (min-width: 600px) and (max-width: 900px) {
        max-width: 200px;
    }

    @media (min-width: 901px) {
        max-width: 250px;
    }
}
