
@use "client/css/globals" as *;

.label {
    font-weight: bold;
}

.textarea {
    border-radius: $borderRadiusSmall;
    font-weight: normal;
    margin-top: $spacingSmaller;
    resize: none;
    width: 100%;
}
