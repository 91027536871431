
@use "client/css/globals" as *;

.generate-customer-initiated-question-prompt {
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: space-between;
    padding: 16.5px 30px;

    .prompt-text {
        color: $offBlack;
        font-size: 15px;
    }
}
