
.tax-hover-bubble {
    // this component is a child of each field's heading
    // the heading receives bolding from .tax-label-text, so we reset it for the help text
    font-weight: initial;

    // the information cicle icon has unexpected vertical alignment
    // rather than modifying the SVG directly, we can apply alignment to the container
    height: 24px;

    // we add a negative left margin to reduce the much space between the icon and the text
    margin-left: -5px;

    // the bubble's caret wasn't aligned on the icon so we add left padding to better center it
    padding-left: 2px;

    // the information cicle icon has unexpected vertical alignment
    // rather than modifying the SVG directly, we can apply alignment to the container
    vertical-align: bottom;

    // remove the default spacing given globally to paragraphs (only if it's the last one)
    ::v-deep p:last-child {
        margin-bottom: 0;
    }
}
