
@use "client/css/globals" as *;

.checklist-comment {
    border-bottom: 1px solid $borderColorLight;
    list-style-type: none;
    margin: 0 0 $spacingSmall;
    padding: 0 0 $spacingSmall;
    transition: height 0.2s;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    &.checklist-comment-deleting {
        opacity: 0.5;
    }

    &.checklist-comments-enter,
    &.checklist-comments-leave-to {
        height: 0 !important; // Override the height set by v-slide
        overflow: hidden;
    }
}

.checklist-comment-previous {
    list-style-type: none;
    margin: 0 0 $spacingSmall;
    padding: 0 0 $spacingSmall;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    &.checklist-comment-deleting {
        opacity: 0.5;
    }

    &.checklist-comments-enter,
    &.checklist-comments-leave-to {
        height: 0 !important; // Override the height set by v-slide
        overflow: hidden;
    }
}

.checklist-comment-header-previous {
    color: $contentColorSecondary;
    display: flex;
    font-size: $fontSizeSmall;
    margin-bottom: $spacingSmaller;

    .checklist-comment-edit {
        margin-left: auto;
    }
}

.checklist-comment-content-previous {
    color: $contentColorSecondary;

    pre,
    code {
        white-space: pre-wrap;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.checklist-comment-view {
    flex-basis: 100%;
    overflow-x: hidden;
    position: relative;
    transition: all 0.2s;

    &.checklist-comments-enter,
    &.checklist-comments-leave-to {
        height: 0 !important; // Override the height set by v-slide
        margin-bottom: 0;
        margin-top: 0;
        overflow-y: hidden;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.checklist-comment-content {
    pre,
    code {
        white-space: pre-wrap;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.checklist-comment-header {
    display: flex;
    font-size: $fontSizeSmall;
    margin-bottom: $spacingSmaller;

    .checklist-comment-edit {
        margin-left: auto;
    }
}

.checklist-comment-action {
    color: $contentColorSecondary;
    cursor: pointer;
    margin-right: $spacingMediumSmall;

    &:hover {
        text-decoration: underline;
    }

    &:last-child {
        margin-right: 0;
    }
}

.checklist-comment-author {
    font-weight: bold;
    margin-right: $spacingSmaller;
}

.checklist-comment-date {
    color: $contentColorSecondary;
}

.checklist-comment-deleting-spinner {
    position: absolute;
    right: $spacingMediumSmall;
    top: $spacingMedium;
}
