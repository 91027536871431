
@use "sass:math";
@use "client/css/globals" as *;

$closeButtonSize: 14px; // copied from base-close-button.vue
$showButtonSize: 23px;

.clear-account-number-button {
    bottom: math.div($fieldValueHeight - $closeButtonSize, 2);
    position: absolute;
    right: math.div($fieldValueHeight - $closeButtonSize, 2);
}

.hide-account-number-button,
.show-account-number-button {
    bottom: math.div($fieldValueHeight - $showButtonSize, 2);
    cursor: pointer;
    position: absolute;
    right: math.div($fieldValueHeight - $showButtonSize, 1);
}
