
@use "client/css/globals" as *;

::v-deep .drawer-content {
    background-color: $backgroundColorSecondary;
    padding: 0;
}

::v-deep .drawer-header {
    align-items: center;
    background-color: $backgroundColorPrimary;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 35px;
}

.drawer-header-text {
    line-height: 48px;
    top: 0;
}

::v-deep .container-mayday {
    top: 0;
}

.navigation-bar {
    background-color: $backgroundColorPrimary;
    padding: 0 35px;

    .drawer-tab {
        border-bottom: 2px solid transparent;
        border-radius: 0;
        color: $contentColorPrimary;
        font-size: 18px;
        font-weight: bold;
        margin-right: 30px;
        padding: 0 0 15px;

        &:last-of-type {
            margin-right: 0;
        }

        &.selected {
            border-bottom: 2px solid $brandColorPrimary;
            color: $brandColorPrimary;
        }
    }
}
