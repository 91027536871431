
@use "client/css/globals" as *;

$flagBorderWidth: 20px;

.development-flag {
    border: $flagBorderWidth solid transparent;
    box-sizing: border-box;
    display: block;
    height: $flagBorderWidth * 2;
    left: 0;
    position: absolute;
    top: 0;
    width: $flagBorderWidth * 2;

    &.show-flag,
    &:hover {
        border-left: $flagBorderWidth solid $pilotPurple;
        border-top: $flagBorderWidth solid $pilotPurple;
        z-index: 1;
    }

    .tooltip {
        display: block;
        height: $flagBorderWidth * 2;
        left: -$flagBorderWidth;
        position: absolute;
        top: -$flagBorderWidth;
        width: $flagBorderWidth * 2;

        .target {
            height: 100%;
            width: 100%;
        }
    }
}
