
@use "client/css/globals" as *;

.bubble-container {
    display: inline-block;
    position: relative;

    &:hover .bubble-target.bubble-target-fadeable {
        opacity: 1;
    }
}

.bubble-target {
    cursor: pointer;
    margin: -($spacingSmall);
    padding: $spacingSmall;

    &.bubble-target-fadeable {
        opacity: 0.5;
    }
}

.bubble-down {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: $contentColorPrimary;
    left: -($spacingMedium);
    margin-top: 7px;
    min-width: 340px;
    padding: $spacingMedium;
    position: absolute;
    text-align: left;
    top: 100%; // differs in bubble-up
    transition: all 0.12s;
    z-index: $zIndexFlyout;

    &.bubble-align-right,
    &.bubble-align-force-right {
        left: auto;
        right: -($spacingMedium);

        .bubble-caret {
            left: auto;
            right: $spacingMedium;
        }
    }

    .bubble-caret {
        left: 23px;
        position: absolute;
        top: -20px; // differs in bubble-up

        &::before,
        &::after {
            border-style: solid;
            border-width: 10px;
            content: "";
            display: block;
            height: 0;
            position: relative;
            width: 0;
        }

        &::before {
            border-color: transparent transparent $borderColorLight;
            top: -1px;
        }

        &::after {
            border-color: transparent transparent $backgroundColorPrimary;
            position: absolute;
            top: 0;
        }
    }
}

.bubble-up {
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: 5px;
    bottom: 100%; // differs in bubble-down
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: $contentColorPrimary;
    left: -($spacingMedium);
    margin-top: 7px;
    min-width: 340px;
    padding: $spacingMedium;
    position: absolute;
    text-align: left;
    transform: translateY(-10px); // differs in bubble-down
    transition: all 0.12s;
    z-index: $zIndexFlyout;

    &.bubble-align-right,
    &.bubble-align-force-right {
        left: auto;
        right: -($spacingMedium);

        .bubble-caret {
            left: auto;
            right: $spacingMedium;
        }
    }

    .bubble-caret {
        bottom: -20px; // differs in bubble-down
        left: 23px;
        position: absolute;
        transform: rotate(180deg); // differs in bubble-down

        &::before,
        &::after {
            border-style: solid;
            border-width: 10px;
            content: "";
            display: block;
            height: 0;
            position: relative;
            width: 0;
        }

        &::before {
            border-color: transparent transparent $borderColorLight;
            top: -1px;
        }

        &::after {
            border-color: transparent transparent $backgroundColorPrimary;
            position: absolute;
            top: 0;
        }
    }
}

.bubble-enter,
.bubble-leave-active {
    opacity: 0;

    &.bubble {
        transform: translateY(10px);
    }
}
