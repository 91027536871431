
@use "client/css/globals" as *;

.guide-card-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
            margin-bottom: 5px;
        }

        .text {
            margin-bottom: 10px;
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;
    }
}

.vendor-card-wrapper {
    .top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        .heading {
            margin-bottom: 0;
        }

        ::v-deep .base-button {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .icon {
                margin-right: 10px;
            }
        }
    }

    .no-vendors {
        align-items: center;
        display: flex;
        flex-direction: column;

        .icon {
            margin-bottom: 10px;
        }

        .text {
            color: $contentColorTertiary;
            margin-bottom: 0;
            text-align: center;
            width: 395px;
        }
    }

    $vendorRow: 66px;

    .vendor-list {
        list-style: none;
        margin: 0;
        max-height: $vendorRow * 10;
        overflow-y: scroll;
        padding: 0;

        .vendor-list-item {
            align-items: center;
            border-bottom: 1px solid $borderColorLighter;
            display: flex;
            height: $vendorRow;
            justify-content: space-between;
            padding: 20px 0;

            .left {
                align-items: center;
                display: flex;

                .name,
                .amount {
                    margin-bottom: 0;
                }

                .dot {
                    margin-bottom: 0;
                    padding: 0 5px;
                }
            }

            .right {
                align-items: center;
                display: flex;

                .tag-left {
                    .needs-input-tag {
                        background: #fff8de;
                        border-radius: 20px;
                        color: #7e6713;
                        font-size: 13px;
                        padding: 3px 10px;
                    }
                }

                .button-right {
                    margin-left: 20px;
                }
            }
        }
    }
}

.submit-card-wrapper {
    .heading {
        margin-bottom: 30px;
        text-align: center;
    }

    .text {
        margin-bottom: 30px;
    }
}

.notification-container {
    align-items: center;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 30px;
    padding: 12px 20px;

    .notification-content {
        line-height: 22.5px;

        & > p {
            margin: 0;
        }

        .instructions {
            color: $contentColorSecondary;
            font-size: $fontSizeMicro;
        }
    }
}
