
@use "client/css/globals" as *;

.drawer-body {
    display: grid;
    grid-row-gap: $spacingMedium;
}

.checklist-opener {
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: space-between;
    margin: 0;
    padding: $spacingSmaller $spacingMediumLarge;

    &:hover ::v-deep path {
        fill: $brandColorPrimaryContrast;
    }
}

.date-picker-wrapper {
    position: relative;
}

#customer-status-due-date {
    padding-left: $spacingLarge;

    &.highlight {
        color: $red1;
    }
}

.calendar-icon {
    left: 12px; // magic number
    position: absolute;
    top: 12px; // magic number
}

#reason-for-delay {
    // Override external margin on PilotAccordion. Tracked by ZAP-8192.
    margin: 0;

    // Widen for long message, but not so wide that it causes horizontal scroll in the drawer.
    ::v-deep .tooltip-content {
        width: 220px;
    }
}

.drawer-input-field {
    .multiselect,
    input {
        width: 100%;
    }
}

.progress-bar-description {
    display: flex;
    justify-content: space-between;
}

.buttons .button {
    width: 140px;
}

.progress-bar-container {
    margin-top: $spacingSmall;
}
