
@use "client/css/globals" as *;

.notification {
    background: $backgroundColorSecondary;
    border: 1px solid $yellow1;
    margin: $spacingMediumLarge 0 0;
    padding: $spacingMediumLarge;

    &.success {
        background: $green1Background;
    }

    &.warning {
        background: $yellow1Background;
    }

    &.error {
        background: $red1Background;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
