
@use "client/css/globals" as *;

.buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: right;
}

.changes-unsaved {
    background-color: $green1Background;
}

.email-error {
    align-items: center;
    display: flex;
    grid-column-start: 1;
    grid-row-start: 2;
}

.error-outline {
    border: 1px solid $red1Light;
}

.error-message {
    font-size: $fontSizeMicro;
    margin: 0;
    padding: 0;
}

.disabled-text {
    color: $darkGrey;
}

.no-margin {
    margin: 0;
}

.changes {
    margin-block-start: 0;
    padding-inline-start: 20px;
}
