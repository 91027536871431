
@use "client/css/globals" as *;

// CSS below was mostly, then reworked into nested styles
.pretty-logo-container {
    align-items: center;
    display: flex;
    height: 80px;
    padding-bottom: 20px;
    position: relative;
    width: 110px;
}

.circular-icon {
    border: 2px solid $borderColorLight;
    border-radius: 50%;
    box-sizing: border-box;
    height: 60px;
    position: absolute;
    width: 60px;

    &.pilot-logo {
        left: 0;
        z-index: 1;
    }

    &.right-logo {
        background-color: white;
        right: 5px;
        z-index: 2;

        &.institution-logo {
            object-fit: cover;
            overflow: hidden; /* Ensures content doesn't spill outside the circle */
        }
    }
}

.circular-gray-icon {
    align-items: center;
    background-color: $borderColorLight;
    border-radius: 50%;
    display: flex;
    height: 46px;
    justify-content: center;
    overflow: hidden;
    width: 46px;
}

// CSS under this comment is not generated by AI

.why-sync-item {
    align-items: center;
    display: grid;
    gap: 15px;
    grid-template-columns: 46px 1fr;
}

.separated-row {
    padding: 20px 30px;

    > div:not(:first-child) {
        padding-top: 10px;
    }

    > div:not(.pretty-logo-container, :last-child) {
        padding-bottom: 10px;
    }
}
