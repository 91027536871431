
@use "client/css/globals" as *;

.header-container {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 40px 1fr;

    .institution-img {
        align-items: center;
        border-radius: 5px;
        justify-content: center;
        max-height: 40px;
        width: 40px;

        &.border {
            background-color: $backgroundColorPrimary;
            border: 1px solid $borderColorLight;
        }
    }

    .name-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .name {
            margin: 0;
        }
    }
}
