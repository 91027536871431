
@use "client/css/globals" as *;

$powerButtonRed: #e1393f;
$animation: all 0.2s ease-in-out;
$timeTrackerWidth: 385px;

#time-tracker {
    $timeTrackerBaseDimension: 16px;

    align-items: center;
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $timeTrackerBaseDimension * 2;
    box-sizing: border-box;
    display: flex;
    height: $timeTrackerBaseDimension * 4;
    padding: $timeTrackerBaseDimension;
    padding-right: $timeTrackerBaseDimension * 2;
    position: relative;
    width: $timeTrackerWidth;

    .power-button {
        $powerButtonDiameter: $timeTrackerBaseDimension * 2.25;

        align-items: center;
        background: $backgroundDarkPrimary;
        border-radius: 100%;
        display: flex;
        height: $powerButtonDiameter;
        justify-content: center;
        transition: $animation;
        width: $powerButtonDiameter;

        &.on {
            background: $powerButtonRed;
        }

        &.disabled {
            background: $darkGrey;
        }

        .spinner {
            top: 0;
            transform: scale(0.5);
        }
    }

    .time-tracker-title {
        flex: 1 1 auto;
        padding-left: 10px;

        .time-tracker-client-name {
            color: $contentColorSecondary;
            font-size: $fontSizeSmall;
        }
    }

    .loading-dots {
        padding-left: 115px;
    }
}
