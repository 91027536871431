
@use "client/css/globals" as *;

tbody tr {
    cursor: pointer;

    &:hover {
        &,
        & > td {
            background: $yellow1Background;
        }
    }
}

$vendorStatusWidth: 6em;

.vendor-status-header {
    background: $backgroundColorPrimary;
    left: 0;
    position: sticky;
    width: $vendorStatusWidth;
    z-index: $zIndexBase + 3;
}

.vendor-name-cell,
.vendor-name-header {
    background: $backgroundColorPrimary;
    left: $vendorStatusWidth;
    position: sticky;
    width: 12em;
    z-index: $zIndexBase + 3;
}

.vendor-name-cell,
.vendor-name-cell::before {
    z-index: $zIndexBase + 1;
}

.month-header {
    background: $backgroundColorPrimary;
    width: 7em;
    z-index: $zIndexBase + 2;

    &:last-child {
        width: 8em; // Extra padding on last column
    }
}

.vendor-status-cell {
    background: $backgroundColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
    left: 0;
    position: sticky;
    text-transform: capitalize;
    z-index: $zIndexBase + 1;
}

.vendor-status-new {
    color: $brandColorPrimary;
}

.vendor-status-missing {
    color: $red1;
}

td:nth-last-child(2) {
    border-left: 1px dotted $borderColorLight;
    border-right: 1px dotted $borderColorLight;
}

$outsetShadowWidth: 5px;

.vendor-name-cell::before,
.vendor-name-header::before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: -#{$outsetShadowWidth - 1}; // This is one less than the width to allow text to disappear behind it.
    top: 0;
    width: $outsetShadowWidth;
}

th:nth-last-child(2) {
    background: $backgroundTonalGrey;
    border-left: 1px dotted $borderColorLight;
    border-right: 1px dotted $borderColorLight;
}

td:nth-last-child(1) {
    color: $contentColorTertiary;
}
