
@use "client/css/globals" as *;

p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
}

.sign-in {
    color: $brandColorPrimary;
}

.registration-wrapper {
    padding-bottom: 80px;
}

.company-display-name {
    font-weight: bold;
}

.join-title {
    margin: 0;
}
