
@use "client/css/globals" as *;

.tick-and-tie-manage {
    border-bottom: 1px solid $borderColorLight;
    margin-bottom: $spacingLarge;
}

.overflow-header {
    width: 60px;
}

thead tr {
    border-bottom-color: $borderColorLight;
}

th:first-of-type {
    font-size: $fontSizeMedium;
}
