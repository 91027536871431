
.split {
    display: grid;
    grid-template:
        "left-column right-column" auto
        / auto auto;
    justify-content: space-between;

    // to stack the columns when the screen is narrow
    @media (max-width: 640px) {
        grid-template:
            "left-column" auto
            "right-column" auto
            / 1fr;
    }

    /*
		The outer container pushes each column's content to the furthest
        point possible on its side, leaving the maximum amount of space-between.

		Each slot has a wrapper to center all its children on the y axis.
	*/
    .left-slot {
        grid-area: left-column;
    }

    .right-slot {
        grid-area: right-column;
    }

    .left-slot,
    .right-slot {
        align-items: center;
        display: grid;
        grid-auto-flow: column;

        &.default {
            column-gap: 10px;
        }

        &.normal {
            column-gap: 20px;
        }

        &.extended {
            column-gap: 30px;
        }
    }
}
