
.checklist-section-toggl {
    cursor: pointer;
    opacity: 0.75;
    position: relative;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }

    svg {
        width: 24px;
    }

    .spinner {
        left: 1px;
        position: absolute;
        top: 1px;
    }

    .tooltip-message {
        white-space: nowrap;
    }
}

.checklist-section-toggle-tooltip {
    position: relative;
    top: 6px;
}

.checklist-section-toggle-disabled {
    cursor: default;
    opacity: 0.4;

    &:hover {
        opacity: 0.4;
    }
}
