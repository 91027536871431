
@use "client/css/globals" as *;

::v-deep .pilot-table-row.expired-text {
    color: $darkGrey;
}

.users-section {
    margin-bottom: 30px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.users-heading {
    align-items: center;
    border-bottom: 1px solid $borderColorLighter;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;

    h2 {
        font-size: $fontSizeBase;
        margin: 0;
    }
}
