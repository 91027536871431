
@use "client/css/globals" as *;

.first-column {
    padding: 15px 10px 15px 30px;
    width: 20%;
}

.inner-column {
    padding: 15px 30px 15px 0;
    width: 20%;
}

.status-tag {
    align-items: center;
    border-radius: 20px;
    display: flex;
    font-size: $fontSizeSmall;
    justify-content: center;
    padding: 3px 10px;
    width: fit-content;

    &.accepted {
        background: $green1Background;
        color: $green1Dark;
    }

    &.rejected {
        background-color: $red1Background;
        color: $red1;
    }
}
