
@use "client/css/globals" as *;

.institution-img {
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 40px;

    &.default {
        border: 1px solid $borderColorLight;
        border-radius: 5px;
    }
}

::v-deep .pilot-table-header-cell {
    &.first {
        padding: 0;
    }

    &.last {
        padding: 0;
    }
}
