
@use "client/css/globals" as *;

.faq-link {
    color: $pilotPurple;
    cursor: pointer;
}

.faq-list {
    padding-left: 25px;
}

.bold-text {
    font-weight: bold;
}

.underlined {
    text-decoration: underline;
    text-decoration-skip-ink: none;
}
