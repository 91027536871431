
.account-settings {
    margin-top: 20px;
}

.account-name {
    padding: 15px 30px;
}

.card-title {
    padding: 15px 30px;
}

.month-picker {
    max-width: 200px;
}

.larger-tooltip-content {
    min-width: 475px;
    text-align: left;
}

.tt-content {
    min-width: 300px;
    text-align: left;

    ul {
        margin-bottom: 20px;
        padding-left: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.amortization-style {
    ::v-deep .label-row-container {
        align-items: flex-start;
    }
}

.amortization-accordion {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .accordion {
        max-width: 600px;
        width: 100%;
    }

    ::v-deep input[type="radio"] {
        width: auto;
    }

    ::v-deep .accordion-header label {
        font-weight: bold;
    }
}

.recognition-start-date-option {
    margin-bottom: 5px;
    margin-top: 5px;

    // The hanging indent here is compensating for the fact that the input is nested
    // under the label, to visually separate the text when it wraps. See note in the
    // template above.
    label {
        padding-left: 20px;
        text-indent: -20px;
    }

    input[type="radio"] {
        // We have generous left margin from the accordion; this lines up the radio
        // button with the above text.
        margin-left: 0;

        // Give the text to the right a little more room to breathe.
        margin-right: 5px;
    }
}

.day-of-month {
    max-height: 20px;
    max-width: 40px;
    padding: 0 0 0 5px;
}
