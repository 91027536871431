
@use "client/css/globals" as *;

.secondary {
    font-size: 13px;
}

.customer-info {
    margin: 0;
    padding: 0;
}

.customer-info-wrapper {
    padding: 20px 25px;
}

.info-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:not(:last-child) {
        margin-bottom: $spacingMedium;
    }

    .info-value {
        font-weight: bold;
    }

    .link {
        color: $pilotPurple;

        &:hover {
            color: $pilotText;
            text-decoration: none;
        }
    }
}
