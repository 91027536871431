
@use "client/css/globals" as *;

$atRiskIconSize: 32px;

.at-risk-component {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr max-content;
}

.at-risk-need-to-know,
.at-risk-label {
    font-weight: bold;
    padding-top: 10px;
}

.at-risk-title-message {
    font-weight: bold;
    height: $atRiskIconSize;

    // Vertical-align text with at-risk icon, which is in a different grid column
    line-height: $atRiskIconSize;
}

.at-risk-title {
    align-items: center;
    display: flex;

    // Add space between the "At Risk" title and "Need to know" sub-title
    margin-bottom: 10px;
}

.at-risk-icon {
    align-items: center;
    background-color: $red1Background;
    border-radius: 50%;
    display: flex;
    height: $atRiskIconSize;
    justify-content: center;
    width: $atRiskIconSize;

    &.not-at-risk {
        background-color: $lightGrey;
    }
}

#at-risk-tip-msg {
    padding-bottom: 10px;
    padding-top: 5px;
}

::v-deep .at-risk-toggle {
    padding-top: 5px;
}

.at-risk-last-reported-msg {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    padding-top: 10px;
}

::v-deep .tooltip-content {
    width: 255px;
}

.icon-info-circle {
    line-height: 0;
}

.at-risk-category {
    padding-bottom: 10px;
}

.at-risk-reason {
    padding-bottom: 10px;
}

.loading-dots {
    display: flex;
    justify-content: center;
}
