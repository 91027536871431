
@use "client/css/globals" as *;

.gusto-connection {
    .header {
        border-bottom: 1px solid $borderColorLight;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

.connection-row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    p {
        margin-bottom: 0;

        &.connected-since {
            font-size: $fontSizeMicro;
        }
    }
}
