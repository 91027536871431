
@use "client/css/globals" as *;

table {
    background: $pastelUltramarine; // So the spacer-row blends with the background of the page
}

// Allows the table to scroll further up the screen.
// This ensures that the bottom rows are more visible to the user.
.spacer-row {
    height: 30vh;
}

::v-deep .pilot-table-header-cell.sticky-header.medium {
    height: 50px;
    padding: 20px 10px 6px;
    z-index: 3; // allows the hover bubble to appear above the column header
}

// lock the upper leftmost cell at the top
::v-deep .pilot-table-header-cell.sticky-header.medium.first {
    background-color: $backgroundColorPrimary;
    left: 0;
    position: sticky;
    z-index: 4;
}

::v-deep .pilot-table-header-cell {
    justify-content: flex-end;
}

::v-deep .pilot-table-row-cell.medium {
    padding: 0;
}

.report-table {
    max-height: 100vh;
    overflow: auto;
    width: 100%;

    .column-header {
        background-color: $backgroundColorPrimary;

        // to lock the leftmost column
        left: 0;
        min-width: 280px;
        position: sticky;
        z-index: 1;
    }

    .column-header::after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: -8px;
        width: 7px;
    }

    .column-header.pilot-table-row-cell b {
        font-weight: normal;
    }

    td.pilot-table-row-cell.medium.first.column-header.report-summary {
        border-bottom: 3px double #9d9d9d;
        border-top: 1px solid $borderColorDark;
        font-weight: bold;
    }

    td.number {
        border-top: 1px solid $borderColorLight;
    }

    td.number.report-summary.report-toplevel {
        border-bottom: 3px double #9d9d9d;
        border-top: 1px solid $borderColorDark;
    }

    td.number.report-summary {
        border-bottom: 3px double #9d9d9d;
        border-top: 1px solid $borderColorDark;
        font-weight: bold;
    }

    @for $i from 1 through 10 {
        .report-indent-#{$i} {
            padding-left: #{$i * $spacingMedium};
        }
    }
}

.report-table.scrolled {
    ::v-deep .pilot-table-header-cell.sticky-header.medium {
        &::after {
            bottom: -1px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.15);
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            right: 0;
        }
    }
}
