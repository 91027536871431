
.allocator-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.external-link {
    line-height: 0;
}
