
@use "client/css/globals" as *;

.no-pointer-events {
    pointer-events: none;
}

.caret-container {
    margin-left: 25px;
}

.caret {
    display: inline-block;
    fill: currentColor; // keyword that represents current computed value of color property
    position: relative;
    top: 3px;
    transition: fill 0.1s; // to match base button transition time
}

.button-dropdown {
    align-items: center;
    display: inline-flex;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 10px;
}

// have to overwrite some unscoped PilotPopover styles that target HTML tags
.dropdown-items {
    margin: 0;
    padding: 0;

    > .dropdown-item {
        border: none;
        cursor: pointer;
        list-style-type: none;
        margin: 0;
        min-width: 230px;
        padding: 10px 15px;

        &:hover {
            background: $backgroundColorSecondary;
        }

        &:first-child {
            margin-top: 5px;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }
}
