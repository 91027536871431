
@use "sass:color";
@use "client/css/globals" as *;

.payroll-expert-table-row {
    td {
        padding-bottom: 15px;
        padding-top: 15px;
        vertical-align: top;

        &.overflow-button {
            text-align: right;
        }
    }

    input {
        &.highlighted {
            background-color: $yellow1Background;
        }

        &.success {
            background-color: color.adjust($green1Background, $lightness: -10%);
        }

        &.has-error {
            border-color: $red1Light;
        }
    }

    .conflict-description {
        margin-top: 5px;
    }

    .grid-container {
        align-items: center;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: auto 1fr;
    }

    .matching-descriptions {
        margin: 0;
    }
}
