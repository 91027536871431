
@use "client/css/globals" as *;

// hide the empty group label for the auto-fill option
::v-deep .multiselect__element:first-child {
    display: none;
}

// below this point is copied from narrowed-account-picker.vue
// TODO(JS-741) - extract into helper component
.group-label {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
}

// terrible hack: multiselect isn't applying the correct class to the group labels
// instead of multiselect__option--group, it applies both the --disabled and --selected classes
// when an option is selected, the --selected class is removed but the --disabled class stays
// we never expect to have disabled options in this component, so I am targeting them
// because they those elements are under control of VueMultiselect, I must use ::v-deep
::v-deep .multiselect__option--disabled,
::v-deep .multiselect__option--disabled.multiselect__option--selected {
    background: transparent;
}

// This is cursed - the ::placeholder pseudo-element doesn't let you hang an ::after on it,
// so to show the little "required" asterisk in red we have to manually position it.
// See .required class in _form.scss
// prettier-ignore-start

/* eslint-disable */

/* stylelint-disable */
.business-product-account-picker.needs-attention
    ::v-deep
    .multiselect__tags:has(.multiselect__input:placeholder-shown) {
    &::after {
        color: $red1;
        content: "*";
        display: inline-block;
        left: 130px;
        position: absolute;
        top: 6px;
    }
}

// prettier-ignore-end

/* stylelint-enable */

/* eslint-enable */
