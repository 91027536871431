
@use "client/css/globals" as *;

// Without this, the status will shift slightly on first hover 🤷
.icon-only {
    line-height: 0;
}

.extra-info {
    padding-top: 20px;
}

::v-deep .markdown {
    a {
        color: $pilotPurple;
        text-decoration: none;
    }
}
