
@use "client/css/globals" as *;
@use "client/css/table";
@use "client/components/css/mixins";

.pilot-table-header-cell {
    align-items: center;
    align-self: flex-end;
    background-color: $backgroundColorPrimary;
    color: $contentColorPrimary;
    display: flex;
    font-weight: 600;

    &.number {
        @include table.tabular-numbers;

        justify-content: flex-end;
    }

    > button {
        align-items: center;
        background: none;
        border: none;
        color: $contentColorPrimary;
        cursor: pointer;
        display: flex;
        font-weight: 600;
        padding: 0;
    }

    .sorting-arrow {
        opacity: 0;
        transition: opacity 0.2s ease-in;

        &.sorted {
            opacity: 1;
        }
    }

    &:hover .sorting-arrow {
        opacity: 1;
    }

    .parent-checkbox {
        top: 2px;
    }

    @include mixins.table-padding;
}

.sticky-header {
    position: -webkit-sticky; /* For the Safari browser */
    position: sticky;
    top: 0;
    z-index: 1;
}

.pilot-table-header-cell button {
    text-align: left;
}
