
@use "client/css/globals" as *;

$verticalPadding: $spacingLarge;
$horizontalSpacing: $verticalPadding;
$contentColumnPadding: $spacingLarge;
$secondaryNavBottomPadding: 3px;
$navIconWidth: 22px;
$navIconSpacing: 20px;

.admin-primary-nav {
    background: $backgroundColorSecondary;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    padding: $verticalPadding 0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: $expandedNavWidth;

    .primary-nav-link,
    .report-issue {
        align-items: center;
        color: $contentColorSecondary;
        display: flex;
        font-size: $fontSizeMedium;
        height: 1.5em; // magic number keep icons at same height on collapse
        margin: 0 0 $spacingMediumLarge;
        padding: 0 $horizontalSpacing;
        text-decoration: none;

        &:hover {
            color: $contentColorPrimary;
        }

        // third-party class
        &.router-link-active {
            color: $brandColorPrimary;

            svg {
                color: $brandColorPrimary;
            }
        }

        &.admin-nav-logo {
            height: 45px; // magic number keeps icons at same height on collapse
            margin: 0 0 $spacingLarge;
        }
    }

    .report-issue-icon {
        width: $navIconWidth;
    }

    .primary-nav-text,
    .report-issue-text {
        padding-left: $navIconSpacing;
    }

    .report-issue {
        border-top: 1px solid #e0e0e0;
        cursor: pointer;
        margin: auto $spacingLarge 0;
        padding: $spacingLarge 0 $spacingLarge;
    }
}

// in order to correctly override above styles, this should be defined after
@media screen and (max-width: $collapseNavBreakpoint) {
    .admin-primary-nav.collapsible-nav {
        width: $collapsedNavWidth;

        .primary-nav-link,
        .report-issue {
            justify-content: center;
            padding: 0;
            text-align: center;
        }

        .primary-nav-text,
        .report-issue-text {
            display: none;
        }

        .admin-nav-logo svg {
            transform: scale(0.6); // a little hacky, logo doesn't autoscale
        }

        .report-issue {
            border: 0;
            margin: auto 0 0;
        }
    }

    .admin-content-column {
        padding-left: $collapsedNavWidth;
    }

    .admin-page-header {
        left: $collapsedNavWidth;
    }
}
