
.container {
    display: grid;
    height: 100%;
    width: 100%;

    &.wide {
        .child {
            width: 100%;
        }
    }

    &.first-child-wide {
        .child:first-child {
            width: 100%;
        }
    }

    &.horizontal {
        grid-auto-flow: column;
        justify-content: space-between;
        width: 100%;

        .child {
            width: 100%;
        }

        &.start {
            align-items: start;
        }

        &.center {
            align-items: center;
        }

        &.end {
            align-items: end;
        }
    }

    &.vertical {
        align-content: space-between;
        grid-auto-flow: row;

        &.start {
            justify-items: start;
        }

        &.center {
            justify-items: center;
        }

        &.end {
            justify-items: end;
        }
    }

    &.fifteen {
        gap: 15px;
    }

    &.twenty-five {
        gap: 25px;
    }
}
