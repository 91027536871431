
@use "client/css/globals" as *;

$helpTextHeight: 20px;

.tick-and-tie-suggestion {
    background: $backgroundColorPrimary;
    border: solid 1px white;
    border-radius: $borderRadiusSmall;
    box-shadow:
        0 0 1px rgba(101, 99, 99, 0.16),
        0 8px 16px rgba(62, 60, 61, 0.08);
    padding: $spacingMedium;

    .buttons {
        align-items: center;
        flex-direction: column;
        height: 0;
        margin-top: 0;
        overflow: hidden;
        transition: all 0.2s;
    }

    &:hover {
        border-color: $brightPurple;
    }

    &:focus,
    &:focus-within {
        border-color: $brandColorPrimary;
        outline: none;

        .buttons {
            // account for button, 2 lines of text and spacing in between
            height: $defaultButtonHeight + $spacingSmall + (2 * $helpTextHeight);
            margin-top: $spacingMedium;
        }
    }
}

.section {
    padding: 0 $spacingSmall;
}

.confidence {
    font-size: $fontSizeSmall;
    font-weight: bold;
    margin-bottom: $spacingMediumSmall;

    &.high {
        color: $green1;
    }

    &.medium {
        color: $contentColorSecondary;
    }

    &.low {
        color: $red1;
    }
}

.checks {
    flex-direction: column;

    label {
        margin-bottom: $spacingSmaller;
    }

    .check {
        padding-bottom: $spacingSmallest;
    }

    .icon {
        margin-right: $spacingSmaller;
        vertical-align: text-top;
    }
}

.help-text {
    color: $brandColorPrimary;
    font-size: $fontSizeSmall;
    font-weight: bold;
    margin-bottom: $spacingSmall;
}
