
@use "client/css/globals" as *;

.card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

// based on global styling for h3 elements
.main-heading {
    font-size: $fontSizeMedium;
    font-weight: bold;
    line-height: 27px;
    margin: 0;
}

.secondary-heading {
    color: $contentColorTertiary;
}
