
@use "client/css/globals" as *;

input:invalid {
    border-color: $red1;
}

label {
    font-weight: bold;
    margin-bottom: $spacingSmall;
}

.field {
    margin-bottom: 30px;
}

.header {
    text-align: center;
}
