
.institution-img {
    border-radius: 5px;
    margin-right: 10px;
    width: 40px;
}

.card-info {
    padding: 25px;
}

.loading-container {
    display: flex;
    height: 82px;
    justify-content: center;
    padding: 30px 0;
}

.empty-state {
    padding: 30px;
    text-align: center;
}

h3.pilot-text {
    margin: 0;
}

.empty-text {
    padding-bottom: 5px;
}
