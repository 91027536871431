
@use "client/css/globals" as *;

.header-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    a {
        color: $pilotPurple;
    }
}

.document-list {
    margin-bottom: 20px;
}

.document-options {
    display: flex;
    flex-direction: column;

    .document-option {
        background: $brandColorPrimaryContrast;
        border: 1px solid $borderColorLight;
        border-bottom: none;
        display: flex;
        height: 44px;
        width: 100%;

        &:first-child {
            border-radius: 5px 5px 0 0;
        }

        &:last-child {
            border-bottom: 1px solid $borderColorLight;
            border-radius: 0 0 5px 5px;
        }

        .document-option-label {
            align-content: center;
            height: 100%;
            padding-left: 10px;
            width: 100%;

            .pilot-text {
                margin-left: 5px;
            }
        }
    }
}
