
@use "client/css/globals" as *;
@use "./tick-and-tie";

.tick-and-tie-matches-table-gutter-row {
    border-top: 1px solid $borderColorLight !important;
    height: $spacingMediumLarge;
}

.tick-and-tie-matches-table {
    thead > tr {
        border: none;
    }
}
