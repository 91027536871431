
@use "client/css/globals" as *;

.header-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

.amount-container {
    display: flex;
    justify-content: flex-start;
    padding: 20px 30px;
    position: relative;
}

.change-in-cash-container {
    border-left: 1px solid $backgroundColorTertiary;
    left: 50%;
    padding-left: 20px;
    position: absolute;
}

.title-container {
    display: flex;
}

.title,
.amount {
    font-weight: bold;
}

.tooltip-body,
.tooltip-info {
    font-size: $fontSizeBase;
    margin-bottom: 10px;
}

.tooltip-body ::v-deep a,
.content ::v-deep a {
    color: $brandColorPrimary;
}

.content {
    font-size: $fontSizeBase;
    font-weight: normal;
}

.tooltip-content {
    width: 300px;
}

.icon-info {
    margin-top: -3px;
    vertical-align: middle;
}

.months-multiselect {
    width: 160px;
}
