
@use "client/css/globals" as *;

.transaction-type {
    font-size: 12px;
    font-weight: 400;
}

.green {
    color: $green1;
}

.je-color {
    color: $contentColorSecondary;
}

.pilot-text-color {
    color: $pilotText;
}
