
/* unscoped in order to target otherwise impossible-to-target elements */
@use "client/css/globals" as *;

#monthly-trend-tooltip {
    background-color: #333;
    border-radius: $borderRadiusMedium;
    color: $brandColorPrimaryContrast;
    font-family: Euclid, sans-serif;
    font-size: 12px; // not in globals.scss
    font-style: normal;
    padding: 6px 10px 8px;
    pointer-events: none;
    position: absolute;
    text-transform: capitalize;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);

    &.above {
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }

    &.above::before {
        border-color: #333 transparent transparent;
        border-style: solid;
        border-width: 8px 8px 0;
        bottom: 1em;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 100%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: $zIndexFlyout;
    }

    table {
        margin-bottom: 0;
        width: unset;

        thead > tr {
            border-bottom: none;
        }

        th,
        td {
            padding: 0;
            text-align: center;
        }
    }
}
