
@use "client/css/globals" as *;

.customer-facing-disclaimer {
    color: $contentColorSecondary;
    margin-bottom: 20px;
}

hr {
    margin: 30px 0;
}

.checklist-opener {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    padding: 15px 24px;
    width: 100%;

    &:hover ::v-deep path {
        fill: $brandColorPrimaryContrast;
    }
}

// specify a parent class to increase specificity, so that the rules aren't
// overwritten by the fields css (specifically the margin values)
.onboarding-document {
    margin-top: $spacingSmall;

    .document-name {
        flex-grow: 1;
        margin: 0;
    }

    .document-name-input {
        width: 100%; // overriding global input style
    }

    .delete-button {
        margin-left: $spacingSmall;
    }
}

.add-button {
    margin-top: $spacingSmall;
}

.portal-link {
    margin-top: 30px;
}

.link-display-name {
    word-wrap: anywhere;
}

.dropbox-link {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
