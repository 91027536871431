
@use "client/css/globals" as *;

::v-deep .pilot-checkbox-container {
    margin-top: 3px;
}

.confirmation-wrapper {
    align-items: flex-start;
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    display: flex;
    margin: $spacingSmall 0;
    padding: 15px 12px;

    .pilot-checkbox {
        margin-right: $spacingSmall;
    }

    .confirmation-text {
        line-height: 1.4;
        margin: 0 0 0 10px;
    }
}

.approval-section {
    margin: $spacingSmall 0;
}

.approval-label {
    display: block;
    font-weight: bold;
    margin-bottom: $spacingSmall;
}

.approval-input {
    box-sizing: border-box;
    padding: $spacingSmall;
    width: 100%;
}
