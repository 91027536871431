
@use "client/css/globals" as *;

.categorize-vendors-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .text-tooltip-wrapper {
        align-items: center;
        display: flex;

        .header-text {
            margin: 0;
        }

        .questions-url {
            color: $contentColorPrimary;
            text-decoration: underline;
        }
    }

    .date-text {
        color: $contentColorSecondary;
    }

    .header-multiselects {
        align-items: flex-end;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 200px) auto;
        grid-template-rows: auto;
    }
}
