
@use "client/css/globals" as *;

::v-deep .report-header.card {
    border: none;
    border-bottom: 1px solid $pastelViolet;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.report-header {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;

    .header-container {
        align-items: center;
        display: flex;
        height: 24px;

        ::v-deep .bubble-container {
            height: 24px;
        }

        // add margin to the first paragraph in the bubble
        ::v-deep .bubble-down > p:first-of-type {
            margin-bottom: 20px;
        }

        h3 {
            margin: 0;
        }
    }
}
