
@use "client/css/globals" as *;

.contents {
    border-color: $borderColorLight;
    border-top-style: solid;
    border-width: thin;
    padding-left: 40px;
    padding-right: 40px;

    &:last-child {
        border-bottom-style: solid;
    }
}

.question-title {
    cursor: pointer;
    font-size: 15px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
}

.expanded-question {
    font-weight: 600;
}

.collapsed-question {
    font-weight: 400;
}

.chevron {
    margin-right: 20px;
}

.answer {
    color: $contentColorSecondary;
    margin-left: 36px;
}
