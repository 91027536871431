
@use "client/css/globals" as *;

.officer-field {
    display: flex;
    margin-bottom: $spacingMediumLarge;
    width: 100%;
}

.text-input-wrapper {
    flex-grow: 1;
}

.remove-button-wrapper {
    margin-left: $spacingSmall;
    margin-top: $spacingSmall;
    min-width: 15px;
}
