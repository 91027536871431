
@use "client/css/globals" as *;

#customer-tools {
    .buttons,
    .description {
        max-width: $fieldWidthBase;
        position: relative;
    }
}

.zapper {
    font-size: $fontSizeLarge;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: translateX(445px) translateY(10px) rotate(0deg);
    transition: all 5s linear;
}
