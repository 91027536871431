
@use "client/css/globals" as *;

.nav-list-mobile {
    margin-right: 50px;
}

/**
 * Use mixin and styles for drawers/modals
 */
.nav-content {
    @include modalContent;

    background: $backgroundColorPrimary;
    bottom: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    left: 0;
    max-width: 100%;
    top: 0;
    transition: all 0.3s;
    z-index: $zIndexDrawer;
}

/**
 * Use mixin and styles for drawers/modals
 */
.nav-overlay {
    @include modalOverlay;

    transition: all 0.3s;
    z-index: $zIndexDrawer - 10;
}

// Override default list styles
ul {
    margin: 0;
    padding: 0;
}

.nav-list-wrapper {
    background-color: $backgroundColorPrimary;
    border-right: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px 0 0;
    transition: all 0.3s;
    width: 250px;

    &.collapsed {
        transition: all 0.3s;
        width: 50px;
    }
}

.pilot-icon-link {
    margin-bottom: 20px;
    padding: 0 10px;
    width: fit-content;
}

.pilot-icon-wrapper {
    align-items: center;
    background-color: $pilotPurple;
    border-radius: 5px;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.nav-items-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px); // 65px is the height of the expand/collapse container
    overflow-x: hidden;
    overflow-y: auto;

    &.collapsed {
        overflow: initial;
    }
}

.primary-items {
    flex: 2;
    padding: 0 5px;
    transition: all 0.3s;
    width: 249px;

    .loading-item {
        margin-bottom: 5px;
    }

    li {
        margin-bottom: 5px;
    }

    li:last-of-type {
        margin: 0;
    }

    &.primary-nav-enter,
    &.primary-nav-leave-to {
        opacity: 0;
        transition: all 0.3s;
        width: 50px;
    }
}

.nav-list-mobile .nav-list-wrapper.collapsed .primary-items {
    width: inherit;
}

.footer-items {
    padding: 10px 15px;
    text-align: left;
    width: 249px;

    // Override button styles
    ::v-deep a.button {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    li {
        margin-bottom: 5px;
    }

    li:last-of-type {
        margin: 0;
    }

    &.footer-nav-enter,
    &.footer-nav-leave-to {
        opacity: 0;
        width: 0;
    }
}

.expand-collapse-wrapper {
    align-items: center;
    border-top: 1px solid $borderColorLight;
    display: flex;
    padding: 10px;

    .tooltip-container {
        align-items: center;
        display: flex;
        height: 20px;
        justify-content: center;
        width: 30px;

        button {
            margin: 0;
            padding: 5px;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: $backgroundColorTertiary;
                transition: background-color 0.3s ease-in-out;
            }
        }
    }
}
