
.header-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;

    a {
        align-items: center;
        display: flex;
    }
}

.user-actions {
    min-width: 200px;
}

.controls {
    align-items: center;
    display: flex;

    > *:not(:last-child) {
        margin-right: 20px;
    }
}
