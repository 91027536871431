
@use "client/css/globals" as *;

.details-container {
    border-bottom: 1px solid $borderColorLight;

    &:last-child {
        border-bottom: none;
    }

    .transaction-body {
        padding: 20px;
        width: 100%;

        .transaction-header {
            margin-bottom: 20px;

            .transaction-title {
                color: $contentColorSecondary;
                font-size: 13px;
                margin-bottom: 5px;
            }

            .transaction-amount {
                color: $offBlack;
                font-size: 24px;
                font-weight: bold;
                margin: 0;
            }
        }
    }

    .action-buttons {
        margin-top: 20px;
    }
}
