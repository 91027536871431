
.categorize-txn-admin-date-picker-container {
    align-items: flex-end;
    column-gap: 20px;
    display: flex;
    justify-content: flex-start;
}

.autocategorize-input {
    // to center align the input with the BaseButton
    padding: 5px 0;
}

::v-deep .base-button {
    margin-right: 0;
}
