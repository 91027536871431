
@use "client/css/globals" as *;

.notice-card {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto 1fr;

    &.bordered {
        border: 1px solid $borderColorLight;
    }
}

.notice-icon {
    align-items: center;
    display: flex;
    grid-column: 1;
    margin-left: 30px;
    margin-right: 30px;
}

.notice-text {
    display: flex;
    flex-direction: column;
    grid-column: 2;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

.notice-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.notice-content {
    font-size: 18px;
}
