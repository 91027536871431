
@use "client/css/globals" as *;

.tile {
    background: $backgroundColorPrimary;
    border-radius: $borderRadiusMedium;
    box-shadow:
        0 8px 16px rgba(62, 60, 61, 0.08),
        0 0 1px rgba(101, 99, 99, 0.16);
    display: none;
    margin-bottom: $spacingMedium;

    &:first-child {
        display: block;
    }

    &.show-all {
        display: block;
    }
}

.show-others {
    margin-bottom: $spacingMedium;
}
