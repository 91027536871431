
@use "client/css/globals" as *;

#bulk-action {
    margin-bottom: 30px;
}

#to-change {
    max-width: $fieldWidthBase;
}

.fancy-loading {
    margin-top: 150px;
}

.bulk-actions-button {
    margin-bottom: 25px;
    margin-right: 15px;
}

.search-results {
    margin-top: 30px;
}

.no-results {
    background: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    height: 50px;
    margin-top: 25px;
    padding: 15px 20px;
}

.post-action {
    margin-top: $spacingMedium;
}

.preview {
    align-items: center;
    background: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusMedium;
    display: flex;
    height: 360px;
    justify-content: center;
    margin-top: 30px;
    padding: 30px;

    &.preview-detail {
        align-items: flex-start;
        display: grid;
        grid-row-gap: 20px;
        grid-template-rows: 100%;
        height: 100%;
        justify-content: flex-start;
        padding: 35px;
    }
}

.preview-section-container {
    display: grid;
    grid-template-columns: 6% 94%;
}

.suggestions {
    background-color: $backgroundColorSecondary;
}

.preview-section-icon {
    margin-right: 15px;
}

.preview-transactions-header {
    font-size: 15px;
    margin-bottom: 25px;
}

.suggestion-container {
    display: grid;
    grid-template-columns: 5% 95%;
    grid-template-rows: 40px auto;
}

.suggestion-list {
    grid-column: 2 / 3;
}

.sparkle {
    align-self: center;
}

.suggestion-title {
    align-self: center;
    margin-bottom: 0;
}

.suggestion-item {
    color: #656363;
    font-size: 15px;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
