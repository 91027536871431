
@use "client/css/globals" as *;

.pilot-container {
    background-image: linear-gradient(
        $brandColorPrimaryDark,
        $brandColorPrimaryDark $pilotBackgroundTopHeight,
        $backgroundColorSecondary $pilotBackgroundTopHeight
    );
    height: 100%;
    min-height: 100vh;
}

.pilot-main {
    margin: auto;
    max-width: $pageWidthMax;
    padding: 0 40px;
}

.pilot-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    min-height: $nonAdminHeaderHeight;
    padding: 40px 20px 40px + 20px;
}

/* styles below are used in the nested view component */
.pilot-page-title {
    color: $brandColorPrimaryContrast;
    font-weight: normal;
    margin-bottom: 30px;
    min-height: $nonAdminTitleHeight;
    padding: 0 30px;

    .pilot-page-centered & {
        text-align: center;
    }
}

.pilot-page-content {
    background-color: $backgroundColorPrimary;
    border-radius: 5px;
    box-shadow: 0 4px 14px rgba(66, 61, 113, 0.08);
    padding: 30px;

    .buttons {
        margin-top: 30px;
    }

    .pilot-page-centered & {
        margin: 0 auto;
        max-width: 480px;
        padding: 40px;
    }
}

@media (max-width: 559px) {
    .pilot-main {
        padding: 0 20px;
    }

    .pilot-page-content {
        .buttons {
            flex-wrap: wrap;

            .button {
                margin: 0 0 10px;
            }
        }
    }
}
