
@use "client/css/globals" as *;

.message {
    ::v-deep .section-card-header {
        margin-bottom: 30px;
    }

    ::v-deep .text-container {
        margin: 10px 0 30px;
    }

    .tax-text {
        margin-bottom: 30px;

        &:last-of-type {
            margin: 0;
        }

        &.bold {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .vendor-list-card {
        border: 1px solid $borderColorLight;
        border-radius: 4px;
        margin-bottom: 30px;
        padding: 15px 25px;

        .vendor-list {
            margin: 0;
            padding-inline-start: 30px;
        }
    }
}
