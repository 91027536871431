
.rule-categorization-date {
    width: 12em;
}

.rule-categorization-amount {
    width: 7em;
}

.rule-categorization-rule {
    width: 12em;
}
