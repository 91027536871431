
.no-new-vendors {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 300px; // arbitrary value
    justify-content: center;

    .txn-group-header {
        font-weight: normal;
    }
}
