
@use "client/css/globals" as *;

p {
    margin: unset; // reset global
}

.empty-state {
    align-items: center;
    border: 1px solid $borderColorLight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    padding: 0 12.5%; // content width = 75% of container
    text-align: center;
}

.reason {
    font-size: $fontSizeMedium;
    font-weight: bold;
}

.explanation {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    margin-top: 0.5em;
}
