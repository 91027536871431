
@use "client/css/globals" as *;

.bank-data-fields {
    flex-grow: 1;
}

.bank-input {
    display: flex;
    width: 100%;
}

.remove-button-wrapper {
    margin-left: $spacingSmall;
    margin-top: $spacingSmall;
    min-width: $spacingMediumSmall;
}
