
$animation: all 0.2s ease-in-out;

.icon-chevron {
    cursor: pointer;
    transition: $animation;

    &.left {
        transform: rotate(90deg);
    }

    &.up {
        transform: rotate(-180deg);
    }

    &.right {
        transform: rotate(-90deg);
    }
}
