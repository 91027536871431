
@use "client/css/globals" as *;

.amount-input::placeholder {
    color: $offBlack;
    opacity: 1 !important;
}

.input {
    width: 100%;
}

.amount-input {
    width: 100%;
}

.caret-icon {
    position: absolute;
    right: 15px;
    top: 65%;
    transform: rotate(0);
    transition: transform 0.3s;
}

.content {
    position: absolute;
    width: 18rem;
}

.input-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.save-button {
    margin-bottom: 15px;
    margin-top: 15px;
}

.reset-button {
    display: block;
}

::v-deep .popover {
    margin-top: 5px;
    width: 100%;
}

.custom-amount-picker-container {
    width: 100%;
}

::v-deep .popover-target {
    width: 100%;
}
