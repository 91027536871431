
@use "client/css/globals" as *;

.pilot-text {
    &.align-icon {
        // Using grid because flex causes icons to shrink when the text wraps
        align-items: center;
        display: grid;
        grid-auto-columns: minmax(auto, max-content);
        grid-auto-flow: column;
    }

    &.primary {
        color: $contentColorPrimary;
    }

    &.secondary {
        color: $contentColorSecondary;
    }

    &.tertiary {
        color: $contentColorTertiary;
    }

    &.urgent {
        color: $urgentOrange;
    }

    &.error {
        color: $red1;
    }

    &.highlight {
        color: $pilotPurple;
    }

    &.darkBgContrast {
        color: $brandColorPrimaryContrast;
    }

    &.green {
        color: $green1;
    }

    &.darkGrey {
        color: $darkGrey;
    }

    &.micro {
        font-size: $fontSizeMicro;
    }

    &.small {
        font-size: $fontSizeSmall;
    }

    &.base {
        font-size: $fontSizeBase;
    }

    &.medium {
        font-size: $fontSizeMedium;
    }

    &.large {
        font-size: $fontSizeLarge;
    }

    &.larger {
        font-size: $fontSizeLarger;
    }

    &.huge {
        font-size: $fontSizeHuge;
    }

    a {
        color: $brandColorPrimary;
    }
}

p.pilot-text,
h2.pilot-text {
    margin: 0;
}
