
@use "client/css/globals" as *;

table {
    margin: 0;
    table-layout: auto;
}

thead > tr,
tbody > tr {
    border-color: $borderColorLight;
}

td,
th {
    padding-bottom: 15px;
    padding-top: 15px;
}

td:first-child,
th:first-child {
    padding-left: 30px;
}

td:last-child,
th:last-child {
    padding-right: 30px;
}
