
@use "client/css/globals" as *;

.circle-button {
    background: transparent;
    border: 2px solid $brandColorPrimary;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 32px;
    text-align: center;
    transition: background 0.2s;
    width: 32px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background: $brandColorPrimary;
        outline: none;

        ::v-deep path {
            stroke: $brandColorPrimaryContrast;
        }
    }

    &:active {
        background: $pilotText;
        border-color: $pilotText;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    svg {
        left: 50%;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.spinner {
    left: 50%;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
