
@use "client/css/globals" as *;

.date-container {
    align-items: center;
    display: flex;
    display: inline-block;
    justify-content: space-between;
    padding: 25px;
    vertical-align: middle;
}

.next-delivery-date {
    font-size: $fontSizeBase;
    font-weight: bold;
}

.books-due-business-day {
    align-items: center;
    color: $contentColorSecondary;
    display: flex;
    font-size: $fontSizeSmall;
    height: 24px;
}

.date-and-icon {
    align-items: center;
    display: flex;
}

::v-deep .bubble-target {
    height: 24px;
    margin: 0;
    padding: 0;
}

.delivery-title {
    font-weight: bold;
}

.delivery-info {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}
