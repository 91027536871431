
@use "client/css/globals" as *;

table {
    margin: 0;
    width: 779px;
}

tbody {
    display: block;
    height: 341px;
    overflow: auto;
}

thead,
tbody > tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

tr {
    border-color: $pastelViolet;
}

tbody > tr {
    border: 0;
    line-height: 26px;

    &:nth-child(-n + 5) {
        border-bottom: 1px solid $pastelViolet;
    }

    &:nth-child(n + 7) {
        border-top: 1px solid $pastelViolet;
    }
}

.table-wrapper {
    background-color: $backgroundColorPrimary;
    border-radius: $borderRadiusLarge;
}

.cell-filing {
    padding: 15px 10px 15px 30px;
}

.cell-status {
    padding: 15px 30px 15px 0;
}

.status-tag {
    align-items: center;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    width: fit-content;
}

.status-tag.processing {
    background: $pastelViolet;
    color: $pilotText;
}

.status-tag.complete {
    background: $green1Background;
    color: $green1Dark;
}

.no-states {
    color: $contentColorSecondary;
    padding: 30px;
    text-align: center;

    p:last-child {
        margin: 0;
    }
}

.no-states-text {
    color: $contentColorPrimary;
    font-weight: bold;
    margin-bottom: 5px;
}

.no-states-link {
    color: $pilotPurple;
    text-decoration: none;

    &:hover {
        color: $pilotText;
    }
}

.btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
}
