
@use "client/css/globals" as *;

.expander-border {
    padding-top: 50px;
}

.widget-display-report-horizontal-content {
    // A height is needed for top row to freeze while scrolling. 60vh approximately has the widget take up the full screen accounting for the trouser header/ footer, app top nav, etc. If the dimensions of these change, we should revisit this height setting.
    height: 60vh;
    overflow-x: auto;
}

.widget-display-report-horizontal-content table td:first-child {
    background-color: $backgroundColorPrimary;
    left: 0;
    position: sticky;
    z-index: $zIndexFlyout + 1;
}

.widget-display-report-table {
    margin: 0 auto;
    width: auto;
}

.widget-display-report-column {
    // non-transparent background so that table contents don't overlap with header when scrolling
    background-color: $backgroundColorPrimary;
    border-top: 1px solid $offBlack;
    min-width: 8.5em;
    position: sticky;
    top: 0;

    // pilot-bubble, which renders the popup comments, has an index of $zIndexFlyout. Frozen top row should show above comment bubbles.
    z-index: $zIndexFlyout + 1;

    &.overflow {
        min-width: unset;
    }
}

.widget-display-report-table .number:first-child {
    // Lock the upper leftmost cell at the top
    left: 0;
    position: sticky;

    // z-index is used so the other cells scroll underneath
    z-index: $zIndexFlyout + 2;
}

// Override global default
thead > tr {
    border-bottom: none;
}

.widget-display-report-column::after {
    background: $borderColorDark;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
}

.widget-display-report-summary {
    border-top: 1px solid $borderColorDark;
    font-weight: bold;
}

.widget-display-report-toplevel.widget-display-report-summary {
    border-bottom: 3px double $offBlack;
}

.widget-display-report-table .widget-display-report-account-link {
    color: $contentColorPrimary;
}

// widget footer should be above frozen top row, leftmost column ($zIndexFlyout + 1), and comment bubbles ($zIndexFlyout)
::v-deep .expander-cuff {
    z-index: $zIndexFlyout + 1;
}

@for $i from 1 through 10 {
    .widget-display-report-indent-#{$i} {
        padding-left: #{$i * $spacingMedium};
    }
}
