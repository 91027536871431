
.title {
    padding: 0;
}

.modal-body-header {
    align-items: center;
    display: flex;
    padding-bottom: 5px;
}

.close-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
