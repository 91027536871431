
.supporting-schedules-table {
    margin-bottom: 43px;
    margin-top: 43px;
}

.schedule-error-balance {
    width: 140px;
}

.discrepancies-memo {
    width: 500px;
}

td.discrepancies-status {
    color: red;
    font-weight: 600;
}

.flex-container {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    gap: 10px 20px;
    margin-top: 10px;

    .buttons {
        height: min-content;
    }

    > * {
        margin: 0;
    }

    .error-notification {
        flex-basis: 100%;
    }
}

.error-message-break {
    flex-basis: 100%;
    height: 0;
}
