
@use "client/css/globals" as *;

.notification-banner {
    padding: $spacingLarge;

    p {
        color: $contentColorSecondary;
    }
}
