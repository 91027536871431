
.expandable-icon-wrapper {
    align-items: center;
    align-self: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
}
