
.add-connection-container {
    display: grid;
    gap: 30px;
    grid-template-columns: 5fr 3fr;

    .full-width {
        grid-column: span 2;
    }

    .institution-url {
        // Static width is required for ellipsis overflow to work
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 400px;
    }

    ::v-deep p {
        word-wrap: anywhere;
    }
}
