
@use "client/css/globals" as *;

.container {
    align-items: center;
    display: flex;

    p {
        margin: 0; // reset global style
    }
}

.tooltip {
    display: none;

    .container:hover & {
        display: initial;
    }
}

.name {
    font-size: $fontSizeMedium;
    font-weight: bold;
}

.category {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
}

.logo {
    flex: 0 0 32px;
    margin-right: 15px;
}
