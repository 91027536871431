
@use "client/css/globals" as *;

.drop-area {
    align-items: center;
    background: $backgroundColorPrimary;
    border: 1px dashed $borderColorLight !important;
    border-radius: $borderRadiusLarge;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 10px;
    text-align: center;
    transition: all 0.2s ease;

    * {
        pointer-events: none;
    }

    &:hover {
        border-color: $brandColorPrimary !important;
    }

    &.dragging {
        background: rgba($brandColorPrimary, 0.05);
        border-color: $brandColorPrimary !important;
    }
}
