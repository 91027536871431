
@use "client/css/globals" as *;

#customer-requests-pre-ship {
    h2 {
        margin-bottom: $spacingSmall;
    }

    section {
        margin-bottom: $spacingLarge;
    }

    .header-secondary {
        color: $contentColorSecondary;
        font-size: $fontSizeBase;
        font-weight: normal;
    }

    .notification-banner {
        padding: $spacingLarge;

        p {
            color: $contentColorSecondary;
        }
    }

    .apply-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .header-text-container {
        display: flex;
        flex-direction: column;
    }

    .header-sub-text {
        color: $contentColorSecondary;
    }
}

.layout {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
