
@use "client/css/globals" as *;

.text {
    margin-bottom: 30px;
}

.button .spinner {
    display: flex;
    justify-content: center;

    // Override global absolute positioning to center spinner in button
    position: static;
    transform: unset;
}

::v-deep .loading {
    // Make the loading text more visible against dark background
    color: $brandColorPrimaryContrast;
}
