
@use "client/css/globals" as *;

.institution-link {
    align-items: center;
    display: flex;
}

a {
    color: $brandColorPrimary;
}

.institution-img {
    border-radius: 5px;
    height: 30px;
    margin-right: 10px;
    width: 30px;
}

.right-align-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

ul {
    padding-left: 0;
}
