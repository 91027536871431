
.down {
    transform: rotate(90deg);
}

.left {
    transform: rotate(180deg);
}

.up {
    transform: rotate(270deg);
}
