
.question-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.relative-creation-date.show-date:hover {
    text-decoration: underline;
}
