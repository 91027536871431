
@use "client/css/globals" as *;
@use "client/css/table";
@use "client/modules/customer-transactions/css/transaction-tables";

.category-info {
    align-items: center;
    display: flex;
}

.vendor-group-header {
    display: flex;
    justify-content: space-between;
}

.change-category {
    background: transparent;
    border: 0;
    color: $pilotPurple;
    cursor: pointer;
}

.txn-table {
    background-color: $backgroundColorPrimary;
    border: 1px solid $borderColorLight;
    border-radius: $borderRadiusSmall;
    margin-top: $spacingMedium;
    padding: $spacingMediumSmall 0 $spacingMedium;
}

.txn-row {
    display: flex;
    padding: $spacingMedium 0;

    // remove leading spacing of first row
    &:first-of-type {
        padding-top: 0;
    }

    // remove trailing spacing of last row
    &:last-of-type {
        padding-bottom: 0;
    }

    // starting with the second row, separate rows with a line
    &:nth-of-type(n + 2) {
        border-top: 1px solid $borderColorLight;
    }
}

.cell-header {
    color: $contentColorSecondary;
    font-weight: bold;

    .txn-row:first-of-type & {
        border-bottom: 1px solid $borderColorLight;
        margin-bottom: $spacingMedium;
        padding-bottom: $spacingMediumSmall;
    }

    .txn-row:not(:first-of-type) & {
        display: none;
    }
}

.cell-header,
.cell-content {
    padding: 0 $spacingSmall;

    .txn-cell:first-of-type & {
        padding-left: $spacingMedium;
    }

    .txn-cell:last-of-type & {
        padding-right: $spacingMedium;
    }
}

.txn-date,
.txn-type,
.txn-account,
.txn-amount {
    flex-basis: 130px;
    flex-grow: 0;
    flex-shrink: 0;
}

.txn-amount {
    @include table.tabular-numbers;
}

.txn-memo {
    flex-grow: 1;
}

.warning-icon {
    margin-right: $spacingMediumSmall;
    vertical-align: middle;
}

.amortize-warning-title {
    font-weight: bold;
}

.vendor-info {
    align-items: center;
    display: flex;
}

.vendor-logo {
    margin-right: 10px;
}

.pending-tag {
    margin-left: 5px;
}
