
.flex-container {
    display: flex;
    gap: 5px;
    margin-top: 0;
}

.margin-bottom {
    margin-bottom: 10px;
}

.dismiss-again-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.right-align-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.bold-text {
    font-weight: bold;
}

.container {
    min-width: 300px; // arbitrary value
}
