
@use "client/css/globals" as *;

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    max-width: 580px;
}

.confirm-button {
    width: 120px;
}

.dirty-warning {
    background: $yellow1Background;
    border: 1px solid $pilotTypeYellow;
    border-radius: 5px;
    margin-top: 15px;
    padding: 25px;

    .warning-header {
        font-weight: 600;
        margin-bottom: 5px;
    }
}
