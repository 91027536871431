
@use "client/css/globals" as *;

.checklist-controls {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 15px;
    white-space: nowrap;
}

.drag-and-drop-toggle {
    display: inline-block;
    margin-right: 20px;
}

$transitionDuration: 0.3s;

.checklist-task-edit-overlay {
    transition: opacity $transitionDuration;

    &::before {
        background: rgba(255, 255, 255, 0.5);
        bottom: -($spacingMedium);
        content: "";
        display: block;
        left: -($pageMargin);
        position: absolute;
        right: -($pageMargin);
        top: 0;
        z-index: $zIndexBase + 1;
    }

    &.fade-enter,
    &.fade-leave-to {
        &::before {
            opacity: 0;
        }
    }
}

.checklist-collapsible {
    margin-bottom: $spacingLarger;

    &.checklist-collapsible-collapsed {
        margin-bottom: $spacingSmall;
    }
}

.checklist-section-title {
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-top: 15px;
    position: relative;
}
