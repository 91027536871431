
@use "client/css/globals" as *;

#reconcile-dates {
    h4 {
        margin: 0 0 $spacingSmaller;
    }

    .multiselect {
        width: 160px;
    }
}

.customer-reconcile-report {
    height: 90vh;
}

.error-difference {
    color: $red1;
}

.reconciliation-errors {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
}

a {
    color: $pilotPurple;
}

.autofix-button {
    white-space: nowrap;
}
