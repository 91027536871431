
@use "client/css/globals" as *;

.capitalizable-ready {
    background: $green1Background;
}

.capitalizable-date {
    width: 100px;
}

.capitalizable-memo {
    width: 250px;
}

.capitalizable-vendor {
    width: 120px;
}

.capitalizable-account {
    width: 200px;
}

.capitalizable-expense {
    width: 180px;
}

.capitalizable-amount {
    width: 100px;
}

.capitalizable-original-amount {
    width: 140px;
}

.capitalizable-translated-amount {
    width: 115px;
}
