
@use "client/css/globals" as *;

.emphasized {
    margin-top: 20px;
}

.underline {
    text-decoration: underline;
}

.tile-row {
    display: flex;

    .row-label {
        flex: 0 0 90px;
        font-weight: bold;
    }
}

// for vue-text-highlight
::v-deep .highlight {
    background: $yellow1Background;
    color: $linkColorPrimary;
}

.group-tile-action {
    align-content: center;
    display: flex;
    gap: 10px;
    padding: 15px 30px;
}

.apply-categorization-text {
    align-self: center;
    font-weight: bold;
    white-space: nowrap;
}

::v-deep .button.small {
    margin: 0;
}
