
@use "client/css/globals" as *;

.load-icon {
    color: $contentColorSecondary;
    font-size: $fontSizeSmall;
    text-align: center;
}

.small-size {
    width: 350px;
}

.spinner-x-small::after {
    border-color: $brandColorPrimary;
    border-left-color: transparent;
    border-right-color: transparent;
    border-width: 1px;
    height: 18px;
    margin-left: 15px;
    width: 18px;
}
