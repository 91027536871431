
// reset global table styles
table {
    border-collapse: unset;
    margin-bottom: unset;
    table-layout: unset;
    width: unset;
}

tr {
    border: unset;
}

td,
th {
    padding: unset;
    text-align: unset;
}
