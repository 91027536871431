
@use "client/css/globals" as *;
@use "client/modules/categorize/css/categorize";

.data-cards {
    max-width: categorize.$categorizeInputWidth;
    width: 100%;
}

.combined {
    background-color: $backgroundColorSecondary;
    border-radius: $borderRadiusLarge;
    display: grid;

    // remove the top border radius from the bottom card, so it appears as a single card
    ::v-deep .insights-card {
        border-radius: 0 0 $borderRadiusLarge $borderRadiusLarge;
        border-top: 1px solid $borderColorLight;
    }
}
