
@use "client/css/globals" as *;

.flex-container {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 15px 30px;

    &.buttons-container {
        flex-wrap: nowrap;
    }

    .left-text {
        margin-left: 15px;

        .header-text {
            font-weight: bold;
        }

        .inspect-cabinet-icon {
            height: 70px;
            width: 70px;
        }
    }

    // override the default margin-right of PilotTag
    ::v-deep .pilot-tag {
        margin-right: 0;
    }

    .download-button {
        font-weight: bold;
        margin-left: 30px;
        margin-right: 10px;
        transition: all 0.1s ease-in-out;
    }
}

.financial-reports-container {
    padding: 15px 30px;

    .last-updated-text {
        color: $contentColorPrimary;
        font-size: $fontSizeSmall;
        font-weight: bold;
    }

    .view-live-reports-link {
        color: $pilotPurple;
        font-size: $fontSizeSmall;
        text-decoration: none;
    }
}

::v-deep .markdown {
    .purple-text {
        color: $pilotPurple;
    }
}
