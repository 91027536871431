
@use "client/css/globals" as *;

.input-container {
    border: 1px solid $borderColorLight;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    transition: all 0.3s ease;
    width: 100%;
}

// A 1px border is necessary for the v-autosize-textarea directive to work properly
// L24 client/directives/form-directives
.text-input {
    // remove default styling
    appearance: none;
    border: 1px solid transparent;
    box-shadow: none;

    // input styling
    box-sizing: border-box;
    height: 20px;
    min-height: initial;
    min-width: 0;
    outline: none;
    overflow: auto;
    padding: 0;
    resize: none;
    width: 100%;
}

.active-input {
    // guarantee input box initial height, it looks like sometimes autosize-textarea completes for height styling and sets it to 21px
    height: 40px;
}

textarea::placeholder {
    color: $darkGrey;
}

.reply-button-container {
    display: flex;
    justify-content: flex-end;
}

.reply-button {
    padding: 3.5px 18px;
}

.loading-icon {
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
}

.attachment-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-right: 15px;
}

.uploaded-attachments {
    padding-bottom: 10px;
    padding-left: 10px;
}

.file-card {
    width: 350px;
}

.button-content {
    align-items: center;
    display: flex;
}
