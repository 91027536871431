
@use "sass:color";
@use "client/css/globals" as *;

$accountHorizontalPadding: 30px;
$accountVerticalPadding: 15px;
$accountHorizontalMargin: 30px;

table {
    table-layout: auto;
}

thead th,
td {
    padding: $accountVerticalPadding $accountHorizontalPadding;
}

th {
    white-space: nowrap;
}

tr {
    border-color: $pastelViolet;
}

#customer-account-mapping {
    display: grid;
    margin-right: $accountHorizontalMargin;
    row-gap: 30px;

    .tag {
        background: $brightPurple;
        cursor: move;
        font-size: $fontSizeSmall;

        .tag-action:hover {
            background: rgba(0, 0, 0, 0.2);
        }

        &.add {
            background: $green1Background;
            color: $green1Dark;
            cursor: pointer;
        }
    }

    .template-group-8de815da-902c-4938-80c5-d8f3c85d671f {
        // Standard
        background: $pastelViolet;
        color: $pilotText;
    }

    .template-group-324585b3-efb4-4fab-8195-fe1026e134d8 {
        // Gusto
        background: #edf9f7;
        color: $contentColorPrimary;
    }

    .template-group-d104d68d-38c9-4aad-827d-c6751f5442e2 {
        // Stripe
        background: #6672e5;
        color: #fff;
    }

    .template-group-ba85886c-814a-4d1d-8787-dd59f0ee48aa {
        // Expensify
        background: #00a5d5;
        color: #fff;
    }

    .template-group-4f84b95c-1eff-4b8b-bead-c38d10d8659a {
        // Abacus
        background: color.adjust(#3e8bd6, $lightness: 20%);
    }

    .template-group-eda6184d-15d8-430f-924c-beb3e2b4687b {
        // Zenefits
        background: #ff5745;
        color: #fff;
    }
}

#unmapped-categories-container {
    // Magic number here is to account for headers, title, and search bar
    max-height: calc(100vh - 275px);
    overflow-y: auto;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

#unmapped-categories-list {
    padding-left: 30px;
}

.qb-account {
    vertical-align: baseline;

    &:hover {
        background-color: $yellow1Background;
    }

    .tag.add {
        &:not(:first-child),
        &:not(:last-child) {
            display: none;
        }
    }
}

.qb-account-name {
    &.level-1 {
        padding-left: $spacingLarge;
    }

    &.level-2 {
        padding-left: $spacingLarge * 2;
    }

    &.level-3 {
        padding-left: $spacingLarge * 3;
    }
}

.qb-account-edit {
    width: 80px;
}

.class-toggle {
    width: 110px;
}

h4 {
    border-bottom: 1px solid $pastelViolet;

    // Override typography.scss
    line-height: 1.5;
    padding-left: 30px;
    padding-right: 30px;
    white-space: nowrap;
}

h4,
ul {
    padding-bottom: $accountVerticalPadding;
    padding-top: $accountVerticalPadding;
}

.filters {
    margin: 0;
}

.account-table-wrapper {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 25%;
}

// adjustments because Loading is an absolutely positioned element
.relative-wrapper {
    min-height: 200px;
    position: relative;
}
